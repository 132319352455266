//AUTHENTIFICATION
export const DEBUT_AUTH = 'DEBUT_AUTH';
export const DECONNEXION_AUTOMATIQUE = 'DECONNEXION_AUTOMATIQUE';
export const DECONNEXION = 'DECONNEXION';
export const DEMANDE_DE_REINITIALISATION_MDP_REUSSIE = 'DEMANDE_DE_REINITIALISATION_MDP_REUSSIE';
export const VALIDATION_CODE_VERIFICATION_REUSSIE = 'VALIDATION_CODE_VERIFICATION_REUSSIE';
export const REINITIALISATION_MDP_REUSSIE = 'REINITIALISATION_MDP_REUSSIE';
export const VALIDATION_CHANGEMENT_MOT_DE_PASSE = 'VALIDATION_CHANGEMENT_MOT_DE_PASSE';
export const COMMUTER_INTERFACE_CLIENT_ADMIN = 'COMMUTER_INTERFACE_CLIENT_ADMIN';
export const COMMUTER_INTERFACE_CLIENT = 'COMMUTER_INTERFACE_CLIENT';
export const COMMUTER_INTERFACE_ADMIN = 'COMMUTER_INTERFACE_ADMIN';
export const GERER_FILTRE_PAR_PROJET = 'GERER_FILTRE_PAR_PROJET';
export const ENREGISTRER_LISTE_PROJETS = 'ENREGISTRER_LISTE_PROJETS';
export const ENREGISTRER_LISTE_PROJETS_FAVORIS = 'ENREGISTRER_LISTE_PROJETS_FAVORIS';

//DICTIONNAIRE DE LANGUE
export const CHANGE_LANGUE = 'CHANGE_LANGUE';

//ALERTE
export const SUPPRIMER_ALERTE = 'SUPPRIMER_ALERTE';
export const AJOUTER_ALERTE = 'AJOUTER_ALERTE';

//UTILISATEUR
export const AJOUTER_UTILISATEUR = 'AJOUTER_UTILISATEUR';
export const ENREGISTRER_LISTE_UTILISATEURS = 'ENREGISTRER_LISTE_UTILISATEURS';

//CAPTEUR
export const ENREGISTRER_LISTE_CAPTEURS = 'ENREGISTRER_LISTE_CAPTEURS';
export const ENREGISTRER_LISTE_CAPTEURS_REDUITES = 'ENREGISTRER_LISTE_CAPTEURS_REDUITES';
export const ENREGISTRER_LISTE_SENSORS_TYPES = 'ENREGISTRER_LISTE_SENSORS_TYPES';
export const ENREGISTRER_LISTE_EMAIL_GROUPS = 'ENREGISTRER_LISTE_EMAIL_GROUPS';
export const ENREGISTRER_LISTE_IMPORT_FILTERS = 'ENREGISTRER_LISTE_IMPORT_FILTERS';

//GRAPHE
export const ENREGISTRER_LISTE_GRAPHES = 'ENREGISTRER_LISTE_GRAPHES';
export const ENREGISTRER_LISTE_FORMATS_DE_DATES = 'ENREGISTRER_LISTE_FORMATS_DE_DATES';
export const ENREGISTRER_LISTE_UNITES_DE_TEMPS = 'ENREGISTRER_LISTE_UNITES_DE_TEMPS';
export const ENREGISTRER_GRAPHE = 'ENREGISTRER_GRAPHE';
export const ENREGISTRER_DONNEES_GRAPHE = 'ENREGISTRER_DONNEES_GRAPHE';
export const ENREGISTRER_DONNEES_GRAPHE_AUTOMATIQUE_XT = 'ENREGISTRER_DONNEES_GRAPHE_AUTOMATIQUE_XT';

//VUE
export const ENREGISTRER_LISTE_VUES = 'ENREGISTRER_LISTE_VUES';
export const ENREGISTRER_VUE = 'ENREGISTRER_VUE';
export const ENREGISTRER_LISTE_COUCHES_VUE = 'ENREGISTRER_LISTE_COUCHES_VUE';
export const ENREGISTRER_LISTE_OBJETS_ASSOCIE_VUE = 'ENREGISTRER_LISTE_OBJETS_ASSOCIE_VUE';
export const VIDER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE = 'VIDER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE';
export const ENREGISTRER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE = 'ENREGISTRER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE';
export const ENREGISTRER_OBJET_A_AFFICHER_ASSOCIE_VUE = 'ENREGISTRER_OBJET_A_AFFICHER_ASSOCIE_VUE';
export const ENREGISTRER_LISTE_VUES_FAVORIS = 'ENREGISTRER_LISTE_VUES_FAVORIS';
export const ENREGISTRER_LISTE_TABLEAUX_DE_BORD = 'ENREGISTRER_LISTE_TABLEAUX_DE_BORD';
export const SUPPRIMER_VUES_LAYERS_OBJETS = 'SUPPRIMER_VUES_LAYERS_OBJETS';

//ALARME
export const ENREGISTRER_LISTE_ALARMES = 'ENREGISTRER_LISTE_ALARMES';
export const ENREGISTRER_LISTE_EMAILS_GROUPS = 'ENREGISTRER_LISTE_EMAILS_GROUPS';
export const ENREGISTRER_LISTE_GABARITS = 'ENREGISTRER_LISTE_GABARITS';
export const ENREGISTRER_LISTE_ALARMES_XY = 'ENREGISTRER_LISTE_ALARMES_XY';

//RAPPORT
export const ENREGISTRER_LISTE_RAPPORTS_PUBLIES = 'ENREGISTRER_LISTE_RAPPORTS_PUBLIES';
export const ENREGISTRER_LISTE_RAPPORTS_GABARITS = 'ENREGISTRER_LISTE_RAPPORTS_GABARITS';
export const ENREGISTRER_RAPPORT_GABARIT = 'ENREGISTRER_RAPPORT_GABARIT';
export const ENREGISTRER_LISTE_RAPPORTS_GENERES = 'ENREGISTRER_LISTE_RAPPORTS_GENERES';

//PRIVILEGE
export const ENREGISTRER_LISTE_PRIVILEGES_PAR_CATEGORIE = 'ENREGISTRER_LISTE_PRIVILEGES_PAR_CATEGORIE';

// ATTENTE REQUÊTE
export const DEBUT_ATTENTE = 'DEBUT_ATTENTE';
export const FIN_ATTENTE = 'FIN_ATTENTE';

// POPUP
export const FERMETURE_MENU_GAUCHE = 'FERMETURE_MENU_GAUCHE';
export const OUVERTURE_MENU_GAUCHE = 'OUVERTURE_MENU_GAUCHE';
export const FERMETURE_EN_TETE = 'FERMETURE_EN_TETE';
export const OUVERTURE_EN_TETE = 'OUVERTURE_EN_TETE';

// INTERFACE CLIENT
export const ENREGISTRER_PROJECT_ID_URL = 'ENREGISTRER_PROJECT_ID_URL';
export const ENREGISTRER_TYPE_OBJET_AFFICHAGE = 'ENREGISTRER_TYPE_OBJET_AFFICHAGE';
export const ENREGISTRER_ID_OBJET_AFFICHAGE = 'ENREGISTRER_ID_OBJET_AFFICHAGE';
export const ENREGISTRER_TEMPS_DE_DEFILEMENT = 'ENREGISTRER_TEMPS_DE_DEFILEMENT';
export const ENREGISTRER_ARBORESCENCE_PROJECTS = 'ENREGISTRER_ARBORESCENCE_PROJECTS';
export const AJOUT_CONTEUR_VUE_MARKERS = 'AJOUT_CONTEUR_VUE_MARKERS';

// MAINTENANCE
export const ENREGISTRER_LISTE_PROJETS_MAINTENANCE = 'ENREGISTRER_LISTE_PROJETS_MAINTENANCE';
export const ENREGISTRER_LISTE_TAUX_DE_PANNES_PAR_JOUR = 'ENREGISTRER_LISTE_TAUX_DE_PANNES_PAR_JOUR';
export const ENREGISTRER_LISTE_CAPTEURS_EN_PANNES = 'ENREGISTRER_LISTE_CAPTEURS_EN_PANNES';
