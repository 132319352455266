import React, {Component} from 'react';
import * as s from "../../../ressources/StyleGlobal/StyleGlobal";
import {connect} from "react-redux";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as actions from "../../../store/actions";
import {jsDictionaryEquals} from "../../../ressources/functionJS/jsDictionaryEquals";
import * as S from './FormulaireSelectionGraphe.module.scss';
import { graphe } from '../../../ressources/glossaires/glossaire_liens_pages';


class FormulaireSelectionGraphe extends Component {

    state = {
        grapheId: null,
        preconfigurerGrapheId: null
    }

    componentDidMount() {
        if (this.props.preconfigurerGrapheId !== undefined) {
            this.setState({...this.state, grapheId: this.props.preconfigurerGrapheId})
            for (let graphe of this.props.listeGraphes) {
                if (graphe.graph_id === this.props.preconfigurerGrapheId) {
                    this.setState({...this.state, preconfigurerGraphe: graphe})
                }
            }
        }
        if (this.props.preconfigurerGrapheId !== undefined) {
            this.setState({...this.state, grapheId: this.props.preconfigurerGrapheId});
        }
        this.props.recupererListeGraphes(this.props.filtre_projects_list_id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.preconfigurerGrapheId !== prevProps.preconfigurerGrapheId) {
            this.setState({...this.state, grapheId: this.props.preconfigurerGrapheId});
        }
        if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.recupererListeGraphes(this.props.filtre_projects_list_id);
        }
    }

    gererGrapheId = (event) => {
        /**
         * gère id du parent en fonction de l'option sélectionnée
         */
        let newGrapheId = parseInt(event.target.value);
        let newGrapheComponet = []
        if (newGrapheId === -1 || newGrapheId === null || newGrapheId==="") {
            this.setState({...this.state, grapheId: null, affichageIdName: checkMot("selectionne_un_graphe")});
        } else if (isNaN(newGrapheId)) {
            this.setState({...this.state, affichageIdName: checkMot("selectionne_un_graphe")});
        } else {
           
            let grapheName = this.props.listeGraphes[this.props.listeGraphes.findIndex((graphe) => parseInt(graphe.graph_id)===newGrapheId)].graph_name
       
            newGrapheComponet = grapheName
            this.setState({...this.state, grapheId: newGrapheId, affichageIdName: grapheName});
        }
        this.props.gererGrapheId(newGrapheId, newGrapheComponet);
    }

    render() {
        // récupérer liste des graphes avec this.props.listeGraphes
        // créer un formulaire pour ajouter un graphe à l'objet vue sous forme de liste déroulante via objet datalist
        return (
            <div className={[s.col_12, S.style].join(" ")}>
                <span className={[S.stil2,].join(" ")}>{checkMot("graph_id")}</span>
                <div className={[s.input_group, s.mb_2].join(" ")}>
                    <div className={[s.input_group_prepend, S.CaseInput].join(" ")}>
                        <div className={[s.input_group_text, s.form_control, S.Input].join(" ")}>
                            {this.state.affichageIdName}
                        </div>
                    </div>

                    <input list="brow"
                           className={[s.form_control].join(" ")}
                           onChange={(event) => this.gererGrapheId(event)}
                           value={this.state.grapheId}
                           disabled={this.props.affichageProfil === true}
                    />
                    <datalist
                        id="brow"
                        className={[S.datalist].join(" ")}
                        // onChange={(event) => this.gererGrapheId(event)}
                    >
                        <option disabled={true} selected={true}>{checkMot("selectionne_un_graphe")}</option>
                        {Object.keys(this.props.listeGraphes).map(indListeGraphes => {
                            return <option key={this.props.listeGraphes[indListeGraphes].graph_id}
                                           value={this.props.listeGraphes[indListeGraphes].graph_id}
                                           selected={this.props.preconfigurerGrapheId === this.props.listeGraphes[indListeGraphes].graph_id}
                            >
                                {this.props.listeGraphes[indListeGraphes].graph_name}
                            </option>
                        })}
                    </datalist>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeGraphes: state.graphe.graphs_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    };
}


const mapDispatchToProps = dispatch => {
        return {
            recupererListeGraphes: (listeIdProjets) => dispatch(actions.reqRecupererListeGraphes(listeIdProjets)),
        };
    }
;
export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionGraphe);
