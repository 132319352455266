import * as actionTypes from '../actions/actionTypes';
import {glossaire_erreurs} from '../../ressources/glossaires/glossaire_erreurs';
import {updateObject} from '../../shared/utility';
import * as privilege from "../../ressources/glossaires/glossaire_privileges";
import dictionnaire_json from "./dictionnaire.json";
import {dico_langues_phrases as dictionnaire_phrase} from "./dictionnaire_phrases";
export const clelocalStoragelanguesite = "langueSite"
const langueSite = localStorage.getItem(clelocalStoragelanguesite)!==undefined&&localStorage.getItem(clelocalStoragelanguesite)!==null?localStorage.getItem(clelocalStoragelanguesite):"fr";
export const initialState = {
    "dico_privileges": {
        [privilege.visualiser_ses_projets]: {
            "fr": "Visualiser ses projets",
            "bg": "Визуализирайте проектите си",
            "zh": "将你的项目可视化",
            "cs": "Vizualizace projektů",
            "da": "Visualiser dine projekter",
            "nl": "Visualiseer uw projecten",
            "en-gb": "Visualise your projects",
            "en-us": "Visualize your projects",
            "et": "Visualiseerige oma projekte",
            "fi": "Visualisoi projektisi",
            "de": "Visualisieren Sie Ihre Projekte",
            "el": "Οραματιστείτε τα έργα σας",
            "hu": "Vizualizálja projektjeit",
            "it": "Visualizza i tuoi progetti",
            "ja": "プロジェクトの可視化",
            "lv": "Vizualizējiet savus projektus",
            "lt": "Vizualizuokite savo projektus",
            "pl": "Wizualizuj swoje projekty",
            "pt-pt": "Visualize os seus projectos",
            "pt-br": "Visualize seus projetos",
            "ro": "Vizualizează-ți proiectele",
            "ru": "Визуализируйте свои проекты",
            "sk": "Vizualizujte svoje projekty",
            "sl": "Vizualizirajte svoje projekte",
            "es": "Visualice sus proyectos",
            "sv": "Visualisera dina projekt"
        },
        [privilege.recuperer_la_liste_des_graphes]: {
            "fr": "Récupérer la liste des graphes",
            "bg": "Извличане на списъка с графики",
            "zh": "检索图形列表",
            "cs": "Získání seznamu grafů",
            "da": "Henter listen over grafer",
            "nl": "De lijst van grafieken ophalen",
            "en-gb": "Retrieving the list of graphs",
            "en-us": "Retrieve the list of graphs",
            "et": "Graafikute nimekirja väljavõtmine",
            "fi": "Hae luettelo kuvaajista",
            "de": "Abrufen der Liste der Diagramme",
            "el": "Ανάκτηση της λίστας των γραφημάτων",
            "hu": "A grafikonok listájának lekérdezése",
            "it": "Recupera l'elenco dei grafici",
            "ja": "グラフのリストを取得する",
            "lv": "Iegūt grafiku sarakstu",
            "lt": "Grafikų sąrašo gavimas",
            "pl": "Uzyskaj listę wykresów",
            "pt-pt": "Recuperar a lista de gráficos",
            "pt-br": "Recuperar a lista de gráficos",
            "ro": "Preluarea listei de grafice",
            "ru": "Получение списка графиков",
            "sk": "Získanie zoznamu grafov",
            "sl": "Pridobi seznam grafov",
            "es": "Recuperar la lista de gráficos",
            "sv": "Hämta listan över grafer"
        },
        [privilege.visualiser_listes_des_alarmes_associe_aux_projets]: {
            "fr": "Modifier les seuils des capteurs",
            "bg": "Промяна на праговете на сензорите",
            "zh": "修改传感器的阈值",
            "cs": "Úprava prahových hodnot snímačů",
            "da": "Ændre sensortærskelværdier",
            "nl": "Sensor drempels wijzigen",
            "en-gb": "Change sensor thresholds",
            "en-us": "Modify sensor thresholds",
            "et": "Muuda anduri piirmäärasid",
            "fi": "Muokkaa anturin kynnysarvoja",
            "de": "Ändern der Sensorschwellenwerte",
            "el": "Τροποποίηση κατωφλίων αισθητήρων",
            "hu": "Az érzékelő küszöbértékek módosítása",
            "it": "Modificare le soglie dei sensori",
            "ja": "センサーのしきい値の変更",
            "lv": "Sensoru sliekšņu maiņa",
            "lt": "Keisti jutiklių ribines vertes",
            "pl": "Modyfikacja progów czujników",
            "pt-pt": "Modificar os limiares dos sensores",
            "pt-br": "Modificar os limites dos sensores",
            "ro": "Modificarea pragurilor senzorilor",
            "ru": "Изменение пороговых значений датчиков",
            "sk": "Úprava prahových hodnôt senzorov",
            "sl": "Spreminjanje pragov senzorjev",
            "es": "Modificar los umbrales de los sensores",
            "sv": "Ändra tröskelvärden för sensorer"
        },
        [privilege.acces_a_l_interface_admin]: {
            "fr": "Accès à l'interface admin (changement d'interface)",
            "bg": "Достъп до интерфейса на администратора (промяна на интерфейса)",
            "zh": "进入管理界面（改变界面）。",
            "cs": "Přístup k rozhraní správce (změna rozhraní)",
            "da": "Adgang til den administrative grænseflade (ændring af grænseflade)",
            "nl": "Toegang tot de admin-interface (verandering van interface)",
            "en-gb": "Access to the admin interface (change of interface)",
            "en-us": "Access to the admin interface (change of interface)",
            "et": "Juurdepääs haldusliidesele (liidese muutmine)",
            "fi": "Pääsy hallintakäyttöliittymään (käyttöliittymän vaihto)",
            "de": "Zugriff auf die Admin-Oberfläche (Wechsel der Oberfläche)",
            "el": "Πρόσβαση στη διεπαφή διαχείρισης (αλλαγή διεπαφής)",
            "hu": "Hozzáférés az adminisztrációs felülethez (felületváltás)",
            "it": "Accesso all'interfaccia di amministrazione (cambio di interfaccia)",
            "ja": "管理画面へのアクセス（インターフェースの変更",
            "lv": "Piekļuve administratora saskarnei (saskarnes maiņa)",
            "lt": "Prieiga prie administratoriaus sąsajos (sąsajos pakeitimas)",
            "pl": "Dostęp do interfejsu administratora (zmiana interfejsu)",
            "pt-pt": "Acesso à interface de administração (alteração da interface)",
            "pt-br": "Acesso à interface de administração (mudança de interface)",
            "ro": "Accesul la interfața de administrare (schimbare de interfață)",
            "ru": "Доступ к интерфейсу администратора (изменение интерфейса)",
            "sk": "Prístup k rozhraniu správcu (zmena rozhrania)",
            "sl": "Dostop do upraviteljskega vmesnika (sprememba vmesnika)",
            "es": "Acceso a la interfaz de administración (cambio de interfaz)",
            "sv": "Tillgång till administrationsgränssnittet (ändring av gränssnittet)"
        },
        [privilege.visualiser_listes_des_capteurs_associe_aux_projets]: {
            "fr": "Visualiser les listes des capteurs associées aux projets",
            "bg": "Преглед на списъци със сензори, свързани с проекти",
            "zh": "查看与项目相关的传感器列表",
            "cs": "Zobrazení seznamů senzorů přidružených k projektům",
            "da": "Se sensorlister, der er knyttet til projekter",
            "nl": "Sensorlijsten in verband met projecten bekijken",
            "en-gb": "View sensor lists associated with projects",
            "en-us": "View sensor lists associated with projects",
            "et": "Projektidega seotud andurite nimekirjade vaatamine",
            "fi": "Hankkeisiin liittyvien anturiluetteloiden tarkasteleminen",
            "de": "Mit Projekten verknüpfte Sensorlisten anzeigen",
            "el": "Προβολή καταλόγων αισθητήρων που σχετίζονται με έργα",
            "hu": "Projektekhez kapcsolódó érzékelőlisták megtekintése",
            "it": "Visualizza gli elenchi di sensori associati ai progetti",
            "ja": "プロジェクトに関連するセンサーリストの表示",
            "lv": "Ar projektiem saistīto sensoru sarakstu skatīšana",
            "lt": "Peržiūrėti su projektais susijusių jutiklių sąrašus",
            "pl": "Wyświetlanie list czujników powiązanych z projektami",
            "pt-pt": "Ver listas de sensores associados a projectos",
            "pt-br": "Ver listas de sensores associados a projetos",
            "ro": "Vizualizați listele de senzori asociate proiectelor",
            "ru": "Просмотр списков датчиков, связанных с проектами",
            "sk": "Zobrazenie zoznamov snímačov súvisiacich s projektmi",
            "sl": "Ogled seznamov senzorjev, povezanih s projekti",
            "es": "Ver las listas de sensores asociadas a los proyectos",
            "sv": "Visa sensorlistor som är kopplade till projekt"
        },
        [privilege.visualiser_listes_des_utilisateurs_associe_aux_projets]: {
            "fr": "Visualiser les listes des utilisateurs associées aux projets",
            "bg": "Преглед на списъци с потребители, свързани с проекти",
            "zh": "查看与项目相关的用户名单",
            "cs": "Zobrazení seznamů uživatelů přidružených k projektům",
            "da": "Se brugerlister, der er knyttet til projekter",
            "nl": "Bekijk gebruikerslijsten gekoppeld aan projecten",
            "en-gb": "View lists of users associated with projects",
            "en-us": "View user lists associated with projects",
            "et": "Projektidega seotud kasutajate nimekirjade vaatamine",
            "fi": "Näytä projekteihin liittyvät käyttäjälistat",
            "de": "Mit Projekten verknüpfte Benutzerlisten anzeigen",
            "el": "Προβολή λιστών χρηστών που σχετίζονται με έργα",
            "hu": "Projektekhez kapcsolódó felhasználói listák megtekintése",
            "it": "Visualizza gli elenchi di utenti associati ai progetti",
            "ja": "プロジェクトに関連するユーザーリストの表示",
            "lv": "Ar projektiem saistīto lietotāju sarakstu skatīšana",
            "lt": "Su projektais susijusių naudotojų sąrašų peržiūra",
            "pl": "Wyświetlanie list użytkowników powiązanych z projektami",
            "pt-pt": "Ver listas de utilizadores associados a projectos",
            "pt-br": "Ver listas de usuários associados a projetos",
            "ro": "Vizualizați listele de utilizatori asociate cu proiectele",
            "ru": "Просмотр списков пользователей, связанных с проектами",
            "sk": "Zobrazenie zoznamov používateľov priradených k projektom",
            "sl": "Ogled seznamov uporabnikov, povezanih s projekti",
            "es": "Ver las listas de usuarios asociadas a los proyectos",
            "sv": "Visa användarlistor som är kopplade till projekt"
        },
        [privilege.creer_des_visiteurs]: {
            "fr": "Créer des visiteurs",
            "bg": "Създаване на посетители",
            "zh": "创造访问者",
            "cs": "Vytvořit návštěvníky",
            "da": "Opret besøgende",
            "nl": "Bezoekers creëren",
            "en-gb": "Create visitors",
            "en-us": "Create visitors",
            "et": "Looge külastajad",
            "fi": "Luo kävijöitä",
            "de": "Besucher anlegen",
            "el": "Δημιουργία επισκεπτών",
            "hu": "Látogatók létrehozása",
            "it": "Creare visitatori",
            "ja": "訪問者の創出",
            "lv": "Izveidot apmeklētājus",
            "lt": "Sukurti lankytojus",
            "pl": "Tworzenie gości",
            "pt-pt": "Criar visitantes",
            "pt-br": "Criar visitantes",
            "ro": "Creați vizitatori",
            "ru": "Создание посетителей",
            "sk": "Vytvorenie návštevníkov",
            "sl": "Ustvarjanje obiskovalcev",
            "es": "Crear visitantes",
            "sv": "Skapa besökare"
        },
        [privilege.modifier_des_visiteurs]: {
            "fr": "Modifier des visiteurs",
            "bg": "Редактиране на посетители",
            "zh": "编辑访客",
            "cs": "Úprava návštěvníků",
            "da": "Redigering af besøgende",
            "nl": "Bezoekers bewerken",
            "en-gb": "Editing visitors",
            "en-us": "Editing visitors",
            "et": "Külastajate toimetamine",
            "fi": "Kävijöiden muokkaaminen",
            "de": "Besucher bearbeiten",
            "el": "Επεξεργασία επισκεπτών",
            "hu": "Látogatók szerkesztése",
            "it": "Modifica dei visitatori",
            "ja": "訪問者の編集",
            "lv": "Apmeklētāju rediģēšana",
            "lt": "Lankytojų redagavimas",
            "pl": "Edytowanie gości",
            "pt-pt": "Edição de visitantes",
            "pt-br": "Edição de visitantes",
            "ro": "Editarea vizitatorilor",
            "ru": "Редактирование посетителей",
            "sk": "Úprava návštevníkov",
            "sl": "Urejanje obiskovalcev",
            "es": "Edición de visitantes",
            "sv": "Redigering av besökare"
        },
        [privilege.supprimer_des_visiteurs]: {
            "fr": "Supprimer des visiteurs",
            "bg": "Изтриване на посетители",
            "zh": "删除访客",
            "cs": "Odstranit návštěvníky",
            "da": "Slet besøgende",
            "nl": "Verwijder bezoekers",
            "en-gb": "Delete visitors",
            "en-us": "Delete visitors",
            "et": "Külastajate kustutamine",
            "fi": "Poista vierailijat",
            "de": "Besucher löschen",
            "el": "Διαγραφή επισκεπτών",
            "hu": "Látogatók törlése",
            "it": "Cancellare i visitatori",
            "ja": "来場者の削除",
            "lv": "Izdzēst apmeklētājus",
            "lt": "Ištrinti lankytojus",
            "pl": "Usuń odwiedzających",
            "pt-pt": "Eliminar visitantes",
            "pt-br": "Eliminar visitantes",
            "ro": "Șterge vizitatori",
            "ru": "Удалить посетителей",
            "sk": "Vymazať návštevníkov",
            "sl": "Izbriši obiskovalce",
            "es": "Borrar visitantes",
            "sv": "Ta bort besökare"
        },
        [privilege.creer_des_clients]: {
            "fr": "Créer des clients",
            "bg": "Създаване на клиенти",
            "zh": "创造客户",
            "cs": "Vytvořit zákazníky",
            "da": "Opret kunder",
            "nl": "Creëer klanten",
            "en-gb": "Create clients",
            "en-us": "Create clients",
            "et": "Loo kliendid",
            "fi": "Luo asiakkaita",
            "de": "Kunden anlegen",
            "el": "Δημιουργία πελατών",
            "hu": "Ügyfelek létrehozása",
            "it": "Creare clienti",
            "ja": "お客様の創造",
            "lv": "Izveidot klientus",
            "lt": "Sukurti klientus",
            "pl": "Tworzenie klientów",
            "pt-pt": "Criar clientes",
            "pt-br": "Criar clientes",
            "ro": "Creați clienți",
            "ru": "Создание клиентов",
            "sk": "Vytvorenie zákazníkov",
            "sl": "Ustvarjanje strank",
            "es": "Crear clientes",
            "sv": "Skapa kunder"
        },
        [privilege.modifier_des_clients]: {
            "fr": "Modifier des clients",
            "bg": "Редактиране на клиенти",
            "zh": "编辑客户",
            "cs": "Úprava zákazníků",
            "da": "Redigering af kunder",
            "nl": "Klanten bewerken",
            "en-gb": "Editing clients",
            "en-us": "Editing clients",
            "et": "Klientide redigeerimine",
            "fi": "Asiakkaiden muokkaaminen",
            "de": "Kunden bearbeiten",
            "el": "Επεξεργασία πελατών",
            "hu": "Ügyfelek szerkesztése",
            "it": "Modifica dei clienti",
            "ja": "お客様の編集",
            "lv": "Klientu rediģēšana",
            "lt": "Klientų redagavimas",
            "pl": "Edycja klientów",
            "pt-pt": "Edição de clientes",
            "pt-br": "Edição de clientes",
            "ro": "Editarea clienților",
            "ru": "Редактирование клиентов",
            "sk": "Úprava zákazníkov",
            "sl": "Urejanje strank",
            "es": "Edición de clientes",
            "sv": "Redigering av kunder"
        },
        [privilege.supprimer_des_clients]: {
            "fr": "Supprimer des clients",
            "bg": "Изтриване на клиенти",
            "zh": "删除客户",
            "cs": "Odstranění zákazníků",
            "da": "Slet kunder",
            "nl": "Klanten verwijderen",
            "en-gb": "Delete customers",
            "en-us": "Delete customers",
            "et": "Klientide kustutamine",
            "fi": "Poista asiakkaat",
            "de": "Kunden löschen",
            "el": "Διαγραφή πελατών",
            "hu": "Vevők törlése",
            "it": "Cancellare i clienti",
            "ja": "お客様の削除",
            "lv": "Dzēst klientus",
            "lt": "Ištrinti klientus",
            "pl": "Usuń klientów",
            "pt-pt": "Apagar clientes",
            "pt-br": "Eliminar clientes",
            "ro": "Ștergeți clienții",
            "ru": "Удалить клиентов",
            "sk": "Odstránenie zákazníkov",
            "sl": "Brisanje strank",
            "es": "Borrar clientes",
            "sv": "Ta bort kunder"
        },
        [privilege.creer_des_projets]: {
            "fr": "Créer des projets",
            "bg": "Създаване на проекти",
            "zh": "创建项目",
            "cs": "Vytvářet projekty",
            "da": "Opret projekter",
            "nl": "Creëer projecten",
            "en-gb": "Creating projects",
            "en-us": "Create projects",
            "et": "Loo projekte",
            "fi": "Luo projekteja",
            "de": "Projekte erstellen",
            "el": "Δημιουργία έργων",
            "hu": "Projektek létrehozása",
            "it": "Creare progetti",
            "ja": "プロジェクトの作成",
            "lv": "Izveidot projektus",
            "lt": "Kurti projektus",
            "pl": "Tworzenie projektów",
            "pt-pt": "Criar projectos",
            "pt-br": "Criar projetos",
            "ro": "Creați proiecte",
            "ru": "Создание проектов",
            "sk": "Vytvárať projekty",
            "sl": "Ustvarjanje projektov",
            "es": "Crear proyectos",
            "sv": "Skapa projekt"
        },
        [privilege.modifier_des_projets]: {
            "fr": "Modifier des projets",
            "bg": "Редактиране на проекти",
            "zh": "编辑项目",
            "cs": "Úpravy projektů",
            "da": "Redigering af projekter",
            "nl": "Projecten bewerken",
            "en-gb": "Editing projects",
            "en-us": "Editing projects",
            "et": "Projektide redigeerimine",
            "fi": "Projektien muokkaaminen",
            "de": "Projekte editieren",
            "el": "Επεξεργασία έργων",
            "hu": "Projektek szerkesztése",
            "it": "Modifica dei progetti",
            "ja": "プロジェクトの編集",
            "lv": "Projektu rediģēšana",
            "lt": "Projektų redagavimas",
            "pl": "Edycja projektów",
            "pt-pt": "Projectos de edição",
            "pt-br": "Projetos de edição",
            "ro": "Editarea proiectelor",
            "ru": "Редактирование проектов",
            "sk": "Úprava projektov",
            "sl": "Urejanje projektov",
            "es": "Edición de proyectos",
            "sv": "Redigering av projekt"
        },
        [privilege.supprimer_des_projets]: {
            "fr": "Supprimer des projets",
            "bg": "Изтриване на проекти",
            "zh": "删除项目",
            "cs": "Odstranění projektů",
            "da": "Slet projekter",
            "nl": "Projecten verwijderen",
            "en-gb": "Delete projects",
            "en-us": "Delete projects",
            "et": "Projektide kustutamine",
            "fi": "Projektien poistaminen",
            "de": "Projekte löschen",
            "el": "Διαγραφή έργων",
            "hu": "Projektek törlése",
            "it": "Cancellare i progetti",
            "ja": "プロジェクトの削除",
            "lv": "Dzēst projektus",
            "lt": "Ištrinti projektus",
            "pl": "Usuń projekty",
            "pt-pt": "Eliminar projectos",
            "pt-br": "Eliminar projetos",
            "ro": "Ștergerea proiectelor",
            "ru": "Удалить проекты",
            "sk": "Odstránenie projektov",
            "sl": "Brisanje projektov",
            "es": "Eliminar proyectos",
            "sv": "Ta bort projekt"
        },
        [privilege.creer_des_capteurs]: {
            "fr": "Créer des capteurs",
            "bg": "Създаване на сензори",
            "zh": "创建传感器",
            "cs": "Vytvořit senzory",
            "da": "Opret sensorer",
            "nl": "Sensoren maken",
            "en-gb": "Creating sensors",
            "en-us": "Create sensors",
            "et": "Andurite loomine",
            "fi": "Luo antureita",
            "de": "Sensoren erstellen",
            "el": "Δημιουργία αισθητήρων",
            "hu": "Érzékelők létrehozása",
            "it": "Creare sensori",
            "ja": "センサーの作成",
            "lv": "Sensoru izveide",
            "lt": "Sukurti jutiklius",
            "pl": "Tworzenie czujników",
            "pt-pt": "Criar sensores",
            "pt-br": "Criar sensores",
            "ro": "Creați senzori",
            "ru": "Создание датчиков",
            "sk": "Vytvorenie senzorov",
            "sl": "Ustvarjanje senzorjev",
            "es": "Crear sensores",
            "sv": "Skapa sensorer"
        },
        [privilege.modifier_des_capteurs]: {
            "fr": "Modifier des capteurs",
            "bg": "Модифициране на сензори",
            "zh": "修改传感器",
            "cs": "Úprava senzorů",
            "da": "Ændre sensorer",
            "nl": "Sensoren wijzigen",
            "en-gb": "Modifying sensors",
            "en-us": "Modify sensors",
            "et": "Andurite muutmine",
            "fi": "Muokkaa antureita",
            "de": "Sensoren modifizieren",
            "el": "Τροποποίηση αισθητήρων",
            "hu": "Érzékelők módosítása",
            "it": "Modificare i sensori",
            "ja": "センサーの変更",
            "lv": "Sensoru modificēšana",
            "lt": "Keisti jutiklius",
            "pl": "Modyfikacja czujników",
            "pt-pt": "Modificar sensores",
            "pt-br": "Modificar os sensores",
            "ro": "Modificarea senzorilor",
            "ru": "Модифицировать датчики",
            "sk": "Úprava snímačov",
            "sl": "Spreminjanje senzorjev",
            "es": "Modificar los sensores",
            "sv": "Ändra sensorer"
        },
        [privilege.supprimer_des_capteurs]: {
            "fr": "Supprimer des capteurs",
            "bg": "Изтриване на сензори",
            "zh": "删除传感器",
            "cs": "Odstranění senzorů",
            "da": "Slet sensorer",
            "nl": "Sensoren verwijderen",
            "en-gb": "Delete sensors",
            "en-us": "Delete sensors",
            "et": "Andurite kustutamine",
            "fi": "Poista anturit",
            "de": "Sensoren löschen",
            "el": "Διαγραφή αισθητήρων",
            "hu": "Érzékelők törlése",
            "it": "Cancellare i sensori",
            "ja": "センサーの削除",
            "lv": "Dzēst sensorus",
            "lt": "Ištrinti jutiklius",
            "pl": "Usuń czujniki",
            "pt-pt": "Apagar sensores",
            "pt-br": "Eliminar sensores",
            "ro": "Ștergeți senzorii",
            "ru": "Удалить датчики",
            "sk": "Odstránenie senzorov",
            "sl": "Brisanje senzorjev",
            "es": "Borrar sensores",
            "sv": "Ta bort sensorer"
        },
        [privilege.creer_des_sensor_types]: {
            "fr": "Créer des types de capteur",
            "bg": "Създаване на типове сензори",
            "zh": "创建传感器类型",
            "cs": "Vytvoření typů snímačů",
            "da": "Oprette sensortyper",
            "nl": "Sensortypes maken",
            "en-gb": "Create sensor types",
            "en-us": "Create sensor types",
            "et": "Looge anduritüübid",
            "fi": "Luo anturityyppejä",
            "de": "Sensortypen anlegen",
            "el": "Δημιουργία τύπων αισθητήρων",
            "hu": "Érzékelőtípusok létrehozása",
            "it": "Creare tipi di sensori",
            "ja": "センサータイプの作成",
            "lv": "Sensoru tipu izveide",
            "lt": "Sukurti jutiklių tipus",
            "pl": "Tworzenie typów czujników",
            "pt-pt": "Criar tipos de sensores",
            "pt-br": "Criar tipos de sensores",
            "ro": "Crearea de tipuri de senzori",
            "ru": "Создание типов датчиков",
            "sk": "Vytvorenie typov senzorov",
            "sl": "Ustvarjanje tipov senzorjev",
            "es": "Crear tipos de sensores",
            "sv": "Skapa sensortyper"
        },
        [privilege.modifier_des_sensor_types]: {
            "fr": "Modifier des types de capteur",
            "bg": "Промяна на типовете сензори",
            "zh": "修改传感器类型",
            "cs": "Úprava typů snímačů",
            "da": "Ændre sensortyper",
            "nl": "Wijzigen van sensortypes",
            "en-gb": "Modifying sensor types",
            "en-us": "Modify sensor types",
            "et": "Anduri tüüpide muutmine",
            "fi": "Anturityyppien muuttaminen",
            "de": "Sensortypen modifizieren",
            "el": "Τροποποίηση τύπων αισθητήρων",
            "hu": "Érzékelőtípusok módosítása",
            "it": "Modificare i tipi di sensori",
            "ja": "センサータイプの変更",
            "lv": "Sensoru tipu modificēšana",
            "lt": "Keisti jutiklių tipus",
            "pl": "Modyfikacja typów czujników",
            "pt-pt": "Modificar os tipos de sensores",
            "pt-br": "Modificar os tipos de sensores",
            "ro": "Modificarea tipurilor de senzori",
            "ru": "Изменение типов датчиков",
            "sk": "Úprava typov snímačov",
            "sl": "Spreminjanje tipov senzorjev",
            "es": "Modificar los tipos de sensores",
            "sv": "Ändra sensortyper"
        },
        [privilege.supprimer_des_sensor_types]: {
            "fr": "Supprimer des types de capteur",
            "bg": "Изтриване на типове сензори",
            "zh": "删除传感器类型",
            "cs": "Odstranění typů snímačů",
            "da": "Slet sensortyper",
            "nl": "Sensortypes verwijderen",
            "en-gb": "Delete sensor types",
            "en-us": "Delete sensor types",
            "et": "Anduritüüpide kustutamine",
            "fi": "Anturityyppien poistaminen",
            "de": "Sensortypen löschen",
            "el": "Διαγραφή τύπων αισθητήρων",
            "hu": "Érzékelőtípusok törlése",
            "it": "Cancellare i tipi di sensore",
            "ja": "センサータイプの削除",
            "lv": "Sensoru tipu dzēšana",
            "lt": "Ištrinti jutiklių tipus",
            "pl": "Usuń typy czujników",
            "pt-pt": "Eliminar tipos de sensores",
            "pt-br": "Excluir tipos de sensores",
            "ro": "Ștergeți tipurile de senzori",
            "ru": "Удаление типов датчиков",
            "sk": "Odstránenie typov senzorov",
            "sl": "Brisanje tipov senzorjev",
            "es": "Borrar tipos de sensores",
            "sv": "Ta bort sensortyper"
        },
        [privilege.recuperer_list_sensor_type]: {
            "fr": "Accès à tous les types de capteur",
            "bg": "Достъп до всички типове сензори",
            "zh": "访问所有的传感器类型",
            "cs": "Přístup ke všem typům senzorů",
            "da": "Adgang til alle sensortyper",
            "nl": "Toegang tot alle sensortypes",
            "en-gb": "Access to all sensor types",
            "en-us": "Access to all types of sensors",
            "et": "Juurdepääs kõikidele anduritüüpidele",
            "fi": "Pääsy kaikkiin anturityyppeihin",
            "de": "Zugriff auf alle Sensortypen",
            "el": "Πρόσβαση σε όλους τους τύπους αισθητήρων",
            "hu": "Hozzáférés az összes érzékelőtípushoz",
            "it": "Accesso a tutti i tipi di sensori",
            "ja": "すべてのセンサータイプへのアクセス",
            "lv": "Piekļuve visiem sensoru veidiem",
            "lt": "Prieiga prie visų jutiklių tipų",
            "pl": "Dostęp do wszystkich typów czujników",
            "pt-pt": "Acesso a todos os tipos de sensores",
            "pt-br": "Acesso a todos os tipos de sensores",
            "ro": "Acces la toate tipurile de senzori",
            "ru": "Доступ ко всем типам датчиков",
            "sk": "Prístup ku všetkým typom snímačov",
            "sl": "Dostop do vseh tipov senzorjev",
            "es": "Acceso a todos los tipos de sensores",
            "sv": "Tillgång till alla sensortyper"
        },
        [privilege.creer_des_graphes]: {
            "fr": "Créer des graphes",
            "bg": "Създаване на графики",
            "zh": "创建图表",
            "cs": "Vytváření grafů",
            "da": "Opret grafer",
            "nl": "Grafieken maken",
            "en-gb": "Creating graphs",
            "en-us": "Create graphs",
            "et": "Graafikute loomine",
            "fi": "Luo kaavioita",
            "de": "Graphen erstellen",
            "el": "Δημιουργία γραφημάτων",
            "hu": "Grafikonok létrehozása",
            "it": "Creare grafici",
            "ja": "グラフの作成",
            "lv": "Izveidot diagrammas",
            "lt": "Kurti grafikus",
            "pl": "Tworzenie wykresów",
            "pt-pt": "Criar gráficos",
            "pt-br": "Criar gráficos",
            "ro": "Creați grafice",
            "ru": "Создание графиков",
            "sk": "Vytvorenie grafov",
            "sl": "Ustvarjanje grafov",
            "es": "Crear gráficos",
            "sv": "Skapa grafer"
        },
        [privilege.modifier_des_graphes]: {
            "fr": "Modifier des graphes",
            "bg": "Редактиране на графики",
            "zh": "编辑图表",
            "cs": "Úprava grafů",
            "da": "Redigering af grafer",
            "nl": "Grafieken bewerken",
            "en-gb": "Modifying graphs",
            "en-us": "Editing graphs",
            "et": "Graafikute redigeerimine",
            "fi": "Kuvaajien muokkaaminen",
            "de": "Graphen editieren",
            "el": "Επεξεργασία γραφημάτων",
            "hu": "Grafikonok szerkesztése",
            "it": "Modifica dei grafici",
            "ja": "グラフの編集",
            "lv": "Grafiku rediģēšana",
            "lt": "Grafikų redagavimas",
            "pl": "Edytowanie wykresów",
            "pt-pt": "Edição de gráficos",
            "pt-br": "Edição de gráficos",
            "ro": "Editarea graficelor",
            "ru": "Редактирование графиков",
            "sk": "Úprava grafov",
            "sl": "Urejanje grafov",
            "es": "Edición de gráficos",
            "sv": "Redigering av grafer"
        },
        [privilege.supprimer_des_graphes]: {
            "fr": "Supprimer des graphes",
            "bg": "Изтриване на графики",
            "zh": "删除图表",
            "cs": "Odstranění grafů",
            "da": "Slet grafer",
            "nl": "Grafieken verwijderen",
            "en-gb": "Delete graphs",
            "en-us": "Delete graphs",
            "et": "Graafikute kustutamine",
            "fi": "Poista kuvaajat",
            "de": "Graphen löschen",
            "el": "Διαγραφή γραφημάτων",
            "hu": "Grafikonok törlése",
            "it": "Cancellare i grafici",
            "ja": "グラフの削除",
            "lv": "Dzēst diagrammas",
            "lt": "Ištrinti grafikus",
            "pl": "Usuń wykresy",
            "pt-pt": "Eliminar gráficos",
            "pt-br": "Excluir gráficos",
            "ro": "Ștergeți graficele",
            "ru": "Удалить графики",
            "sk": "Odstránenie grafov",
            "sl": "Brisanje grafov",
            "es": "Borrar gráficos",
            "sv": "Radera grafer"
        },
        [privilege.acces_a_tous_les_projets]: {
            "fr": "Accès à tous les projets",
            "bg": "Достъп до всички проекти",
            "zh": "进入所有项目",
            "cs": "Přístup ke všem projektům",
            "da": "Adgang til alle projekter",
            "nl": "Toegang tot alle projecten",
            "en-gb": "Access to all projects",
            "en-us": "Access to all projects",
            "et": "Juurdepääs kõikidele projektidele",
            "fi": "Pääsy kaikkiin hankkeisiin",
            "de": "Zugriff auf alle Projekte",
            "el": "Πρόσβαση σε όλα τα έργα",
            "hu": "Hozzáférés az összes projekthez",
            "it": "Accesso a tutti i progetti",
            "ja": "すべてのプロジェクトへのアクセス",
            "lv": "Piekļuve visiem projektiem",
            "lt": "Prieiga prie visų projektų",
            "pl": "Dostęp do wszystkich projektów",
            "pt-pt": "Acesso a todos os projectos",
            "pt-br": "Acesso a todos os projetos",
            "ro": "Acces la toate proiectele",
            "ru": "Доступ ко всем проектам",
            "sk": "Prístup ku všetkým projektom",
            "sl": "Dostop do vseh projektov",
            "es": "Acceso a todos los proyectos",
            "sv": "Tillgång till alla projekt"
        },
        [privilege.creer_des_admins]: {
            "fr": "Créer des administrateurs",
            "bg": "Създаване на администратори",
            "zh": "创建管理员",
            "cs": "Vytvořit správce",
            "da": "Opret administratorer",
            "nl": "Beheerders aanmaken",
            "en-gb": "Create administrators",
            "en-us": "Create administrators",
            "et": "Administraatorite loomine",
            "fi": "Luo järjestelmänvalvojia",
            "de": "Administratoren anlegen",
            "el": "Δημιουργία διαχειριστών",
            "hu": "Adminisztrátorok létrehozása",
            "it": "Creare amministratori",
            "ja": "管理者の作成",
            "lv": "Izveidot administratorus",
            "lt": "Sukurti administratorius",
            "pl": "Tworzenie administratorów",
            "pt-pt": "Criar administradores",
            "pt-br": "Criar administradores",
            "ro": "Creați administratori",
            "ru": "Создание администраторов",
            "sk": "Vytvorenie správcov",
            "sl": "Ustvarjanje upraviteljev",
            "es": "Crear administradores",
            "sv": "Skapa administratörer"
        },
        [privilege.modifier_des_admins]: {
            "fr": "Modifier des administrateurs",
            "bg": "Редактиране на администратори",
            "zh": "编辑管理人",
            "cs": "Upravit správce",
            "da": "Rediger administratorer",
            "nl": "Beheerders bewerken",
            "en-gb": "Editing administrators",
            "en-us": "Edit administrators",
            "et": "Administraatorite redigeerimine",
            "fi": "Muokkaa ylläpitäjiä",
            "de": "Administratoren bearbeiten",
            "el": "Επεξεργασία διαχειριστών",
            "hu": "Adminisztrátorok szerkesztése",
            "it": "Modifica amministratori",
            "ja": "管理者の編集",
            "lv": "Rediģēt administratorus",
            "lt": "Redaguoti administratoriai",
            "pl": "Edytuj administratorów",
            "pt-pt": "Editar administradores",
            "pt-br": "Editar administradores",
            "ro": "Editați administratorii",
            "ru": "Редактирование администраторов",
            "sk": "Upraviť správcov",
            "sl": "Urejanje upraviteljev",
            "es": "Editar administradores",
            "sv": "Redigera administratörer"
        },
        [privilege.supprimer_des_admins]: {
            "fr": "Supprimer des administrateurs",
            "bg": "Изтриване на администратори",
            "zh": "删除管理员",
            "cs": "Odstranění správců",
            "da": "Slet administratorer",
            "nl": "Beheerders verwijderen",
            "en-gb": "Delete administrators",
            "en-us": "Delete administrators",
            "et": "Administraatorite kustutamine",
            "fi": "Poista ylläpitäjät",
            "de": "Administratoren löschen",
            "el": "Διαγραφή διαχειριστών",
            "hu": "Adminisztrátorok törlése",
            "it": "Cancellare gli amministratori",
            "ja": "管理者の削除",
            "lv": "Dzēst administratorus",
            "lt": "Ištrinti administratorius",
            "pl": "Usuń administratorów",
            "pt-pt": "Eliminar administradores",
            "pt-br": "Excluir administradores",
            "ro": "Ștergeți administratorii",
            "ru": "Удалить администраторов",
            "sk": "Odstránenie správcov",
            "sl": "Izbriši upravitelje",
            "es": "Eliminar administradores",
            "sv": "Ta bort administratörer"
        },
        [privilege.creer_des_supers_admins]: {
            "fr": "Créer des supers administrateurs",
            "bg": "Създаване на супер администратори",
            "zh": "创建超级管理员",
            "cs": "Vytvoření superadministrátorů",
            "da": "Oprette superadministratorer",
            "nl": "Creëer super beheerders",
            "en-gb": "Create super administrators",
            "en-us": "Create super administrators",
            "et": "Luua superadministraatorid",
            "fi": "Luo superylläpitäjät",
            "de": "Superadministratoren anlegen",
            "el": "Δημιουργία σούπερ διαχειριστών",
            "hu": "Szuper adminisztrátorok létrehozása",
            "it": "Creare super amministratori",
            "ja": "スーパー・アドミニストレーターの作成",
            "lv": "Superadministratoru izveide",
            "lt": "Sukurti superadministratorius",
            "pl": "Tworzenie super administratorów",
            "pt-pt": "Criar super administradores",
            "pt-br": "Criar super administradores",
            "ro": "Creați super administratori",
            "ru": "Создание супер администраторов",
            "sk": "Vytvorenie superadministrátorov",
            "sl": "Ustvarjanje super skrbnikov",
            "es": "Crear superadministradores",
            "sv": "Skapa superadministratörer"
        },
        [privilege.modifier_des_supers_admins]: {
            "fr": "Modifier des supers administrateurs",
            "bg": "Редактиране на супер администратори",
            "zh": "编辑超级管理员",
            "cs": "Úpravy superadministrátorů",
            "da": "Redigering af superadministratorer",
            "nl": "Superbeheerders bewerken",
            "en-gb": "Editing super administrators",
            "en-us": "Editing super administrators",
            "et": "Superadministraatorite redigeerimine",
            "fi": "Supervalvojien muokkaaminen",
            "de": "Superadministratoren bearbeiten",
            "el": "Επεξεργασία υπερδιαχειριστών",
            "hu": "Szuper adminisztrátorok szerkesztése",
            "it": "Modifica dei super amministratori",
            "ja": "スーパー・アドミニストレーターの編集",
            "lv": "Superadministratoru rediģēšana",
            "lt": "Superadministratorių redagavimas",
            "pl": "Edytowanie super administratorów",
            "pt-pt": "Edição de super administradores",
            "pt-br": "Edição de super administradores",
            "ro": "Editarea super administratorilor",
            "ru": "Редактирование супер администраторов",
            "sk": "Úprava superadministrátorov",
            "sl": "Urejanje super administratorjev",
            "es": "Edición de los superadministradores",
            "sv": "Redigering av superadministratörer"
        },
        [privilege.supprimer_des_supers_admins]: {
            "fr": "Supprimer des supers administrateurs",
            "bg": "Изтриване на супер администратори",
            "zh": "删除超级管理员",
            "cs": "Odstranění superadministrátorů",
            "da": "Slet superadministratorer",
            "nl": "Superbeheerders verwijderen",
            "en-gb": "Delete super administrators",
            "en-us": "Delete super administrators",
            "et": "Kustuta superadministraatorid",
            "fi": "Poista super ylläpitäjät",
            "de": "Superadministratoren löschen",
            "el": "Διαγραφή υπερδιαχειριστών",
            "hu": "Szuperadminisztrátorok törlése",
            "it": "Cancellare i super amministratori",
            "ja": "スーパー管理者の削除",
            "lv": "Superadministratoru dzēšana",
            "lt": "Ištrinti superadministratorius",
            "pl": "Usuń super administratorów",
            "pt-pt": "Eliminar super administradores",
            "pt-br": "Excluir super administradores",
            "ro": "Ștergeți super administratorii",
            "ru": "Удаление супер администраторов",
            "sk": "Odstránenie superadministrátorov",
            "sl": "Brisanje super administratorjev",
            "es": "Eliminar los superadministradores",
            "sv": "Ta bort superadministratörer"
        }
    }, // Fin Privileges
    "dico_langues": {
        ...dictionnaire_json.dico_langues,
        ...dictionnaire_phrase.dico_langues,

        "traduction_titre_graphe_automatique": {
            "fr": (nom_capteur) => "Évolution du capteur " + nom_capteur + " en fonction du temps",
            "pt-pt": (nom_capteur) => "Evolução do sensor " + nom_capteur + " em função do tempo",
            "pt-br": (nom_capteur) => "Evolução do sensor " + nom_capteur + " em função do tempo",
        },
        "api_succes_corps_connexion": {
            "fr": (nom) => "Bonjour " + nom + "!",
            "en": (nom) => "Hello " + nom + "!",
            "pt-pt": (nom) => "Bom dia " + nom + "!",
            "es": (nom) => "Buenos dias " + nom + "!"
        },
        "api_echec_corps_connexion": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s)",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_creer_utilisateur": {
            "fr": (id) => "Id du nouveau utilisateur " + id + ".",
            "en": (id) => "New user id " + id + ".",
            "pt-pt": (id) => "Novo id de Utilizador" + id + ".",
            "es": (id) => "Nuevo ID de usuario " + id + "."
        },
        "api_echec_corps_creer_utilisateur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_supprimer_un_capteur": {
            "fr": (idCapteur) => "Le capteur id " + idCapteur + " a été supprimé.",
            "en": (idCapteur) => "The id sensor " + idCapteur + " has been deleted.",
            "pt-pt": (idCapteur) => "O sensor de id " + idCapteur + " foi deletado.",
            "es": (idCapteur) => "El sensor de identificación " + idCapteur + " ha sido eliminado."
        },
        "api_echec_corps_supprimer_un_capteur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_supprimer_un_graphe": {
            "fr": (idCapteur) => "Le graphe id " + idCapteur + " a été supprimé.",
            "en": (idCapteur) => "The id sensor " + idCapteur + " has been deleted.",
            "pt-pt": (idCapteur) => "O sensor de id " + idCapteur + " foi deletado.",
            "es": (idCapteur) => "El sensor de identificación " + idCapteur + " ha sido eliminado."
        },
        "api_echec_corps_supprimer_un_graphe": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_capteurs": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_capteurs_reduites": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_sensor_type": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_groupes_d_emails": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_filtres_d_importation": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_creation_d_un_capteur": {
            "fr": (idCapteur) => "Le nouveau capteur n°" + idCapteur + " a été créé.",
            "en": (idCapteur) => "The new sensor n°" + idCapteur + " has been created.",
            "pt-pt": (idCapteur) => "O novo sensor n°" + idCapteur + " foi criado.",
            "es": (idCapteur) => "El nuevo sensor n°" + idCapteur + " fue creado."
        },
        "api_echec_corps_creation_d_un_capteur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_modification_d_un_capteur": {
            "fr": (idCapteur) => "Le capteur n°" + idCapteur + " a été modifié.",
            "en": (nombreAvertissement) => "The sensor" + nombreAvertissement + " was modified.",
            "pt-pt": (nombreAvertissement) => "O sensor " + nombreAvertissement + " foi modificado.",
            "es": (nombreAvertissement) => "El sensor " + nombreAvertissement + " fue modificado."
        },
        "api_echec_corps_modification_d_un_capteur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_creation_d_un_sensor_type": {
            "fr": (sensorTypeName) => "Le nouveau type de capteur " + sensorTypeName + " a été enregistré.",
            "en": (sensorTypeName) => "The new type of sensor " + sensorTypeName + " has been registered.",
            "pt-pt": (sensorTypeName) => "O novo tipo de sensor " + sensorTypeName + " foi registrado.",
            "es": (sensorTypeName) => "El nuevo tipo de sensor " + sensorTypeName + " ha sido registrado."
        },
        "api_echec_corps_creation_d_un_sensor_type": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_modification_d_un_sensor_type": {
            "fr": (sensorTypeName) => "La modification " + sensorTypeName + " a été enregistré.",
            "en": (sensorTypeName) => "The modification " + sensorTypeName + " has been registered.",
            "pt-pt": (sensorTypeName) => "A modificação " + sensorTypeName + " foi registrado.",
            "es": (sensorTypeName) => "Modificación " + sensorTypeName + " ha sido registrado."
        },
        "api_echec_corps_modification_d_un_sensor_type": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_suppression_d_un_sensor_type": {
            "fr": (idSensorType) => "Le type de capteur de l'id " + idSensorType + " a été supprimé.",
            "en": (idSensorType) => "The sensor type of the id" + idSensorType + "has been deleted.",
            "pt-pt": (idSensorType) => "O tipo de sensor do id " + idSensorType + " foi deletado.",
            "es": (idSensorType) => "El tipo de sensor de la identificación" + idSensorType + " ha sido eliminado."
        },
        "api_echec_corps_suppression_d_un_sensor_type": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_modification_d_un_utilisateur": {
            "fr": (id) => "Id de l'utilisateur " + id + " a été modifié.",
            "en": (id) => "User ID " + id + " was modified",
            "pt-pt": (id) => "ID do Utilizador " + id + " foi modificado.",
            "es": (id) => "ID de usuario " + id + " fue modificado"
        },
        "api_echec_corps_modification_d_un_utilisateur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_recuperation_de_l_utilisateur_connecte": {
            "fr": (lastname) => "Re-bonjour " + lastname + ".",
            "en": (lastname) => "Hello again " + lastname + ".",
            "pt-pt": (lastname) => "Olá de novo " + lastname + ".",
            "es": (lastname) => "Buenos dias de nuevo " + lastname + "."
        },
        "api_echec_corps_liste_des_privileges_associes_a_une_categorie_d_utilisateur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_supprimer_un_utilisateur": {
            "fr": (id) => "L'utilisateur avec l'id " + id + " est supprimé.",
            "en": (id) => "The user with the id " + id + " is deleted.",
            "pt-pt": (id) => "O usuário com o id " + id + " esta deletado.",
            "es": (id) => "El usuario con la identificación " + id + " esta borrado."
        },
        "api_echec_corps_supprimer_un_utilisateur": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_utilisateurs": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_changer_son_mot_de_passe": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_demande_de_reinitialisation_du_mot_de_passe": {
            "fr": (email) => "Merci de consulter votre boîte-mail " + email + " pour réinitialiser votre mot de passe.",
            "en": (email) => "Please check your mailbox " + email + " to reset your password.",
            "pt-pt": (email) => "Por favor, verifique sua caixa de correio" + email + " para redefinir sua senha.",
            "es": (email) => "Por favor, revise su correo " + email + " para restablecer su contraseña."
        },
        "api_echec_corps_demande_de_reinitialisation_du_mot_de_passe": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_creation_d_un_projet": {
            "fr": (id) => "Id du nouveau Projet " + id + ".",
            "en": (id) => "New Project Id " + id + ".",
            "pt-pt": (id) => "Novo Id de Projeto " + id + ".",
            "es": (id) => "Id du nouveau Projet " + id + "."
        },
        "api_echec_corps_creation_d_un_projet": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_creer_un_graphe": {
            "fr": (id) => "Id du nouveau graphe " + id + ".",
            "en": (id) => "Id of the new graph " + id + ".",
            "pt-pt": (id) => "Id do novo gráfico " + id + ".",
            "es": (id) => "Id del nuevo gráfico " + id + "."
        },
        "api_echec_corps_creer_un_graphe": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_modifier_un_graphe": {
            "fr": (id) => "Le graphe " + id + " a bien été modifié.",
            "en": (id) => "The graph " + id + " has been changed.",
            "pt-pt": (id) => "O gráfico " + id + " foi mudado.",
            "es": (id) => "La gráfica " + id + " ha sido cambiado."
        },
        "api_echec_corps_modifier_un_graphe": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_rajouter_un_projet_en_favori": {
            "fr": (id) => "Id du Projet ajouté aux favoris " + id + ".",
            "en": (id) => "Project id added to favorites " + id + ".",
            "pt-pt": (id) => "Id do projeto adicionado aos favoritos " + id + ".",
            "es": (id) => "Id del proyecto agregado a favoritos " + id + "."
        },
        "api_echec_corps_rajouter_un_projet_en_favori": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_modification_d_un_projet": {
            "fr": (id) => "Id du projet " + id + " a bien été modifié.",
            "en": (id) => "Project id " + id + " has been modified.",
            "pt-pt": (id) => "Id do projeto " + id + " foi modificado.",
            "es": (id) => "Projecto Id " + id + " ha sido modificado."
        },
        "api_echec_corps_modification_d_un_projet": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_supprimer_un_projet": {
            "fr": (id) => "Id du projet " + id + " a bien été supprimé.",
            "en": (id) => "Project id " + id + " has been deleted.",
            "pt-pt": (id) => "Id do projeto " + id + " foi deletado.",
            "es": (id) => "Projecto Id " + id + " ha sido eliminado."
        },
        "api_echec_corps_supprimer_un_projet": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_supprimer_un_projet_des_favoris": {
            "fr": (id) => "Id du projet " + id + " a bien été supprimé des favoris.",
            "en": (id) => "Project id " + id + " has been deleted from favorites.",
            "pt-pt": (id) => "Id do projeto " + id + " foi excluído dos favoritos.",
            "es": (id) => "Projecto Id " + id + " ha sido eliminado de favoritos."
        },
        "api_echec_corps_supprimer_un_projet_des_favoris": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_succes_corps_ajouter_une_image_de_projet": {
            "fr": (id) => "L'image a bien été ajouté au projet " + id + ".",
            "en": (id) => "The image has been added to the project " + id + ".",
            "pt-pt": (id) => "A imagem foi adicionada ao projeto " + id + ".",
            "es": (id) => "La imagen ha sido agregada al proyecto " + id + "."
        },
        "api_echec_corps_ajouter_une_image_de_projet": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_projet": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperer_la_liste_des_graphes": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_projets_des_favoris": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_formats_de_dates": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_corps_recuperation_de_la_liste_des_unites_de_temps": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_echec_erreurs_en_boucle": {
            "fr": (parametreAvertissement) => "[Avertisemment n°" + parametreAvertissement.numAvertissement + "] " + glossaire_erreurs[parametreAvertissement.idErreur],
            "en": (parametreAvertissement) => "[Warning n°" + parametreAvertissement.numAvertissement + "] " + glossaire_erreurs[parametreAvertissement.idErreur],
            "pt-pt": (parametreAvertissement) => "[Aviso n°" + parametreAvertissement.numAvertissement + "] " + glossaire_erreurs[parametreAvertissement.idErreur],
            "es": (parametreAvertissement) => "[Advertencia n°" + parametreAvertissement.numAvertissement + "] " + glossaire_erreurs[parametreAvertissement.idErreur]
        },
        "api_echec_corps_reinitialisation_du_mot_de_passe": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "api_erreur_requete_corps": {
            "fr": (parametreCorps) => "[" + parametreCorps.request_type + "] " + parametreCorps.messageErreur,
            "en": (parametreCorps) => "[" + parametreCorps.request_type + "] " + parametreCorps.messageErreur,
            "pt-pt": (parametreCorps) => "[" + parametreCorps.request_type + "] " + parametreCorps.messageErreur,
            "es": (parametreCorps) => "[" + parametreCorps.request_type + "] " + parametreCorps.messageErreur
        },
        "api_echec_corps_deconnexion": {
            "fr": (nombreAvertissement) => "Attention il y a " + nombreAvertissement + " avertissement(s):",
            "en": (nombreAvertissement) => "Attention there is" + nombreAvertissement + " warning (s)",
            "pt-pt": (nombreAvertissement) => "Atenção existe " + nombreAvertissement + " aviso(s)",
            "es": (nombreAvertissement) => "Atención hay " + nombreAvertissement + " advertencia (s)"
        },
        "Une_resolution_plus_grande_est_requise_pour_acceder_au_graphe": {
            "fr":"Une résolution plus grande est requise pour accéder au graphe",
      
        },
    }, // Fin de dictionnaire
    "langueSite": langueSite, //
    // "langueSite": ["fr", "bg", "zh", "cs", "da", "nl", "en-gb", "en-us", "et", "fi", "de", "el", "hu", "it", "ja", "lv", "lt", "pl", "pt-pt", "pt-br", "ro", "ru", "sk", "sl", "es", "sv"]
};

const changementLangue = (state, action) => {
    localStorage.setItem(clelocalStoragelanguesite, action.langueSite);
    return updateObject(state, {langueSite: action.langueSite});
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUE:
            return changementLangue(state, action);
        default:
            return state;
    }
};
export default reducer;
