import React, {Component} from 'react';
import {Link} from "react-router-dom";
import zxcvbn from "zxcvbn";
import {connect} from "react-redux";

import Bouton from "../../composant/UI/Bouton/Bouton";
import MdpMetre from "../../composant/UI/MdpMetre/MdpMetre";

import Auxiliaire from "../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../hoc/AttenteRequete/AttenteRequete";
import * as actions from "../../store/actions";
import * as requestType from "../../ressources/glossaires/glossaire_requetes";
import * as s from '../../ressources/StyleGlobal/StyleGlobal';
import logoEntry from '../../ressources/Logo/argos_png_logo_white.png';
import S from './ReinitialisationMDP.module.scss';
import {checkMot} from "../../ressources/functionJS/checkMot";

class ReinitialisationMDP extends Component{

    constructor(props) {
        super(props);
        this.state = {
            nouveauMotDePasse: '',
            forceNouveauMotDePasse:0,
            confirmeNouveauMotDePasse: '',
            confirmeNouveauMotDePasseBool:false,
            afficheValidationReinitialisationBool:false,
            codeDeVerification: ''
        };
    }
    componentDidMount() {
        // récupère l'information du get de l'url
        const variableGet = window.location.href.split("?");
        if(variableGet.length>1){
            const listeVarible = variableGet[1].split('=');
            if(listeVarible.length===2){
                this.props.validationCodeVerification(listeVarible[1]);
                this.setState({...this.state, codeDeVerification:listeVarible[1]})
            }
        }


    }
    gererNouveauMotDePasse = event => this.setState({ nouveauMotDePasse: event.target.value });
    gererForceNouveauMotDePasse = force => this.setState({ forceNouveauMotDePasse: force });
    gererConfirmeNouveauMotDePasse = event => {
        this.setState({ confirmeNouveauMotDePasse: event.target.value, confirmeNouveauMotDePasseBool:this.state.nouveauMotDePasse===event.target.value});
    }
    render() {
        // http://localhost:3000/reinitialisation?codeverification=123456798
        let classeConnexion = S.PageAccueilConnexion;
        const fomulaireDeReinitialisation = <Auxiliaire>
            <h1 className={S.TextTitre}>{checkMot("reinitialiser_mdptitre")}</h1>
            <form onSubmit={event => event.preventDefault()}>
                <div className={[s.form_group, s.row].join(" ")}>
                    <label htmlFor="inputPassword" className={[s.col, s.col_form_label].join(" ")}>{checkMot("nouveau_mdp")}</label>
                    <div className={[s.col_sm_11].join(" ")}>
                        <input type="password" className={[s.form_control, S.MotDePasse].join(" ")}
                               id="inputPassword" placeholder="nouveauMDP" value={this.state.nouveauMotDePasse} onChange={this.gererNouveauMotDePasse}/>
                        <MdpMetre valeurForceMDP={zxcvbn(this.state.nouveauMotDePasse)} password={this.state.nouveauMotDePasse} />
                    </div>
                    <span className={[s.col_sm_1].join(" ")}>
                        {zxcvbn(this.state.nouveauMotDePasse).score>3
                            ? <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle-fill"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            : <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        }
                    </span>
                {/*    todo enlever l'intitulé des champs "Nouveau mot de passe" et "Confirmer Nouveau MDP"*/}
                </div>{/*nouveau mdp*/}
                <div className={[s.form_group, s.row].join(" ")}>
                    <label htmlFor="inputPassword" className={[s.col, s.col_form_label].join(" ")}>{checkMot("confirmer_nouveau_mdp")}</label>
                    <div className={[s.col_sm_11].join(" ")}>
                        <input type="password" className={[s.form_control, S.MotDePasse].join(" ")}
                               id="inputPassword" placeholder="ConfirmerNouveauMDP" value={this.state.confirmeNouveauMotDePasse}
                               onChange={this.gererConfirmeNouveauMotDePasse}/>
                    </div>
                    <span className={[s.col_sm_1].join(" ")}>
                        {this.state.confirmeNouveauMotDePasseBool
                            ? <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle-fill"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            :<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        }
                    </span>
                </div>{/*confirmer nouveau mdp*/}
                <div className={S.Bouton}>
                    <AttenteRequete request_type={requestType.reinitialisation_du_mot_de_passe} str={checkMot("reinitialisation_en_cours")}>
                        <Bouton cliquer={() => this.props.reinitialisationMDP(this.state.codeDeVerification, this.state.nouveauMotDePasse, this.state.confirmeNouveauMotDePasse)}
                                desactive={!(zxcvbn(this.state.nouveauMotDePasse).score>3 && this.state.confirmeNouveauMotDePasseBool)}>{checkMot("reinitialiser_le_mot_de_passe")}</Bouton>
                    </AttenteRequete>
                </div>
            </form>
     </Auxiliaire>;
        const formulaireDeReinitialisationValide = <Auxiliaire>
            <div className={S.FormulaireReinitialisation}>
                <h4 className={S.TextTitre}>{checkMot("votre_mot_de_passe_a_ete_reinitialise_retournez_sur_la_page_d_accueil_pour_vous_connecter")}</h4>
                <Link to={"/"}>{checkMot("page_de_connexion")}</Link>
            </div>
        </Auxiliaire>
        const codeNonVerifier = <Auxiliaire>
            <div className={S.FormulaireReinitialisation}>
                <h4 className={S.TextTitre}>{checkMot("code_de_verification_ou_lien_incorrecte")}</h4>
            {/*todo ajouter le text de dans le dictionnaire de langue */}
            {/*todo ajouter un lien pour revenir à la page de connexion */}
            </div>
        </Auxiliaire>

        return (
            <div className={classeConnexion}>
                  <img alt="logo" src={logoEntry} className={S.logoSty}/>
                {this.props.codeVerifier
                    ?this.props.reinitialisationMDPReussie
                        ?formulaireDeReinitialisationValide
                        :fomulaireDeReinitialisation
                    :codeNonVerifier
                }
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        codeVerifier: state.authentifie.codeReinitialisationMDPVerifier,
        reinitialisationMDPReussie: state.authentifie.reinitialisationMDPReussie,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        validationCodeVerification: (codeVerification) => dispatch( actions.validationCodeVerification(codeVerification) ),
        reinitialisationMDP: (codeVerification, nouveauMotDePasse, confirmeNouveauMotDePasse) => dispatch( actions.reinitialisationMDP(codeVerification, nouveauMotDePasse, confirmeNouveauMotDePasse) )

    };
};

export default connect(mapStateToProps,mapDispatchToProps)(ReinitialisationMDP);
