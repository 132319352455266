import React, {Component} from 'react';
import {RadialGauge} from 'canvas-gauges'
import * as S from './GaugesCompass.module.scss';

class GaugesCompass extends Component {
    componentDidMount() {
        const options = Object.assign({}, this.props, {
            renderTo: this.GaugesCompass,
            value: this.props.value,
            units: this.props.units,
            ...this.props.parametreAffichage
        })


        this.gauge = new RadialGauge(options).draw()

    }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.ratio!==prevProps.ratio){
            const options = Object.assign({}, this.props, {
                renderTo: this.GaugesCompass,
                value: this.props.value,
                units: this.props.units,
                ...this.props.parametreAffichage
            })


            this.gauge = new RadialGauge(options).draw()

        }
    }

    render() {
        return (

            // <div className={S.widget}>
            //     <div className={S.widget_head}>
            //         {this.props.widget_header}
            //     </div>
                <div className={S.widget_body}>

                    <canvas className={S.text} ref={(canvas) => {
                        this.GaugesCompass = canvas
                    }}/>
                </div>
            //     <div className={S.widget_footer}>
            //         {this.props.widget_footer}
            //     </div>
            // </div>

        )
    }
}

export default GaugesCompass;
