import {glossaire_date_format} from "../glossaires/glossaire_date_format";

export const unixTimestampToDate=(unix, date_format_id)=> {
    /**
     * converti l'unix timestamp en date lisible
     * @param unix: int date en format unix timestamp
     * @type {{month: string, hour: string, year: string, day: string, minute: string, second: string}}
     */
    let format;
    switch (date_format_id){
        case glossaire_date_format["jj/mm/aaaa"]:
            format = {day: '2-digit', month: '2-digit',  year: '2-digit'};
            break;
        case glossaire_date_format["jj/mm/aaaa hh:mm:ss"]:
            format = {day: '2-digit', month: '2-digit',  year: '2-digit',  hour: '2-digit',  minute: '2-digit',  second: '2-digit'};
            break;
        default:
            format = {day: '2-digit', month: '2-digit',  year: 'numeric',  hour: '2-digit',  minute: '2-digit',  second: '2-digit'};
            break;
    }
    if(unix === null || unix === "null" || unix === "" || isNaN(unix)){
        return null
    }else{
        return Intl.DateTimeFormat('en-GB', format).format(unix*1000)
    }
}