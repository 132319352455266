import React, {Component} from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import {connect} from "react-redux";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as S from './FormulaireSelectionGabarits.module.scss';
import * as actions from "../../../store/actions";
import {jsDictionaryEquals} from "../../../ressources/functionJS/jsDictionaryEquals";

class FormulaireSelectionGabarits extends Component {

    state = {
        list_template_id: [],

    }

    componentDidMount() {
        this.props.recupererListeGabarit(this.props.filtre_projects_list_id);
        this.setState({list_template_id: [...this.props.preconfigurerListTemplateId]})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!jsDictionaryEquals(this.props.filtre_projects_list_id, prevProps.filtre_projects_list_id)) {
            this.props.recupererListeGabarit(this.props.filtre_projects_list_id);
        }
        if (!jsDictionaryEquals(this.state.list_template_id, prevState.list_template_id)) {
            this.props.gererListTemplateId(this.state.list_template_id)
        }
    }

    gererListeIdProjet = (event) => {
        /**
         * ajoute la valeur de l'évent sélectionné dans la liste déroulante
         */
        if (event.target.value !== "unselectable" && this.state.list_template_id.indexOf(parseInt(event.target.value)) < 0) {// restreint la sélection par défaut et la redondance
            let newListeIdProjet = [...this.state.list_template_id];

            newListeIdProjet.push(parseInt(event.target.value));
            this.setState({...this.state, list_template_id: newListeIdProjet});
        } else {


            let newListeIdProjet = [...this.state.list_template_id];
            const index = this.state.list_template_id.indexOf(parseInt(event.target.value));
            if (index > -1) {
                newListeIdProjet.splice(index, 1);
            }
            this.setState({...this.state, list_template_id: newListeIdProjet});
        }
    }
    enleverProjet = (indInArray) => {
        /** via l'id du gabarit enlève le gabarit de la liste des gabarits sélectionnés
         * @idNameListe : [id, name]
         * */
            // enlève l'id du gabarit de la liste des id gabarit pour l'envoie
        let newListeIdProjet = [...this.state.list_template_id];
        newListeIdProjet.splice(indInArray, 1);
        //mise à jour de l'état de la liste d'ids à envoyer et de la liste d'affichage lors de la suppression d'un gabarit
        this.setState({...this.state, list_template_id: newListeIdProjet});
    }

    render() {
        const listeDeroulanteProjetCheck = <Auxiliaire>
            {/* <label className={S.text} htmlFor="inlineFormCustomSelect">{checkMot("liste_gabarits")}</label> */}

            <div className={S.Dib}>
                {Object.keys(this.props.list_templates).map(indGabarit => {
                    return <div className={[S.project, s.row, S.Dib].join(" ")}>
                        <input className={[s.col_1].join(" ")} type="checkbox"
                               checked={this.state.list_template_id.indexOf(this.props.list_templates[indGabarit].template_id) > -1}
                               value={this.props.list_templates[indGabarit].template_id}
                               onChange={(event) => this.gererListeIdProjet(event)}
                               disabled={this.props.desactiverModification}
                        />

                        <div className={[s.col_11, S.Dib].join(" ")}
                             key={this.props.list_templates[indGabarit].template_id}>
                            {this.props.list_templates[indGabarit].template_id + ", " + this.props.list_templates[indGabarit].template_name}
                        </div>
                    </div>
                })}
            </div>
        </Auxiliaire>;
        // récupération des informations des gabarits sélectionnés
        let information_gabarit_liste = [];
        if (this.props.list_templates !== undefined && this.state.list_template_id !== []) {
            for (let i = 0; i < this.props.list_templates.length; i++) {
                for (let j = 0; j < this.state.list_template_id.length; j++) {
                    if (this.state.list_template_id[j] === this.props.list_templates[i].template_id) {
                        information_gabarit_liste.push(this.props.list_templates[i])
                    }
                }
            }
        }

        const iconeEnlever = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  className="bi bi-file-x-fill" viewBox="0 0 16 16">
            <path fillRule="evenodd"
                  d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM6.854 6.146a.5.5 0 1 0-.708.708L7.293 8 6.146 9.146a.5.5 0 1 0 .708.708L8 8.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 8l1.147-1.146a.5.5 0 0 0-.708-.708L8 7.293 6.854 6.146z"/>
        </svg>
        return (
            <Auxiliaire>
                <div className={s.table_responsive}>
                    <table className={s.table}>
                        <thead>
                        <tr>
                            {this.props.desactiverModification
                                ? null
                                : <th scope="col">{checkMot("liste_des_gabarits")}</th>
                            }
                            <th scope="col">{checkMot("liste_des_gabarits_selectionnes")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {this.props.desactiverModification
                                ? null
                                : <td>{listeDeroulanteProjetCheck}</td>

                            }
                            <td> {Object.keys(information_gabarit_liste).map(indGabarit => {
                                return <div key={indGabarit} onClick={() => this.props.desactiverModification?null:this.enleverProjet(indGabarit)}
                                            style={this.props.desactiverModification?{}:{cursor: "pointer"}}>
                                    {this.props.desactiverModification?null:<span>{iconeEnlever}</span>}
                                    {" "+information_gabarit_liste[indGabarit].template_id + ", " + information_gabarit_liste[indGabarit].template_name}
                                </div>
                            })}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div className={s.row}> */}
                {/* <div> {listeDeroulanteProjet}</div> */}
                {/* <div className={s.col_6}>{listeDeroulanteProjetCheck}</div> */}

                {/* affiche les gabarits sélectionnés avec la possibilité de les retirer individuellement avec une croix*/}
                {/* <div className={s.col_6}> {Object.keys(information_gabarit_liste).map(indGabarit=>{ */}
                {/* return <div key={indGabarit}><span onClick={()=>this.enleverProjet(indGabarit)}>{iconeEnlever}</span> {information_gabarit_liste[indGabarit].template_id+", "+information_gabarit_liste[indGabarit].template_id}</div> */}
                {/* })}</div> */}
                {/* </div> */}
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        list_templates: state.alarme.list_templates,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id
    };
}
const mapDispatchToProps = dispatch => {
    return {
        recupererListeGabarit: (listeIdProjets) => dispatch(actions.reqListeGabarit(listeIdProjets)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionGabarits);
