import React, {Component} from 'react';

import Bouton from "../../UI/Bouton/Bouton";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";

import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";

import * as S from './ImporterCapteurCSV.module.scss';

class ImporterCapteurCSV extends Component {
    state = {
        apercus: '',
        configuration: null,
        configurationSimpleBool: true,
        creerSimple: true,
    }
    telechagementCSV = (evt) => {
        const fileObj = evt.target.files[0]; // We've not allowed multiple files.
        // See https://developer.mozilla.org/en-US/docs/Web/API/FileReader
        const reader = new FileReader();

        // Defining the function here gives it access to the fileObj constant.
        let fileloaded = e => {
            // e.target.result is the file's content as text
            // Don't trust the fileContents!
            // Test any assumptions about its contents!
            const fileContents = e.target.result;
            const first80char = fileContents.substring(0, 80);
            // affichage de l'aperçus
            this.setState({...this.state, apercus: 'First 80 characters of the file:\n' + first80char + "       ..."});

            const configurationCapteur = JSON.parse(fileContents);
            this.setState({...this.state, configuration: configurationCapteur});
            if (configurationCapteur['sensors_list'] !== undefined && configurationCapteur['sensors_list'].length > 0) {
                //si c'est une liste de plusieurs configurations
                this.setState({
                    ...this.state,
                    configurationSimpleBool: false,
                    creerSimple: false,
                })
            } else {
                // si c'est un object avec une configuration de capteur
                //envoi de la configuration simple
                if (configurationCapteur.sensor_id !== undefined) {
                    //configuration de modification de capteur
                    this.setState({
                        ...this.state,
                        configurationSimpleBool: true,
                        creerSimple: false,
                    })
                } else {
                    //configuration de création de capteur
                    this.setState({
                        ...this.state,
                        configurationSimpleBool: true,
                        creerSimple: true,
                    })
                }
            }
        }
        // todo : vérifier la sécurité
        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        // The fileloaded event handler is triggered when the read completes
        reader.onload = fileloaded;
        reader.readAsText(fileObj); // read the file
    }

    render() {
        let affichageBouton;
        if (this.state.configuration !== null) {
            //après chargement du fichier, affiche les boutons associé aux types de configurations(simple/multiple-création/modification)
            if (this.state.configurationSimpleBool) {
                if (this.state.creerSimple) {
                    //req de création d'un capteur
                    affichageBouton = <AttenteRequete request_type={requestType.creation_d_un_capteur}
                                                      str={checkMot("importation_en_cour")}>
                        <Bouton
                            cliquer={() => this.props.reqAjouter(this.state.configuration)}>{checkMot("importer_configuration_creation_capteur")}</Bouton>
                    </AttenteRequete>
                } else {
                    //req de modification d'un capteur
                    affichageBouton = <AttenteRequete request_type={requestType.modification_d_un_capteur}
                                                      str={checkMot("importation_en_cour")}>
                        <Bouton
                            cliquer={() => this.props.reqModifier(this.state.configuration)}>{checkMot("importer_configuration_modification_capteur")}</Bouton>
                    </AttenteRequete>
                }
            } else {
                //req d'importation de capteur en lot
                affichageBouton = <AttenteRequete request_type={requestType.importation_des_capteurs_en_lot}
                                                  str={checkMot("importation_en_cour")}>
                    <Bouton
                        cliquer={() => this.props.reqImportationCapteursEnLot(this.state.configuration)}>{checkMot("importer_liste_configurations_capteur")}</Bouton>
                </AttenteRequete>
            }
        }

        return (
            <Auxiliaire>
                <label htmlFor="avatar" className={S.label}>{checkMot("choisir_fichier_configuration")}</label>
                <input type="file"
                       onChange={evt => this.telechagementCSV(evt)}
                       accept=".json"/>
                <div className={S.Apercus}>
                    {this.state.apercus}
                </div>
                {affichageBouton}
            </Auxiliaire>
        );
    }
}

export default ImporterCapteurCSV
