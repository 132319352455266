import React, {Component} from 'react';
import {connect} from 'react-redux';
import exportFromJSON from 'export-from-json';
import {textFilter} from "react-bootstrap-table2-filter";

import Tableau from "../../UI/tableau/Tableau";
import Bouton from "../../UI/Bouton/Bouton";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import AccesRestreins from "../../../hoc/AccesRestreins/AccesRestreins";

import {checkMot} from "../../../ressources/functionJS/checkMot";
import {jsDictionaryEquals} from "../../../ressources/functionJS/jsDictionaryEquals";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import * as privilege from "../../../ressources/glossaires/glossaire_privileges";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../../store/actions";

import {VscNewFile, VscRefresh, VscCloudDownload, VscSearch} from "react-icons/vsc";

class TableauCapteur extends Component {
    state = {
        listExport: [],
        columns: [
            {
                dataField: 'sensor_id', text: [checkMot("capteur_id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_id", checkMot("capteur_id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'project_id', text: [checkMot("projet_id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_id", checkMot("projet_id"))}><VscSearch size={10}/>
                    </Bouton>

                ], sort: true
            },
            {
                dataField: 'type_id', text: [checkMot("id_type"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("type_id", checkMot("id_type"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'sensor_name', text: [checkMot("nom"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_name", checkMot("nom"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'sensors_group', text: [checkMot("groupe_de_capteurs"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensors_group", checkMot("groupe_de_capteurs"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'remarks', text: [checkMot("remarques"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("remarks", checkMot("remarques"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'format_date', text: [checkMot("format_date"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("format_date", checkMot("format_date"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'path_ascii_file', text: [checkMot("chemin_du_fichier_ascii"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("path_ascii_file", checkMot("chemin_du_fichier_ascii"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'position', text: [checkMot("position"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("position", checkMot("position"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'import_filter_id', text: [checkMot("filtre_importation_id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("import_filter_id", checkMot("filtre_importation_id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'emailgroup_id', text: [checkMot("id_du_groupe_d_email"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("emailgroup_id", checkMot("id_du_groupe_d_email"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'active', text: [checkMot("activite_du_capteur"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("active", checkMot("activite_du_capteur"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'sensor_projection', text: [checkMot("repere_du_capteur"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_projection", checkMot("repere_du_capteur"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'coordinate_x', text: [checkMot("coordonnee_x"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("coordinate_x", checkMot("coordonnee_x"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'coordinate_y', text: [checkMot("coordonnee_y"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("coordinate_y", checkMot("coordonnee_y"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {
                dataField: 'coordinate_z', text: [checkMot("coordonnee_z"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("coordinate_z", checkMot("coordonnee_z"))}><VscSearch size={10}/>
                    </Bouton>

                ], sort: true,
            },
            // {dataField: 'component_list', text: checkMot("component_list"), sort: true,},
        ],
        selectRow: {
            mode: 'checkbox',
            clickToSelect: true,
            style: () => {
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row, isSelect) => {
                if (isSelect) {
                    this.props.selectioneLigne(row);
                    let newListExport = [...this.state.listExport];
                    newListExport.push(row);
                    this.setState({...this.state, listExport: newListExport})
                    return true;
                } else {
                    let newListExport = [...this.state.listExport];
                    for (let i = 0; i < newListExport.length; i++) {
                        if (newListExport[i].sensor_id === row.sensor_id) {
                            newListExport.splice(i, 1);
                            break;
                        }
                    }
                    this.setState({...this.state, listExport: newListExport})
                }
            },
            onSelectAll: (isSelect, rows) => {

                // console.log("isSelect: ",isSelect);
                // console.log("rows: ",rows);
                // console.log("e: ",e);
                if (isSelect) {
                    let newListExport = [...rows];
                    //todo: attention, les capteurs caché sont également ajouté à la liste
                    this.setState({...this.state, listExport: newListExport})
                } else {
                    let newListExport = [];
                    this.setState({...this.state, listExport: newListExport})
                }
            }
        },
        iconeCreer: <VscNewFile size={15}/>
    }
    componentDidMount() {
        //récupère la liste des capteurs (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {
            this.props.recupererListeCapteurs([parseInt(this.props.project_id_url)]);
        } else if (this.props.filtre_projects_list_id.length > 0) {
            this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des capteurs (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") {
            this.props.recupererListeCapteurs([parseInt(this.props.project_id_url)]);
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
            this.props.recupererListeEmailgroups();
            this.props.recupererListeImportFilters();
        }

    }
    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }
    exporterConfiguration = () => {
        const data = {sensors_list: this.state.listExport};
        const fileName = "ArgosCapteurListExport";
        const exportType = 'json';
        exportFromJSON({data, fileName, exportType});
    }
    render() {
        let listeCleACache = [];
        if (this.props.listeCapteurs !== undefined) {
            if (this.props.interfaceAdmin) {
                for (let indLigne = 0; indLigne < this.props.listeCapteurs.length; indLigne++) {
                    if (this.props.filtre_projects_list_id.indexOf(parseInt(this.props.listeCapteurs[indLigne].project_id)) < 0) {
                        listeCleACache.push(this.props.listeCapteurs[indLigne].sensor_id)
                    }
                }
            } else {
                for (let indLigne = 0; indLigne < this.props.listeCapteurs.length; indLigne++) {
                    if (parseInt(this.props.listeCapteurs[indLigne].project_id) !== parseInt(this.props.project_id_url)) {
                        listeCleACache.push(this.props.listeCapteurs[indLigne].sensor_id)
                    }
                }
            }
        }
        return (
            <Auxiliaire>
                <Tableau
                    cleLigne={"sensor_id"}
                    privilegeCreer={[privilege.creer_des_capteurs, privilege.recuperer_list_sensor_type]}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    lignesCache={listeCleACache}
                    donnees={this.props.listeCapteurs}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                >
                    <AttenteRequete request_type={requestType.recuperation_de_la_liste_des_capteurs}
                                    str={checkMot("requete_en_cours")}>
                        <Bouton hoverText={checkMot("refresh")} type={type_btn.outil} rond={true}
                                cliquer={() => this.props.recupererListeCapteurs(this.props.filtre_projects_list_id)}>
                            <VscRefresh size={15}/>
                        </Bouton>
                    </AttenteRequete>
                    <AccesRestreins numPrivilege={privilege.modifier_des_capteurs}>
                        <Bouton type={type_btn.outil} rond={true} hoverText={checkMot("exporter")}
                                cliquer={this.exporterConfiguration}><VscCloudDownload size={15}/> </Bouton>
                    </AccesRestreins>
                    {this.props.children}
                </Tableau>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeCapteurs: state.capteur.capteursListe,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        interfaceAdmin: state.authentifie.interfaceAdmin,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        recupererListeCapteurs: (listeIdProjets) => dispatch(actions.reqRecupererListeCapteurs(listeIdProjets)),
        recupererListeEmailgroups: () => dispatch(actions.reqRecupererListeEmailgroups()),
        recupererListeImportFilters: () => dispatch(actions.reqRecupererListeImportFilters()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauCapteur);
