import React from "react";
import S from './RubriqueNavigationAdmin.module.scss';
import {Link} from "react-router-dom";

const rubriqueNavigationAdmin = props => (
    <li  className={[S.RubriqueNavigation].join(" ")} key={props.link}>
        <Link
            // to="/product-details"
            to={props.link}
            className={props.ajoutClass}>{props.children}</Link>
    </li>
);

export default rubriqueNavigationAdmin;