import React from 'react';
import Menu from '@material-ui/core/Menu';
import Bouton from "../../../composant/UI/Bouton/Bouton";
import {connect} from "react-redux";
import S from './EnTeteParametre.module.scss';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import CommutateurLangue from "../../../composant/CommutateurLangue/CommutateurLangue";
import { VscSettingsGear } from "react-icons/vsc";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";
const EnTeteParametre = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return(
    <Auxiliaire>
            <Bouton rond={true}  cliquer={handleClick}  type={type_btn.Wait}>
            <VscSettingsGear size={14}/>
            </Bouton>

            <Menu
                id="TopBar"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={props.interfaceAdmin ? S.MenuParametre_admin : S.MenuParametre_client}
            >
                <div className={S.divItem}>
                    <CommutateurLangue/>
                </div>
            </Menu>
    </Auxiliaire>

    
  );
}

const mapStateToProps = state => {
    return {
        interfaceAdmin: state.authentifie.interfaceAdmin,
    };
}
export default connect( mapStateToProps)( EnTeteParametre );