import React, {Component} from 'react';
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {
    hedes,
    ConteneurInfo,
    style,
} from './ProfilProjetsTauxCriticite.module.scss';

class ProfilProjetsTauxCriticite extends Component {
    render() {
        return (
            <div className={ConteneurInfo}>
                {/*entête du profil rapport généré*/}
                <div className={[s.card_header, hedes].join(" ")}>
                    {/* logo "i"*/}
                    <span className={style}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-info-circle-fill"
                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>  
                    </span>
                    <span className={style}>
                    {/*l'id de l'rapport généré*/}
                        {checkMot("generate_report_id")} : {this.props.infoRapportGenere.generate_report_id}
                    </span>
                </div>
                {/*conteneur pour l'affichage général du conteneur info*/}
                <div className={s.container}>

                </div>
            </div>
        )

    }
}

export default ProfilProjetsTauxCriticite;


