import React, {Component} from 'react';
import {connect} from "react-redux";

import {jsDictionaryEquals} from "../../../../../../../../ressources/functionJS/jsDictionaryEquals";
import * as S from "./TableauAlarmesRapport.module.scss";
import * as actions from "../../../../../../../../store/actions";
import Collapse from "../../../../../../../../hoc/Collapse/Collapse";
import {checkMot} from "../../../../../../../../ressources/functionJS/checkMot";
import Tableau from "../../../../../../../UI/tableau/Tableau";
import Bouton from "../../../../../../../UI/Bouton/Bouton";
import * as type_btn from "../../../../../../../../ressources/glossaires/glossaire_type_de_bouton";
import {VscSearch} from "react-icons/vsc";
import {textFilter} from "react-bootstrap-table2-filter";
import * as glossaire_type_export from "../../../../../../../../ressources/glossaires/glossaire_type_export";
import {AiOutlineLineChart} from "react-icons/ai";

class TableauAlarmesRapport extends Component {
    state = {
        columns: [
            {
                dataField: 'cle_unique', text: [checkMot("cle_unique")], sort: true, hidden: true
            },
            {
                dataField: 'sensor_id', text: [checkMot("id")], sort: true
            },
            {
                dataField: 'position_in_sensor', text: [checkMot("position_in_sensor")], sort: true
            },
            // {
            //     dataField: 'private', text: checkMot("propriete") + " ", sort: true,
            //     formatter: (cell) => {
            //         switch (parseInt(cell)) {
            //             case 1:
            //                 return checkMot("prive")
            //             case 0:
            //                 return checkMot("public")
            //             default:
            //                 return checkMot(cell)
            //         }
            //     },
            //     filter: selectFilter({
            //         options: {
            //             1: checkMot("prive"),
            //             0: checkMot("public")
            //         },
            //         placeholder: checkMot("selectionner")
            //     })
            //
            // },
            // {
            //     dataField: 'graph_type_id', text: checkMot("type_de_graphe") + " ", sort: true,
            //     formatter: (cell) => {
            //         switch (parseInt(cell)) {
            //             case glossaire_type_de_graphe.graphe_x_t:
            //                 return checkMot("xt")
            //             case glossaire_type_de_graphe.graphe_x_y:
            //                 return checkMot("xy")
            //             case glossaire_type_de_graphe.graphe_x_y_t:
            //                 return checkMot("xyt")
            //             case glossaire_type_de_graphe.graphe_avancement_detaille:
            //                 return checkMot("avancement_detaille")
            //             case glossaire_type_de_graphe.graphes_avancement_jour_semaine_mois:
            //                 return checkMot("avancement_jour_semaine_et_mois")
            //             default:
            //                 return checkMot(cell)
            //         }
            //     },
            //     filter: selectFilter({
            //         options:{
            //             [glossaire_type_de_graphe.graphe_x_t]: checkMot("xt"),
            //             [glossaire_type_de_graphe.graphe_x_y]: checkMot("xy"),
            //             [glossaire_type_de_graphe.graphe_x_y_t]: checkMot("xyt"),
            //             [glossaire_type_de_graphe.graphe_avancement_detaille]: checkMot("avancement_detaille"),
            //             [glossaire_type_de_graphe.graphes_avancement_jour_semaine_mois]: checkMot("avancement_jour_semaine_et_mois")
            //         },
            //         placeholder: checkMot("selectionner")
            //     })
            // },
        ],
        columns_config: [
            {
                dataField: 'index', text: [checkMot("index"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("index", checkMot("index"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell, row, rowIndex) => {
                    return <span>{rowIndex}<Bouton desactive={rowIndex===0} cliquer={()=> {
                        let new_sensors_list_config = [...this.state.sensors_list_config]
                        const stock = {...new_sensors_list_config[rowIndex]}
                        new_sensors_list_config[rowIndex]=new_sensors_list_config[rowIndex-1]
                        new_sensors_list_config[rowIndex-1]=stock
                        this.setState({sensors_list_config:new_sensors_list_config})
                    }}>^</Bouton><Bouton desactive={rowIndex===this.state.sensors_list_config.length-1} cliquer={()=> {
                        let new_sensors_list_config = [...this.state.sensors_list_config]
                        const stock = {...new_sensors_list_config[rowIndex]}
                        new_sensors_list_config[rowIndex]=new_sensors_list_config[rowIndex+1]
                        new_sensors_list_config[rowIndex+1]=stock
                        this.setState({sensors_list_config:new_sensors_list_config})
                    }}>v</Bouton></span>;
                },
            },
            {
                dataField: 'cle_unique', text: [checkMot("cle_unique"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("cle_unique", checkMot("cle_unique"))}><VscSearch
                        size={10}/>
                    </Bouton>
                ], sort: true, hidden: true
            },
            {
                dataField: 'sensor_id', text: [checkMot("id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_id", checkMot("id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'sensor_name', text: checkMot("sensor_name"), sort: true,
                formatter: (cell, row) => {
                    for (let i = 0; i < this.props.listeCapteurs.length; i++) {
                        if (this.props.listeCapteurs[i].sensor_id === row.sensor_id) {
                            return this.props.listeCapteurs[i].sensor_name
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'position_in_sensor', text: [checkMot("position_in_sensor"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("position_in_sensor", checkMot("position_in_sensor"))}><VscSearch
                        size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'prefix', text: checkMot("prefix"), sort: true,
                formatter: (cell, row) => {
                    for (let i = 0; i < this.props.listeCapteurs.length; i++) {
                        if (this.props.listeCapteurs[i].sensor_id === row.sensor_id) {
                            for (let j = 0; j < this.props.listeCapteurs[i].component_list.length; j++) {
                                if (this.props.listeCapteurs[i].component_list[j].position_in_sensor === row.position_in_sensor) {
                                    return this.props.listeCapteurs[i].component_list[j].prefix
                                }
                            }
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'bouton_supprimer',
                text: [checkMot("bouton_supprimer")],
                sort: true,
                hidden: this.props.affichageProfil
            },
        ],
        columns_config_selection: [
            {
                dataField: 'sensor_id', text: [checkMot("id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltreConfig("sensor_id", checkMot("id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'sensor_name', text: [checkMot("sensor_name"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltreConfig("sensor_name", checkMot("sensor_name"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'composantes', text: checkMot("composantes"), sort: true,
            }
        ],
        ...this.props.preconfigureObjet,
        sensors_list_config: []
    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigureObjet === undefined) {
            this.setState({
                //paramètre par défaut du rapport gabarit(voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigureObjet !== undefined) {
            let liste_capteur_composante = []
            if (this.props.preconfigureObjet.sensors_list !== undefined) {
                liste_capteur_composante = this.props.preconfigureObjet.sensors_list.map(composante => {
                        return {
                            ...composante, cle_unique: composante.sensor_id + "_" + composante.position_in_sensor,
                            bouton_supprimer: <Bouton
                                cliquer={() => this.supprimerComposante(composante.sensor_id + "_" + composante.position_in_sensor)}>sup</Bouton>
                        }
                    }
                )
            }
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigureObjet,
                sensors_list_config: liste_capteur_composante
            })
        }
        if (this.props.filtre_projects_list_id.length > 0) {
            this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigureObjet !== this.props.preconfigureObjet && this.props.preconfigureObjet === undefined) {
            this.setState({
                //todo: paramètre par défaut
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigureObjet !== undefined && !jsDictionaryEquals(prevProps.preconfigureObjet, this.props.preconfigureObjet)) {
            let liste_capteur_composante = []
            if (this.props.preconfigureObjet.sensors_list !== undefined) {
                liste_capteur_composante = this.props.preconfigureObjet.sensors_list.map(composante => {
                        return {
                            ...composante, cle_unique: composante.sensor_id + "_" + composante.position_in_sensor,
                            bouton_supprimer: <Bouton
                                cliquer={() => this.supprimerComposante(composante.sensor_id + "_" + composante.position_in_sensor)}>sup</Bouton>
                        }
                    }
                )
            }
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigureObjet,
                sensors_list_config: liste_capteur_composante
            })
        }
        if (!jsDictionaryEquals(prevState, this.state)) {
            let new_sensors_list = this.state.sensors_list_config.map(capteur => {
                return {
                    sensor_id: capteur.sensor_id,
                    position_in_sensor: capteur.position_in_sensor
                }

            })
            let remonte_info = {}
            for (let i of Object.keys(this.props.preconfigureObjet)){
                remonte_info[i]=this.state[i]
            }
            this.props.gererJson({
                ...remonte_info,
                sensors_list: new_sensors_list
            })
        }
        if (this.props.filtre_projects_list_id !== prevProps.filtre_projects_list_id) {
            this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
        }
        if (this.props.affichageProfil!==prevProps.affichageProfil){
            //mise à jour de la liste de colonnes si le props affichageProfil change
            this.setState({
                ...this.state,
                columns_config: [
                    {
                        dataField: 'cle_unique', text: [checkMot("cle_unique"),
                            <Bouton type={type_btn.lupeSearch}
                                    cliquer={() => this.afficherFiltre("cle_unique", checkMot("cle_unique"))}><VscSearch
                                size={10}/>
                            </Bouton>
                        ], sort: true, hidden: true
                    },
                    {
                        dataField: 'sensor_id', text: [checkMot("id"),
                            <Bouton type={type_btn.lupeSearch}
                                    cliquer={() => this.afficherFiltre("sensor_id", checkMot("id"))}><VscSearch size={10}/>
                            </Bouton>
                        ], sort: true
                    },
                    {
                        dataField: 'sensor_name', text: checkMot("sensor_name"), sort: true,
                        formatter: (cell, row) => {
                            for (let i = 0; i < this.props.listeCapteurs.length; i++) {
                                if (this.props.listeCapteurs[i].sensor_id === row.sensor_id) {
                                    return this.props.listeCapteurs[i].sensor_name
                                }
                            }
                            return cell;
                        },
                    },
                    {
                        dataField: 'position_in_sensor', text: [checkMot("position_in_sensor"),
                            <Bouton type={type_btn.lupeSearch}
                                    cliquer={() => this.afficherFiltre("position_in_sensor", checkMot("position_in_sensor"))}><VscSearch
                                size={10}/>
                            </Bouton>
                        ], sort: true
                    },
                    {
                        dataField: 'prefix', text: checkMot("prefix"), sort: true,
                        formatter: (cell, row) => {
                            for (let i = 0; i < this.props.listeCapteurs.length; i++) {
                                if (this.props.listeCapteurs[i].sensor_id === row.sensor_id) {
                                    for (let j = 0; j < this.props.listeCapteurs[i].component_list.length; j++) {
                                        if (this.props.listeCapteurs[i].component_list[j].position_in_sensor === row.position_in_sensor) {
                                            return this.props.listeCapteurs[i].component_list[j].prefix
                                        }
                                    }
                                }
                            }
                            return cell;
                        },
                    },
                    {
                        dataField: 'bouton_supprimer',
                        text: [checkMot("bouton_supprimer")],
                        sort: true,
                        hidden: this.props.affichageProfil
                    },
                ],
            })
        }
        if (!jsDictionaryEquals(prevState.sensors_list_config, this.state.sensors_list_config)){
            //mise à jour de la liste de colonnes si le state sensors_list_config change
            this.setState({
                ...this.state,
                columns_config_selection: [
                    {
                        dataField: 'sensor_id', text: [checkMot("id"),
                            <Bouton type={type_btn.lupeSearch}
                                    cliquer={() => this.afficherFiltreConfig("sensor_id", checkMot("id"))}><VscSearch size={10}/>
                            </Bouton>
                        ], sort: true
                    },
                    {
                        dataField: 'sensor_name', text: [checkMot("sensor_name"),
                            <Bouton type={type_btn.lupeSearch}
                                    cliquer={() => this.afficherFiltreConfig("sensor_name", checkMot("sensor_name"))}><VscSearch size={10}/>
                            </Bouton>
                        ], sort: true
                    },
                    {
                        dataField: 'composantes', text: checkMot("composantes"), sort: true,
                    }
                ],
            })
        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns_config.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns_config: [...newCol]
        })
    }
    afficherFiltreConfig = (dataField, placeholder) => {
        const newCol = this.state.columns_config_selection.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns_config_selection: [...newCol]
        })
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value});
    supprimerComposante = (cle_unique) => {
        let newListe_capteur_composante = [...this.state.sensors_list_config];
        for (let indComposante = 0; indComposante < this.state.sensors_list_config.length; indComposante++) {
            if (cle_unique === this.state.sensors_list_config[indComposante].cle_unique) {
                newListe_capteur_composante.splice(indComposante, 1)
            }
        }
        this.setState({...this.state, sensors_list_config: newListe_capteur_composante,columns_config_selection: [
                {
                    dataField: 'sensor_id', text: [checkMot("id"),
                        <Bouton type={type_btn.lupeSearch}
                                cliquer={() => this.afficherFiltreConfig("sensor_id", checkMot("id"))}><VscSearch size={10}/>
                        </Bouton>
                    ], sort: true
                },
                {
                    dataField: 'sensor_name', text: [checkMot("sensor_name"),
                        <Bouton type={type_btn.lupeSearch}
                                cliquer={() => this.afficherFiltreConfig("sensor_name", checkMot("sensor_name"))}><VscSearch size={10}/>
                        </Bouton>
                    ], sort: true
                },
                {
                    dataField: 'composantes', text: checkMot("composantes"), sort: true,
                }
            ],})
    }
    render() {
        let listeComposanteSelection = this.props.listeCapteurs.map(capteur=>{

            let liste_checkbox = []
            for (let j = 0; j < capteur.component_list.length; j++) {
                const lienGrapheAuto = <a href={""} onClick={(event) => {
                    event.preventDefault();
                    window.open("/export_impression_ecran-"+glossaire_type_export.graphe_automatique+"-" + capteur.sensor_id + "_" + capteur.component_list[j].position_in_sensor, "_blank", "toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,outerWidth=" + (window.outerWidth / 4) + ",outerHeight=" + (window.outerHeight / 4));
                }}>
                    {/*{capteur.component_list[j].position_in_sensor + ":" + capteur.component_list[j].prefix}*/}
                    {<AiOutlineLineChart/>}
                </a>
                if (this.state.sensors_list_config.find((composante_selectione)=> composante_selectione.cle_unique === capteur.sensor_id+"_"+capteur.component_list[j].position_in_sensor)) {
                    liste_checkbox.push([<Bouton desactive={"desable"}>{capteur.component_list[j].position_in_sensor+":"+capteur.component_list[j].prefix}</Bouton>, lienGrapheAuto])
                }else {
                    liste_checkbox.push([<Bouton cliquer={()=>{
                        let new_sensors_list_config=[...this.state.sensors_list_config]
                        new_sensors_list_config.push({
                            cle_unique: capteur.sensor_id + "_" + capteur.component_list[j].position_in_sensor,
                            sensor_id: capteur.sensor_id,
                            position_in_sensor: capteur.component_list[j].position_in_sensor,
                            bouton_supprimer: <Bouton
                                cliquer={() => this.supprimerComposante(capteur.sensor_id + "_" + capteur.component_list[j].position_in_sensor)}>sup</Bouton>
                        })
                        this.setState({sensors_list_config:new_sensors_list_config})
                    }}>{capteur.component_list[j].position_in_sensor+":"+capteur.component_list[j].prefix}</Bouton>, lienGrapheAuto])
                }
            }

            return {
                ...capteur,
                composantes: liste_checkbox
            }
        })
        return (
            <Collapse ExpandText={
                <div className={S.contenuEnTete}>
                    {this.props.contenuEnTete}
                    <span className={S.titre}>{this.state.text}</span>
                    {/*    todo: mettre un tableau de données composantes avec nom capteur et préfix composante*/}
                    <Tableau
                        cleLigne={"cle_unique"}
                        donnees={this.state.sensors_list_config}
                        colonnes={this.state.columns}
                        hide_general_search={true}
                    />
                </div>
            } ExpandTextOuvert={
                <div className={S.contenuEnTete}>
                    {this.props.contenuEnTete}
                    <span className={S.titre}>{this.state.text}</span>
                </div>
            }>
                {/* Todo: composant de configuration de la liste de capteurs/composants */}
                <div className={S.ConteneurHorizontal}>
                    <div className={S.ConteneurCentralGauche}>
                        <Tableau
                            cleLigne={"cle_unique"}
                            donnees={this.state.sensors_list_config}
                            colonnes={this.state.columns_config}
                            hide_general_search={true}
                        />
                    </div>
                    <div className={S.ConteneurCentralDroite}>
                        <Tableau
                            cleLigne={"sensor_id"}
                            donnees={listeComposanteSelection}
                            colonnes={this.state.columns_config_selection}
                            hide_general_search={true}
                        />
                    </div>
                </div>
                {this.props.children}
            </Collapse>
        );
    }
}


const mapStateToProps = state => {
    return {
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        listeCapteurs: state.capteur.capteursListe,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        recupererListeCapteurs: (listeIdProjets) => dispatch(actions.reqRecupererListeCapteurs(listeIdProjets)),
        reqCreerRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
        reqModifierRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauAlarmesRapport);
