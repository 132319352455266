import React, {Component} from "react";
import {connect} from "react-redux";
import S from "./RubriquesNavigationAdmin.module.scss";
import * as s from "../../../ressources/StyleGlobal/StyleGlobal";
import RubriqueNavigation from "./RubriqueNavigationAdmin/RubriqueNavigationAdmin";
import * as url from "../../../ressources/glossaires/glossaire_liens_pages";
import {Link} from "react-router-dom";
import Modal from "react-modal";
import Filtre from "../../FiltreParProjet/FiltreParProjet"
import Bouton from "../../UI/Bouton/Bouton";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";
import {checkMot} from "../../../ressources/functionJS/checkMot";

import {RiFolderOpenFill, RiLineChartFill, RiTreasureMapFill} from "react-icons/ri";
import {BsFilter} from "react-icons/bs";
import {GiAutoRepair, GiMovementSensor} from "react-icons/gi";
import {FaUsers} from "react-icons/fa";
import {MdNotificationsActive} from "react-icons/md";
import {HiOutlineDocumentReport} from "react-icons/all";

class rubriquesNavigationAdmin extends Component {
    state = {
        showModal: false,//todo false par défaut
    };
    handleOpenModal = (event) => {
        event.preventDefault()
        this.setState({showModal: true});
    }
    handleCloseModal = (event) => {
        event.preventDefault()
        this.setState({showModal: false});
    }
    render() {
        let FiltreActive = [S.normal];
        if (this.props.filtre_projects_list_id !== null && this.props.filtre_projects_list_id.length > 0) {
            FiltreActive.push(S.actif)
        }
        return (
            <ul className={[S.RubriquesNavigation].join(" ")}>
                <li className={[S.RubriqueNavigation].join(" ")} key={"projet"}>
                    <Link to={url.projet}>
                        <div className={s.row}>

                            <div className={s.col_3}>

                                <RiFolderOpenFill size={25}/>

                            </div>

                            <div className={[s.col_9, s.text_left].join(" ")}>
                                {checkMot("projet")}
                            </div>
                        </div>
                    </Link>
                </li>
                <span className={FiltreActive.join(" ")} key={"filtre"}>
                    <Link to={url.filtre} onClick={(e) => this.handleOpenModal(e)}>
                    <div className={s.row}>
                   
                            <div className={s.col_3}>
                            
                            <BsFilter size={25}/> 
                        
                            </div>
                            
                            <div className={[s.col_9, s.text_left, S.FiltreActive].join(" ")}>
                            {checkMot("filtre")} 
                            </div>
                        </div>
                        
                        <strong>{(this.props.filtre_projects_list_id.join(", "))}</strong>
                    </Link>
                </span>
                <Modal isOpen={this.state.showModal} className={S.Modal} overlayClassName={S.Overlay}
                       onRequestClose={(e) => this.handleCloseModal(e)}>
                    <Bouton ajoutClass={[S.btsFermer].join(" ")} type={type_btn.fermer} rond={true}
                            cliquer={(e) => this.handleCloseModal(e)}/>
                    {/* <span onClick={} className={S.btsFermer}><CgCloseO size={30}/></span> */}
                    <Filtre/>
                </Modal>
                <RubriqueNavigation link={url.capteur}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <GiMovementSensor size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("capteur")}
                        </div>
                    </div>
                </RubriqueNavigation>
                <RubriqueNavigation link={url.graphe}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <RiLineChartFill size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("graphe")}
                        </div>
                    </div>
                </RubriqueNavigation>
                <RubriqueNavigation link={url.vue}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <RiTreasureMapFill size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("vue")}
                        </div>
                    </div>
                </RubriqueNavigation>
                <RubriqueNavigation link={url.alarme}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <MdNotificationsActive size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("alarme")}
                        </div>
                    </div>
                </RubriqueNavigation>
                <RubriqueNavigation link={url.rapport}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <HiOutlineDocumentReport size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("rapport")}
                        </div>
                    </div>
                </RubriqueNavigation>
                <RubriqueNavigation link={url.utilisateur}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <FaUsers size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("utilisateur")}
                        </div>
                    </div>
                </RubriqueNavigation>
                <RubriqueNavigation link={url.maintenance}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <GiAutoRepair size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("maintenance")}
                        </div>
                    </div>
                </RubriqueNavigation>
                {/* <RubriqueNavigation link={url.historique}>
                    <div className={s.row}>
                        <div className={s.col_3}>
                            <RiFileHistoryLine size={25}/>
                        </div>
                        <div className={[s.col_9, s.text_left].join(" ")}>
                            {checkMot("historique")}
                        </div>
                    </div>
                </RubriqueNavigation> */}
            </ul>
        )
    }
}

const mapStateToProps = state => {
    return {
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id
    };
}

export default connect(mapStateToProps)(rubriquesNavigationAdmin);