import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

export const initialState = {
    utilisateursListe: [],//todo liste vide de base
};

const ajouterUtilisateur = (state) => {
    let newModificationListe = [...state.modificationListe];
    newModificationListe.push({
        test:"modification" //todo faire un vrai test d'ajout
    });
    return updateObject( state, {modificationListe: newModificationListe} );
};
const enregistrerListeUtilisateurs = (state, action) => {
    return updateObject( state, {utilisateursListe: action.utilisateursListe});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AJOUTER_UTILISATEUR: return ajouterUtilisateur(state, action);
        case actionTypes.ENREGISTRER_LISTE_UTILISATEURS: return enregistrerListeUtilisateurs(state, action);
        default:
            return state;
    }
};

export default reducer;
