import React, {Component} from "react";
import ObjectExport from "./ObjectExport/ObjectExport"
import * as S from "./CatalogueObject.module.scss"


import bouton_lien_audio_JPG from "../../ressources/imgCatalogueExemple/text/TextAudio.JPG";
import bouton_lien_video_JPG from "../../ressources/imgCatalogueExemple/text/TextVideo.JPG";
import bouton_lien_img_JPG from "../../ressources/imgCatalogueExemple/text/TextImg.JPG";
import CapteurDefaut from "../../ressources/imgCatalogueExemple/capteur/CaptureDefaut.JPG";
import CapteurDefaut1 from "../../ressources/imgCatalogueExemple/capteur/CaptureDefaut1.JPG";
import CapteurNotification from "../../ressources/imgCatalogueExemple/capteur/CaptureNotification.JPG";
import CaptureRectangulaire from "../../ressources/imgCatalogueExemple/capteur/CaptureRectangulaire.JPG";
import CaptureRectangulaireVert from "../../ressources/imgCatalogueExemple/capteur/CaptureRectangulaireVert.JPG";

import VueNomVueRediction from "../../ressources/imgCatalogueExemple/capteur/VueNomVueRediction.JPG";
import VueBottonRediction from "../../ressources/imgCatalogueExemple/capteur/VueBottonRediction.JPG";
import VueNomVueSwitch from "../../ressources/imgCatalogueExemple/capteur/VueNomVueSwitch.JPG";

import WidgetsRoseDesVents from "../../ressources/imgCatalogueExemple/capteur/WidgetsRoseDesVents.JPG";
import WidgetsBarDeProgression from "../../ressources/imgCatalogueExemple/capteur/WidgetsBarDeProgression.JPG";

import Audio from "./objetsVue/texte_bouton_lien_audio.json";
import Video from "./objetsVue/texte_bouton_lien_video.json";
import Image from "./objetsVue/texte_bouton_lien_image.json";
import bouton_de_redirection from "./objetsVue/bouton_de_redirection.json";
import bouton_de_redirection_rond from "./objetsVue/bouton_de_redirection_rond.json";
import bouton_switch_text from "./objetsVue/bouton_switch_text.json";
import bouton_carre_vert_25px from "./objetsVue/bouton_carre_vert_25px.json";
import Rectangle_dernier_donne from "./objetsVue/Rectangle_dernier_donne.json";
import graphe_de_curbes from "./objetsVue/graphe_de_curbes.json";
import GaugesCompass from "./objetsVue/GaugesCompass.json";
import progresse_bar from "./objetsVue/progresse_bar.json";
import bar_de_progretion_vertical_termometre from "./objetsVue/bar_de_progretion_vertical_termometre.json";
import IconGrapheDefaut from "../../ressources/imgCatalogueExemple/capteur/IconGrapheDefaut.JPG";

import GrapheXTBar from "../../ressources/imgCatalogueExemple/Graphe/GrapheXTBar.JPG";
import GrapheXTLine from "../../ressources/imgCatalogueExemple/Graphe/GrapheXTLine.JPG";
import GrapheXTPie from "../../ressources/imgCatalogueExemple/Graphe/GrapheXTPie.JPG";
import GrapheAngularGauge from "../../ressources/imgCatalogueExemple/Graphe/GrapheAngularGauge.JPG";


// import * as s from '../../ressources/StyleGlobal/StyleGlobal';
import { SiAudiomack } from "react-icons/si";


class CatalogueObject extends Component {
    componentDidMount() {
     
    }
    componentDidUpdate() {
        
    }

    state = {
       
    }
    render(){
        return(
            <div className={S.master}>
                <div className={S.cataHead}>
                    <h3 className={S.hederCat}>Catalogue des Objets</h3>
                </div>
               <div className={ [S.flexcontainer, S.flex_container_style].join(" ")}>
                    <div className={S.flex_item}>
                            <h5 className={S.text}>Lien Externe</h5>
                                    <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="fichier audio"
                                                ImgObj = {<img src={bouton_lien_audio_JPG} alt=""/>}
                                                DescriptionObj="Bouton fichier audio"
                                                JsonCodeObj = {JSON.stringify(Audio)}
                                            />  
                                    </div>
                                    <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="fichier video"
                                                ImgObj={<img src={bouton_lien_video_JPG} alt=""/>}
                                                DescriptionObj="Bouton fichier video"
                                                JsonCodeObj = {JSON.stringify(Video)}
                                            />  
                                    </div>
                                    <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="fichier d'image"
                                                ImgObj={<img src={bouton_lien_img_JPG} alt=""/>}
                                                DescriptionObj="Bouton fichier Image"
                                                JsonCodeObj = {JSON.stringify(Image)}
                                            />  
                                    </div>
                                    <h5 className={S.text}>Vue</h5>
                                    <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="fichier d'image"
                                                ImgObj={<img src={VueNomVueRediction} alt="Redirection sur une vue"/>}
                                                DescriptionObj="Redirection sur une vue "
                                                JsonCodeObj = {JSON.stringify(bouton_de_redirection)}
                                            />  
                                    </div>
                                    <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="fichier d'image"
                                                ImgObj={<img src={VueBottonRediction} alt="Bouton de Redirection"/>}
                                                DescriptionObj="Bouton de Redirection"
                                                JsonCodeObj = {JSON.stringify(bouton_de_redirection_rond)}
                                            />  
                                    </div>
                                    <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="fichier d'image"
                                                ImgObj={<img src={VueNomVueSwitch} alt="Interrupteur"/>}
                                                DescriptionObj="Interrupteur"
                                                JsonCodeObj = {JSON.stringify(bouton_switch_text)}
                                            />  
                                    </div>
                    </div>
                    <div className={S.flex_item}>
                        <h5 className={S.text}>Capteur</h5>
                            <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="Icône circulaire"
                                                ImgObj={<img src={CapteurDefaut} alt="Icône circulaire"/>}
                                                DescriptionObj="Icône circulaire"
                                                JsonCodeObj='par défaut'
                                            />  
                                </div>
                                <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="Icône carré"
                                                ImgObj={<img src={CapteurDefaut1} alt="Icône carré"/>}
                                                DescriptionObj="Icône carré"
                                                JsonCodeObj={JSON.stringify(bouton_carre_vert_25px)}
                                            />  
                                </div>
                              
                                <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="Icône notification"
                                                ImgObj={<img src={CapteurNotification} alt="Icône notification"/>}
                                                DescriptionObj="Icône notification"
                                                JsonCodeObj='capteur notification'
                                            />  
                                </div>
                                <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="Capteur Type rectangulaire à composantes"
                                                ImgObj={<img src={CaptureRectangulaire} alt="Capteur Type rectangulaire à composantes"/>}
                                                DescriptionObj="Capteur Type rectangulaire à composantes"
                                                JsonCodeObj ="Capteur Type rectangulaire à composantes"
                                            />  
                                </div>
                                <div className={S.object}>
                                            <ObjectExport 
                                                TitleObj="Capteur afficheur"
                                                ImgObj={<img src={CaptureRectangulaireVert} alt="Capteur Type afficheur"/>}
                                                DescriptionObj="Capteur Type afficheur"
                                                JsonCodeObj = {JSON.stringify(Rectangle_dernier_donne)}
                                            />  
                                </div>
                   </div>
                    <div className={S.flex_item}>
                        <h5 className={S.text}>Graphe</h5>
                        <div className={S.object}>
                                        <ObjectExport 
                                                TitleObj="Icône graphe"
                                                ImgObj={<img src={IconGrapheDefaut} alt="Icône graphe"/>}
                                                DescriptionObj="Icône graphe"
                                                JsonCodeObj='Icône graphe'
                                            />  
                        </div>
                    
                        <div className={S.object}>
                                        <ObjectExport 
                                                TitleObj="Graphe XT type Ligne"
                                                ImgObj={<img src={GrapheXTLine} alt=""/>}
                                                DescriptionObj="Graphe XT type Ligne"
                                                JsonCodeObj = {JSON.stringify(graphe_de_curbes)}
                                            />  
                        </div>
                        <div className={S.object}>
                                        <ObjectExport 
                                                TitleObj="Graphe XT type Barre"
                                                ImgObj={<img src={GrapheXTBar} alt="Graphe XT type Barre"/>}
                                                DescriptionObj="Graphe XT type Barre"
                                                JsonCodeObj='Graphe XT type Barre'
                                            />  
                        </div>
                        {/* <div className={S.object}>
                                        <Object 
                                                TitleObj="Diagramme circulaire"
                                                ImgObj={<img src={GrapheXTPie}/>}
                                                DescriptionObj="Diagramme circulaire"
                                                JsonCodeObj='Diagramme circulaire'
                                            />  
                        </div> */}
                        <div className={S.object}>
                                        <ObjectExport 
                                                TitleObj="Jauge Angulaire"
                                                ImgObj={<img src={GrapheAngularGauge} alt="Jauge Angulaire"/>}
                                                DescriptionObj="Jauge Angulaire"
                                                JsonCodeObj = {JSON.stringify(progresse_bar)}
                                            />  
                        </div>
                    </div>
                    <div className={S.flex_item}>
                    <h5 className={S.text}>Widgets</h5>
                    
                        <div className={S.object}>
                                        <ObjectExport 
                                                TitleObj="Barre de Progression"
                                                ImgObj={<img src={WidgetsBarDeProgression} alt="Widgets barre de Progression"/>}
                                                DescriptionObj="Widgets barre de Progression"
                                                JsonCodeObj = {JSON.stringify(bar_de_progretion_vertical_termometre)}
                                            />  
                                               <ObjectExport 
                                                TitleObj="Rose Des Vents"
                                                ImgObj={<img src={WidgetsRoseDesVents} alt="Widgets rose des Vents"/>}
                                                DescriptionObj="Widgets rose des Vents" 
                                                GaugesCompass
                                                JsonCodeObj = {JSON.stringify(GaugesCompass)}
                                            />  
                            </div>
                    
                    </div>
                </div>
            </div>
        )
    }
   

}



export default CatalogueObject;
