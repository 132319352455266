export const IsArrayString = (str, defaultValue) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        if (defaultValue!==undefined){
            return str
        }else{
            return defaultValue;
        }
    }
}