import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from "axios";
import * as actions from "./index";
import * as actionTypes from './actionTypes';
import {store} from "../../index";

const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) => {
    if (res.data.errors !== undefined) {
        for (let erreurKey = 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre, ["ApiEchecErreursEnBoucle", {
                numAvertissement: erreurKey,
                idErreur: res.data.errors[erreurKey]
            }]));
        }
    } else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api", "errors est undefine, pour une requête de connexion"));
    }
}
const requetePost = (dispatch, requeteAPI) => {
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */

    const state = store.getState();
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios.post("", requeteAPI, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            console.log('envoi: ', JSON.parse(res.config.data));
            console.log('reception: ', res);
            let titreAlerteSucces = "Requête api succès";
            let corpsAlerteSucces = "Requête api succès";
            let titreAlerteEchec = "Requête api échec";
            let corpsAlerteEchec = "Requête api échec";
            let succesRequete = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            let succesRequeteAvecErreur = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            let echecRequete = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            switch (requeteAPI.request_type) {
                case requestType.recuperer_la_liste_des_alarmes:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_alarmes";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_alarmes";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_alarmes";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_alarmes";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeAlarmes(res.data));

                    break;
                case requestType.confirmer_une_alarme:
                    titreAlerteSucces = "ApiSuccesTitre_confirmer_une_alarme";
                    corpsAlerteSucces = "ApiSuccesCorps_confirmer_une_alarme";
                    titreAlerteEchec = "ApiEchecTitre_confirmer_une_alarme";
                    corpsAlerteEchec = "ApiEchecCorps_confirmer_une_alarme";

                    // succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
                    break;
                case requestType.recuperer_la_liste_des_groupes_d_email:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_groupes_d_email";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_groupes_d_email";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_groupes_d_email";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_groupes_d_email";

                    succesRequete.actionReducteur = () => dispatch(enregistrerListeEmailsGroupes(res.data));
                    break;
                case requestType.creer_un_groupe_d_email:
                    titreAlerteSucces = "ApiSuccesTitre_creer_un_groupe_d_email";
                    corpsAlerteSucces = "ApiSuccesCorps_creer_un_groupe_d_email";
                    titreAlerteEchec = "ApiEchecTitre_creer_un_groupe_d_email";
                    corpsAlerteEchec = "ApiEchecCorps_creer_un_groupe_d_email";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.email_group_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.email_group_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.modifier_un_groupe_d_email:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_un_groupe_d_email";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_un_groupe_d_email";
                    titreAlerteEchec = "ApiEchecTitre_modifier_un_groupe_d_email";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_un_groupe_d_email";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.graph_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.graph_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.supprimer_un_groupe_d_email:
                    titreAlerteSucces = "ApiSuccesTitre_supprimer_un_groupe_d_email";
                    corpsAlerteSucces = "ApiSuccesCorps_supprimer_un_groupe_d_email";
                    titreAlerteEchec = "ApiEchecTitre_supprimer_un_groupe_d_email";
                    corpsAlerteEchec = "ApiEchecCorps_supprimer_un_groupe_d_email";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.graph_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.graph_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.creer_un_gabarit:
                    titreAlerteSucces = "ApiSuccesTitre_creer_un_gabarit";
                    corpsAlerteSucces = "ApiSuccesCorps_creer_un_gabarit";
                    titreAlerteEchec = "ApiEchecTitre_creer_un_gabarit";
                    corpsAlerteEchec = "ApiEchecCorps_creer_un_gabarit";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.template_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.template_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.modifier_un_gabarit:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_un_gabarit";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_un_gabarit";
                    titreAlerteEchec = "ApiEchecTitre_modifier_un_gabarit";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_un_gabarit";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.template_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.template_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.supprimer_un_gabarit:
                    titreAlerteSucces = "ApiSuccesTitre_supprimer_un_gabarit";
                    corpsAlerteSucces = "ApiSuccesCorps_supprimer_un_gabarit";
                    titreAlerteEchec = "ApiEchecTitre_supprimer_un_gabarit";
                    corpsAlerteEchec = "ApiEchecCorps_supprimer_un_gabarit";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.template_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.template_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_gabarits:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_gabarits";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_gabarits";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_gabarits";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_gabarits";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeGabarits(res.data));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, corpsAlerteSucces));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.configurer_des_alarmes_xy:
                    titreAlerteSucces = "ApiSuccesTitre_configurer_des_alarmes_xy";
                    corpsAlerteSucces = "ApiSuccesCorps_configurer_des_alarmes_xy";
                    titreAlerteEchec = "ApiEchecTitre_configurer_des_alarmes_xy";
                    corpsAlerteEchec = "ApiEchecCorps_configurer_des_alarmes_xy";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.alarm_xy_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.alarm_xy_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.modifier_des_alarmes_xy:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_des_alarmes_xy";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_des_alarmes_xy";
                    titreAlerteEchec = "ApiEchecTitre_modifier_des_alarmes_xy";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_des_alarmes_xy";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.alarm_xy_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.alarm_xy_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.supprimer_une_configuration_d_alarme_xy:
                    titreAlerteSucces = "ApiSuccesTitre_supprimer_une_configuration_d_alarme_xy";
                    corpsAlerteSucces = "ApiSuccesCorps_supprimer_une_configuration_d_alarme_xy";
                    titreAlerteEchec = "ApiEchecTitre_supprimer_une_configuration_d_alarme_xy";
                    corpsAlerteEchec = "ApiEchecCorps_supprimer_une_configuration_d_alarme_xy";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.alarm_xy_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.alarm_xy_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_configurations_des_alarmes_xy:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_configurations_des_alarmes_xy";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_configurations_des_alarmes_xy";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_configurations_des_alarmes_xy";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_configurations_des_alarmes_xy";

                    succesRequete.actionReducteur = () => dispatch(enregistrerListeAlarmesXY(res.data));

                    // succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.graph_id]));
                    //
                    // if(res.data.errors.length > 0){
                    //     succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.graph_id]));
                    //     succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    // }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                default:
                    break;
            }
            if (res.data.state) {
                if (res.data.errors.length === 0) {
                    succesRequete.actionReducteur();
                    succesRequete.alerte();
                    succesRequete.fonctionSupplementaire();
                } else {
                    succesRequeteAvecErreur.actionReducteur();
                    succesRequeteAvecErreur.alerte();
                    succesRequeteAvecErreur.fonctionSupplementaire();
                }
            } else {
                echecRequete.actionReducteur();
                echecRequete.alerte();
                echecRequete.fonctionSupplementaire();
            }
            if (requeteAPI.request_type === requestType.confirmer_une_alarme) {
                dispatch(actions.reqRecupererListeAlarmes([state.interfaceClient.project_id_url]));
            }
            if (requeteAPI.request_type === requestType.creer_un_groupe_d_email || requeteAPI.request_type === requestType.modifier_un_groupe_d_email || requeteAPI.request_type === requestType.supprimer_un_groupe_d_email) {
                dispatch(actions.reqListeGroupesEmails(state.authentifie.filtre_projects_list_id));
            }
            if (requeteAPI.request_type === requestType.creer_un_gabarit || requeteAPI.request_type === requestType.modifier_un_gabarit || requeteAPI.request_type === requestType.supprimer_un_gabarit) {
                dispatch(actions.reqListeGabarit(state.authentifie.filtre_projects_list_id));
            }
            if (requeteAPI.request_type === requestType.configurer_des_alarmes_xy || requeteAPI.request_type === requestType.modifier_des_alarmes_xy || requeteAPI.request_type === requestType.supprimer_une_configuration_d_alarme_xy) {
                dispatch(actions.reqListeAlarmesXY(state.authentifie.filtre_projects_list_id));
            }
        })
        .catch(err => {
            console.log("Error API", requeteAPI.request_type, ":", err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
        })
        .finally(() => dispatch(actions.finAttente(requeteAPI.request_type)));
}

export const reqRecupererListeAlarmes = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_alarmes,
            list_project_id: listeIdProjets
        });
    };
};
export const reqConfirmerAlarme = (formulaireConfirmationAlarme) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.confirmer_une_alarme,
            ...formulaireConfirmationAlarme
            // alarm_id: idAlarme,
            // acknowledgement_status: statusConfirmation,
            // remark: remarque,
            // image: image,
        });
    };
};
export const reqListeGroupesEmails = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_groupes_d_email,
            list_project_id: listeIdProjets

        });
    };
};
export const reqCreerGroupeEmail = (FormulaireGroupeEmail) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.creer_un_groupe_d_email,
            project_id: FormulaireGroupeEmail.project_id,
            email_group_name: FormulaireGroupeEmail.email_group_name,
            list_emails: FormulaireGroupeEmail.list_emails,

        });
    };
};
export const reqModifierGroupeEmail = (FormulaireGroupeEmail) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_un_groupe_d_email,
            group_email_id: FormulaireGroupeEmail.group_email_id,
            project_id: FormulaireGroupeEmail.project_id,
            email_group_name: FormulaireGroupeEmail.email_group_name,
            list_emails: FormulaireGroupeEmail.list_emails,

        });
    };
};
export const reqSupprimerGroupeEmail = (idGroupeEmail) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_un_groupe_d_email,
            group_email_id: idGroupeEmail,

        });
    };
};
export const reqCreerGabarit = (FormulaireGabarit) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.creer_un_gabarit,
            template_name: FormulaireGabarit.template_name,
            project_id: FormulaireGabarit.project_id,
            private: FormulaireGabarit.private,
            alarm_level: FormulaireGabarit.alarm_level,
            start: FormulaireGabarit.start,
            end: FormulaireGabarit.end,
            template: FormulaireGabarit.template,

        });
    };
};
export const reqModifierGabarit = (FormulaireGabarit) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_un_gabarit,
            template_id: FormulaireGabarit.template_id,
            template_name: FormulaireGabarit.template_name,
            project_id: FormulaireGabarit.project_id,
            private: FormulaireGabarit.private,
            alarm_level: FormulaireGabarit.alarm_level,
            start: FormulaireGabarit.start,
            end: FormulaireGabarit.end,
            template: FormulaireGabarit.template,

        });
    };
};
export const reqSupprimerGabarit = (idGabarit) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_un_gabarit,
            template_id: idGabarit,

        });
    };
};
export const reqListeGabarit = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_gabarits,
            list_project_id: listeIdProjets

        });
    };
};

export const reqCreerAlarmesXY = (FormulaireAlarmesXY) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.configurer_des_alarmes_xy,
            template_name: FormulaireAlarmesXY.template_name,
            project_id: FormulaireAlarmesXY.project_id,
            template_id: FormulaireAlarmesXY.template_id,
            Xsensor_id: FormulaireAlarmesXY.Xsensor_id,
            Ysensor_id: FormulaireAlarmesXY.Ysensor_id,
            Xcomponent_id: FormulaireAlarmesXY.Xcomponent_id,
            Ycomponent_id: FormulaireAlarmesXY.Ycomponent_id,

        });
    };
};
export const reqModifierAlarmesXY = (FormulaireAlarmesXY) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_des_alarmes_xy,
            alarm_xy_id: FormulaireAlarmesXY.alarm_xy_id,
            project_id: FormulaireAlarmesXY.project_id,
            template_id: FormulaireAlarmesXY.template_id,
            Xsensor_id: FormulaireAlarmesXY.Xsensor_id,
            Ysensor_id: FormulaireAlarmesXY.Ysensor_id,
            Xcomponent_id: FormulaireAlarmesXY.Xcomponent_id,
            Ycomponent_id: FormulaireAlarmesXY.Ycomponent_id,

        });
    };
};
export const reqSupprimerAlarmesXY = (idAlarmesXY) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_une_configuration_d_alarme_xy,
            alarm_xy_id: idAlarmesXY,

        });
    };
};
export const reqListeAlarmesXY = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_configurations_des_alarmes_xy,
            list_project_id: listeIdProjets

        });
    };
};

const enregistrerListeAlarmes = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_ALARMES, alarms_list: data.alarms_list};
};
const enregistrerListeEmailsGroupes = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_EMAILS_GROUPS, list_emails_groups: data.list_emails_groups};
};
const enregistrerListeGabarits = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_GABARITS, list_templates: data.list_templates};
};
const enregistrerListeAlarmesXY = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_ALARMES_XY, list_alarms_xy: data.list_alarms_xy};
};
