import React, {Component} from "react";
import {connect} from "react-redux";
//scss
import * as S from "./AffichageGrapheAutomatiqueXT.module.scss";
//glossaire et variable importés
import * as actions from "../../../store/actions";
//composant
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import GrapheXT from './GrapheXT/GrapheXT';
import Bouton from "../../UI/Bouton/Bouton";
//icône
import Popup from "../../UI/Popup/Popup";
import ChangementIntervalTempsGrapheAutomatique
    from "./ChangementEchantillonGrapheAutomatiqueXT/ChangementIntervalTempsGrapheAutomatique";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import {checkPhrase} from "../../../ressources/functionJS/checkPhrase";

class AffichageGrapheAutomatiqueXT extends Component {

    state = {
        afficherModifierIntervalle: false
    }
    envoieReq = () => {
        if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined && this.props.debut_date !== undefined && this.props.fin_date !== undefined) {
            this.props.reqRecupererDonneesGrapheAutomatiqueXTIntervalle(this.props.sensor_id, this.props.position_in_sensor, this.props.debut_date, this.props.fin_date);
        }
        else if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined) {
            this.props.reqRecupererDonneesGrapheAutomatiqueXT(this.props.sensor_id, this.props.position_in_sensor);
        }
    }

    switchAfficherModifierIntervalle = () => this.setState({
        ...this.state,
        afficherModifierIntervalle: !this.state.afficherModifierIntervalle
    });

    componentDidMount() {
        if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined && this.props.debut_date !== undefined && this.props.fin_date !== undefined) {
            this.props.reqRecupererDonneesGrapheAutomatiqueXTIntervalle(this.props.sensor_id, this.props.position_in_sensor, this.props.debut_date, this.props.fin_date);
        }
        else if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined) {
            this.props.reqRecupererDonneesGrapheAutomatiqueXT(this.props.sensor_id, this.props.position_in_sensor);
        }
    }

    render() {
        if (this.props.donneesGraphe !== {} && this.props.donneesGraphe.config !== undefined) {

            const config = {
                ...this.props.donneesGraphe.config,
                title: this.props.donneesGraphe.config.title !== undefined && this.props.donneesGraphe.config.title.indexOf("évolution du capteur ")>0? checkPhrase("traduction_titre_graphe_automatique", this.props.donneesGraphe.config.title.substring("évolution du capteur ".length, this.props.donneesGraphe.config.title.length - " en fonction du temps".length)) : checkPhrase("traduction_titre_graphe_automatique", this.props.donneesGraphe.config.title)

            }
            let afficheGraphe;
            if (this.props.donneesGraphe !== undefined && this.props.donneesGraphe.config !== undefined) {
                afficheGraphe = <GrapheXT donneesGraphe={this.props.donneesGraphe}
                                          switchAfficherModifierIntervalle={this.switchAfficherModifierIntervalle}
                                          dimensionWidth={this.props.dimensionWidth}
                                          dimensionHeight={this.props.dimensionHeight}
                                          configuration={config}
                                          donnees={this.props.donneesGraphe.data}
                                          seuils={this.props.donneesGraphe.alarms_levels}/>;
            } else {
                afficheGraphe = <Bouton
                    ajoutClass={S.style}
                    cliquer={() => {
                        if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined && this.props.debut_date !== undefined && this.props.fin_date !== undefined) {
                            this.props.reqRecupererDonneesGrapheAutomatiqueXTIntervalle(this.props.sensor_id, this.props.position_in_sensor, this.props.debut_date, this.props.fin_date);
                        }
                        else if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined) {
                            this.props.reqRecupererDonneesGrapheAutomatiqueXT(this.props.sensor_id, this.props.position_in_sensor);
                        }
                    }
                    }>{checkMot("refresh")}</Bouton>
            }
            return <Auxiliaire>
                <Popup eventAuClique={this.envoieReq} afficherLien={true}
                       texte_ouverture={this.props.prefix + " = " + this.props.derniereValeur + " " + this.props.engineering_unit}>
                    <AttenteRequete request_type={requestType.recuperation_des_donnees_d_un_graphe_automatique_xt}
                                    str={checkMot("en_cour_de_reception_des_donnees_du_graphe_automatique")}>
                        {afficheGraphe}
                    </AttenteRequete>
                    <ChangementIntervalTempsGrapheAutomatique sensor_id={this.props.sensor_id}
                                                              position_in_sensor={this.props.position_in_sensor}
                                                              afficherPopup={this.state.afficherModifierIntervalle}
                                                              fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}/>
                </Popup>
            </Auxiliaire>;
        } else {
            return <Bouton
                ajoutClass={S.style}
                cliquer={() => {

                    if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined && this.props.debut_date !== undefined && this.props.fin_date !== undefined) {
                        this.props.reqRecupererDonneesGrapheAutomatiqueXTIntervalle(this.props.sensor_id, this.props.position_in_sensor, this.props.debut_date, this.props.fin_date);
                    }
                    else if (this.props.sensor_id !== undefined && this.props.position_in_sensor !== undefined) {
                        this.props.reqRecupererDonneesGrapheAutomatiqueXT(this.props.sensor_id, this.props.position_in_sensor);
                    }
                }
                }>{checkMot("refresh")}</Bouton>
        }
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        donneesGraphe: state.graphe.liste_donnees_graphes[ownProps.sensor_id + "+" + ownProps.position_in_sensor]!==undefined?state.graphe.liste_donnees_graphes[ownProps.sensor_id + "+" + ownProps.position_in_sensor]:{},
    };
}
const mapDispatchToProps = dispatch => {
    return {
        reqRecupererDonneesGrapheAutomatiqueXT: (sensor_id, position_in_sensor) => dispatch(actions.reqRecupererDonneesGrapheAutomatiqueXT(sensor_id, position_in_sensor, null, null, [], [null, null])),
        reqRecupererDonneesGrapheAutomatiqueXTIntervalle: (sensor_id, position_in_sensor, debut_date, fin_date) => dispatch(actions.reqRecupererDonneesGrapheAutomatiqueXT(sensor_id, position_in_sensor, null, null, [], [debut_date, fin_date])),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffichageGrapheAutomatiqueXT);