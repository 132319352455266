import React from "react";
import {connect} from "react-redux";
import {ListGroupItem} from "reactstrap";

import * as actions from "../../../../../store/actions";
import { BiChevronDown, BiChevronRight  } from "react-icons/bi";
import S from "../ArborescenceProjets.module.scss";
import Bouton from "../../../../UI/Bouton/Bouton";
import {AiOutlineTable, VscGraph} from "react-icons/all";
import GrapheTauxDePannesConteneur from "./GrapheTauxDePannesConteneur/GrapheTauxDePannesConteneur";

const DEFAULT_PADDING = 16;
const ICON_SIZE = 0;
const LEVEL_SPACE = 16;
const ListItem = ({
                      level = 0,
                      hasNodes,
                      isOpen,
                      label,
                      searchTerm,
                      openNodes,
                      toggleNode,
                      matchSearch,
                      focused,
                      project_id,
                      parent_id,
                      project_name,
                      project_fails,
                      sen_total,
                      ratio_fails,
                      ...props
                  }) => (
    <ListGroupItem
        key={props.project_id}
        style={{
            paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
            paddingTop: '0px',
            paddingBottom: '0px',
            cursor: 'pointer',
            backgroundColor: '#3C3935',
            boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
            zIndex: focused ? 999 : 'unset',
            position: 'relative',
            textAlign: 'center',
        }}
    >
        <div className={S.Noeud}>
                <div  onClick={e => {
                    hasNodes && toggleNode && toggleNode();
                    e.stopPropagation();
                }}>
                    {hasNodes//affichage d'icône dossier et flèche déroulante si le noeud à des enfants sinon flèche simple
                        ? <span onClick={e => {
                            hasNodes && toggleNode && toggleNode();
                            e.stopPropagation();
                        }}>
                        {
                            isOpen //affichage de l'icône d'ouverture/fermeture
                                ? <span>
                                    {/*icône d'ouvert*/}
                                    {/* <FaCaretDown size={20} className={S.icon}/> */}
                                    < BiChevronDown size={15} className={S.arrow}/>
                                </span>
                                : <span className={S.newstile}>
                                    {/*icône fermer*/}
                                    {/* <FaCaretRight size={20} className={S.icon}/> */}
                                    <BiChevronRight size={15} className={S.arrow}/>
                                </span>
                        }
                    </span>
                        :  null
                    }
                    {/*affichage du lien de redirection pour modifier l'url et enregistre la modification dans redux*/}
                    <span>{label}</span>
                </div>

                <div>
                    <Bouton ajoutClass={S.bouton} cliquer={()=>{}}>
                        {/* <AiOutlineStar size={14} color={'#eeb214'}/> */}
                        {"project_id" + project_id +"; "}
                        {"project_fails" + project_fails +"; "}
                        {"sen_total" + sen_total +"; "}
                        {"ratio_fails" + ratio_fails +"; "}
                    </Bouton>
                    <GrapheTauxDePannesConteneur
                        start_interval={props.start_interval}
                        end_interval={props.end_interval}
                        criticity_id_list={props.criticity_id_list}
                        types_id_list={props.types_id_list}
                        texte_ouverture={""} icone={<VscGraph size={14} className={S.icon}/>}/>
                    <Bouton ajoutClass={S.bouton} cliquer={()=>props.handleProjetCapteurDefaillant(parseInt(project_id))}>
                        <AiOutlineTable size={14} className={S.icon}/>
                    </Bouton>
                </div>
        </div>
    </ListGroupItem>
);
const mapStateToProps = state => {
    return {
        project_id_url: state.interfaceClient.project_id_url,
        favorite_projects_list_id: state.authentifie.favorite_projects_list_id
    };
}
const mapDispatchToProps = dispatch => {
    return {
        enregistrementProjectIdUrl: (project_id) => dispatch(actions.enregistrerProjectIdUrl(project_id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
