import React, {Component} from "react";
import Bouton from "../../../../../UI/Bouton/Bouton";
import * as S from "./FormulaireYScale.module.scss"
import { BiSave } from "react-icons/bi";
import * as type_btn from "../../../../../../ressources/glossaires/glossaire_type_de_bouton";
class FormulaireYScale extends Component {
    state={
        defaultYScaleMin: this.props.defaultValue!==undefined? this.props.defaultValue[0]:null,
        defaultYScaleMax: this.props.defaultValue!==undefined? this.props.defaultValue[1]:null,
        yScaleMin: this.props.value!==undefined? this.props.value[0]:null,
        yScaleMax: this.props.value!==undefined? this.props.value[1]:null,
    }
    render() {
        const { onUpdate } = this.props;
        const gereMin = (event) => {
            this.setState({yScaleMin:event.target.value});
        }
        const gereMax = (event) => {
            this.setState({yScaleMax:event.target.value});
        }
        const envoiYScale = () => {
            let min;
            let max;
            if(this.state.yScaleMin!==undefined && this.state.yScaleMin!==null && this.state.yScaleMin!==""){
                min=parseFloat(this.state.yScaleMin);
            }else {
                min=null;
            }
            if(this.state.yScaleMax!==undefined && this.state.yScaleMax!==null && this.state.yScaleMax!==""){
                max=parseFloat(this.state.yScaleMax);
            }else {
                max=null;
            }
            onUpdate([min,max]);
        }
        return [
           <span> <input className={S.input} defaultValue={this.state.defaultYScaleMin}
                   placeholder={"min"}
                   onChange={(event)=>gereMin(event)}/>,
            <input className={S.input} defaultValue={this.state.defaultYScaleMax}
                   placeholder={"max"}
                   onChange={(event)=>gereMax(event)}/>,
            <Bouton ajoutClass={S.btnn} type={type_btn.succes} cliquer={envoiYScale}> <BiSave size={20}/> </Bouton>
            </span>
        ];
    }
}
export default FormulaireYScale;