import React, {Component} from "react";
import {connect} from "react-redux";
import {textFilter} from "react-bootstrap-table2-filter";

import AffichageVue from "../../../Vue/AffichageVue/AffichageVue";
import EditTableauxDeBordFavoris from "./EditTableauxDeBordFavoris/EditTableauxDeBordFavoris";
import DefilerTableauxDeBord from "./DefilerTableauxDeBord/DefilerTableauxDeBord";

import Tableau from "../../../UI/tableau/Tableau";
import Bouton from "../../../UI/Bouton/Bouton";
import Popup from "../../../UI/PopupVue/Popup";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import ofF from "../../../../ressources/Logo/off-f.png";
import oN from "../../../../ressources/Logo/on-f.png";
import * as actions from "../../../../store/actions";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as privilege from "../../../../ressources/glossaires/glossaire_privileges";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";
import {glossaire_type_de_vue} from "../../../../ressources/glossaires/glossaire_type_de_vue";
import * as glossaire_url_objet from "../../../../ressources/glossaires/glossaire_url_objet";
import {searchKeyInTree} from "../../../../ressources/functionJS/searchKeyInTree";
import {getProjectIDTree} from "../../../../ressources/functionJS/getProjectIDTree";

import {VscSearch, VscMultipleWindows, VscListUnordered, VscNewFile, VscRefresh} from "react-icons/vsc";
import S from "./TableauDeBord.module.scss";

class TableauxDeBord extends Component {
    componentDidMount() {
        //récupère la liste des vues (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            //Liste des id projets des projets enfants de this.props.project_id_url (projet sélectionné par l'utilisateur) incluant l'id du projet parent
            const listeDesProjetsId = getProjectIDTree(searchKeyInTree({nodes: this.props.arborescence_projets}, parseInt(this.props.project_id_url)));
            this.props.recupererListeVues(listeDesProjetsId);
            this.setState({listeDesProjetsId: listeDesProjetsId})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des vues en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            const listeDesProjetsId = getProjectIDTree(searchKeyInTree({nodes: this.props.arborescence_projets}, parseInt(this.props.project_id_url)));
            this.props.recupererListeVues(listeDesProjetsId);
            this.setState({listeDesProjetsId: listeDesProjetsId})

        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }
    state = {
        columns: [// liste des clés pour l'entête du tableau de vue
        {
            dataField: 'favoris', text: [checkMot("favoris"), 
                // <Bouton key={"favoris_header_search"} type={type_btn.lupeSearch}
                //         cliquer={() => this.afficherFiltre("favoris", checkMot("favoris"))}><VscSearch size={10}/>
                // </Bouton>
            ], sort: true, align: 'center', headerAlign: 'center',   style: {
                width:"150px"
              },
            formatter: (cell) => {
                switch (parseInt(cell)) {
                    case 0:
                        return  <img alt="off" title="Non favori" src={ofF} className=""/>
                    case 1:
                        return  <img alt="off" title="favori" src={oN} className=""/>
                    default:
                        return checkMot(cell)
                }
            },
        },
            {
                dataField: 'view_id', text: [checkMot("id"),
                    <Bouton key={"view_id_header_search"} type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("view_id", checkMot("id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true, hidden: true, align: 'left', headerAlign: 'left',
            },
            {
                dataField: 'project_id', text: [checkMot("projet"),
                    <Bouton key={"project_id_header_search"} type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_id", checkMot("projet"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,  align: 'left', headerAlign: 'left',hidden: true,
                formatter: (cell) => {
                    for (let i = 0; i < this.props.projects_list.length; i++) {
                        if (this.props.projects_list[i].project_id === cell) {
                            return this.props.projects_list[i].project_name
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'view_name', text: [checkMot("nom")+" ",
                    // <Bouton key={"view_name_header_search"} type={type_btn.lupeSearch}
                    //         cliquer={() => this.afficherFiltre("view_name", checkMot("nom"))}><VscSearch size={10}/>
                    // </Bouton>
                ], sort: true,  align: 'left', headerAlign: 'left', filter: textFilter(),
                formatter: (cell, row) => {
                    return <span title={row.view_title}>{cell}</span>;
                },
            },
            {
                dataField: 'view_title', text: [checkMot("descriptif")+" ",
                    // <Bouton key={"view_title_header_search"} type={type_btn.lupeSearch}
                    //         cliquer={() => this.afficherFiltre("view_title", checkMot("descriptif"))}><VscSearch size={10}/>
                    // </Bouton>
                ],   sort: true,  align: 'left', headerAlign: 'left', hidden: true,
            },
     
        ],
        selectRow: {// paramètre d'affichage des lignes du tableau
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            style: () => {//style après sélection
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne

                this.props.enregistrerTypeObjetAffichage(glossaire_url_objet.vue)
                this.props.enregistrerIDObjetAffichage(row.view_id)
                window.history.pushState("object or string", "Title", "/" + this.props.project_id_url + "-" + glossaire_url_objet.vue + "-" + row.view_id);

                this.ouvrirPopupVue()
                return this.vueSelectionne(row.view_id);
            },
            // todo: fige la liste selected si on le défini
            // selected: !isNaN(this.props.ID_objet_affichage) && this.props.type_objet_affichage === glossaire_url_objet.vue? [this.props.ID_objet_affichage]:[]
        },
        iconeCreer: <VscNewFile size={15}/>,
        tableauDeBord_id: this.props.ID_objet_affichage,
        afficherPopupVue: this.props.type_objet_affichage === glossaire_url_objet.vue,
        listeDesProjetsId: [],
        indTDBFavoris: 0,
        liste_tableaux_de_bord_id: [],
        tempsDeDefilement: parseInt(this.props.tempsDeDefilement),
        afficherDiaporama: this.props.type_objet_affichage === glossaire_url_objet.diaporama_vue
    }
    vueSelectionne = (view_id) => {
        this.setState({tableauDeBord_id: view_id})
    }
    fermerPopupVue = () => {
        this.props.enregistrerTypeObjetAffichage(null)
        this.props.enregistrerIDObjetAffichage(null)
        window.history.pushState("object or string", "Title", "/" + this.props.project_id_url);
        this.setState({afficherPopupVue: false})
    }
    ouvrirPopupVue = () => {
        this.setState({afficherPopupVue: true})
    }
    modifieTempsAvantTDBSuivant = (event) => {
        this.setState({tempsDeDefilement: event.target.value})
        this.props.enregistrerTempsDeDefilement(event.target.value)
    }
    gererAfficherDiaporama = () => {
        this.props.enregistrerTypeObjetAffichage(glossaire_url_objet.diaporama_vue)
        window.history.pushState("object or string", "Title", "/" + this.props.project_id_url + "-" + glossaire_url_objet.diaporama_vue);
        this.setState({afficherDiaporama: !this.state.afficherDiaporama})
    }
    gererFermerDiaporama = () => {
        this.props.enregistrerTypeObjetAffichage(null)
        this.props.enregistrerIDObjetAffichage(null)
        window.history.pushState("object or string", "Title", "/" + this.props.project_id_url);
        this.setState({afficherDiaporama: false})
    }

    render() {
        let donnees_avec_favoris = []
        let liste_tableaux_de_bord_favoris = []
        if (this.props.favorite_views_list !== undefined) {
            liste_tableaux_de_bord_favoris = [...this.props.favorite_views_list.map((value) => value[0])]
        }
        for (let i = 0; i < this.props.listeVues.length; i++) {
            if(parseInt(this.props.listeVues[i].view_type) === glossaire_type_de_vue.tableau_de_bord && this.state.listeDesProjetsId.indexOf(parseInt(this.props.listeVues[i].project_id)) >-1){
                if (liste_tableaux_de_bord_favoris.indexOf(this.props.listeVues[i].view_id) > -1) {
                    donnees_avec_favoris.push({...this.props.listeVues[i], favoris: 1})
                } else {
                    donnees_avec_favoris.push({...this.props.listeVues[i], favoris: 0})
                }
            }
        }
        if(donnees_avec_favoris.length > 0){
            this.props.siLaListeAUneValeur(true)
        }
        let indDiaporama = liste_tableaux_de_bord_favoris.indexOf(this.props.ID_objet_affichage);
        if (indDiaporama === -1) {
            indDiaporama = 0
            if (this.props.ID_objet_affichage === 0 && this.state.afficherDiaporama) {
                //REMARQUE: solution temporaire pour actualisation de la mémoire
                //modifier l'url à l'id de la 1ere vue
                //todo: modifier le {data} si besoin et mettre un {title} plus pertinent
                window.history.pushState("object or string", "Title", "/" + this.props.project_id_url + "-" + glossaire_url_objet.diaporama_vue + "-" + liste_tableaux_de_bord_favoris[indDiaporama]);
            }
        }
        return (
            <Auxiliaire>
                <Tableau
                    privilegeCreer={privilege.creer_des_vues}
                    privilegeSupprimer={privilege.supprimer_des_vues}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    cleLigne={"view_id"}
                    iconeMultiSelection={false}
                    iconeEffacer={this.state.iconeEffacer}
                    donnees={donnees_avec_favoris}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                >
                    <Popup hoverText={checkMot("defiler_tableaux_de_bord_favoris")} typeBoutonAffichage={type_btn.outil}
                           ajoutOverlay={S.Overlay}
                           icone={<VscMultipleWindows size={15}/>}
                           barre_outils={
                               <input type="number" step="0.5" min={4} value={this.state.tempsDeDefilement}
                                      onChange={(event) => this.modifieTempsAvantTDBSuivant(event)}
                                      className={[S.inputNumber].join(" ")}
                                      placeholder={checkMot("intervalle_de_defillement_en_seconde")}
                               />
                           }
                           showModal={this.state.afficherDiaporama}
                           eventAuClique={this.gererAfficherDiaporama}
                           eventALaFermeture={this.gererFermerDiaporama}
                    >
                        <DefilerTableauxDeBord
                            liste_tableaux_de_bord_id={liste_tableaux_de_bord_favoris}
                            tempsAvantTDBSuivant={parseInt(this.state.tempsDeDefilement) * 1000}
                            indDiaporama={indDiaporama}
                            project_id_url={this.props.project_id_url}
                            afficher_diaporama={this.state.afficherDiaporama}
                            enregistrerIDObjetAffichage={this.props.enregistrerIDObjetAffichage}
                        />
                    </Popup>
                    <Popup hoverText={checkMot("editer_tableaux_de_bord_favoris")} typeBoutonAffichage={type_btn.outil}
                           ajoutOverlay={S.Overlay}
                           icone={<VscListUnordered size={15}/>}>
                        <EditTableauxDeBordFavoris user_id={this.props.user_id}/>
                    </Popup>
                    <AttenteRequete request_type={requestType.recuperation_de_la_liste_des_vues} str={checkMot("maj_vues_en_cour")}>
                        {/*bouton de rafraîchissement du tableau*/}
                        <Bouton hoverText={checkMot("refresh")} type={type_btn.outil} rond={true} cliquer={() => {
                            this.props.recupererListeVues(this.state.listeDesProjetsId)
                        }}>
                            <VscRefresh size={15}/>
                        </Bouton>
                    </AttenteRequete>
                </Tableau>
                <Popup sansBouton={true} eventAuClique={this.ouvrirPopupVue} eventALaFermeture={this.fermerPopupVue}
                       showModal={this.state.afficherPopupVue}><AffichageVue
                    view_id={this.state.tableauDeBord_id}/></Popup>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeVues: state.vue.views_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        projects_list: state.authentifie.projects_list,
        project_id_url: state.interfaceClient.project_id_url,
        arborescence_projets: state.interfaceClient.arborescence_projets,
        user_id: state.authentifie.user_id,
        favorite_views_list: state.vue.favorite_views_list,
        type_objet_affichage: state.interfaceClient.type_objet_affichage,
        ID_objet_affichage: state.interfaceClient.ID_objet_affichage,
        tempsDeDefilement: state.interfaceClient.tempsDeDefilement,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeVues: (listeIdProjets) => dispatch(actions.reqRecupererListeVues(listeIdProjets)),
        enregistrerProjectIdUrl: (idProjets) => dispatch(actions.enregistrerProjectIdUrl(idProjets)),
        enregistrerTypeObjetAffichage: (typeObjetAffichage) => dispatch(actions.enregistrerTypeObjetAffichage(typeObjetAffichage)),
        enregistrerIDObjetAffichage: (iDObjetAffichage) => dispatch(actions.enregistrerIDObjetAffichage(iDObjetAffichage)),
        enregistrerTempsDeDefilement: (tempsDeDefilement) => dispatch(actions.enregistrerTempsDeDefilement(tempsDeDefilement)),
        // recupererListeCapteursReduites:(listeIdProjets) => dispatch(actions.reqRecupererListeCapteursReduites(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauxDeBord);
