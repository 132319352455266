import React, {Component} from "react";
import {connect} from "react-redux";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import Checkbox from './Checkbox/Checkbox';

function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

class ListePrivilegesDetaillees extends Component {
    defaultText="Dynamic Default Checkbox value";
    state = {
        dynamicDefaultCheckboxValues: [],

    };
    componentDidMount() {
        if(this.props.defaultChecked!==[] && this.props.defaultChecked!==null && this.props.defaultChecked!==undefined ){
            this.setState({...this.state, dynamicDefaultCheckboxValues:this.props.defaultChecked});
            this.props.remontePrivilegePersonnalise(this.props.defaultChecked);
        }
        // console.log('this.props.defaultChecked',this.props.defaultChecked)
        // console.log('this.state.dynamicDefaultCheckboxValues',this.state.dynamicDefaultCheckboxValues)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.defaultChecked!==[] && this.props.defaultChecked!==null && this.props.defaultChecked!==undefined && !arrayEquals(prevProps.defaultChecked,this.props.defaultChecked)){
            this.setState({...this.state, dynamicDefaultCheckboxValues:this.props.defaultChecked});
            this.props.remontePrivilegePersonnalise(this.state.dynamicDefaultCheckboxValues);
        }
    }

    onChangeDefaultCheckboxValueHandler = (privilegeSelection) =>{
        /**
         * Ajoute le privilège si 'privilegeSelection' n'est pas dans la liste des privilèges
         * Retire le privilège si 'privilegeSelection' est dans la liste des privilèges
         * */
        const newState = {...this.state};
        if (this.state.dynamicDefaultCheckboxValues.indexOf(parseInt(privilegeSelection)) > -1) {
            newState.dynamicDefaultCheckboxValues.splice(this.state.dynamicDefaultCheckboxValues.indexOf(parseInt(privilegeSelection)), 1);
        } else {
            newState.dynamicDefaultCheckboxValues.push(parseInt(privilegeSelection));
        }
        this.setState(newState);
        this.props.remontePrivilegePersonnalise(this.state.dynamicDefaultCheckboxValues);
    }

    checkMot = (str) => {
        if (this.props.dicoLangues[str]!== undefined){
            if (this.props.dicoLangues[str][this.props.langueSite] !== undefined){ // false, but the key exists!
                return this.props.dicoLangues[str][this.props.langueSite];
            }else {
                console.log(str, ' n\'existe pas en', this.props.langueSite);
                return this.props.dicoLangues[str]["fr"];
            }
        }else {
            console.log('/!\\',str, ' n\'existe pas dans le dictionnaire de langues. /!\\');
            return str;
        }


    }
    render() {
        const radioChoice = Object.keys(this.props.dico_privileges).map(clePrivilege => {
            const dico_privileges = this.props.dico_privileges;
            const langueSite = this.props.langueSite;
            return <Checkbox name={dico_privileges[clePrivilege][langueSite]}
                             selectRadio={()=>this.onChangeDefaultCheckboxValueHandler(clePrivilege)}
                             checked={this.state.dynamicDefaultCheckboxValues.indexOf(parseInt(clePrivilege))>-1}>{clePrivilege+': '+dico_privileges[clePrivilege][langueSite]} </Checkbox>
        });
        return <Auxiliaire>
            {radioChoice}
        </Auxiliaire>;
    }

}

const mapStateToProps = state => {
    return {
        dico_privileges: state.dico.dico_privileges,
        langueSite:state.dico.langueSite,
    };
}

export default connect(mapStateToProps)(ListePrivilegesDetaillees);

