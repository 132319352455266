import React, {Component} from "react";
import {connect} from "react-redux";
import Auxiliaire from "../Auxiliaire/Auxiliaire";
import Spinner from "reactstrap/es/Spinner";
class AttenteRequete extends Component {
    render() {
        return (
            this.props.attenteReq===undefined||(!this.props.attenteReq)
                ?this.props.children
                :<Auxiliaire><Spinner/>{this.props.str}</Auxiliaire>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        attenteReq: state.attenteRequete["ATTENTE_REQUETE"+ownProps.request_type],
    };
}

export default connect(mapStateToProps)(AttenteRequete);

