import React, {Component} from 'react';
import {connect} from "react-redux";

import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";

import S from './RapportGabarit.module.scss';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import Bouton from "../../UI/Bouton/Bouton";
import {BiArrowFromLeft, BiArrowFromRight} from "react-icons/bi";
import ConteneurInformation from "../../ConteneurInformation/ConteneurInformation";
import * as indexConteneurInfo from "../../../ressources/glossaires/glossaire_conteneur_info";
import FormulaireRapportGabarit from "./ProfilRapportGabarit/FormulaireRapportGabarit/FormulaireRapportGabarit";
import ProfilRapportGabarit from "./ProfilRapportGabarit/ProfilRapportGabarit.js";
import * as actions from "../../../store/actions";
import TableauRapportGabarit from "./TableauRapportGabarit/TableauRapportGabarit";

class RapportGabarit extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,

    }

    //Fonction pour enregistrer les informations de la ligne sélectionné depuis le tableau de de rapports gabarits
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });

    //fonction pour refermer le contneur information lors d'une annulation de création
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Rapport gabarit non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }

    //fonction pour ouverture du conteneur information avec le formulaire de création
    creer = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        //initialisation des varable de style
        let styleConteneurCentralGauche = [S.ConteneurCentralGauche]
        let styleConteneurCentralDroite = [S.ConteneurCentralDroite]
        let commutateurClasses = [S.CommutateurInfo];

        if (this.state.montrerInfo) {
            //style pour un conteneur info ouvert
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
            // styleConteneurCentralDroite.push(S.ConteneurCentralGaucheOuvert);
        } else {
            //style pour un conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        if (this.state.montrerInfo && this.state.montrerInfoFull) {
            //conteneur info ouvert & plein écran
            //on met la largeur à 100% du conteneur info
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
            //on réduit à zéro la largeur du conteneur central
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
        } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
            //conteneur info ouvert & !plein écran
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
        } else {
            //conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        return (
            <Auxiliaire>
                <div className={styleConteneurCentralGauche.join(" ")}>
                    <TableauRapportGabarit projetSelectione={this.ligneSelectione} creer={this.creer}>
                        {/*<AccesRestreins*/}
                        {/*    listNumPrivilege={[privilege.creer_des_gabarits, privilege.modifier_des_gabarits, privilege.supprimer_des_gabarits]}>*/}
                        {/*    {GabaritVar}*/}
                        {/*</AccesRestreins>*/}
                        {/*{GroupeEmailVar}*/}
                    </TableauRapportGabarit>
                </div>
                <div className={styleConteneurCentralDroite.join(" ")}>
                    {/* bouton plein ferme conteneur  info */}
                    <Bouton ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
                            cliquer={this.commutateurInfoCreer} rond={true} type={type_btn.danger}>
                        <BiArrowFromLeft size={15}/>
                    </Bouton>
                    {/* bouton plein écran */}
                    <Bouton ajoutClass={S.expand} cliquer={this.commutateurInfoFull} rond={true}
                            type={type_btn.danger}>
                        <BiArrowFromRight size={15}/>
                    </Bouton>
                    <ConteneurInformation
                        // information du type de conteneur information à afficher
                        selectionInfo={this.state.indexConteneurInfo}
                        // formulaire de création
                        ajouterForm={<FormulaireRapportGabarit ajouterAlerte={this.props.ajouterAlerte}
                                                               annuler={this.commutateurAnnuler}/>}
                        // formulaire de profil vue
                        profil={<ProfilRapportGabarit infoRapportGabarit={this.state.parametresSelectione}
                                                      reqCreer={this.props.reqCreer}
                                                      reqSupprimer={this.props.reqSupprimer}
                                                      reqGenererRapport={this.props.reqGenererRapport}
                                                      commutateurInfoFull={this.commutateurInfoFull}/>}
                    />
                </div>
            </Auxiliaire>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        reqCreer: (formulaireRapportGabarit) => dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
        reqSupprimer: (idRapportGabarit) => dispatch(actions.reqSupprimerRapportGabarit(idRapportGabarit)),
        reqGenererRapport: (idRapportGabarit, start_report, end_report) => dispatch(actions.reqGenererRapport(idRapportGabarit, start_report, end_report))
    };
};

export default connect(null, mapDispatchToProps)(RapportGabarit);
