import React, {Component} from 'react';
import {connect} from 'react-redux';
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from 'react-bootstrap-table-next';

import {customTotal} from "../../../UI/tableau/Tableau";

import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as actions from "../../../../store/actions";
import * as glossaire_affichage_type_capteur from "../../../../ressources/glossaires/glossaire_affichage_type_capteur";

class TableauSensorType extends Component {
    componentDidMount() {
        //importer la table de sensorType (utiliser la requête api)
        this.props.recupererListeSensorType();
    }

    state = {
        columns: [
            {dataField: 'sensor_type_id', text: checkMot("sensor_type_id"), sort: true},
            {dataField: 'sensor_type_name', text: checkMot("sensor_type_name"), sort: true},
            {dataField: 'sensor_type_display', text: checkMot("sensor_type_display"), sort: true,
                formatter: (cell) => {
                    switch (cell) {
                        case glossaire_affichage_type_capteur.default_display:
                            return checkMot("default")
                        case glossaire_affichage_type_capteur.notify:
                            return checkMot("notify")
                        default:
                            return checkMot("default")
                    }
                },
            },
            {dataField: 'sensor_type_path_image', text: checkMot("sensor_type_path_image"), sort: true},
        ],
        sensor_types_list: this.props.sensor_types_list,
    }

    render() {
        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            style: {
                background: "#a6153e",
                color: "white",
            },
            onSelect: (row, isSelect) => {
                if (isSelect) {
                    //todo: utiliser la requête récupérer la liste de projets d'un utilisateur et l'ajouter à l'information de la ligne
                    // this.props.recupererListeProjetsDunUtilisateur(row.user_id);
                    // const newRowWithProjectsList = {...row, this.props.listeUtilisateurSelectionne}
                    return this.props.selectioneLigne(row);
                }
            }
        }

        const options = {
            paginationSize: 25,
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: 'All', value: this.props.sensor_types_list.length
            }] // A numeric array is also available. the purpose of above example is custom the text
        };
        return (
            this.props.sensor_types_list !== undefined && this.props.sensor_types_list !== []
                ? <BootstrapTable
                    keyField={"sensor_type_id"}
                    data={this.props.sensor_types_list}
                    columns={this.state.columns}
                    selectRow={selectRow}
                    pagination={paginationFactory(options)}
                    row
                />
                : null
        );
    }
}

const mapStateToProps = state => {
    return {
        sensor_types_list: state.capteur.sensor_types_list
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeSensorType: () => dispatch(actions.reqRecupererListeSensorType()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauSensorType);