import React, {Component} from 'react';
import * as s from '../../../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireAxe.module.scss';

import Auxiliaire from "../../../../../../../hoc/Auxiliaire/Auxiliaire";
import {jsDictionaryEquals} from "../../../../../../../ressources/functionJS/jsDictionaryEquals";
import * as glossaire_pattern from "../../../../../../../ressources/glossaires/glossaire_pattern";
import FormulaireSelectionDateHeureUnix from "../FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix";
import {checkMot} from "../../../../../../../ressources/functionJS/checkMot";


class FormulaireAxe extends Component {

    state = {
        // Valeur par défaut de Axe
        title: "",
        type: "linear",
        range: [null, null]
    }

    componentDidMount() {
        if (this.props.preConfigAxe !== undefined) {
            this.setState({...this.props.preConfigAxe, range: [...this.props.preConfigAxe.range]})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.preConfigAxe !== undefined && this.props.preConfigAxe !== prevProps.preConfigAxe) {
            //met à jour les données des champs si preConfigAxe est différent de undefined
            this.setState({
                ...this.props.preConfigAxe,
                range: [...this.props.preConfigAxe.range]
            });
        }
        if (!jsDictionaryEquals(this.state, prevState)) {
            //état de la config qui remonte dans le composant FormulaireGraphe.js
            this.props.gererConfigAxe(this.props.axisName, {
                ...this.state
            });
        }
    }

    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererMinAxe = (event) => this.setState({
        ...this.state,
        range: [parseFloat(event.target.value), this.state.range[1]]
    });
    gererMaxAxe = (event) => this.setState({
        ...this.state,
        range: [this.state.range[0], parseFloat(event.target.value)]
    });

    gererMinAxeDate = (unix) => this.setState({...this.state, range: [parseInt(unix), this.state.range[1]]});
    gererMaxAxeDate = (unix) => this.setState({...this.state, range: [this.state.range[0], parseInt(unix)]});

    render() {
        let formulairePlageDeLAxe;
        switch (this.state.type) {
            case "date":
                formulairePlageDeLAxe =
                    <Auxiliaire>
                        <div className={[S.style, s.text_left].join(" ")}>{checkMot("config_pour_x_t")}</div>
                        {/**DEBUT select_xaxis_manual_scaling  **/}

                        <div className={[S.style, s.text_left].join(" ")}>
                            {checkMot("plage_de_donnees_x_min_max")}
                        </div>
                        <div className={s.row}>
                            <div className={s.col_6}>
                                <FormulaireSelectionDateHeureUnix value={this.state.range[0]}
                                                                  gereUnixDate={this.gererMinAxeDate}
                                                                  disabled={this.props.disabled === true}/>
                            </div>
                            <div className={s.col_6}>
                                <FormulaireSelectionDateHeureUnix value={this.state.range[1]}
                                                                  gereUnixDate={this.gererMaxAxeDate}
                                                                  disabled={this.props.disabled === true}/>
                            </div>
                        </div>


                    </Auxiliaire>;
                break;
            case "linear":
                formulairePlageDeLAxe = <Auxiliaire>

                    <div className={[S.style, s.text_left].join(" ")}>
                        {checkMot("plage_de")}{this.props.axisName}
                    </div>
                    <div className={s.row}>
                        <div className={s.col_6}>
                        <input
                            type="text"
                            pattern={glossaire_pattern.float}
                            className={[s.form_control, S.input].join(" ")}
                            placeholder="echelle_de_l_axe_gauche_min_"
                            disabled={this.props.disabled === true}
                            value={this.state.range[0]}
                            onChange={(event) => this.gererMinAxe(event)}/>

                        </div>
                        <div className={s.col_6}>

                        <input
                            type="text"
                            pattern={glossaire_pattern.float}
                            className={[s.form_control, S.input].join(" ")}
                            placeholder="echelle_de_l_axe_gauche_max"
                            disabled={this.props.disabled === true}
                            value={this.state.range[1]}
                            onChange={(event) => this.gererMaxAxe(event)}/>
                        </div>
                    </div>


                </Auxiliaire>;
                break;
            default:
                formulairePlageDeLAxe =
                    <div className={[S.style, s.text_left].join(" ")}>{checkMot("selectionner_un_type_de_courbe")}
                    </div>
        }
        return (
            <Auxiliaire>

                <div className={[S.style, s.text_left].join(" ")}>
                    {this.props.axisName}
                </div>

                {/**DEBUT this.props.axisName**/}
                    <div className={[S.style, s.text_left].join(" ")}>{checkMot("titre")}</div>
                    <input
                        type="text"
                        pattern="[0-9a-zA-Z._%+-]+"
                        className={[s.form_control].join(" ")}
                        placeholder="title"
                        name="title"
                        value={this.state.title}
                        disabled={this.props.disabled === true}
                        // required
                        onChange={(event) => this.gererEtatDeCibleName(event)}/>

                {/**FIN this.props.axisName**/}
                {/*//Possibilité de choisir le type de l'axe pour une application future*/}
                {/*<div className={s.col_6}>/!**DEBUT type**!/*/}
                {/*    <span>{checkMot("dash")}</span>*/}
                {/*    <select*/}
                {/*        onChange={(event) => this.gererEtatDeCibleName(event)}*/}
                {/*        name="type"*/}
                {/*        defaultValue={this.state.type}*/}
                {/*        disabled={this.props.disabled === true}*/}
                {/*        value={this.state.type}*/}
                {/*        className={[s.custom_select, s.mr_sm_2].join(" ")}>*/}
                {/*        <OptionSelect value={"-"} str="-"/>*/}
                {/*        <OptionSelect value={"linear"} str="linear"/>*/}
                {/*        <OptionSelect value={"log"} str="log"/>*/}
                {/*        <OptionSelect value={"date"} str="date"/>*/}
                {/*        <OptionSelect value={"category"} str="category"/>*/}
                {/*        <OptionSelect value={"multicategory"} str="multicategory"/>*/}
                {/*    </select>*/}
                {/*</div>/!**FIN type**!/*/}

                {formulairePlageDeLAxe}

            </Auxiliaire>
        );
    }
}

export default FormulaireAxe;