import React, {useState} from 'react';
import imageTransparente from "./imageTransparente.svg";

function AffichageIconeCapteur(props) {
    const [afficherImageParDefaut, setAfficherImageParDefaut] = useState(false);
    let cheminImage;
    switch (document.domain) {
        case "localhost:3000":
            cheminImage = props.src;
            break;
        case "argos-dev-test.itmsol.fr":
            cheminImage = "../../." + props.src;
            break;
        case "argos-dev.itmsol.fr":
            cheminImage = "../../." + props.src;
            break;
        case "argos-v19.itmsol.fr":
            cheminImage = "../../." + props.src;
            break;
        default:
            cheminImage = props.src;
    }
    const imageCapteur = <img className={props.ajoutClass}
                              src={cheminImage}
                              onError={() => setAfficherImageParDefaut(true)} //test en local
                              alt={props.alt}
                              height={props.height}
                              width={props.width}
                              style={{borderRadius: "100%"}}
    />
    const imageParDefaut = <img className={props.ajoutClass}
                                src={imageTransparente}
                                alt={props.alt}
                                height={props.height}
                                width={props.width}
                                style={{borderRadius: "100%"}}
    />
    return afficherImageParDefaut?imageParDefaut:imageCapteur
}

export default AffichageIconeCapteur;