import React, {Component} from 'react';
import {connect} from 'react-redux';
import S from './App.module.scss';
import * as s from './ressources/StyleGlobal/StyleGlobal';
import MiseEnPage from "./conteneur/MiseEnPage/MiseEnPage";
import {Route} from "react-router-dom";
import ReinitialisationMDP from "./conteneur/ReinitialisationMDP/ReinitialisationMDP";
// import Alertes from "./composant/Alertes/Alertes";

class App extends Component {
    render() {
        return (
            <div className={S.Theme}>
                <Route path="/" component={() => <MiseEnPage/>}/>
                <Route path="/reinitialisation" component={() => <ReinitialisationMDP/>}/>
                {/*{this.props.interfaceAdmin?<Alertes/>:null}*/}
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        langueSite: state.dico.langueSite,
        // interfaceAdmin: state.authentifie.interfaceAdmin,
    };
}

export default connect(mapStateToProps)(App);
