import * as actionTypes from './actionTypes';


export const debutAttente = requestType => {
    /**
     * met le boolean du réducteur de la requête type en Début d'attente
     * */
    return {type:actionTypes.DEBUT_ATTENTE, requestType:requestType };
};
export const finAttente = (requestType) => {
    /**
     * met le boolean du réducteur de la requête type en Fin d'attente
     * */
    return {type:actionTypes.FIN_ATTENTE, requestType:requestType};
};