import React, {Component} from 'react';
import {checkMot} from "../../ressources/functionJS/checkMot";

import S from './Maintenance.module.scss';
import Auxiliaire from "../../hoc/Auxiliaire/Auxiliaire";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import ArborescenceConteneur from "./ArborescenceConteneur/ArborescenceConteneur";
import ProjetsTauxCriticite from "./ProjetsTauxCriticite/ProjetsTauxCriticite";
import MultipleSelect from "../UI/MultipleSelect/MultipleSelect";
import FormulaireSelectionMultipleTypeCapteur
    from "../UI/FormulaireSelectionMultipleTypeCapteur/FormulaireSelectionMultipleTypeCapteur";
import FormulaireSaisieDuree from "../UI/FormulaireSaisieDurée/FormulaireSaisieDuree";
import {jsDictionaryEquals} from "../../ressources/functionJS/jsDictionaryEquals";


const parProjet = "parProjet"
const parCapteur = "parCapteur"

class Maintenance extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,
        ongletSelectionne: parProjet,
        projects_id: [],
        types_id_list: [],
        criticity_id_list: [5],
        time_period: 24 * 60 * 60,
        start_interval: null,
        end_interval: null,

    }

    componentDidMount() {
        // Créez un objet Date pour la date et l'heure actuelles
        let now = new Date();

// Réglez l'heure sur minuit (00:00:00)
        now.setHours(0, 0, 0, 0);

// Ajoutez un jour (en millisecondes) pour atteindre la fin de la journée
        let endOfDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);

// Convertissez en timestamp Unix (secondes depuis le 1er janvier 1970)
        let unixTimestamp = Math.floor(endOfDay.getTime() / 1000);


        let start_interval = unixTimestamp - this.state.time_period
        this.setState({
            start_interval: start_interval,
            end_interval: unixTimestamp,
            criticity_id_list: this.state.criticity_id_list,
            types_id_list: this.state.types_id_list,
            projects_id: this.props.filtre_projects_list_id
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Créez un objet Date pour la date et l'heure actuelles
        let now = new Date();

// Réglez l'heure sur minuit (00:00:00)
        now.setHours(0, 0, 0, 0);

// Ajoutez un jour (en millisecondes) pour atteindre la fin de la journée
        let endOfDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);

// Convertissez en timestamp Unix (secondes depuis le 1er janvier 1970)
        let unixTimestamp = Math.floor(endOfDay.getTime() / 1000);


        let start_interval = unixTimestamp - parseInt(this.state.time_period)
        let end_interval = unixTimestamp
        let newState = {}
        if (!jsDictionaryEquals(this.state.criticity_id_list, prevState.criticity_id_list)) {
            newState["criticity_id_list"] = this.state.criticity_id_list;
            newState["types_id_list"] = this.state.types_id_list;
        }
        if (this.state.start_interval !== prevState.start_interval) {
            newState["start_interval"] = start_interval;
        }
        if (this.state.end_interval !== prevState.end_interval) {
            newState["end_interval"] = end_interval;
        }
        if (!jsDictionaryEquals(this.state.projects_id, this.props.filtre_projects_list_id)) {
            newState["projects_id"] = this.props.filtre_projects_list_id;
        }
        if (!jsDictionaryEquals(newState, {})){
            this.setState(newState)
        }
    }

    gererCriticities_id = (criticity_id_list) => this.setState({criticity_id_list: criticity_id_list})
    gererSensor_types_id = (types_id_list) => this.setState({types_id_list: types_id_list})
    gererTimePeriod = (time_period) => this.setState({time_period: parseInt(time_period), start_interval: this.state.end_interval-parseInt(time_period)});
    handleProjetCapteurDefaillant = (project_id) => {
        this.props.reqRecupererLaListeDesCapteursEnPanne(this.state.start_interval, this.state.end_interval, this.state.types_id_list, this.state.criticity_id_list, [project_id])
        this.setState({ongletSelectionne: parCapteur})
    };

    render() {
        let moduleOnglet;
        let toolsbar = [
            <FormulaireSaisieDuree
                gererDuree={this.gererTimePeriod}
                preconfigurerDuree={this.state.time_period}
                mainStyle={{padding: 0, margin: 0}}
                sx={{m: 0, minWidth: 100, maxWidth: 120, marginLeft: 0,}}
                // style={{width: "300px"}}
            />,
            <MultipleSelect
                preconfigurerListe={this.state.criticity_id_list}
                label={checkMot("criticite")}
                namesList={[
                    {label: "5", value: 5},
                    {label: "4", value: 4},
                    {label: "3", value: 3},
                    {label: "2", value: 2},
                    {label: "1", value: 1},
                    {label: "null", value: null},
                ]}
                labelKey={"label"}
                valueKey={"value"}
                sx={{m: 0, minWidth: 80, maxWidth: 120, marginLeft: 1,}}
                gererListeSelectionne={this.gererCriticities_id}
            />,
            <FormulaireSelectionMultipleTypeCapteur
                preconfigurerSensorTypeIds={this.state.types_id_list}
                gererSensor_types_id={this.gererSensor_types_id}
            />
        ]
        switch (this.state.ongletSelectionne) {
            case parProjet:
                moduleOnglet = <ArborescenceConteneur toolsbar={toolsbar}
                                                      start_interval={this.state.start_interval}
                                                      end_interval={this.state.end_interval}
                                                      criticity_id_list={this.state.criticity_id_list}
                                                      types_id_list={this.state.types_id_list}
                                                      handleProjetCapteurDefaillant={this.handleProjetCapteurDefaillant}/>
                break;
            case parCapteur:
                moduleOnglet = <ProjetsTauxCriticite toolsbar={toolsbar}
                                                     start_interval={this.state.start_interval}
                                                     end_interval={this.state.end_interval}
                                                     criticity_id_list={this.state.criticity_id_list}
                                                     types_id_list={this.state.types_id_list}/>
                break;
            default:
                moduleOnglet = <div
                    style={{position: "absolute", top: "50px", left: "15px"}}>{checkMot("selectionner_un_onglet")}</div>
                break
        }

        return (
            <Auxiliaire>
                <div className={[S.ConteneurVue].join(" ")}>
                    <div className={S.barConteneur}>
                        <div className={S.barOnglet}>
                            <div title={checkMot("par_projet")}
                                 className={[S.titreOnglet, this.state.ongletSelectionne === parProjet ? S.titreOngletSelectionne : ""].join(" ")}
                                 onClick={() => this.setState({ongletSelectionne: parProjet})}> {checkMot("par_projet")} </div>
                            <div title={checkMot("par_capteur")}
                                 className={[S.titreOnglet, this.state.ongletSelectionne === parCapteur ? S.titreOngletSelectionne : ""].join(" ")}
                                 onClick={() => this.setState({ongletSelectionne: parCapteur})}> {checkMot("par_capteur")} </div>
                        </div>
                    </div>
                    {moduleOnglet}
                </div>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        projects_list: state.maintenance.projects_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        reqRecupererLaListeDesCapteursEnPanne: (start_interval, end_interval, types_id_list, criticity_id_list, projects_id) => dispatch(actions.reqRecupererLaListeDesCapteursEnPanne(start_interval, end_interval, types_id_list, criticity_id_list, projects_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
