import React, { Component } from 'react';
import Auxiliaire from "../Auxiliaire/Auxiliaire";
import { FcExpand, FcCollapse } from "react-icons/fc";
import S from './Collapse.module.scss';
import * as s from "../../ressources/StyleGlobal/StyleGlobal";
import { FaMinus, FaPlus } from "react-icons/fa";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
class Collapse extends Component {
    constructor(props) {
        super(props);
    
        this.state={
        showAdvanceParameters: this.props.showAdvanceParameters===true,
        contentHoverText: this.props.ExpandText,
        };
 
   
    }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.showAdvanceParameters===true && prevProps.showAdvanceParameters===false && this.state.showAdvanceParameters===false){
            this.setState({showAdvanceParameters:true})
        }
    }

    render() {
        
        let contentExpandText; 
        let contentExpandTextOuvert;
        if(this.props.ExpandText !== undefined){
            contentExpandText =  <div className={[s.text_left,s.col_10].join(" ")}>{this.props.ExpandText}</div>
        }
        if(this.props.ExpandTextOuvert !== undefined){
            contentExpandTextOuvert = <div className={[s.text_left,s.col_10].join(" ")}>{this.props.ExpandTextOuvert}</div>
        }else{
            contentExpandTextOuvert = <div className={[s.text_left,s.col_10].join(" ")}>{this.props.ExpandText}</div>
        }
        let optionUn =  <Auxiliaire> <div className={[s.row, S.perso].join(" ")}> {contentExpandTextOuvert} <div className={[s.text_right, s.col_2].join(" ")}><span onClick={(event)=>{this.setState({showAdvanceParameters:!this.state.showAdvanceParameters}); event.preventDefault();}} className={S.butoon}><MdExpandLess size={30} /></span></div> </div> </Auxiliaire>;
        let optionDeux =  <Auxiliaire> <div className={s.row}>   {contentExpandText} <div className={[s.text_right, s.col_2].join(" ") }> <span onClick={(event)=>{this.setState({showAdvanceParameters:!this.state.showAdvanceParameters}); event.preventDefault();}} className={S.butoon}><MdExpandMore  size={30} /></span> </div> </div></Auxiliaire>;
        return(
            <Auxiliaire>
                <div className={[s.card, S.container, s.row].join(" ")}>
                    <span className={[S.stile, s.col_xl_12, s.card_header].join(" ")}>
                        {this.state.showAdvanceParameters ?  optionUn : optionDeux } 
                    </span>
                    <div className={[s.accordion, s.col_xl_12, S.bodyCon].join(" ")}>
                        {this.state.showAdvanceParameters?this.props.children:null}
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}
export default Collapse;