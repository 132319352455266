import React, {Component} from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './ImporterImage.module.scss';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import {checkMot} from "../../../ressources/functionJS/checkMot";

class ImporterImage extends Component {
    state = {}
    componentDidMount() {
        if(this.props.preconfigureImage!==undefined){
            this.setState({image: this.props.preconfigureImage})
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.preconfigureImage!==undefined && this.props.preconfigureImage!==prevProps.preconfigureImage){
            this.setState({image: this.props.preconfigureImage})
        }
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({image: e.target.result});
                this.props.gererImageImporter({base64: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);

        }
    }

    render() {
        return (
            <Auxiliaire>
                <div className={[s.col_12, S.margin].join(" ")}>
                    <label htmlFor="avatar"/>
                    <input type="file"
                           onChange={evt => this.onImageChange(evt)}
                           accept={this.props.extensionAccepter}/>
                </div>
                <img alt={checkMot("apercu_image")} src={this.state.image} className={S.imageVisualisation}/>
            </Auxiliaire>
        );
    }
}

export default ImporterImage
