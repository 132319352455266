import React, {Component} from 'react';
import {connect} from "react-redux";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as actions from "../../../store/actions";
import MultipleSelect from "../MultipleSelect/MultipleSelect";

class FormulaireSelectionMultipleTypeCapteur extends Component {
    state = {
        preconfigurerSensorTypeIds: [],
    }

    componentDidMount() {
        this.props.recupererListeSensorType();
        this.setState({...this.state, preconfigurerSensorTypeIds: this.props.preconfigurerSensorTypeIds});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.preconfigurerSensorTypeId !== prevProps.preconfigurerSensorTypeId) {
            this.setState({...this.state, preconfigurerSensorTypeIds: this.props.preconfigurerSensorTypeIds});
        }
    }

    gererSensorTypeId = (event) => {
        /**
         * gère id du SensorType en fonction de l'option sélectionnée
         */
        let newSensorType = event.target.value;
        this.setState({...this.state, sensorTypeId: newSensorType});
        this.props.gererSensorTypeId(newSensorType);
    }

    render() {
        // récupérer liste des capteurs avec this.props.listeCapteurs
        // créer un formulaire pour ajouter un capteur à l'objet vue sous forme de liste déroulante via objet datalist
        return (<MultipleSelect
            preconfigurerListe={this.props.preconfigurerSensorTypeIds}
            label={checkMot("sensor_type")}
            namesList={this.props.listeSensorTypes}
            labelKey={"sensor_type_name"}
            valueKey={"sensor_type_id"}
            sx={{m: 0, minWidth: 220, maxWidth: 500, marginLeft: 1}}
            gererListeSelectionne={this.props.gererSensor_types_id}
        />);
    }
}

const mapStateToProps = state => {
    return {
        listeSensorTypes: [
            ...state.capteur.sensor_types_list,
            {
                sensor_type_id: null,
                sensor_type_path_image: "null",
                sensor_type_display: "default",
                sensor_type_name: "null"
            }
        ],
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeSensorType: () => dispatch(actions.reqRecupererListeSensorType()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionMultipleTypeCapteur);
