import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import {
    projet,
    filtre,
    capteur,
    graphe,
    alarme,
    rapport,
    historique,
    utilisateur,
    maintenance,
    vue
} from "../../ressources/glossaires/glossaire_liens_pages";
import {clelocalStoragelanguesite} from "./dictionnaire";

// valeur du filtre local storage de la liste de filtre_projects_id
export const local_filtre_projects_list_id = "local_filtre_projects_list_id"
const local_filtre_projects_list_id_raw_value = localStorage.getItem(local_filtre_projects_list_id)
const local_filtre_projects_list_id_value = local_filtre_projects_list_id_raw_value!==undefined&&local_filtre_projects_list_id_raw_value!==null&&local_filtre_projects_list_id_raw_value!==""?local_filtre_projects_list_id_raw_value.split(",").map(v=>parseInt(v)):[];

const initialState = {
    resetUniqueId: -1,
    //Etats de connexion
    affichageRouletteReconnexion: true, // todo: par défault mettre à true
    identifier: false, // todo: par défault mettre à false
    reConnexion: false, // todo: par défault mettre à false

    //Etats de l'utilisateur connecté
    login_name: null,
    enterprise: null,
    interfaceAdmin: [projet, filtre, capteur, graphe, vue, alarme, rapport, utilisateur, maintenance, historique].indexOf("/" + window.location.href.split('/')[3]) > -1,
    typeUtilisateur: "admin", // todo: par défault mettre à null
    // idPrivilegeUtilisateur: 30, //todo: mettre à null

    user_id: null,
    lastname: null,
    name: null,
    privileges_list: [],
    projects_list: [],
    favorite_projects_list_id: [],
    email: null,
    phone_index: null,
    phone_number: null,
    company_name: null,
    profil_image: null,

    //Etats de vérification/confirmation/succès de l'api
    demandeReinitialisationMDPReussie: false,
    codeReinitialisationMDPVerifier: false,
    reinitialisationMDPReussie: false,
    validationChangementMotDePasse: false,

    //Etats du filtre par projet
    filtre_projects_list_id: local_filtre_projects_list_id_value
};
const emptyArray=(array)=>array!==undefined?array:[];
//todo: après authentification => lancer un procéssuce de mise en place des favories (thème de couleur, langue, ...)
const succesAuth = (state, action) => {
    return updateObject(state, {
        //Etats de connexion, lorsque l'utilisateur est connecté
        affichageRouletteReconnexion: true,
        identifier: true,
        reConnexion: true, // todo mettre à true pour se reconnecter sans passer par la page de reconnexion
        //Stockage des informations lors de la connexion
        user_id: action.user_id,
        lastname: action.lastname,
        name: action.name,
        privileges_list: emptyArray(action.privileges_list),
        projects_list: emptyArray(action.projects_list),
        favorite_projects_list_id: emptyArray(action.favorite_projects_list_id),
        email: action.email,
        phone_index: action.phone_index,
        phone_number: action.phone_number,
        company_name: action.company_name,
        profil_image: action.profil_image
    });
};
const deconnexionAutomatique = (state) => {
    return updateObject(state, {
        //Etat de connexion, déconnexion au niveau de la bdd, reconnexion via la popup de connexion
        affichageRouletteReconnexion: false,
        identifier: false
    });
};
const deconnexion = (state) => {
    const langueSite = localStorage.getItem(clelocalStoragelanguesite);
    localStorage.clear();
    localStorage.setItem(clelocalStoragelanguesite, langueSite);
    return updateObject(state, {
        //Etats de connexion, utilisateur déconnecté, retour à la page d'accueil
        identifier: false, // todo: par défault mettre à false
        reConnexion: false, // todo: par défault mettre à false

        //Etats de l'utilisateur connecté
        login_name: null,
        enterprise: null,
        interfaceAdmin: false,
        typeUtilisateur: "admin", // todo: par défault mettre à null
        // idPrivilegeUtilisateur: 30, //todo: mettre à null

        user_id: null,
        lastname: null,
        name: null,
        privileges_list: [],
        projects_list: [],
        favorite_projects_list_id: [],
        email: null,
        phone_index: null,
        phone_number: null,
        company_name: null,
        profil_image: null,

        //Etats de vérification/confirmation/succès de l'api
        demandeReinitialisationMDPReussie: false,
        codeReinitialisationMDPVerifier: false,
        reinitialisationMDPReussie: false,
        validationChangementMotDePasse: false,
    });
};
const demandeReinitialisationMDPReussie = (state) => {
    return updateObject(state, {
        //Etat de demande de réinitialisation MDP, après succès de la demande, envoie l'utilisateur sur une page d'indication "regarder vos mails"
        demandeReinitialisationMDPReussie: true
    });
};
const validationCodeVerificationReussie = (state) => {
    return updateObject(state, {
        //Etat de validation de code de vérification pour la réinitialisation de MDP, envoie l'utilisateur sur la page de réinitialisation de mot de passe
        codeReinitialisationMDPVerifier: true
    });
};
const reinitialisationMDPReussie = (state) => {
    return updateObject(state, {
        //Etat de MDP réinitialisé, l'utilisateur peut retourné sur la page de connexion pour se connecté avec son nouveau mdp
        reinitialisationMDPReussie: true
    });
};
const validationChangementMotDePasse = (state) => {
    return updateObject(state, {
        //Etat de Changement de mot de passe, succès du changement de mdp, l'utilisateur arrive sur une page d'indication "déconnecté vous pour vous connecté avec votre nouveau mdp"
        validationChangementMotDePasse: true
    });
};
const commutateurInterfaceClient = (state) => {
    document.documentElement.style.setProperty('--leafletContainerBackgroundColor', "#FFF");
    return updateObject(state, {
        //Etat de Changement de mot de passe, succès du changement de mdp, l'utilisateur arrive sur une page d'indication "déconnecté vous pour vous connecté avec votre nouveau mdp"
        interfaceAdmin: false
    });
};
const commutateurInterfaceAdmin = (state) => {
    document.documentElement.style.setProperty('--leafletContainerBackgroundColor', "#444");
    return updateObject(state, {
        //Etat de Changement de mot de passe, succès du changement de mdp, l'utilisateur arrive sur une page d'indication "déconnecté vous pour vous connecté avec votre nouveau mdp"
        interfaceAdmin: true
    });
};
const gererFiltreParProjet = (state, action) => {
    localStorage.setItem(local_filtre_projects_list_id, action.filtre_projects_list_id);
    return updateObject(state, {
        //Etat de Changement filtre par projet
        filtre_projects_list_id: action.filtre_projects_list_id
    });
};
const enregistrerListeProjets = (state, action) => {
    return updateObject(state, {projects_list: action.projects_list});
}
const enregistrerListeProjetsFavoris = (state, action) => {
    return updateObject(state, {favorite_projects_list_id: action.favorite_projects_list_id});
}
// const ajoutProjectID_local_filtre_projects_list_id = (state, action) => {
//     localStorage.setItem(local_filtre_projects_list_id, action.project_id);
//     let new_filtre_projects_list_id = state.local_filtre_projects_list_id
//     return updateObject(state, {filtre_projects_list_id: action.project_id});
// };
// const supprimerProjectID_local_filtre_projects_list_id = (state, action) => {
//     localStorage.setItem(local_filtre_projects_list_id, action.project_id);
//     return updateObject(state, {filtre_projects_list_id: action.project_id});
// };
// const vider_local_filtre_projects_list_id = (state) => {
//     localStorage.setItem(local_filtre_projects_list_id, []);
//     return updateObject(state, {filtre_projects_list_id: []});
// };


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEBUT_AUTH:
            return succesAuth(state, action);
        case actionTypes.DECONNEXION_AUTOMATIQUE:
            return deconnexionAutomatique(state);
        case actionTypes.DECONNEXION:
            return deconnexion(state);
        case actionTypes.DEMANDE_DE_REINITIALISATION_MDP_REUSSIE:
            return demandeReinitialisationMDPReussie(state);
        case actionTypes.VALIDATION_CODE_VERIFICATION_REUSSIE:
            return validationCodeVerificationReussie(state);
        case actionTypes.REINITIALISATION_MDP_REUSSIE:
            return reinitialisationMDPReussie(state);
        case actionTypes.VALIDATION_CHANGEMENT_MOT_DE_PASSE:
            return validationChangementMotDePasse(state);
        case actionTypes.COMMUTER_INTERFACE_CLIENT:
            return commutateurInterfaceClient(state);
        case actionTypes.COMMUTER_INTERFACE_ADMIN:
            return commutateurInterfaceAdmin(state);
        case actionTypes.GERER_FILTRE_PAR_PROJET:
            return gererFiltreParProjet(state, action);
        case actionTypes.ENREGISTRER_LISTE_PROJETS:
            return enregistrerListeProjets(state, action);
        case actionTypes.ENREGISTRER_LISTE_PROJETS_FAVORIS:
            return enregistrerListeProjetsFavoris(state, action);
        default:
            return state;
    }
};

export default reducer;
