import React, {Component} from 'react';
import {connect} from "react-redux";
import TableauGraphe from './TableauGraphe/TableauGraphe';
import ProfilGraphe from "./ProfilGraphe/ProfilGraphe";
import FormulaireGraphe from "./ProfilGraphe/FormulaireGraphe/FormulaireGraphe";
import Bouton from "../../../UI/Bouton/Bouton";
import ConteneurInformation from "../../../ConteneurInformation/ConteneurInformation";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import * as actions from "../../../../store/actions";
import * as indexConteneurInfo from '../../../../ressources/glossaires/glossaire_conteneur_info';
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";

import S from "./Graphe.module.scss";
import {BiArrowFromLeft, BiArrowFromRight} from "react-icons/bi";

class Graphe extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,

    }
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };

    // commutateurInfoCreer = () => this.setState((prevState ) => {return {  ...this.state, montrerInfo: !prevState.montrerInfo}});
    commutateurInfoCreer = () => this.setState((prevState) => {

        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Graphe non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }
    creerGraphe = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        let styleConteneurCentralGauche = [S.ConteneurCentralGauche]
        let styleConteneurCentralDroite = [S.ConteneurCentralDroite]
        let commutateurClasses = [S.CommutateurInfo];
        if (this.state.montrerInfo && this.state.montrerInfoFull) {
            //conteneur info ouvert & plein écran
            //on met la largeur à 100% du conteneur info
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
            //on réduit à zéro la largeur du conteneur central
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
        } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
            //conteneur info ouvert & !plein écran
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
        } else {
            //conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }
        return (
            <Auxiliaire>
                <div className={[S.ConteneurGraphe].join(" ")}>
                    <div className={styleConteneurCentralGauche.join(" ")}>
                        <TableauGraphe siLaListeAUneValeur={this.props.siLaListeAUneValeur} projetSelectione={this.ligneSelectione} creer={this.creerGraphe}/>
                        {/* <div className={S.titleModule}> {checkMot("graphes")} </div> */}
                    </div>
                    <div className={styleConteneurCentralDroite.join(" ")}>
                        {/* bouton plein ferme conteneur  info */}
                        <Bouton ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
                                cliquer={this.commutateurInfoCreer} rond={true} type={type_btn.danger}>
                            {/* {this.state.montrerInfo ? <IoIosClose size={30}/> : <IoIosClose size={30}/>} */}
                            <BiArrowFromLeft size={15}/>
                        </Bouton>
                        {/* bouton plein écran */}
                        <Bouton ajoutClass={S.expand} cliquer={this.commutateurInfoFull} rond={true}
                                type={type_btn.danger}>
                            {/* {this.state.montrerInfoFull ?  <BiExitFullscreen size={15}/> : <BiExpand size={15} />} */}
                            < BiArrowFromRight size={15}/>
                        </Bouton>
                        <ConteneurInformation
                            selectionInfo={this.state.indexConteneurInfo}
                            ajouterForm={<FormulaireGraphe ajouterAlerte={this.props.ajouterAlerte}
                                                           annuler={this.commutateurAnnuler}/>}
                            profil={<ProfilGraphe infoGraphe={this.state.parametresSelectione}
                                                  reqModifier={this.props.reqModifier}
                                                  reqSupprimer={this.props.reqSupprimer}/>}
                        />
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        reqSupprimer: (idGraphe) => dispatch(actions.reqSupprimerGraphe(idGraphe))
    };
};

export default connect(null, mapDispatchToProps)(Graphe);

