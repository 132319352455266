import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {//window.location.href.split('/')[3].split("-")[0] == "export"
    type_affichage: window.location.href.split('/')[3].split("-")[1],
    iD_objet_affichage: window.location.href.split('/')[3].split("-")[2],
    intervalle_objet_affichage: window.location.href.split('/')[3].split("-")[3],
};

const enregistrerProjectIdUrl = (state, action) => {
    return updateObject( state, {project_id_url: action.project_id_url});
}
const enregistrerTypeObjetAffichage = (state, action) => {
    //todo: filtrer l'information si le type n'appartient pas au glossaire de type d'objet à afficher
    return updateObject( state, {type_objet_affichage: action.type_objet_affichage});
}
const enregistrerIDObjetAffichage = (state, action) => {
    return updateObject( state, {ID_objet_affichage: action.ID_objet_affichage});
}
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ENREGISTRER_PROJECT_ID_URL: return enregistrerProjectIdUrl(state, action);
        case actionTypes.ENREGISTRER_TYPE_OBJET_AFFICHAGE: return enregistrerTypeObjetAffichage(state, action);
        case actionTypes.ENREGISTRER_ID_OBJET_AFFICHAGE: return enregistrerIDObjetAffichage(state, action);
        default:
            return state;
    }
};

export default reducer;
