import React, {Component} from 'react';

import TableauGroupeEmail from "./TableauGroupeEmail/TableauGroupeEmail"
import FormulaireGroupeEmail from "./ProfilGroupeEmail/FormulaireGroupeEmail/FormulaireGroupeEmail"
import ProfilGroupeEmail from "./ProfilGroupeEmail/ProfilGroupeEmail"

import AccesRestreins from "../../../hoc/AccesRestreins/AccesRestreins";

import * as privilege from "../../../ressources/glossaires/glossaire_privileges";
import * as indexConteneurInfo from "../../../ressources/glossaires/glossaire_conteneur_info";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as S from "./GroupeEmail.module.scss"
import {RiAddCircleLine} from "react-icons/ri";
import * as s from "../../../ressources/StyleGlobal/StyleGlobal"

class GroupeEmail extends Component {
    state = {
        InfoSelect: null,
        indexConteneurInfo: 0,
    }
    selectioneLigne = (ligne) => {
        this.setState({...this.state, InfoSelect: ligne, indexConteneurInfo: indexConteneurInfo.profil})
    }
    //fonction pour ouverture du conteneur information avec le formulaire de création
    creerGroupeEmail = () => {
        this.setState({...this.state, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        let affichageFormulaireGroupeEmail;
        switch (this.state.indexConteneurInfo) {
            case indexConteneurInfo.ajouterForm:
                affichageFormulaireGroupeEmail = <span style={{display: 'block'}}>
                                <FormulaireGroupeEmail
                                    reqAjouter={this.props.reqAjouter}
                                    annuler={() => this.setState({...this.state, showCreer: false})}
                                />
                            </span>
                break;
            case indexConteneurInfo.profil:
                affichageFormulaireGroupeEmail = <ProfilGroupeEmail
                    InfoSelect={this.state.InfoSelect}
                    reqModifier={this.props.reqModifier}
                    reqSupprimer={this.props.reqSupprimer}
                />
                break;
            default:
                affichageFormulaireGroupeEmail = <div className={S.fontSize}>{checkMot("selectionne_un_groupe_de_mail")}</div>;
        }
        return (
            <div className={[s.row, S.comtnet].join(" ")}>

                <div className={s.col_sm_6}>
                    <div className={S.fontSize}>{checkMot("liste_des_groupes_des_emails")}</div>
                    <AccesRestreins
                        listNumPrivilege={[privilege.creer_des_groupes_emails, privilege.modifier_des_groupes_emails, privilege.supprimer_des_groupes_emails]}>
                        <TableauGroupeEmail selectioneLigne={this.selectioneLigne}/>
                    </AccesRestreins>
                    <AccesRestreins numPrivilege={privilege.creer_des_groupes_emails}>
                        <button onClick={this.creerGroupeEmail} className={S.btadd}><RiAddCircleLine size={34}/>
                            {checkMot("creer_un_groupe_email")}</button>
                    </AccesRestreins>
                </div>
                <div className={s.col_sm_6}>
                    {affichageFormulaireGroupeEmail}
                </div>
            </div>
        );
    }
}

export default GroupeEmail;