import * as actionTypes from './actionTypes';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from "axios";
import * as actions from "./index";


const requetePost=(dispatch, requeteAPI)=>{
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */
    axios.post("", requeteAPI, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    ).then(res => {
        console.log(res.config.data);
        console.log(res);
        // let titreAlerteSucces = "Requête api succès";
        // let corpsAlerteSucces = "Requête api succès";
        let titreAlerteEchec = "Requête api échec";
        let corpsAlerteEchec = "Requête api échec";
        let succesRequete = {
            alerte() {},
            actionReducteur() {},
            fonctionSupplementaire() {}
        };
        let succesRequeteAvecErreur = {
            alerte() {},
            actionReducteur() {},
            fonctionSupplementaire() {}
        };
        let echecRequete = {
            alerte() {},
            actionReducteur() {},
            fonctionSupplementaire() {}
        };

        switch ( requeteAPI.request_type ) {
            case requestType.liste_des_privileges_associes_a_une_categorie_d_utilisateur:
                // titreAlerteSucces = "ApiSuccesTitre_liste_des_privileges_associes_a_une_categorie_d_utilisateur";
                // corpsAlerteSucces = "ApiSuccesCorps_liste_des_privileges_associes_a_une_categorie_d_utilisateur";
                titreAlerteEchec = "ApiEchecTitre_liste_des_privileges_associes_a_une_categorie_d_utilisateur";//todo: mettre l'alerte
                corpsAlerteEchec = "ApiEchecCorps_liste_des_privileges_associes_a_une_categorie_d_utilisateur";
                succesRequete.actionReducteur = () => dispatch(enregistrerListePrivilegesParCategorie(res.data));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerListePrivilegesParCategorie(res.data));
                    // echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    // echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            default:
                break;
        }
        if(res.data.state){
            if(res.data.errors.length === 0){
                succesRequete.actionReducteur();
                succesRequete.alerte();
                succesRequete.fonctionSupplementaire();
            }else{
                succesRequeteAvecErreur.actionReducteur();
                succesRequeteAvecErreur.alerte();
                succesRequeteAvecErreur.fonctionSupplementaire();
            }
        }else {
            echecRequete.actionReducteur();
            echecRequete.alerte();
            echecRequete.fonctionSupplementaire();
        }
    })
        .catch(err => {
            console.log("Error API",requeteAPI.request_type,":",err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
        });
}
export const reqListePrivilegesParCategorie = () => {
    return dispatch =>{
        requetePost(dispatch,{
            request_type: requestType.liste_des_privileges_associes_a_une_categorie_d_utilisateur,
        });
    }
};


const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) =>{
    if(res.data.errors !== undefined){
        for(let erreurKey= 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre,["ApiEchecErreursEnBoucle", {numAvertissement:erreurKey, idErreur:res.data.errors[erreurKey]}]));
        }
    }else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api","errors est undefine, pour une requête de connexion"));
    }
}

const enregistrerListePrivilegesParCategorie = (data) => {
    /**
     * Enregistre les informations de privilèges par catégories d'utilisateur todo:après la connexion?
     */
    return {type:actionTypes.ENREGISTRER_LISTE_PRIVILEGES_PAR_CATEGORIE,
        list_cat_functions: data.list_cat_functions
    };
};
