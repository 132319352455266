import React, {Component} from 'react';
import {connect} from "react-redux";

import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as actions from "../../../../store/actions";

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {text_geral, stile} from './FormulaireSelectionEmailgroup.module.scss';
import {arrayEquals} from "../../../../ressources/functionJS/arrayEquals";

class FormulaireSelectionEmailgroup extends Component {

    state = {
        emailgroupId: null,
    }

    componentDidMount() {
        this.props.recupererListeGroupesEmails(this.props.filtre_projects_list_id);
        this.setState({...this.state, emailgroupId: this.props.preconfigurerEmailgroupId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.preconfigurerEmailgroupId !== prevProps.preconfigurerEmailgroupId) {
            this.setState({...this.state, emailgroupId: this.props.preconfigurerEmailgroupId});
        }
        if (!arrayEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            //cas où le filtre ids de projets change
            this.props.recupererListeGroupesEmails(this.props.filtre_projects_list_id);
        }
    }

    gererEmailgroupId = (event) => {
        /**
         * gère id du Emailgroup en fonction de l'option sélectionnée
         */
        let newEmailgroup = event.target.value;
        this.setState({...this.state, emailgroupId: newEmailgroup});
        this.props.gererEmailgroupId(newEmailgroup);

    }

    render() {
        // récupérer liste des Emailgroups avec this.props.list_emails_groups
        // créer un formulaire pour ajouter un Emailgroup à un capteur sous forme de liste déroulante
        return (
            <Auxiliaire>
                <label className={[stile].join(" ")}>
                    {checkMot("groupe_email")}
                    {/*Affiche l'id et le nom du Emailgroup*/}
                    {/*{this.state.emailgroupId!==null*/}
                    {/*    ?Object.keys(this.props.list_emails_groups).map(indListeEmailgroups=>{*/}
                    {/*        return parseInt(this.state.emailgroupId)===parseInt(this.props.list_emails_groups[indListeEmailgroups].group_email_id)*/}
                    {/*            ?<span> id: {this.props.list_emails_groups[indListeEmailgroups].group_email_id+", name: "+this.props.list_emails_groups[indListeEmailgroups].email_group_name}</span>*/}
                    {/*            :null})*/}
                    {/*    :null*/}
                    {/*}*/}
                </label>
                {this.props.list_emails_groups !== undefined && this.props.list_emails_groups !== []
                    ? <select
                        className={[s.custom_select, s.mr_sm_2, text_geral].join(" ")}
                        onChange={(event) => this.gererEmailgroupId(event)}
                        disabled={this.props.affichageProfil === true}
                    >
                        <option key={"TypeNull"} selected={true}>
                            {checkMot("selectionnegroupe_email")}

                        </option>
                        {Object.keys(this.props.list_emails_groups).map(indListeEmailgroups => {
                            return <option key={this.props.list_emails_groups[indListeEmailgroups].group_email_id}
                                           value={this.props.list_emails_groups[indListeEmailgroups].group_email_id}
                                           selected={parseInt(this.props.preconfigurerEmailgroupId) === parseInt(this.props.list_emails_groups[indListeEmailgroups].group_email_id)}>
                                {checkMot("id") + ": " + this.props.list_emails_groups[indListeEmailgroups].group_email_id + ", " + checkMot("nom") + ": " + this.props.list_emails_groups[indListeEmailgroups].email_group_name}
                            </option>
                        })}
                    </select>
                    : <div>  {checkMot("liste_capteur_type_pas_recupere_actualiser_page")}</div>}
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        list_emails_groups: state.alarme.list_emails_groups,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeGroupesEmails: (listeIdProjets) => dispatch(actions.reqListeGroupesEmails(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionEmailgroup);
