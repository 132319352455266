import React, {Component} from 'react';
import {connect} from "react-redux";

import FormulaireSelectionProjet from "../../../Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet";
import Bouton from "../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";

import {IsArrayString} from "../../../../ressources/functionJS/IsArrayString";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as glossaire_pattern from "../../../../ressources/glossaires/glossaire_pattern";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../../../store/actions";

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireVue.module.scss';
import {AiOutlineCheck} from "react-icons/ai";
import {glossaire_type_de_vue} from "../../../../ressources/glossaires/glossaire_type_de_vue";

class FormulaireVue extends Component {
    state = {
        //paramètre par défaut de la vue
   
        view_name: "",
        view_title: "",
        update_time: null,
        view_projection: 1,
        view_bound_coordinates: [[0, 0], [0, 0]],
        view_zoom_levels: [0, 1],
        view_copyright: "",
        view_flag_fix_pos: 0,
        is_clustered: 1,
        is_main_view: 1,
        is_visible: 1,
        view_type: 1,

    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigurerVue === undefined) {
            this.setState({
                //paramètre par défaut de la vue (voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerVue !== undefined) {
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerVue,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigurerVue !== this.props.preconfigurerVue && this.props.preconfigurerVue === undefined) {
            this.setState({
                //todo: paramètre par défaut du graphe
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerVue !== undefined && !jsDictionaryEquals(prevProps.preconfigurerVue, this.props.preconfigurerVue)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerVue,
            })
        }
    }

    gererEnvoi = () => {
        //mise en forme de l'objet pour l'envoi de la requête API
        let formulaireVue = {
            //clé du formulaire de la vue de l'api
            project_id: parseInt(this.state.project_id),
            view_type: parseInt(this.state.view_type),
            view_name: this.state.view_name,
            view_title: this.state.view_title,
            update_time: parseInt(this.state.update_time),
            view_projection: parseInt(this.state.view_projection),
            view_bound_coordinates: IsArrayString(this.state.view_bound_coordinates, [[0, 0], [0, 0]]),
            view_zoom_levels: IsArrayString(this.state.view_zoom_levels, [0, 1]),
            view_copyright: this.state.view_copyright,
            view_flag_fix_pos: parseInt(this.state.view_flag_fix_pos),
            is_clustered: parseInt(this.state.is_clustered),
            is_main_view: parseInt(this.state.is_main_view),
            is_visible: parseInt(this.state.is_visible),
        }

        //Envoi la requête de création de vue
        if (this.props !== undefined && this.props.preconfigurerVue === undefined) {
            //si la vue n'a pas de pré-remplissage des champs du formulaire
            this.props.reqCreerVue(formulaireVue);
        } else {
            //sinon modification
            formulaireVue["view_id"] = parseInt(this.props.preconfigurerVue.view_id);
            this.props.reqModifierVue(formulaireVue)
        }
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererArrayEtatDeCibleName = (event) => this.setState({
        ...this.state,
        [event.target.name]: IsArrayString(event.target.value)
    });
    gererProjetId = (projetId) => {
        this.setState({...this.state, project_id: projetId});
    };
    affichageFormulaire = (style, label_state, label_name, pattern) => {
        /**
         * permet d'afficher un composant input de gestion de la modification
         */

        switch (pattern) {
            case glossaire_pattern.array:
                return (
                    <div className={[s.col_6, S.style].join(" ")}>
                        <span className={[S.stil2].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerVue !== undefined ? JSON.stringify(this.state[label_state]) : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererArrayEtatDeCibleName(event)}/>
                    </div>
                )
            default:
                return (
                    <div className={[s.col_6, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerVue !== undefined ? this.state[label_state] : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleName(event)}/>
                    </div>
                )
        }
    }

    render() {
        return (
            <Auxiliaire>
                <div className={[s.row].join(" ")}>
                    <div className={S.ConteneurInfo}>
                        <div className={[s.container, s.text_left].join(" ")}>
                            <form onSubmit={event => event.preventDefault()}>
                                <div className={s.form_group}>
                                    <div className={[s.form_row, s.align_items_center].join(" ")}>
                                        {/**DEBUT project_id**/}
                                        <FormulaireSelectionProjet affichageProfil={this.props.affichageProfil}
                                                                   gererProjetId={this.gererProjetId}
                                                                   preconfigurerProjetId={this.state.project_id}/>
                                                                   {/**FIN project_id**/}
                                        {/**DEBUT view_type**/}
                                        <div className={[s.col_6, S.style].join(" ")}>
                                            <span className={[S.stil2,].join(" ")}>{checkMot("type_de_vue")}</span>
                                            <select value={this.state.view_type}
                                                    onChange={(event) => this.gererEtatDeCibleName(event)}
                                                    name="view_type"
                                                    className={[s.custom_select, s.mr_sm_2].join(" ")}
                                                    disabled={this.props.affichageProfil === true}>
                                                {
                                                    Object.keys(glossaire_type_de_vue).map((cleTypeDVue) => {
                                                        return <option
                                                            selected={glossaire_type_de_vue[cleTypeDVue] === parseInt(this.state.view_type)}
                                                            value={glossaire_type_de_vue[cleTypeDVue]}>{checkMot(cleTypeDVue)}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/**FIN view_type**/}
                                        {/**DEBUT view_name**/}{this.affichageFormulaire(s.col_12, "view_name", checkMot("view_name"), glossaire_pattern.text)}{/**FIN view_name**/}
                                        {/**DEBUT view_title**/}{
                                        this.affichageFormulaire(s.col_12, "view_title", checkMot("view_title"),)}


                                        <div className={[s.col_6, S.style].join(" ")}>
                                            <span className={[S.stil2,].join(" ")}>{checkMot("descriptif")}</span>
                                            <input
                                                type="text"
                                                pattern={glossaire_pattern.text}
                                                className={[s.form_control,].join(" ")}
                                                placeholder={"view_title"}
                                                name={"view_title"}
                                                value={this.props.preconfigurerVue !== undefined ? this.state["view_title"] : null}
                                                disabled={this.props.affichageProfil === true}
                                                onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                        </div>
                                        {/**FIN view_title**/}
                                        {/**DEBUT update_time**/}{this.affichageFormulaire(s.col_12, "update_time", checkMot("update_time"), glossaire_pattern.integer)}{/**FIN update_time**/}

                                        {/*{ todo: limite de la vue */}
                                        {/*    this.props.view_id !== undefined*/}
                                        {/*        ?*/}
                                        {/*        <Popup texte_ouverture={"cadrer_la_vue_niveau_de_zoom_et_coordonnnees"}>*/}
                                        {/*            <AffichageVue view_id={this.props.view_id}*/}
                                        {/*                          uniciteVue={checkMot("positionner_limite_de_zoom_coord_vue")}*/}
                                        {/*                          // recupZoom={this.gererRecupZoom}*/}
                                        {/*                          recupCoord={this.gererRecupCoordLimitVue}*/}
                                        {/*            />*/}
                                        {/*        </Popup>*/}
                                        {/*        : null*/}
                                        {/*}*/}

                                        {/**DEBUT view_projection**/}{this.affichageFormulaire(s.col_12, "view_projection", checkMot("view_projection"), glossaire_pattern.text)}{/**FIN view_projection**/}
                                        {/**DEBUT view_bound_coordinates**/}{this.affichageFormulaire(s.col_12, "view_bound_coordinates", checkMot("view_bound_coordinates"), glossaire_pattern.array)}{/**FIN view_bound_coordinates**/}

                                        {/**DEBUT view_zoom_levels**/}{this.affichageFormulaire(s.col_12, "view_zoom_levels", checkMot("view_zoom_levels"), glossaire_pattern.array)}{/**FIN view_zoom_levels**/}
                                        {/**DEBUT view_copyright**/}{this.affichageFormulaire(s.col_12, "view_copyright", checkMot("view_copyright"), glossaire_pattern.text)}{/**FIN view_copyright**/}
                                        {/**DEBUT view_flag_fix_pos**/}{this.affichageFormulaire(s.col_12, "view_flag_fix_pos", checkMot("view_flag_fix_pos"), glossaire_pattern.text)}{/**FIN view_flag_fix_pos**/}
                                        {/**DEBUT is_clustered**/}{this.affichageFormulaire(s.col_12, "is_clustered", checkMot("is_clustered"), glossaire_pattern.boolean)}{/**FIN view_flag_fix_pos**/}
                                        {/**DEBUT is_main_view**/}{this.affichageFormulaire(s.col_12, "is_main_view", checkMot("is_main_view"), glossaire_pattern.boolean)}{/**FIN is_main_view**/}
                                        {/**DEBUT is_visible**/}{this.affichageFormulaire(s.col_12, "is_visible", checkMot("is_visible"), glossaire_pattern.boolean)}{/**FIN is_visible**/}
                                    </div>
                                </div>
                                {this.props.affichageProfil === true
                                    ? null
                                    : <div className={s.form_group}>
                                        <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                                            <div>
                                                <Bouton type={type_btn.danger} cliquer={(e) => {
                                                    e.preventDefault();
                                                    this.props.annuler();
                                                }}>{checkMot("annuler")}</Bouton>
                                            </div>
                                            <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                                                {this.props !== undefined && this.props.preconfigurerVue === undefined
                                                    ? <AttenteRequete request_type={requestType.creer_une_vue}
                                                                      str={checkMot("creation_en_cour")}>
                                                        <Bouton type={type_btn.succes} ajoutClass={S.Bts}
                                                                cliquer={() => this.gererEnvoi()}><AiOutlineCheck/>
                                                            {checkMot("creer")}</Bouton>
                                                    </AttenteRequete>
                                                    : <AttenteRequete request_type={requestType.modifier_une_vue}
                                                                      str={checkMot("modification_en_cour")}>
                                                        <Bouton type={type_btn.succes}
                                                                cliquer={() => this.gererEnvoi()}>{checkMot("enregistrer")}</Bouton>
                                                    </AttenteRequete>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        reqCreerVue: (formulaireGrapheT_X) => dispatch(actions.reqCreerVue(formulaireGrapheT_X)),
        reqModifierVue: (formulaireGrapheT_X) => dispatch(actions.reqModifierVue(ownProps.view_id, formulaireGrapheT_X)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireVue);
