import React, {Component} from "react";
import Modal from "react-modal";
import Bouton from "../../../UI/Bouton/Bouton";
import S from './FormulaireDateDeCouche.module.scss';
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";
import * as s from "../../../../ressources/StyleGlobal/StyleGlobal";
import calendrier from "./calendrier.png";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";

const affichagePetit = "17% 17% 17% 17%"
const affichageGrand = "0% 0% 0% 0%"

class FormulaireDateDeCouche extends Component {
    state = {
        date_init: "2023-03-31 00:00:00",
        date_fin: "2023-03-31 17:00:00",
        initialisationFormulaire: true,
        dicoCouche: {},
        showModal: false,
        overflowX: "auto",
        overflowY: "auto",

        inset: this.props.afficherExpand ? affichageGrand : affichagePetit,
        afficherExpand: this.props.afficherExpand !== undefined ? this.props.afficherExpand : false,
    }

    componentDidMount() {
        if (this.props.width === "100%") {
            this.setState({
                inset: "5.9% 0.1% 0% 0%"
            })
        } else {
            this.setState({
                inset: affichagePetit
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.width !== prevProps.width) {
            if (this.props.width === "100%") {
                this.setState({
                    inset: "5.9% 0.1% 0% 0%"
                })
            } else {
                this.setState({
                    inset: affichagePetit
                })
            }
        }

        if (this.state.initialisationFormulaire === true && !jsDictionaryEquals(this.props.historique_couches, prevProps.historique_couches)) {
            let newDicoCouche = {}
            for (let couche of this.props.historique_couches) {
                if (couche !== undefined && couche["properties"] !== undefined && couche["properties"]["name"] === "avancement") {
                    newDicoCouche[couche["name"]] = couche;

                } else if (couche !== undefined && couche["parametre"] !== undefined
                    && couche["parametre"]["layers"] !== undefined
                    && (
                        couche["parametre"]["layers"].startsWith("ISOTASS")
                        || couche["parametre"]["layers"].startsWith("MNDZ")
                    )) {
                    const path = couche["parametre"]["map"].split("\\");
                    const nom_dossier = path[path.length - 2]
                    if (Object.keys(newDicoCouche).indexOf(nom_dossier) < 0) {
                        newDicoCouche[nom_dossier] = [couche];
                    } else {
                        newDicoCouche[nom_dossier] = [...newDicoCouche[nom_dossier], couche];
                    }
                }

            }
            this.setState({dicoCouche: newDicoCouche});
        }
    }

    setCloseShowModal = () => {
        this.setState({
            showModal: false
        })
        if (this.props.eventAuCliqueFermeture instanceof Function) {
            this.props.eventAuCliqueFermeture()
        }
    }

    setOpenShowModal = () => {
        this.setState({
            showModal: true
        })
        if (this.props.eventAuCliqueOuverture instanceof Function) {
            this.props.eventAuCliqueOuverture()
        }
    }

    setExpand = () => {
        this.setState({
            inset: "0% 0% 0% 0%",
            afficherExpand: true,
            overflowX: "hidden",
        })
        if (document.getElementById(this.props.popup_id) !== null) {
            document.getElementById(this.props.popup_id).requestFullscreen();
        }
    }
    setPetit = () => {
        this.setState({
            inset: affichagePetit,
            afficherExpand: false,
            overflowX: "auto",
            overflowY: "auto",
        })
        if (document.fullscreenElement !== null) {
            document.exitFullscreen();
        }
    }

    envoi = async () => {

        for (let keyName of Object.keys(this.state.dicoCouche)) {
            if (this.state.dicoCouche[keyName] instanceof Array) {
                try {

                    const obj = {
                        "projectName": keyName,
                        "ti": this.state.date_init,
                        "tf": this.state.date_fin
                    };
                    const init = {
                        method: "POST", // *GET, POST, PUT, DELETE, etc.
                        mode: "cors", // no-cors, *cors, same-origin
                        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: "same-origin", // include, *same-origin, omit
                        headers: {
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow", // manual, *follow, error
                        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                        body: JSON.stringify(obj),
                    }
                    const request = await fetch("https://qgis.argos-v19.itmsol.fr/qgis/isolignes_historique/iso_histo.php", init);
                    request.json().then((data) => {
                        // do something with the data sent in the request
                        if (data["state"] === "OK") {
                            this.setState({initialisationFormulaire: false})
                        }
                        for (let key of Object.keys(data)) {
                            for (let layer of this.state.dicoCouche[keyName]) {

                                const layerToRemove = this.props.controlLayers._layers.find(obj => {
                                    return obj.name === layer.layer_historique_name
                                }); // Récupère le layer à supprimer
                                if (layer.layer_historique_name !== undefined && layerToRemove !== undefined) {
                                    this.props.map_vue.removeLayer(layerToRemove.layer); // Supprime le layer de la carte
                                    this.props.controlLayers.removeLayer(layerToRemove.layer); // Supprime le layer du controlLayers
                                    this.props.map_vue.invalidateSize();
                                }

                            }
                            if (key === "mndz") {
                                this.props.ajoutLayer(
                                    {
                                        ...data[key],
                                        name: data[key].parametre.layers,
                                        position: 9
                                    })
                            } else if (key === "isotass") {
                                this.props.ajoutLayer(
                                    {
                                        ...data[key],
                                        name: data[key].parametre.layers,
                                        position: 10
                                    })
                            }
                        }
                    })
                }catch (e){
                    console.log(e)
                }
            } else {
                //    todo modifier le trace du tunnelier

                let ind_max_uts =0
                const date_uts_fin = new Date(this.state.date_fin).valueOf()/1000
                console.log(date_uts_fin)
                let flag = true
                while (ind_max_uts<this.state.dicoCouche[keyName]["geometry"]["t"].length && flag){
                    console.log(this.state.dicoCouche[keyName]["geometry"]["t"][ind_max_uts]>=date_uts_fin, this.state.dicoCouche[keyName]["geometry"]["t"][ind_max_uts], date_uts_fin)
                    if (this.state.dicoCouche[keyName]["geometry"]["t"][ind_max_uts]>=date_uts_fin){
                        flag = false
                    }else{
                        ind_max_uts+=1
                    }
                }
                console.log(ind_max_uts, this.state.dicoCouche[keyName])
                let geojson = {
                    ...this.state.dicoCouche[keyName],
                    "geometry":{
                        "type":this.state.dicoCouche[keyName]["geometry"]["type"],
                        "coordinates":this.state.dicoCouche[keyName]["geometry"]["coordinates"].slice(0, ind_max_uts),
                        "t":this.state.dicoCouche[keyName]["geometry"]["t"].slice(0, ind_max_uts),
                    },
                }
                const layerToRemove = this.props.controlLayers._layers.find(obj => {
                    return obj.name === keyName
                }); // Récupère le layer à supprimer
                if (keyName !== undefined && layerToRemove !== undefined) {
                    this.props.map_vue.removeLayer(layerToRemove.layer); // Supprime le layer de la carte
                    this.props.controlLayers.removeLayer(layerToRemove.layer); // Supprime le layer du controlLayers
                    this.props.map_vue.invalidateSize();
                }
                this.props.ajout_avancement_tunnelier(geojson)

            }
        }

    }

    render() {

        let Textheader;
        let colorBg = [s.text_right, s.col_1]
        let classpopup = [s.text_right]
        if (!(this.props.hideHeader === true)) {
            classpopup.push([s.card_header, S.styleB].join(" "))


            Textheader = <span className={[S.headerText, s.col_11].join(" ")}>{this.props.headerPopupText}</span>

        } else {
            colorBg.push([S.headerTextD].join(" "))
            Textheader = <span className={[S.headerTextD, s.col_11].join(" ")}>{this.props.headerPopupText}</span>

        }

        let boutonExpand;
        if (this.state.afficherExpand) {
            boutonExpand = <Bouton ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(" ")} type={type_btn.Min}
                                   rond={true}
                                   cliquer={() => {
                                       this.setPetit();
                                       if (document.fullscreenElement !== null) {
                                           document.exitFullscreen();
                                       }
                                   }}/>


        } else {
            boutonExpand = <Bouton ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(" ")} type={type_btn.Max}
                                   rond={true}
                                   cliquer={() => {
                                       this.setExpand();
                                       if (document.getElementById(this.props.popup_id) !== null) {
                                           document.getElementById(this.props.popup_id).requestFullscreen();
                                       }
                                   }}/>
        }

        return (
            <div className={S.divMaster}>

                <img title={checkMot("historique_couches")} src={calendrier}
                     style={{width: "45px", padding: "12px", cursor: "pointer"}} onClick={this.setOpenShowModal} alt={checkMot("calendrier")}/>
                <Modal id={this.props.popup_id}
                       isOpen={this.state.showModal}
                       onRequestClose={this.setCloseShowModal}
                       style={{
                           overlay: {
                               position: "absolute",
                               zIndex: "1000",

                               //    padding: "1px",
                               // paddingTop: "50px",
                               //    backgroundColor: "white",
                               border: "black 1px solid",
                               //    borderRadius: "10px",
                               backgroundColor: "transparent",


                               transition: "width 2s, height 4s",


                           },
                           content: {

                               overflowX: this.state.overflowX,
                               overflowY: this.state.overflowY,

                               inset: this.state.inset,
                               padding: "0px",
                               boxShadow: "-5px 0px 5px rgba(0, 0, 0, 0.226)",

                           }

                       }}
                >
                    <div className={classpopup.join(" ")}>
                        {Textheader}
                        <span className={colorBg.join(" ")}>
                {boutonExpand}
                            <Bouton ajoutClass={[S.PositionBoutonFermeture].join(" ")} type={type_btn.fermer}
                                    rond={true} cliquer={this.setCloseShowModal}/>
                    </span>
                    </div>
                    {/* <a href="https://www.itmsol.fr" target="_blank" rel="noopener noreferrer">
                    <img className={S.glogo} src={glogo} alt="itmsol"/>
                    </a> */}


                    <div className={[s.d_flex, s.justify_content_sm_around, S.ConteneurHorizontal].join(" ")}>
                        <div>
                            {this.props.children}

                            <div style={{padding: "5px"}}>
                                <h5>{checkMot("couche_avec_historique")}</h5>
                                {Object.keys(this.state.dicoCouche).map(keyName => {
                                    if (this.state.dicoCouche[keyName] instanceof Array) {
                                        return <ul>{keyName} {this.state.dicoCouche[keyName].map(couche =>
                                            <li className={S.IndentationLi}>{couche.name}</li>)}</ul>;
                                    } else {
                                        return <ul>{keyName}</ul>
                                    }
                                })}
                                {/*{this.props.historique_couches.map(couche => <ul>{couche.name}</ul>)}*/}
                            </div>
                        </div>
                        <div>
                            <h5>{checkMot("selectionner_une_plage_de_temps")}</h5>
                            <input value={this.state.date_init}
                                   onChange={(event) => this.setState({"date_init": event.target.value})}/>
                            <input value={this.state.date_fin}
                                   onChange={(event) => this.setState({"date_fin": event.target.value})}/>
                            <Bouton cliquer={this.envoi}>Envoie</Bouton>
                        </div>
                    </div>

                </Modal>
            </div>
        );
    }
}


export default FormulaireDateDeCouche;
