import React, {Component} from 'react';
import {connect} from "react-redux";

import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import * as actions from "../../../../store/actions";
import {checkMot} from "../../../../ressources/functionJS/checkMot";

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import { stile  } from './FormulaireSelectionImportFilter.module.scss';

class FormulaireSelectionImportFilter extends Component {

    state={
        importFilterId:null,
    }

    componentDidMount() {
        this.props.recupererListeImportFilters();
        this.setState({...this.state, importFilterId:this.props.preconfigurerImportFilterId});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.preconfigurerImportFilterId!==prevProps.preconfigurerImportFilterId){
            this.setState({...this.state, importFilterId:this.props.preconfigurerImportFilterId});
        }
    }
    gererImportFilterId = (event) => {
        /**
         * gère id du ImportFilter en fonction de l'option sélectionnée
         */
        let newImportFilter = event.target.value;
        this.setState({...this.state,importFilterId:newImportFilter});
        this.props.gererImportFilterId(newImportFilter);

    }
    render(){
        // récupérer liste des ImportFilters avec this.props.listeImportFilters
        // créer un formulaire pour ajouter un ImportFilter à un capteur sous forme de liste déroulante
        return(
            <Auxiliaire>
                <label className={[stile].join(" ")} htmlFor="inlineFormCustomSelect">
                    {checkMot("importation_filtre_id")}
                    {/*Affiche l'id et le nom du ImportFilter*/}
                    {/*{this.state.importFilterId!==null*/}
                    {/*    ?Object.keys(this.props.listeImportFilters).map(indListeImportFilters=>{*/}
                    {/*        return parseInt(this.state.importFilterId)===parseInt(this.props.listeImportFilters[indListeImportFilters].import_filter_id)*/}
                    {/*            ?<span> id: {this.props.listeImportFilters[indListeImportFilters].import_filter_id+", name: "+this.props.listeImportFilters[indListeImportFilters].import_filter_name}</span>*/}
                    {/*            :null})*/}
                    {/*    :null*/}
                    {/*}*/}
                </label>
                {this.props.listeImportFilters!==undefined&&this.props.listeImportFilters!==[]
                    ?<select
                        className={[s.custom_select, 
                        ].join(" ")}
                        onChange={(event) =>this.gererImportFilterId(event)}
                    >
                        {this.props.preconfigurerImportFilterId===undefined
                            ?<option key={"TypeNull"} selected={true} disabled={true}>
                            {checkMot("selectionne_type_capteur")}
                            </option>
                            :null}
                        {Object.keys(this.props.listeImportFilters).map(indListeImportFilters=>{
                            return <option key={this.props.listeImportFilters[indListeImportFilters].import_filter_id}
                                           value={this.props.listeImportFilters[indListeImportFilters].import_filter_id}
                                           selected={parseInt(this.props.preconfigurerImportFilterId)===parseInt(this.props.listeImportFilters[indListeImportFilters].import_filter_id)}>
                                id: {this.props.listeImportFilters[indListeImportFilters].import_filter_id+", name: "+this.props.listeImportFilters[indListeImportFilters].import_filter_name}
                            </option>
                        })}
                    </select>
                    :<div>{checkMot("liste_capteur_pas_recupere_actuliser_page")}</div>}
            </Auxiliaire>
        );
    }
}
const mapStateToProps = state => {
    return {
        listeImportFilters: state.capteur.import_filters_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeImportFilters:() => dispatch(actions.reqRecupererListeImportFilters()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionImportFilter);
