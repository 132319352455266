import React, {Component} from 'react';
import {connect} from "react-redux";

import FormulaireSelectionProjet from "../../../Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet";
import Bouton from "../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";

import {IsArrayString} from "../../../../ressources/functionJS/IsArrayString";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as glossaire_pattern from "../../../../ressources/glossaires/glossaire_pattern";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../../../store/actions";

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireAlarmeXY.module.scss';
import {AiOutlineCheck} from "react-icons/ai";

class FormulaireAlarmeXY extends Component {
    state = {
        //paramètre par défaut de l'alarme xy
        
        template_id: null,
        Xsensor_id: null,
        Ysensor_id: null,
        Xcomponent_id: null,
        Ycomponent_id: null

    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigurerAlarmeXY === undefined) {
            this.setState({
                //paramètre par défaut de l'alarme xy(voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerAlarmeXY !== undefined) {
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerAlarmeXY,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigurerAlarmeXY !== this.props.preconfigurerAlarmeXY && this.props.preconfigurerAlarmeXY === undefined) {
            this.setState({
                //todo: paramètre par défaut
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerAlarmeXY !== undefined && !jsDictionaryEquals(prevProps.preconfigurerAlarmeXY, this.props.preconfigurerAlarmeXY)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerAlarmeXY,
            })
        }
    }

    gererEnvoi = () => {
        //mise en forme de l'objet pour l'envoi de la requête API
        let formulaireAlarmeXY = {
            //clé du formulaire de l'alarme xyde l'api
            alarm_xy_id: parseInt(this.state.alarm_xy_id),
            project_id: parseInt(this.state.project_id),
            template_id: parseInt(this.state.template_id),
            Xsensor_id: parseInt(this.state.Xsensor_id),
            Ysensor_id: parseInt(this.state.Ysensor_id),
            Xcomponent_id: parseInt(this.state.Xcomponent_id),
            Ycomponent_id: parseInt(this.state.Ycomponent_id)
        }

        //Envoi la requête de création d'alarme xy
        if (this.props !== undefined && this.props.preconfigurerAlarmeXY === undefined) {
            //si l'alarme xyn'a pas de pré-remplissage des champs du formulaire
            this.props.reqCreerVue(formulaireAlarmeXY);
        } else {
            //sinon modification
            formulaireAlarmeXY["alarm_xy_id"] = parseInt(this.props.preconfigurerAlarmeXY.alarm_xy_id);
            this.props.reqModifierVue(formulaireAlarmeXY)
        }
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererArrayEtatDeCibleName = (event) => this.setState({
        ...this.state,
        [event.target.name]: IsArrayString(event.target.value)
    });
    gererProjetId = (projetId) => {
        this.setState({...this.state, project_id: projetId});
    };
    affichageFormulaire = (style, label_state, label_name, pattern) => {
        /**
         * permet d'afficher un composant input de gestion de la modification
         */

        switch (pattern) {
            case glossaire_pattern.array:
                return (
                    <div className={[s.col_6, S.style].join(" ")}>
                        <span className={[S.stil2].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerAlarmeXY !== undefined ? JSON.stringify(this.state[label_state]) : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererArrayEtatDeCibleName(event)}/>
                    </div>
                )
            default:
                return (
                    <div className={[s.col_6, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerAlarmeXY !== undefined ? this.state[label_state] : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleName(event)}/>
                    </div>
                )
        }
    }

    render() {
        return (
            <Auxiliaire>
                <div className={[s.row].join(" ")}>
                    <div className={S.ConteneurInfo}>
                        <div className={[s.container, s.text_left].join(" ")}>
                            <form onSubmit={event => event.preventDefault()}>
                                <div className={s.form_group}>
                                    <div className={[s.form_row, s.align_items_center].join(" ")}>
                                        {/**DEBUT project_id**/}
                                        <FormulaireSelectionProjet affichageProfil={this.props.affichageProfil}
                                                                   gererProjetId={this.gererProjetId}
                                                                   preconfigurerProjetId={this.state.project_id}/>{/**FIN project_id**/}
                                        {/**DEBUT Xsensor_id**/}{this.affichageFormulaire(s.col_12, "Xsensor_id", checkMot("xsensor_id"), glossaire_pattern.integer)}{/**FIN Xsensor_id**/}
                                        {/**DEBUT Ysensor_id**/}{this.affichageFormulaire(s.col_12, "Ysensor_id", checkMot("ysensor_id"), glossaire_pattern.integer)}{/**FIN Ysensor_id**/}
                                        {/**DEBUT Xcomponent_id**/}{this.affichageFormulaire(s.col_12, "Xcomponent_id", checkMot("xcomponent_id"), glossaire_pattern.integer)}{/**FIN Xcomponent_id**/}
                                        {/**DEBUT Ycomponent_id**/}{this.affichageFormulaire(s.col_12, "Ycomponent_id", checkMot("ycomponent_id"), glossaire_pattern.integer)}{/**FIN Ycomponent_id**/}
                                        {/**DEBUT template_id**/}{this.affichageFormulaire(s.col_12, "template_id", checkMot("template_id"), glossaire_pattern.integer)}{/**FIN template_id**/}
                                    </div>
                                </div>
                                {this.props.affichageProfil === true
                                    ? null
                                    : <div className={s.form_group}>
                                        <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                                            <div>
                                                <Bouton type={type_btn.danger} cliquer={(e) => {
                                                    e.preventDefault();
                                                    this.props.annuler();
                                                }}>{checkMot("annuler")}</Bouton>
                                            </div>
                                            <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                                                {this.props !== undefined && this.props.preconfigurerAlarmeXY === undefined
                                                    ? <AttenteRequete request_type={requestType.configurer_des_alarmes_xy}
                                                                      str={checkMot("creation_en_cour")}>
                                                        <Bouton type={type_btn.succes} ajoutClass={S.Bts}
                                                                cliquer={() => this.gererEnvoi()}><AiOutlineCheck/>
                                                            {checkMot("creer")}</Bouton>
                                                    </AttenteRequete>
                                                    : <AttenteRequete request_type={requestType.modifier_des_alarmes_xy}
                                                                      str={checkMot("modification_en_cour")}>
                                                        <Bouton type={type_btn.succes}
                                                                cliquer={() => this.gererEnvoi()}>{checkMot("enregistrer")}</Bouton>
                                                    </AttenteRequete>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reqCreerVue: (formulaireAlarmeXY) => dispatch(actions.reqCreerAlarmesXY(formulaireAlarmeXY)),
        reqModifierVue: (formulaireAlarmeXY) => dispatch(actions.reqModifierAlarmesXY(formulaireAlarmeXY)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireAlarmeXY);
