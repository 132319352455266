import React, {Component} from 'react';
import {connect} from "react-redux";

import DiagrammeCirculaire from "../../../Graphe/AffichageGraphe/DiagrammeCirculaire/DiagrammeCirculaire";
import ChangementIntervalTempsGraphe
    from "../../../Graphe/AffichageGraphe/ChangementEchantillonGraphe/ChangementEchantillonGraphe";
import GrapheAvancementDetaille
    from "../../../Graphe/AffichageGraphe/GrapheAvancementDetaille/GrapheAvancementDetaille";
import PopupObjet from "./PopupObjet/PopupObjet";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";

import {stringOrEmptyString} from "../../../../ressources/functionJS/stringOrEmptyString";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as glossaire_type_objets_vue from "../../../../ressources/glossaires/glossaire_type_objets_vue";

import {Spinner} from "reactstrap";
import * as s from "../../../../ressources/StyleGlobal/StyleGlobal";
import * as S from "./ObjetGraphe.module.scss"
import {RiLineChartFill} from "react-icons/ri";
import SwitchGraphe from "../../../Graphe/AffichageGraphe/SwitchGraphe";
import * as glossaire_type_export from "../../../../ressources/glossaires/glossaire_type_export";
import {ratioStyleCSS2PuissanceNiveauZoom} from "../../../../ressources/functionJS/ratioStyleCSS2PuissanceNiveauZoom";

const minDimensionGraphe = 500;

class ObjetGraphe extends Component {
    state = {
        afficherPopup: this.props.afficherPopup !== undefined ? this.props.afficherPopup : false,
        afficherModifierIntervalle: false,
        afficherEchantillonPersonnalise: false,
        viewobj_json_content: null,
        dimensionWidth: 60,
        dimensionHeight: 60,
        showSeuils: false,
    }

    componentDidMount() {
        if (this.props.viewobj_json_content !== undefined) {
            this.setState({
                viewobj_json_content: this.props.viewobj_json_content,
                dimensionWidth: this.props.viewobj_json_content.dimensionWidth,
                dimensionHeight: this.props.viewobj_json_content.dimensionHeight,
                showSeuils: this.props.viewobj_json_content.showSeuils !== undefined ? this.props.viewobj_json_content.showSeuils : true
            })
        } else if (this.props.objetAAfficher.viewobj_path === undefined) {
            this.setState({
                viewobj_json_content: this.props.viewobj_json_content,
                dimensionWidth: this.props.viewobj_json_content.dimensionWidth,
                dimensionHeight: this.props.viewobj_json_content.dimensionHeight,
                showSeuils: this.props.viewobj_json_content.showSeuils !== undefined ? this.props.viewobj_json_content.showSeuils : true
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.viewobj_json_content !== undefined && !jsDictionaryEquals(this.props.viewobj_json_content, prevProps.viewobj_json_content) && !jsDictionaryEquals(this.state.viewobj_json_content, this.props.viewobj_json_content)) {
            //si le json de l'objet est en cours de modification
            this.setState({viewobj_json_content: {...this.props.viewobj_json_content}})
        } else if (this.props.objetAAfficher.viewobj_path === undefined && this.props.objetAAfficher.viewobj_path !== prevProps.objetAAfficher.viewobj_path) {
            this.setState({
                viewobj_json_content: this.props.viewobj_json_content,
                showSeuils: this.props.viewobj_json_content.showSeuils !== undefined ? this.props.viewobj_json_content.showSeuils : true
            })
        }
    }

    switchAfficherPopup = () => {
        this.setState({afficherPopup: !this.state.afficherPopup})
    }
    switchAfficherModifierIntervalle = () => this.setState({
        ...this.state,
        afficherModifierIntervalle: !this.state.afficherModifierIntervalle
    });

    render() {
        let afficheGraphe;//objet graphique plotly
        let grapheAAfficher;//objet contenant les données d'affichage
        //dimension de l'objet sur la vue
        let dimensionWidthObjetVue = this.state.dimensionWidth * this.props.mapZoom
        let dimensionHeightObjetVue = this.state.dimensionHeight * this.props.mapZoom
        //dimension du graphe à afficher, cas de la vue et de la popup
        let dimensionWidthGraphe = dimensionWidthObjetVue;
        let dimensionHeightGraphe = dimensionHeightObjetVue;
        if (!(this.state.viewobj_json_content !== null &&//si le json existe
            //si les dimensions sont plus petit que le min précisé dans le json
            ((dimensionWidthObjetVue > this.state.viewobj_json_content.minDimensionWidthGraphe &&
                    dimensionHeightObjetVue > this.state.viewobj_json_content.minDimensionHeightGraphe)
                ||
                //si les dimensions minimales du graphe ne sont pas précisé dans le json
                (this.state.viewobj_json_content.minDimensionWidthGraphe === undefined &&
                    this.state.viewobj_json_content.minDimensionHeightGraphe === undefined &&
                    //si les dimensions sont plus petit que celui par défaut "minDimensionGraphe"
                    dimensionWidthObjetVue > minDimensionGraphe &&
                    dimensionHeightObjetVue > minDimensionGraphe)))) {
            //affiche le graphe avec les dimensions proportionnelles de la fenêtre du navigateur, todo:dimensionner le graphe en fonction de la popup
            let body = document.body, html = document.documentElement;
            dimensionWidthGraphe = Math.max(body.scrollWidth, body.offsetWidth,
                html.clientWidth, html.scrollWidth, html.offsetWidth) * 0.8;
            dimensionHeightGraphe = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight) * 0.78;
        }
        if (this.state.afficherEchantillonPersonnalise) {
            if (this.props.donneesGraphe !== undefined) {
                grapheAAfficher = this.props.donneesGraphe[this.props.objetAAfficher.graph_id]
            }
        } else {
            grapheAAfficher = this.props.objetAAfficher.data
        }
        if (grapheAAfficher !== undefined && grapheAAfficher.config !== undefined) {//cas où les information du graphe vient de l'objet graphe
            let boolAfficherGraphe = true;
            for (let indSetDonnees = 0; indSetDonnees < grapheAAfficher.length; indSetDonnees) {
                if (grapheAAfficher[indSetDonnees].t !== undefined && grapheAAfficher[indSetDonnees].t !== null && grapheAAfficher[indSetDonnees].t.length > 0) {
                    boolAfficherGraphe = false;
                    break;
                }
            }
            if (boolAfficherGraphe) {
                afficheGraphe = <SwitchGraphe
                    donneesGraphe={grapheAAfficher}
                    switchAfficherModifierIntervalle={this.switchAfficherModifierIntervalle}
                    configuration={grapheAAfficher.config}
                    donnees={grapheAAfficher.data}
                    seuils={grapheAAfficher.alarms_levels !== undefined && this.state.showSeuils ? grapheAAfficher.alarms_levels : []}
                    dimensionWidth={dimensionWidthGraphe}
                    dimensionHeight={dimensionHeightGraphe}
                    viewobj_json_content={this.state.viewobj_json_content}
                    reqRecupererDonneesGraphe={this.props.reqRecupererDonneesGraphe}
                />;
            } else {
                afficheGraphe = "Erreur données API non conforme.";
            }
        } else if (!this.props.attenteReqListe['ATTENTE_REQUETE' + requestType.recuperation_des_donnees_d_un_graphe]) {
            afficheGraphe = <div><Spinner/> {checkMot("chargement_du_graphe_en_cours")}</div>;
        }

        let representation_vue = <div className={[S.objetGraphe, S.FondImageGraphe].join(" ")}
                                      onClick={() => window.open("/export_impression_ecran-" + glossaire_type_export.graphe + "-" + this.props.objetAAfficher.graph_id, "_blank", "toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=" + (window.screen.availWidth) + ",innerHeight=" + (window.screen.availHeight))}>
            <RiLineChartFill size={this.props.dimensionsIcon - 5}/>
        </div>
        //représentation de l'objet sur la vue

        if (this.props.objetAAfficher !== undefined) {
            if (this.state.viewobj_json_content === null && this.props.objetAAfficher.viewobj_path !== undefined && stringOrEmptyString(this.props.objetAAfficher.viewobj_path) !== "" && !this.state.attenteRecupFichierJson && this.props.viewobj_json_content === undefined) {
                try {
                    let cheminURL;
                    switch (document.domain) {
                        case "localhost:3000":
                            cheminURL = this.props.objetAAfficher.viewobj_path
                            break;
                        case "argos-dev-test.itmsol.fr":
                            cheminURL = "https://" + document.domain + this.props.objetAAfficher.viewobj_path.slice(1, this.props.objetAAfficher.viewobj_path.length)
                            break;
                        case "argos-dev.itmsol.fr":
                            cheminURL = "https://" + document.domain + this.props.objetAAfficher.viewobj_path.slice(1, this.props.objetAAfficher.viewobj_path.length)
                            break;
                        case "argos-v19.itmsol.fr":
                            cheminURL = "https://" + document.domain + this.props.objetAAfficher.viewobj_path.slice(1, this.props.objetAAfficher.viewobj_path.length)
                            break;
                        default:
                            cheminURL = this.props.objetAAfficher.viewobj_path
                    }
                    fetch(cheminURL + "?varget0" + Math.random())
                        //todo: modifier le chemin absolu en chemin relatif
                        .then(res => res.json()).then(data => {
                        this.setState({
                            attenteRecupFichierJson: false,
                            viewobj_json_content: data,
                            dimensionWidth: data.dimensionWidth,
                            dimensionHeight: data.dimensionHeight,
                            showSeuils: data.showSeuils !== undefined ? data.showSeuils : true
                        })
                    }).catch(e => console.error("error: ", e));
                } catch (e) {
                    this.setState({attenteRecupFichierJson: false, viewobj_json_content: null})
                    console.error("error", e)
                }
            } else if (this.state.viewobj_json_content !== null && this.state.viewobj_json_content.type_de_representation !== undefined) {
                if ((dimensionWidthObjetVue > this.state.viewobj_json_content.minDimensionWidthGraphe && dimensionHeightObjetVue > this.state.viewobj_json_content.minDimensionHeightGraphe) ||
                    (this.state.viewobj_json_content.minDimensionWidthGraphe === undefined && this.state.viewobj_json_content.minDimensionHeightGraphe === undefined && dimensionWidthObjetVue > minDimensionGraphe && dimensionHeightObjetVue > minDimensionGraphe) ||
                    (this.state.viewobj_json_content.type_de_representation===glossaire_type_objets_vue.bouton_de_redirection_graphe)) {
                    switch (this.state.viewobj_json_content.type_de_representation) {
                        case glossaire_type_objets_vue.affichgedirectgraphe:

                            representation_vue = <div key={this.props.key}
                                                      style={{...this.state.viewobj_json_content.tailleFixe === true ? this.state.viewobj_json_content.styleMainDiv : ratioStyleCSS2PuissanceNiveauZoom(this.state.viewobj_json_content.styleMainDiv, this.props.mapZoom)}}>
                                {afficheGraphe}
                            </div>
                            break;
                        case glossaire_type_objets_vue.diagrammeCirculaire:

                            representation_vue = <div key={this.props.key}
                                                      style={{...this.state.viewobj_json_content.tailleFixe === true ? this.state.viewobj_json_content.styleMainDiv : ratioStyleCSS2PuissanceNiveauZoom(this.state.viewobj_json_content.styleMainDiv, this.props.mapZoom)}}>
                                <DiagrammeCirculaire
                                    donnees={grapheAAfficher.data}
                                    dimensionWidth={dimensionWidthObjetVue}
                                    dimensionHeight={dimensionHeightObjetVue}
                                    viewobj_json_content={this.state.viewobj_json_content}/>
                            </div>
                            break;
                        case glossaire_type_objets_vue.grapheAvancementDetaille:

                            representation_vue = <div key={this.props.key}
                                                      style={{...this.state.viewobj_json_content.tailleFixe === true ? this.state.viewobj_json_content.styleMainDiv : ratioStyleCSS2PuissanceNiveauZoom(this.state.viewobj_json_content.styleMainDiv, this.props.mapZoom)}}>
                                <GrapheAvancementDetaille
                                    donneesGraphe={grapheAAfficher}
                                    switchAfficherModifierIntervalle={this.switchAfficherModifierIntervalle}
                                    configuration={grapheAAfficher.config}
                                    donnees={grapheAAfficher.data}
                                    seuils={grapheAAfficher.alarms_levels !== undefined && this.state.showSeuils ? grapheAAfficher.alarms_levels : []}
                                    dimensionWidth={dimensionWidthObjetVue}
                                    dimensionHeight={dimensionHeightObjetVue}
                                    viewobj_json_content={this.state.viewobj_json_content}
                                />
                            </div>
                            break;
//                         case glossaire_type_objets_vue.historique_positions_x_y:
//
// //create data and add it to the map
//                             let coords=[]
//                             for(let i=0;i<this.props.objetAAfficher.data.data[0].y.length;i++){
//                                 coords.push([this.props.objetAAfficher.data.data[1].y[i],this.props.objetAAfficher.data.data[0].y[i]])
//                             }
//
//                             let spiral = [{
//                                 "type": "LineString",
//                                 "coordinates": coords
//                             }];
//                             var spiralLayer = this.props.L.geoJson(spiral, {
//                                 style: {
//                                     "color": "#d44a00",
//                                     "weight": 1,
//                                     "opacity": 0.3,
//                                     // "dasharray":"3 1"
//                                 }
//                             });
//
//                             var spiralBounds = spiralLayer.getBounds();
//                             // this.props.map.fitBounds(spiralBounds);
//                             spiralLayer.addTo(this.props.map);
//
//                             var spiralLine = this.props.L.polyline(spiralLayer).addTo(this.props.map)
//
//                             representation_vue = <div key={this.props.key}>
//                             </div>
//                             break;
                        case glossaire_type_objets_vue.bouton_de_redirection_graphe:
                            representation_vue = <button
                                style={{...this.state.viewobj_json_content.tailleFixe === true ? this.state.viewobj_json_content.styleMainDiv : ratioStyleCSS2PuissanceNiveauZoom(this.state.viewobj_json_content.styleMainDiv, this.props.mapZoom)}}
                                onClick={() => window.open("/export_impression_ecran-" + glossaire_type_export.graphe + "-" + this.props.objetAAfficher.graph_id, "_blank", "toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=" + (window.screen.availWidth) + ",innerHeight=" + (window.screen.availHeight))}
                                className={[s.btn, s.btn_sm].join(" ")}
                                disabled={this.state.viewobj_json_content.disabled === true}>
                                {
                                    this.state.viewobj_json_content.nom_du_bouton !== undefined
                                        ? this.state.viewobj_json_content.nom_du_bouton
                                        : this.props.objetAAfficher.name
                                }
                            </button>
                            break;
                        default:
                            representation_vue = <div className={[S.objetGraphe, S.FondImageGraphe].join(" ")}
                                                      onClick={this.switchAfficherPopup}>
                                <PopupObjet afficherPopup={this.state.afficherPopup}
                                            fermerPopup={this.switchAfficherPopup}
                                            iconeObjet={<RiLineChartFill size={this.props.dimensionsIcon - 5}/>}
                                >
                                    <div className={S.dimensionGraphe}
                                         style={{...this.state.viewobj_json_content.styleMainDiv}}>
                                        {afficheGraphe}
                                    </div>
                                    <div className={s.row}>
                                        <ChangementIntervalTempsGraphe graph_id={this.props.objetAAfficher.graph_id}
                                                                       afficherPopup={this.state.afficherModifierIntervalle}
                                                                       fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}
                                                                       changementEchantillonObjetGrapheVue={() => this.setState({afficherEchantillonPersonnalise: true})}/>
                                    </div>
                                </PopupObjet>
                            </div>
                            break;
                    }
                } else {
                    representation_vue =
                        <div style={{
                            width: dimensionWidthObjetVue,
                            height: dimensionHeightObjetVue,
                            backgroundColor: "grey"
                        }}>
                            <div className={[S.objetGraphe, S.FondImageGraphe].join(" ")}
                                 style={{textAlign: "center"}}//centre l'icone de graphe
                                 onClick={this.switchAfficherPopup}>
                                <PopupObjet afficherPopup={this.state.afficherPopup}
                                            fermerPopup={this.switchAfficherPopup}
                                            iconeObjet={<RiLineChartFill size={this.props.dimensionsIcon - 5}/>}
                                >
                                    <div style={{
                                        width: "100%",
                                        height: "100%", ...this.state.viewobj_json_content.styleMainDiv
                                    }}>
                                        {afficheGraphe}
                                    </div>
                                    <div className={s.row}>
                                        <ChangementIntervalTempsGraphe graph_id={this.props.objetAAfficher.graph_id}
                                                                       afficherPopup={this.state.afficherModifierIntervalle}
                                                                       fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}
                                                                       changementEchantillonObjetGrapheVue={() => this.setState({afficherEchantillonPersonnalise: true})}/>
                                    </div>
                                </PopupObjet>
                            </div>
                        </div>
                }
            }
        }
        return <Auxiliaire>{representation_vue}</Auxiliaire>
    }
}

const mapStateToProps = (state) => {
    return {
        donneesGraphe: state.graphe.liste_donnees_graphes,
        attenteReqListe: state.attenteRequete
    };
}
export default connect(mapStateToProps)(ObjetGraphe);
