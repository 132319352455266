import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

export const initialState = {
};

const debutAttente = (state, action) => {
    return updateObject( state, {["ATTENTE_REQUETE"+action.requestType]: true} );
};
const finAttente = (state, action) => {
    return updateObject( state, {["ATTENTE_REQUETE"+action.requestType]: false} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.DEBUT_ATTENTE: return debutAttente(state, action);
        case actionTypes.FIN_ATTENTE: return finAttente(state, action);
        default:
            return state;
    }
};

export default reducer;
