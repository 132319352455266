import React, {Component} from 'react';
import {connect} from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import * as actions from "../../../../store/actions";
import Bouton from "../../../UI/Bouton/Bouton";
import {checkMot} from "../../../../ressources/functionJS/checkMot";

class TableauGabarit extends Component {
    componentDidMount() {
        //importer la table de sensorType (utiliser la requête api)
        this.props.recupererListeGabarit(this.props.filtre_projects_list_id);
    }

    state = {
        columns: [
            {dataField: 'template_id', text: checkMot("template_id"), sort: true},
            {dataField: 'project_id', text: checkMot("project_id"), sort: true},
            {dataField: 'template_name', text: checkMot("template_name"), sort: true},
            {dataField: 'start', text: checkMot("start"), sort: true},
            {dataField: 'end', text: checkMot("end"), sort: true},
            {dataField: 'private', text: checkMot("private"), sort: true},
            {dataField: 'alarm_level', text: checkMot("alarm_level"), sort: true},
            // {dataField: 'template', text: checkMot("template"), sort: true},
        ],
        list_templates: this.props.list_templates,
        selectRow: {
            mode: 'radio',
            clickToSelect: true,
            style: {
                background: "#a6153e",
                color: "white",
            },
            onSelect: (row, isSelect) => {
                if (isSelect) {
                    return this.props.selectioneLigne(row);
                }
            }
        }
    }
    
    render() {
        return (
            this.props.list_templates !== undefined && this.props.list_templates !== []
                ? <BootstrapTable
                    keyField={"template_id"}
                    data={this.props.list_templates}
                    columns={this.state.columns}
                    selectRow={this.state.selectRow}
                />
                : <Bouton
                    cliquer={() => this.props.recupererListeGabarit(this.props.filtre_projects_list_id)}>{checkMot("refresh")}</Bouton>
        );
    }
}


const mapStateToProps = state => {
    return {
        list_templates: state.alarme.list_templates,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeGabarit: (listeIdProjets) => dispatch(actions.reqListeGabarit(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauGabarit);