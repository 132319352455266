export const validerHeure = (heureString) => {
    // Utilisez une expression régulière pour valider l'heure au format "HH:MM".
    const heureRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (!heureString.match(heureRegex)) {
        return false;
    }

    // Séparez l'heure et les minutes.
    const [heures, minutes] = heureString.split(':');

    // Convertissez les chaînes en nombres.
    const heuresNum = parseInt(heures, 10);
    const minutesNum = parseInt(minutes, 10);

    // Ajoutez des conditions pour vérifier que les heures et les minutes sont valides.
    return !(heuresNum < 0 || heuresNum > 23 || minutesNum < 0 || minutesNum > 59);


}
