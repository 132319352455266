import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from "axios";
import * as actions from "./index";
import * as actionTypes from './actionTypes';
import {store} from "../../index";

const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) => {
    if (res.data.errors !== undefined) {
        for (let erreurKey = 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre, ["ApiEchecErreursEnBoucle", {
                numAvertissement: erreurKey,
                idErreur: res.data.errors[erreurKey]
            }]));
        }
    } else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api", "errors est undefine, pour une requête de connexion"));
    }
}
const requetePost = (dispatch, requeteAPI) => {
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */

    const state = store.getState();
    dispatch(actions.debutAttente(requeteAPI.request_type));
    console.log('envoi: ', requeteAPI);

    axios.post("", requeteAPI, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            console.log('reception: ', res);
            let titreAlerteSucces = "Requête api succès";
            let corpsAlerteSucces = "Requête api succès";
            let titreAlerteEchec = "Requête api échec";
            let corpsAlerteEchec = "Requête api échec";
            let succesRequete = {
                beforeActionReducteur() {},
                actionReducteur() {},
                alerte() {},
                fonctionSupplementaire() {}
            };
            let succesRequeteAvecErreur = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            let echecRequete = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            switch (requeteAPI.request_type) {
                case requestType.recuperation_de_la_liste_des_vues:
                    titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_vues";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_vues";
                    titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_vues";
                    corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_vues";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    // succesRequete.actionReducteur = () => dispatch(enregistrerListeVues(res.data));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeVues(res.data));
                    break;
                case requestType.recuperer_une_vue:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_une_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_une_vue";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_une_vue";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_une_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    succesRequete.actionReducteur = () => dispatch(enregistrerVue(res.data));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.creer_une_vue:
                    titreAlerteSucces = "ApiSuccesTitre_creer_une_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_creer_une_vue";
                    titreAlerteEchec = "ApiEchecTitre_creer_une_vue";
                    corpsAlerteEchec = "ApiEchecCorps_creer_une_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.ajouter_une_couche_de_vue:
                    titreAlerteSucces = "ApiSuccesTitre_ajouter_une_couche_de_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_ajouter_une_couche_de_vue";
                    titreAlerteEchec = "ApiEchecTitre_ajouter_une_couche_de_vue";
                    corpsAlerteEchec = "ApiEchecCorps_ajouter_une_couche_de_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_couches_de_vue:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_couches_de_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_couches_de_vue";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_couches_de_vue";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_couches_de_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    if(Array.isArray(res.data.layers_list)&&res.data.layers_list!==null){
                        succesRequete.actionReducteur = () => dispatch(enregistrerListeCoucheVues(res.data, requeteAPI.view_id));
                    }else {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    }
                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.modifier_l_ordre_des_couches:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_l_ordre_des_couches";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_l_ordre_des_couches";
                    titreAlerteEchec = "ApiEchecTitre_modifier_l_ordre_des_couches";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_l_ordre_des_couches";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.supprimer_une_couche_de_vue:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_couches_de_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_couches_de_vue";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_couches_de_vue";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_couches_de_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.modifier_une_vue:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_une_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_une_vue";
                    titreAlerteEchec = "ApiEchecTitre_modifier_une_vue";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_une_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.view_id]));
                    // succesRequete.fonctionSupplementaire = () => dispatch(actions.reqRecupererVue(requeteAPI.view_id));

                    if (res.data.errors!== undefined && res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.supprimer_une_vue:
                    titreAlerteSucces = "ApiSuccesTitre_supprimer_une_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_supprimer_une_vue";
                    titreAlerteEchec = "ApiEchecTitre_supprimer_une_vue";
                    corpsAlerteEchec = "ApiEchecCorps_supprimer_une_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.view_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_objets_associe_a_la_vue:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_objets_associe_a_la_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_objets_associe_a_la_vue";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_objets_associe_a_la_vue";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_objets_associe_a_la_vue";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeObjetsAssocieVue(res.data, requeteAPI.view_id));
                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue";

                    succesRequete.beforeActionReducteur = () => dispatch(viderListeObjetsAAfficherAssocieVue(requeteAPI.view_id));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeObjetsAAfficherAssocieVue(res.data, requeteAPI.view_id));
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_les_informations_complementaires_d_un_objet:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_les_informations_complementaires_d_un_objet";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_les_informations_complementaires_d_un_objet";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_les_informations_complementaires_d_un_objet";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_les_informations_complementaires_d_un_objet";

                    // succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                    succesRequete.actionReducteur = () => dispatch(enregistrerObjetAAfficherAssocieVue(res.data, requeteAPI.viewobj_id, requeteAPI.view_id));
                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.view_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_tableaux_de_bord_favoris:
                    titreAlerteSucces = "ApiSuccesTitre_liste_des_tableaux_de_bord_favoris";
                    corpsAlerteSucces = "ApiSuccesCorps_liste_des_tableaux_de_bord_favoris";
                    titreAlerteEchec = "ApiEchecTitre_liste_des_tableaux_de_bord_favoris";
                    corpsAlerteEchec = "ApiEchecCorps_liste_des_tableaux_de_bord_favoris";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    // succesRequete.actionReducteur = () => dispatch(enregistrerListeVues(res.data));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeVuesFavoris(res.data));
                    break;
                case requestType.recuperer_la_liste_des_tableaux_de_bord_utilisateur:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_tableaux_de_bord_utilisateur";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_tableaux_de_bord_utilisateur";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_tableaux_de_bord_utilisateur";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_tableaux_de_bord_utilisateur";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerTableauxDeBord(res.data, requeteAPI.user_id));
                    break;
                case requestType.modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur";
                    titreAlerteEchec = "ApiEchecTitre_modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    break;
                default:
                    break;
            }
            if (res.data.state) {
                if (res.data.errors.length === 0) {
                    succesRequete.beforeActionReducteur();
                    succesRequete.actionReducteur();
                    succesRequete.alerte();
                    succesRequete.fonctionSupplementaire();
                } else {
                    succesRequeteAvecErreur.actionReducteur();
                    succesRequeteAvecErreur.alerte();
                    succesRequeteAvecErreur.fonctionSupplementaire();
                }
            } else {
                echecRequete.actionReducteur();
                echecRequete.alerte();
                echecRequete.fonctionSupplementaire();
            }
            if (requeteAPI.request_type === requestType.recuperation_de_la_liste_des_vues) {
                //teste de rafraîchissement automatique après chaque interval de temps
                // setTimeout(()=>dispatch(actions.reqRecupererListeVues()),7000)
            } else {
                //rafraîchie la liste des vues après chaque requête
            }
            if (requeteAPI.request_type === requestType.supprimer_une_vue || requeteAPI.request_type === requestType.modifier_une_vue || requeteAPI.request_type === requestType.creer_une_vue) {
                dispatch(actions.reqRecupererListeVues(state.authentifie.filtre_projects_list_id));
            }
        })
        .catch(err => {
            console.error("Error API", requeteAPI.request_type, ":", err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
        })
        .finally(() => dispatch(actions.finAttente(requeteAPI.request_type)));
}

export const reqRecupererListeVues = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperation_de_la_liste_des_vues,
            list_project_id: listeIdProjets

        });
    };
};
export const reqRecupererVue = (view_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_une_vue,
            view_id: view_id
        });
    };
};
export const reqCreerVue = (formulaireVue) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.creer_une_vue,
            ...formulaireVue
        });
    };
};
export const reqAjouterCoucheVue = (view_id, layer) => {
    /**
     * requête pour ajouter une couche à une vue
     */
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.ajouter_une_couche_de_vue,
            view_id: view_id,
            layer:{...layer}
        });
    };
};
export const reqRecupererListeCouchesVue = (view_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_couches_de_vue,
            view_id: view_id,
        });
    };
};
export const reqRecupererListeTableauxDeBordFavoris = () => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_tableaux_de_bord_favoris,
        });
    };
};
export const reqModifierOrdreCouches = (view_id, layers_position_order_list) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_l_ordre_des_couches,
            view_id: view_id,
            layers_position_order_list: layers_position_order_list
        });
    };
};
export const reqSupprimerCoucheVue = (layer_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_une_couche_de_vue,
            layer_id: layer_id,
        });
    };
};
export const reqModifierVue = (view_id, formulaireVue) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_une_vue,
            view_id: view_id,
            ...formulaireVue
        });
    };
};
export const reqSupprimerVue = (idVue) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_une_vue,
            view_id: idVue,

        });
    };
};
export const reqRecupererListeObjetsAssocieVue = (view_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_objets_associe_a_la_vue,
            view_id: view_id,
        });
    };
};
export const reqAjouterObjetVue = (view_id, viewobj) => {
    /**
     * requête pour ajouter un objet à une vue
     */
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.ajouter_un_objet_associe_a_la_vue,
            view_id: view_id,
            viewobj:{...viewobj}
        });
    };
};
export const reqModifierObjetVue = (view_id, viewobj_id, viewobj) => {
    /**
     * requête pour ajouter un objet à une vue
     */
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_un_objet_associe_a_la_vue,
            viewobj_id: viewobj_id,
            view_id: view_id,
            viewobj:{...viewobj}
        });
    };
};
export const reqSupprimerObjetVue = (viewobj_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_un_objet_associe_a_la_vue,
            viewobj_id: viewobj_id,
        });
    };
};
export const reqRecupererListeObjetsAAfficherAssocieVue = (view_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue,
            view_id: view_id,
        });
    };
};
export const reqRecupererObjetAAfficherAssocieVue = (view_id, viewobj_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_les_informations_complementaires_d_un_objet,
            view_id: view_id,
            viewobj_id: viewobj_id
        });
    };
};
export const reqRecupererListeTableauxDeBord = (user_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_tableaux_de_bord_utilisateur,
            user_id: user_id
        });
    };
};
export const reqModifierListeTableauxDeBordFavorisUtilisateur = (user_id, liste_fav_tableaux_de_bord) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur,
            user_id: user_id,
            favorite_views_list: liste_fav_tableaux_de_bord
        });
    };
};
export const supprimerVuesLayersObjets = () => {
    return {type: actionTypes.SUPPRIMER_VUES_LAYERS_OBJETS};
};
const enregistrerListeVues = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_VUES, views_list: data.views_list};
};
const enregistrerListeVuesFavoris = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_VUES_FAVORIS, favorite_views_list: data.favorite_views_list};
};
const enregistrerVue = data => {
    return {type: actionTypes.ENREGISTRER_VUE, view: data.view};
};
const enregistrerListeCoucheVues = (data, view_id) => {
    return {type: actionTypes.ENREGISTRER_LISTE_COUCHES_VUE, layers_list: data.layers_list, view_id:view_id};
};
const enregistrerListeObjetsAssocieVue = (data, view_id) => {
    return {type: actionTypes.ENREGISTRER_LISTE_OBJETS_ASSOCIE_VUE, objects_list_view: data.objects_list_view, view_id:view_id};
};
export const viderListeObjetsAAfficherAssocieVue = (view_id) => {
    return {type: actionTypes.VIDER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE, view_id:view_id};
};
const enregistrerListeObjetsAAfficherAssocieVue = (data, view_id) => {
    return {type: actionTypes.ENREGISTRER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE, data_objects_list_view: data.data_objects_list_view, view_id:view_id};
};
const enregistrerObjetAAfficherAssocieVue = (data,viewobj_id, view_id) => {
    return {type: actionTypes.ENREGISTRER_OBJET_A_AFFICHER_ASSOCIE_VUE, viewobj_content: data.viewobj_content, viewobj_id:viewobj_id, view_id:view_id};
};
const enregistrerTableauxDeBord = (data, user_id) => {
    return {type: actionTypes.ENREGISTRER_LISTE_TABLEAUX_DE_BORD, views_list: data.views_list, favorite_views_list: data.favorite_views_list, user_id:user_id};
};
