import React, {Component} from 'react';

import S from './ChangerDeMDP.module.scss';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import Bouton from "../../UI/Bouton/Bouton";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import MdpMetre from "../../UI/MdpMetre/MdpMetre";
import zxcvbn from "zxcvbn";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";
import {checkMot} from "../../../ressources/functionJS/checkMot";
class ChangerDeMDP extends Component{

    constructor(props) {
        super(props);
        this.state = {
            ancienMotDePasse: '',
            nouveauMotDePasse: '',
            confirmeNouveauMotDePasse: '',
            afficheFormulaireDeModificationBool:false,
            afficheFormulaireDeModificationMDPBool:false,
        };
    }
    gererAncienMotDePasse = event => this.setState({ ancienMotDePasse: event.target.value });
    gererNouveauMotDePasse = event => this.setState({ nouveauMotDePasse: event.target.value });
    gererForceNouveauMotDePasse = force => this.setState({ forceNouveauMotDePasse: force });
    gererConfirmeNouveauMotDePasse = event => {
        this.setState({ confirmeNouveauMotDePasse: event.target.value, confirmeNouveauMotDePasseBool:this.state.nouveauMotDePasse===event.target.value});
    }
    render() {

        const formulaireChangerMDP = <Auxiliaire>
        <div className={[S.Profil ].join(" ")}>
            <div className={[S.profilH1, s.card_header].join(" ")} >{checkMot("changer_votre_mot_de_passe")}:</div>
        <div className={[s.card, S.style].join(" ")}>
            <div className={[s.form_group, s.row].join(" ")}>
                <label className={[s.col_sm_3, S.label].join(" ")}>{checkMot("ancien_mdp")}</label>
                <div className={[s.col_sm_6].join(" ")}>
                    <input type="password" className={[s.form_control, S.MotDePasse, S.iputNew].join(" ")}
                           id="inputPassword" placeholder="ancienMDP" value={this.state.ancienMotDePasse}
                           onChange={this.gererAncienMotDePasse}/>
                </div>
                <span className={[s.col_sm_1].join(" ")}/>
            </div>
            <div className={[s.form_group, s.row].join(" ")}>
                <label className={[s.col_sm_3, s.col_form_label, S.label].join(" ")}>{checkMot("nouveau_mdp")}</label>
                <div className={[s.col_sm_3].join(" ")}>
                    <input type="password" className={[s.form_control, S.MotDePasse, S.iputNew].join(" ")}
                           id="inputPassword" placeholder="nouveauMDP" value={this.state.nouveauMotDePasse}
                           onChange={this.gererNouveauMotDePasse}/>
                </div>
                    <div className={[s.col_sm_3, S.iputNewZ].join(" ")}>
                         <MdpMetre valeurForceMDP={zxcvbn(this.state.nouveauMotDePasse)} password={this.state.nouveauMotDePasse} />
                    </div>
                <span className={[s.col_sm_1].join(" ")}>
                        {zxcvbn(this.state.nouveauMotDePasse).score>3
                            ? <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle-fill"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            : <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        }
                    </span>
            </div>
            <div className={[s.form_group, s.row].join(" ")}>
                <label htmlFor="inputPassword" className={[s.col_sm_3, s.col_form_label, S.label].join(" ")}>{checkMot("confirmer_nouveau_mdp")}</label>
                <div className={[s.col_sm_6].join(" ")}>
                    <input type="password" className={[s.form_control, S.MotDePasse, S.iputNew].join(" ")}
                           id="inputPassword" placeholder="ConfirmerNouveauMDP" value={this.state.confirmeNouveauMotDePasse}
                           onChange={this.gererConfirmeNouveauMotDePasse}/>
                </div>
                <span className={[s.col_sm_1].join(" ")}>
                        {this.state.confirmeNouveauMotDePasseBool
                            ? <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle-fill"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            :<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        }
                    </span>
            </div>
            <div className={s.row}>
                <div className={[S.Bouton, s.col_3, s.text_left].join(" ")}>
                    <Bouton ajoutClass={S.btnRed}  cliquer={this.props.annulerModificationMDP} type={type_btn.danger}>{checkMot("annuler")}</Bouton>
                </div>
                <div className={[S.Bouton, s.col_3, s.text_left].join(" ")}>
                    <AttenteRequete request_type={requestType.changer_son_mot_de_passe} str={checkMot("changement_de_mdp_en_cours")}>
                        <Bouton  ajoutClass={S.btn} cliquer={() => this.props.changerDeMDP(this.state.ancienMotDePasse, this.state.nouveauMotDePasse, this.state.confirmeNouveauMotDePasse)}
                                desactive={!(zxcvbn(this.state.nouveauMotDePasse).score>3 && this.state.confirmeNouveauMotDePasseBool)} type={type_btn.succes}>{checkMot("changer_votre_mot_de_passe")}</Bouton>
                    </AttenteRequete>
                </div>
            </div>
            </div>
        </div>
        </Auxiliaire>;
        const changementMDPReussi = <div>
            <h3>{checkMot("vous_avez_changer_de_mot_de_passe_deconnecte_vous_pour_vous_reconnecte_avec_votre_nouveau_mot_de_passe")}</h3>
            <div className={S.Bouton}>
            {checkMot("vous_avez_changer_de_mot_de_passe_deconnecte_vous_pour_vous_reconnecte_avec_votre_nouveau_mot_de_passe")}Ou revenez sur votre
                <Bouton ajoutClass={S.btn} cliquer={this.props.annulerModificationMDP}>{checkMot("profil")}</Bouton>
            </div>
        </div>
        return (
            this.props.validationChangementMotDePasse?changementMDPReussi:formulaireChangerMDP
        );
    }

}


export default ChangerDeMDP;
