import React, {Component} from 'react';
import {connect} from "react-redux";

import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import {checkMot} from "../../../../ressources/functionJS/checkMot";

import * as actions from "../../../../store/actions";

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {text_geral, stile} from './FormulaireSelectionSensorType.module.scss';

class FormulaireSelectionSensorType extends Component {
    state = {
        sensorTypeId: null,
    }

    componentDidMount() {
        this.props.recupererListeSensorType();
        this.setState({...this.state, sensorTypeId: this.props.preconfigurerSensorTypeId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.preconfigurerSensorTypeId !== prevProps.preconfigurerSensorTypeId) {
            this.setState({...this.state, sensorTypeId: this.props.preconfigurerSensorTypeId});
        }
    }

    gererSensorTypeId = (event) => {
        /**
         * gère id du SensorType en fonction de l'option sélectionnée
         */
        let newSensorType = event.target.value;
        this.setState({...this.state, sensorTypeId: newSensorType});
        this.props.gererSensorTypeId(newSensorType);
    }

    render() {
        // récupérer liste des SensorTypes avec this.props.listeSensorTypes
        // créer un formulaire pour ajouter un SensorType à un capteur sous forme de liste déroulante
        return (
            <Auxiliaire>

                <label className={[stile].join(" ")} htmlFor="inlineFormCustomSelect">
                    {checkMot("capteur_type")}
                    {/*Affiche l'id et le nom du SensorType*/}
                    {/*{this.state.sensorTypeId!==null*/}
                    {/*    ?Object.keys(this.props.listeSensorTypes).map(indListeSensorTypes=>{*/}
                    {/*        return parseInt(this.state.sensorTypeId)===parseInt(this.props.listeSensorTypes[indListeSensorTypes].sensor_type_id)*/}
                    {/*            ?<span> id: {this.props.listeSensorTypes[indListeSensorTypes].sensor_type_id+", name: "+this.props.listeSensorTypes[indListeSensorTypes].sensor_type_name}</span>*/}
                    {/*            :null})*/}
                    {/*    :null*/}
                    {/*}*/}
                </label>
                {this.props.listeSensorTypes !== undefined && this.props.listeSensorTypes !== []
                    ? <select
                        className={[s.custom_select, s.mr_sm_2, text_geral].join(" ")}
                        onChange={(event) => this.gererSensorTypeId(event)}

                    >
                        <option key={"TypeNull"} value={null} selected={this.props.preconfigurerSensorTypeId === null}>
                            {checkMot("selectionne_type_capteur")}
                        </option>
                        {Object.keys(this.props.listeSensorTypes).map(indListeSensorTypes => {
                            return <option key={this.props.listeSensorTypes[indListeSensorTypes].sensor_type_id}
                                           value={this.props.listeSensorTypes[indListeSensorTypes].sensor_type_id}
                                           selected={parseInt(this.props.preconfigurerSensorTypeId) === parseInt(this.props.listeSensorTypes[indListeSensorTypes].sensor_type_id)}>
                                id: {this.props.listeSensorTypes[indListeSensorTypes].sensor_type_id + ", name: " + this.props.listeSensorTypes[indListeSensorTypes].sensor_type_name}
                            </option>
                        })}
                    </select>
                    : <div>{checkMot("liste_capteur_pas_recupere_actuliser_page")}</div>}

            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeSensorTypes: state.capteur.sensor_types_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeSensorType: () => dispatch(actions.reqRecupererListeSensorType()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionSensorType);
