import React, {Component} from 'react';
import {checkMot} from "../../ressources/functionJS/checkMot";

import S from './Rapport.module.scss';
import Auxiliaire from "../../hoc/Auxiliaire/Auxiliaire";
import * as indexConteneurInfo from "../../ressources/glossaires/glossaire_conteneur_info";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import RapportGabarit from "./RapportGabarit/RapportGabarit";
import RapportGenere from "./RapportGenere/RapportGenere";
import Rapports from "../InterfaceClient/Tabs/Rapports/Rapports";

const rapport_gabarit ="rapport_gabarit"
const rapport_genere ="rapport_genere"
const rapport_publie ="rapport_publie"

class Rapport extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,
        ongletSelectionne: rapport_gabarit
    }

    //Fonction pour enregistrer les informations de la ligne sélectionné depuis le tableau de Alarmes
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });

    //fonction pour refermer le contneur information lors d'une annulation de création
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Alarmes non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }

    //fonction pour ouverture du conteneur information avec le formulaire de création
    creerVue = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        //affiche le contenu de l'onglet sélectionné
        let moduleOnglet;
        switch (this.state.ongletSelectionne) {
            case rapport_gabarit:
                moduleOnglet=<RapportGabarit/>
                break;
            case rapport_genere:
                moduleOnglet=<RapportGenere/>
                break;
            case rapport_publie:
                moduleOnglet=<Rapports/>
                break;
            default:
                moduleOnglet=<div style={{position:"absolute", top:"50px", left:"15px"}}>{checkMot("selectionner_un_onglet")}</div>
                break
        }

        return (
            <Auxiliaire>
                <div className={[S.ConteneurVue].join(" ")}>
                    <div className={S.barConteneur}>
                        <div className={S.barOnglet}>
                            <div className={[S.titreOnglet, this.state.ongletSelectionne === rapport_gabarit?S.titreOngletSelectionne:""].join(" ")} onClick={()=>this.setState({ongletSelectionne: rapport_gabarit})}> {checkMot("rapport_gabarit")} </div>
                            <div className={[S.titreOnglet, this.state.ongletSelectionne === rapport_genere?S.titreOngletSelectionne:""].join(" ")} onClick={()=>this.setState({ongletSelectionne: rapport_genere})}> {checkMot("rapport_genere")} </div>
                            <div className={[S.titreOnglet, this.state.ongletSelectionne === rapport_publie?S.titreOngletSelectionne:""].join(" ")} onClick={()=>this.setState({ongletSelectionne: rapport_publie})}> {checkMot("rapport_publie")} </div>
                        </div>
                    </div>
                    {moduleOnglet}
                </div>
            </Auxiliaire>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        reqSupprimer: (idRapportGabarit) => dispatch(actions.reqSupprimerAlarmesXY(idRapportGabarit))
    };
};

export default connect(null, mapDispatchToProps)(Rapport);
