import React, {Component} from 'react';
import {connect} from 'react-redux';
import {textFilter} from "react-bootstrap-table2-filter";

import Tableau from "../../../UI/tableau/Tableau";
import Bouton from "../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";

import * as actions from "../../../../store/actions";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";

import * as S from "./TableauProjetsTauxCriticite.module.scss";

import {VscNewFile, VscRefresh, VscSearch} from "react-icons/vsc";
import {convertiUtsToDateHour} from "../../../InterfaceClient/Tabs/Alarmes/TableauAlarmesClient/TableauAlarmesClient";
import {TIME_UNITS, uniteAffichageDuree} from "../../../UI/FormulaireSaisieDurée/FormulaireSaisieDuree";

class TableauProjetsTauxCriticite extends Component {
    state = {
        columns: [
            {

                dataField: 'detection_timestamp_sensor_id', text: [checkMot("detection_timestamp_sensor_id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("detection_timestamp_sensor_id", checkMot("detection_timestamp_sensor_id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return convertiUtsToDateHour(cell)
                },
                hidden: true
            },
            {

                dataField: 'detection_timestamp', text: [checkMot("detection_timestamp"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("detection_timestamp", checkMot("detection_timestamp"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return convertiUtsToDateHour(cell)
                },
            },
            {

                dataField: 'timestamp_last_value', text: [checkMot("timestamp_last_value"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("timestamp_last_value", checkMot("timestamp_last_value"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return convertiUtsToDateHour(cell)
                },
            },
            {

                dataField: 'delay', text: [checkMot("delay"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("delay", checkMot("delay"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {

                    const selectedUnit = uniteAffichageDuree(cell);
                    const valeur = cell / TIME_UNITS[selectedUnit];
                    return valeur.toFixed(2) + " " + checkMot(selectedUnit)
                },
            },
            {

                dataField: 'criticity_level', text: [checkMot("criticity_level"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("criticity_level", checkMot("criticity_level"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {

                dataField: 'project_name', text: [checkMot("project_name"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_name", checkMot("project_name"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            // {
            //
            //     dataField: 'sensor_id', text: [checkMot("sensor_id"),
            //         <Bouton type={type_btn.lupeSearch}
            //                 cliquer={() => this.afficherFiltre("sensor_id", checkMot("sensor_id"))}><VscSearch size={10}/>
            //         </Bouton>
            //     ], sort: true,
            // },
            {

                dataField: 'sensor_name', text: [checkMot("sensor_name"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_name", checkMot("sensor_name"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {

                dataField: 'sensor_type', text: [checkMot("sensor_type"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_type", checkMot("sensor_type"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
            {

                dataField: 'sensor_group', text: [checkMot("sensor_group"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("sensor_group", checkMot("sensor_group"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
            },
        ],
        selectRow: {// paramètre d'affichage des lignes du tableau
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            style: () => {//style après sélection
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            // onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne
            //     return this.props.projetSelectione(row);
            // }
        },
        iconeCreer: <VscNewFile size={15}/>,
    }

    componentDidMount() {
        //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererLaListeDesCapteursEnPanne(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, [parseInt(this.props.project_id_url)]);
        } else if (this.props.filtre_projects_list_id.length > 0) {// envoie la requête de recupération de alarme si la liste de projets n'est pas vide
            this.props.reqRecupererLaListeDesCapteursEnPanne(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des alarmes_xy en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererLaListeDesCapteursEnPanne(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, [parseInt(this.props.project_id_url)]);
        } else if (JSON.stringify(prevProps.sensors_list_maintenance) !== JSON.stringify(this.props.sensors_list_maintenance)) {// envoie la requête de recupération de alarme si la liste de projets change entre temps
            this.setState({...this.state, sensors_list_maintenance: this.props.sensors_list_maintenance});
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.reqRecupererLaListeDesCapteursEnPanne(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id);
        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }

    render() {
        let liste_tableaux_de_bord_favoris = []
        if (this.props.sensors_list_maintenance !== undefined) {
            for (let objetProject of this.props.sensors_list_maintenance) {
                //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
                if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
                    if (objetProject["project_id"] === this.props.project_id_url) {
                        liste_tableaux_de_bord_favoris.push({
                            ...objetProject,
                            detection_timestamp_sensor_id: objetProject["detection_timestamp"] + "_" + objetProject["sensor_id"]
                        })
                    }
                } else if (this.props.filtre_projects_list_id.length > 0) {// envoie la requête de recupération de alarme si la liste de projets n'est pas vide
                    if (this.props.filtre_projects_list_id.indexOf(objetProject["project_id"]) >= 0) {
                        liste_tableaux_de_bord_favoris.push({
                            ...objetProject,
                            detection_timestamp_sensor_id: objetProject["detection_timestamp"] + "_" + objetProject["sensor_id"]
                        })
                    }
                }
            }
        }

        return (
            <Auxiliaire>
                <Tableau
                    cleLigne={"detection_timestamp_sensor_id"}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    // donnees={this.props.sensors_list_maintenance}
                    donnees={liste_tableaux_de_bord_favoris}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                >
                    <span className={S.refreshForm}>
                        <AttenteRequete request_type={requestType.recuperer_objet_projets_taux_de_pannes}
                                        str={checkMot("maj_rapports_generes_en_cours")}>
                            {this.props.children}
                            {/*bouton de rafraîchissement du tableau*/}
                            <Bouton key={"refresh-projets-list-maintenance-criticity-" + this.props.criticity_id_list}
                                    overText={checkMot("refresh")}
                                    type={type_btn.outil}
                                    rond={true}
                                    desactive={this.props.start_interval === null || this.props.end_interval === null || this.props.criticity_id_list.length === 0}
                                    cliquer={() => {
                                        this.props.reqRecupererLaListeDesCapteursEnPanne(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id)
                                    }}>
                                <VscRefresh size={15}/>

                            </Bouton>
                        </AttenteRequete>
                    </span>
                </Tableau>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeRapportsGabarits: state.rapport.templates_reports_list,
        sensors_list_maintenance: state.maintenance.sensors_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        projects_list: state.authentifie.projects_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        reqRecupererLaListeDesCapteursEnPanne: (start_interval, end_interval, types_id_list, criticity_id_list, projects_id) => dispatch(actions.reqRecupererLaListeDesCapteursEnPanne(start_interval, end_interval, types_id_list, criticity_id_list, projects_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauProjetsTauxCriticite);
