import axios from "axios";
import * as actions from "./index";
import * as requestType from "../../ressources/glossaires/glossaire_requetes";
import * as actionTypes from "./actionTypes";
import {store} from "../../index";


export const reqAjouterCapteur = (formulaireCreerCapteur) => {
    return dispatch => {
        requetePost(dispatch,{request_type: requestType.creation_d_un_capteur,
            ...formulaireCreerCapteur,
        });
    };
};
export const reqModifierCapteur = (formulaireModifierCapteur) => {
    return dispatch => {
        requetePost(dispatch,{request_type: requestType.modification_d_un_capteur,
            ...formulaireModifierCapteur,
        });
    };
};
export const reqAjouterSensorType = (nomSensorType, sensor_type_display) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.creation_d_un_sensor_type,
            sensor_type_name: nomSensorType,
            sensor_type_display: sensor_type_display
        });
    };
};
export const reqModifierSensorType = (idSensorType, sensor_type_name, sensor_type_display) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.modification_d_un_sensor_type,
            sensor_type_id: idSensorType,
            sensor_type_name: sensor_type_name,
            sensor_type_display: sensor_type_display
        });
    };
};
export const reqSupprimerSensorType = (idSensorType) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.suppression_d_un_sensor_type,
            sensor_type_id: idSensorType
        });
    };
};
export const reqSupprimerCapteur = (idCapteur) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.supprimer_un_capteur,
            sensor_id: idCapteur
        });
    };
};
export const reqRecupererListeCapteurs = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_capteurs,
            projects_id: listeIdProjets
        });
    };
};
export const reqRecupererListeSensorType = () => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_sensor_type,
        });
    };
};
export const reqRecupererListeImportFilters = () => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_filtres_d_importation,
        });
    };
};
export const reqRecupererListeEmailgroups = () => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_groupes_d_emails,
        });
    };
};
export const reqAjouterImageTypeDeCapteur = (idTypeDeCapteur, base64_img) => {
    /**
     * interface ajouter_une_image_de_type_de_capteur permettant de d'ajouter une image à un type de capteur
     */
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.ajouter_une_image_de_type_de_capteur,
            sensor_type_id: idTypeDeCapteur,
            image: base64_img,

        });
    };
};
export const reqImportationCapteursEnLot = (sensors_list) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.importation_des_capteurs_en_lot,
            ...sensors_list,
        });
    };
};
export const reqRecupererListeCapteursReduites = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_capteurs_reduites,
            projects_id: listeIdProjets
        });
    };
};

const requetePost=(dispatch, requeteAPI)=>{
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */

    const state = store.getState();
    // console.log("filtre_projects_list_id", state.authentifie.filtre_projects_list_id);
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios.post("", requeteAPI, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
        console.log('envoi req ', requeteAPI.request_type, ':', JSON.parse(res.config.data));
        console.log('reception req ', requeteAPI.request_type, ':', res);
        let titreAlerteSucces = "Requête api succès";
        let corpsAlerteSucces = "Requête api succès";
        let titreAlerteEchec = "Requête api échec";
        let corpsAlerteEchec = "Requête api échec";
        let succesRequete = {
            alerte() {},
            actionReducteur() {},
            fonctionSupplementaire() {}
        };
        let succesRequeteAvecErreur = {
            alerte() {},
            actionReducteur() {},
            fonctionSupplementaire() {}
        };
        let echecRequete = {
            alerte() {},
            actionReducteur() {},
            fonctionSupplementaire() {}
        };

        switch ( requeteAPI.request_type ) {
            case requestType.creation_d_un_capteur:
                titreAlerteSucces = "ApiSuccesTitre_creation_d_un_capteur";
                corpsAlerteSucces = "ApiSuccesCorps_creation_d_un_capteur";
                titreAlerteEchec = "ApiEchecTitre_creation_d_un_capteur";
                corpsAlerteEchec = "ApiEchecCorps_creation_d_un_capteur";
                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.sensor_id]));
                succesRequete.fonctionSupplementaire = () => window.onbeforeunload=null;
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.sensor_id]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.modification_d_un_capteur:
                titreAlerteSucces = "ApiSuccesTitre_modification_d_un_capteur";
                corpsAlerteSucces = "ApiSuccesCorps_modification_d_un_capteur";
                titreAlerteEchec = "ApiEchecTitre_modification_d_un_capteur";
                corpsAlerteEchec = "ApiEchecCorps_modification_d_un_capteur";
                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.sensor_id]));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.sensor_id]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.creation_d_un_sensor_type:
                titreAlerteSucces = "ApiSuccesTitre_creation_d_un_sensor_type";
                corpsAlerteSucces = "ApiSuccesCorps_creation_d_un_sensor_type";
                titreAlerteEchec = "ApiEchecTitre_creation_d_un_sensor_type";
                corpsAlerteEchec = "ApiEchecCorps_creation_d_un_sensor_type";
                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.name]));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.name]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.modification_d_un_sensor_type:
                titreAlerteSucces = "ApiSuccesTitre_modification_d_un_sensor_type";
                corpsAlerteSucces = "ApiSuccesCorps_modification_d_un_sensor_type";
                titreAlerteEchec = "ApiEchecTitre_modification_d_un_sensor_type";
                corpsAlerteEchec = "ApiEchecCorps_modification_d_un_sensor_type";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.name]));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.name]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.suppression_d_un_sensor_type:
                titreAlerteSucces = "ApiSuccesTitre_suppression_d_un_sensor_type";
                corpsAlerteSucces = "ApiSuccesCorps_suppression_d_un_sensor_type";
                titreAlerteEchec = "ApiEchecTitre_suppression_d_un_sensor_type";
                corpsAlerteEchec = "ApiEchecCorps_suppression_d_un_sensor_type";

                // succesRequete.actionReducteur = () =>

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.supprimer_un_capteur:
                titreAlerteSucces = "ApiSuccesTitre_supprimer_un_capteur";
                corpsAlerteSucces = "ApiSuccesCorps_supprimer_un_capteur";
                titreAlerteEchec = "ApiEchecTitre_supprimer_un_capteur";
                corpsAlerteEchec = "ApiEchecCorps_supprimer_un_capteur";
                succesRequete.alerte =  () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.sensor_id]));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.recuperation_de_la_liste_des_capteurs:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_capteurs";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_capteurs";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_capteurs";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_capteurs";
                succesRequete.actionReducteur =  () => dispatch(enregistrerListeCapteurs(res.data.sensors_list));
                succesRequete.alerte =  () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.recuperation_de_la_liste_des_capteurs_reduites:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_capteurs_reduites";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_capteurs_reduites";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_capteurs_reduites";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_capteurs_reduites";
                succesRequete.actionReducteur =  () => dispatch(enregistrerListeCapteursReduites(res.data.sensors_short_list));
                succesRequete.alerte =  () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.recuperation_de_la_liste_des_sensor_type:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_sensor_type";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_sensor_type";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_sensor_type";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_sensor_type";

                succesRequete.actionReducteur(dispatch(enregistrerListeSensorType(res.data.sensor_types_list)));
                succesRequete.alerte=()=> dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.actionReducteur(dispatch(enregistrerListeSensorType(res.data.sensor_types_list)));
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.recuperation_de_la_liste_des_groupes_d_emails:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_groupes_d_emails";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_groupes_d_emails";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_groupes_d_emails";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_groupes_d_emails";

                succesRequete.actionReducteur(dispatch(enregistrerListeEmailgroups(res.data.emailgroups_list)));
                succesRequete.alerte=()=> dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.actionReducteur(dispatch(enregistrerListeEmailgroups(res.data.emailgroups_list)));
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.recuperation_de_la_liste_des_filtres_d_importation:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_filtres_d_importation";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_filtres_d_importation";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_filtres_d_importation";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_filtres_d_importation";

                succesRequete.actionReducteur(dispatch(enregistrerListeImportFilters(res.data.import_filters_list)));
                succesRequete.alerte=()=> dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.actionReducteur(dispatch(enregistrerListeImportFilters(res.data.import_filters_list)));
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.ajouter_une_image_de_type_de_capteur:
                titreAlerteSucces = "ApiSuccesTitre_ajouter_une_image_de_type_de_capteur";
                corpsAlerteSucces = "ApiSuccesCorps_ajouter_une_image_de_type_de_capteur";
                titreAlerteEchec = "ApiEchecTitre_ajouter_une_image_de_type_de_capteur";
                corpsAlerteEchec = "ApiEchecCorps_ajouter_une_image_de_type_de_capteur";

                succesRequete.alerte=()=> dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            default:
                break;
        }
        if(res.data.state){
            if(res.data.errors.length === 0){
                succesRequete.actionReducteur();
                succesRequete.alerte();
                succesRequete.fonctionSupplementaire();
            }else{
                succesRequeteAvecErreur.actionReducteur();
                succesRequeteAvecErreur.alerte();
                succesRequeteAvecErreur.fonctionSupplementaire();
            }
        }else {
            echecRequete.actionReducteur();
            echecRequete.alerte();
            echecRequete.fonctionSupplementaire();
        }
        // if(requeteAPI.request_type===requestType.recuperation_de_la_liste_des_capteurs || requeteAPI.request_type===requestType.recuperation_de_la_liste_des_sensor_type){
        //     //teste de rafraîchissement automatique après chaque interval de temps
        //     // setTimeout(()=>dispatch(actions.reqRecupererListeSensorType()),7000)
        //     // setTimeout(()=>dispatch(actions.reqRecupererListeCapteurs()),7000)
        // }else {
        //     //rafraîchie la liste des capteurs et capteurs_types après chaque requête
        //     dispatch(actions.reqRecupererListeSensorType());
        //     dispatch(actions.reqRecupererListeCapteurs(state.authentifie.filtre_projects_list_id));
        // }
        if(requeteAPI.request_type===requestType.creation_d_un_sensor_type || requeteAPI.request_type===requestType.suppression_d_un_sensor_type || requeteAPI.request_type===requestType.modification_d_un_sensor_type){
            dispatch(actions.reqRecupererListeSensorType());
        }else if(requeteAPI.request_type===requestType.creation_d_un_capteur || requeteAPI.request_type===requestType.supprimer_un_capteur || requeteAPI.request_type===requestType.modification_d_un_capteur){
            dispatch(actions.reqRecupererListeCapteurs(state.authentifie.filtre_projects_list_id));
        }
        dispatch(actions.finAttente(requeteAPI.request_type));
        })
        .catch(err => {
            console.log("Error API",requeteAPI.request_type,":",err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
            // dispatch(actions.finAttente(requeteAPI.request_type));
        })
        .finally(()=>dispatch(actions.finAttente(requeteAPI.request_type)));
}
const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) =>{
    if(res.data.errors !== undefined){
        for(let erreurKey= 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre,["ApiEchecErreursEnBoucle", {numAvertissement:erreurKey, idErreur:res.data.errors[erreurKey]}]));
        }
    }else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api","errors est undefine, pour une requête de connexion"));
    }
}

const enregistrerListeCapteurs = sensors_list => { return {type:actionTypes.ENREGISTRER_LISTE_CAPTEURS, capteursListe:sensors_list};};
const enregistrerListeSensorType = sensor_types_list => {return {type:actionTypes.ENREGISTRER_LISTE_SENSORS_TYPES, sensor_types_list:sensor_types_list}};
const enregistrerListeEmailgroups = emailgroups_list => {return {type:actionTypes.ENREGISTRER_LISTE_EMAIL_GROUPS, emailgroups_list:emailgroups_list}};
const enregistrerListeImportFilters = import_filters_list => {return {type:actionTypes.ENREGISTRER_LISTE_IMPORT_FILTERS, import_filters_list:import_filters_list}};
const enregistrerListeCapteursReduites = sensors_short_list => { return {type:actionTypes.ENREGISTRER_LISTE_CAPTEURS_REDUITES, sensors_short_list:sensors_short_list};};

