import React, {Component} from 'react';
import {connect} from "react-redux";

import FormulaireSelectionProjet from "../../../../Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet";
import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";

import {IsArrayString} from "../../../../../ressources/functionJS/IsArrayString";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import * as glossaire_pattern from "../../../../../ressources/glossaires/glossaire_pattern";
import * as actions from "../../../../../store/actions";

import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireRapportGenere.module.scss';
import FormulaireSelectionDateHeureUnix
    from "../../../../Graphe/ProfilGraphe/FormulaireGraphe/FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix";

class FormulaireRapportGenere extends Component {
    state = {
        //paramètre par défaut de l'alarme xy
       
        template_id: null,
        path_report: null,
        creation_time: null,
        Xcomponent_id: null,
        Ycomponent_id: null

    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigurerRapportGenere === undefined) {
            this.setState({
                //paramètre par défaut de l'alarme xy(voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerRapportGenere !== undefined) {
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerRapportGenere,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigurerRapportGenere !== this.props.preconfigurerRapportGenere && this.props.preconfigurerRapportGenere === undefined) {
            this.setState({
                //todo: paramètre par défaut
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerRapportGenere !== undefined && !jsDictionaryEquals(prevProps.preconfigurerRapportGenere, this.props.preconfigurerRapportGenere)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerRapportGenere,
            })
        }
    }

    gererEnvoi = () => {
        //mise en forme de l'objet pour l'envoi de la requête API
        let formulaireAlarmeXY = {
            //clé du formulaire de l'alarme xyde l'api
            alarm_xy_id: parseInt(this.state.alarm_xy_id),
            project_id: parseInt(this.state.project_id),
            template_id: parseInt(this.state.template_id),
            path_report: parseInt(this.state.path_report),
            creation_time: parseInt(this.state.creation_time),
            Xcomponent_id: parseInt(this.state.Xcomponent_id),
            Ycomponent_id: parseInt(this.state.Ycomponent_id)
        }

        //Envoi la requête de création d'alarme xy
        if (this.props !== undefined && this.props.preconfigurerRapportGenere === undefined) {
            //si l'alarme xyn'a pas de pré-remplissage des champs du formulaire
            this.props.reqCreerVue(formulaireAlarmeXY);
        } else {
            //sinon modification
            formulaireAlarmeXY["alarm_xy_id"] = parseInt(this.props.preconfigurerRapportGenere.alarm_xy_id);
            this.props.reqModifierVue(formulaireAlarmeXY)
        }
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererArrayEtatDeCibleName = (event) => this.setState({
        ...this.state,
        [event.target.name]: IsArrayString(event.target.value)
    });
    gererProjetId = (projetId) => {
        this.setState({...this.state, project_id: projetId});
    };
    affichageFormulaire = (style, label_state, label_name, pattern) => {
        /**
         * permet d'afficher un composant input de gestion de la modification
         */

        switch (pattern) {
            case glossaire_pattern.unix_timestamp:
                return (
                    <div className={[style, S.style].join(" ")}>
                        <span className={[S.stil2].join(" ")}>{label_name}</span>
                        {/**DEBUT last_unixtimestamp_run**/}<FormulaireSelectionDateHeureUnix
                        value={this.props.preconfigurerRapportGenere !== undefined ? this.state[label_state] : null}
                        gereUnixDate={(event) => this.gererEtatDeCibleName(event)}
                        disabled={this.props.affichageProfil === true}/>
                        {/**FIN last_unixtimestamp_run**/}
                    </div>
                )
            default:
                return (
                    <div className={[style, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerRapportGenere !== undefined ? this.state[label_state] : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleName(event)}/>
                    </div>
                )
        }
    }

    render() {
        return (
            <Auxiliaire>
                <div className={[s.row].join(" ")}>
                    <div className={S.ConteneurInfo}>
                        <div className={[s.container, s.text_left].join(" ")}>
                            <form onSubmit={event => event.preventDefault()}>
                                <div className={s.form_group}>
                                    <div className={[s.form_row, s.align_items_center].join(" ")}>
                                        {/**DEBUT project_id**/}
                                        <FormulaireSelectionProjet affichageProfil={this.props.affichageProfil}
                                                                   gererProjetId={this.gererProjetId}
                                                                   preconfigurerProjetId={this.state.project_id}/>{/**FIN project_id**/}
                                        {/**DEBUT path_report**/}{this.affichageFormulaire(s.col_12, "path_report", checkMot("path_report"), glossaire_pattern.integer)}{/**FIN path_report**/}
                                        {/**DEBUT creation_time**/}{this.affichageFormulaire(s.col_12, "creation_time", checkMot("creation_time"), glossaire_pattern.unix_timestamp)}{/**FIN creation_time**/}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reqCreerVue: (formulaireAlarmeXY) => dispatch(actions.reqCreerAlarmesXY(formulaireAlarmeXY)),
        reqModifierVue: (formulaireAlarmeXY) => dispatch(actions.reqModifierAlarmesXY(formulaireAlarmeXY)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireRapportGenere);
