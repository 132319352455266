import React, {Component} from 'react';
import {connect} from "react-redux";

import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";

import S from './ProjetsTauxCriticite.module.scss';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import Bouton from "../../UI/Bouton/Bouton";
import {BiArrowFromLeft, BiArrowFromRight} from "react-icons/bi";
import ConteneurInformation from "../../ConteneurInformation/ConteneurInformation";
import * as indexConteneurInfo from "../../../ressources/glossaires/glossaire_conteneur_info";
import * as actions from "../../../store/actions";
import TableauProjetsTauxCriticite from "./TableauProjetsTauxCriticite/TableauProjetsTauxCriticite";
import ProfilProjetsTauxCriticite from "./ProfilProjetsTauxCriticite/ProfilProjetsTauxCriticite";

class ProjetsTauxCriticite extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,

    }

    //Fonction pour enregistrer les informations de la ligne sélectionné depuis le tableau de Alarmes
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });

    //fonction pour refermer le contneur information lors d'une annulation de création
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Alarmes non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }

    //fonction pour ouverture du conteneur information avec le formulaire de création
    creerVue = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        //initialisation des varable de style
        let styleConteneurCentralGauche = [S.ConteneurCentralGauche]
        let styleConteneurCentralDroite = [S.ConteneurCentralDroite]
        let commutateurClasses = [S.CommutateurInfo];

        if (this.state.montrerInfo) {
            //style pour un conteneur info ouvert
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
            // styleConteneurCentralDroite.push(S.ConteneurCentralGaucheOuvert);
        } else {
            //style pour un conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        if (this.state.montrerInfo && this.state.montrerInfoFull) {
            //conteneur info ouvert & plein écran
            //on met la largeur à 100% du conteneur info
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
            //on réduit à zéro la largeur du conteneur central
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
        } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
            //conteneur info ouvert & !plein écran
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
        } else {
            //conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        return (
            <Auxiliaire>
                    <div className={styleConteneurCentralGauche.join(" ")}>
                        <TableauProjetsTauxCriticite
                            projetSelectione={this.ligneSelectione}
                            creer={this.creerVue}
                            start_interval={this.props.start_interval}
                            end_interval={this.props.end_interval}
                            criticity_id_list={this.props.criticity_id_list}
                            types_id_list={this.props.types_id_list}
                        >
                            {this.props.toolsbar}
                        </TableauProjetsTauxCriticite>
                    </div>
                    <div className={styleConteneurCentralDroite.join(" ")}>
                        {/* bouton plein ferme conteneur  info */}
                        <Bouton ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
                                cliquer={this.commutateurInfoCreer} rond={true} type={type_btn.danger}>
                            <BiArrowFromLeft size={15}/>
                        </Bouton>
                        {/* bouton plein écran */}
                        <Bouton ajoutClass={S.expand} cliquer={this.commutateurInfoFull} rond={true}
                                type={type_btn.danger}>
                            <BiArrowFromRight size={15}/>
                        </Bouton>
                        <ConteneurInformation
                            // information du type de conteneur information à afficher
                            selectionInfo={this.state.indexConteneurInfo}
                            // // formulaire de création
                            // ajouterForm={<FormulaireRapportGenere ajouterAlerte={this.props.ajouterAlerte}
                            //                             annuler={this.commutateurAnnuler}/>}
                            // formulaire de profil vue
                            profil={<ProfilProjetsTauxCriticite infoRapportGenere={this.state.parametresSelectione}
                                                         reqPublierRapportGenere={this.props.reqPublierRapportGenere}
                                                         reqChargerDocumentRapport={this.props.reqChargerDocumentRapport}
                                                         reqModifier={this.props.reqModifier}
                                                         reqSupprimer={this.props.reqSupprimer}/>}
                        />
                    </div>
            </Auxiliaire>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        // reqRecupererLeTauxDePannesParJour: (start_interval, end_interval, types_id_list, criticity_id_list, projects_id) => dispatch(actions.reqRecupererLeTauxDePannesParJour(start_interval, end_interval, types_id_list, criticity_id_list, projects_id)),
        // reqRecupererLaListeDesCapteursEnPanne: (start_interval, end_interval, types_id_list, criticity_id_list, projects_id) => dispatch(actions.reqRecupererLaListeDesCapteursEnPanne(start_interval, end_interval, types_id_list, criticity_id_list, projects_id)),
    };
};

export default connect(null, mapDispatchToProps)(ProjetsTauxCriticite);
