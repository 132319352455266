import React, {Component} from 'react';
import {connect} from "react-redux";

import {jsDictionaryEquals} from "../../../../../../../../ressources/functionJS/jsDictionaryEquals";
import * as S from "./SousSectionRapport.module.scss";
import * as actions from "../../../../../../../../store/actions";
import Collapse from "../../../../../../../../hoc/Collapse/Collapse";
import FormulaireListesObjetsRapport from "../../FormulaireListeObjetsRapport";

class SousSectionRapport extends Component {
    state = {
        ...this.props.preconfigureObjet
    }

    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value});

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigureObjet === undefined) {
            this.setState({
                //paramètre par défaut du rapport gabarit(voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigureObjet !== undefined) {
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigureObjet,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigureObjet !== this.props.preconfigureObjet && this.props.preconfigureObjet === undefined) {
            this.setState({
                //todo: paramètre par défaut
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigureObjet !== undefined && !jsDictionaryEquals(prevProps.preconfigureObjet, this.props.preconfigureObjet)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigureObjet,
            })
        }
        if (!jsDictionaryEquals(prevState, this.state)) {
            this.props.gererJson(this.state)
        }
    }

    gererObject_list = (objects_list) => this.setState({
        ...this.state, objects_list: [...objects_list]
    });

    render() {
        return (
            <Collapse showAdvanceParameters={true} ExpandText={
                <div className={S.contenuEnTete}>
                    {this.props.contenuEnTete}
                    <span className={S.titre}>{this.state.sub_section_name}</span>
                </div>
            }>
                {this.props.children}
                <input
                    className={S.Input}
                    type="sub_section_name"
                    name="sub_section_name"
                    value={this.state.sub_section_name}
                    disabled={this.props.affichageProfil === true}
                    onChange={e => this.gererEtatDeCibleName(e)}
                />
                <FormulaireListesObjetsRapport project_id={this.props.project_id}
                                               gererObjectsList={this.gererObject_list}
                                               objects_list={this.state.objects_list}
                                               affichageProfil={this.props.affichageProfil}
                                               typeObjetParent={this.state.report_object_type}/>
            </Collapse>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reqCreerRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
        reqModifierRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
    };
};

export default connect(null, mapDispatchToProps)(SousSectionRapport);
