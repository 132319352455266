import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    ListePrivilegesParCategorie: {} //todo mettre à {}
};

const enregistrerListePrivilegesParCategorie = (state, action) => {
    console.log(action.list_cat_functions);
    return updateObject( state, {ListePrivilegesParCategorie:action.list_cat_functions} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ENREGISTRER_LISTE_PRIVILEGES_PAR_CATEGORIE:
            return enregistrerListePrivilegesParCategorie(state, action);
        default:
            return state;
    }
};

export default reducer;
