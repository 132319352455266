import React from 'react';
function AffichageImage(props){
    let cheminURL;
    switch (document.domain) {
        case "localhost:3000":
            cheminURL = props.src;
            break;
        case "argos-dev-test.itmsol.fr":
            cheminURL = "../../."+props.src;
            break;
        case "argos-dev.itmsol.fr":
            cheminURL = "../../."+props.src;
            break;
        case "argos-v19.itmsol.fr":
            cheminURL = "../../."+props.src;
            break;
        default:
            cheminURL = props.src;
    }
    return(
        <div className={props.ajoutClass}>
            <img className={props.ajoutClass} src={cheminURL} alt={props.alt} />
        </div>
    )
}

export default AffichageImage;
