import React, {Component} from 'react';
import Bouton from "../../../UI/Bouton/Bouton";
import {modalCorp, text_style, btn_enre} from "../ProfilProjet.module.scss";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as s from "../../../../ressources/StyleGlobal/StyleGlobal";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import ImporterImage from "../../../UI/ImporterImage/ImporterImage";
import {checkMot} from "../../../../ressources/functionJS/checkMot";

class AjouterImageProjet extends Component {
    state = {
        imageImporter: null
    }
    gererImageImporter = (imageImporter) => {
        this.setState({imageImporter: imageImporter})
    }

    render() {
        let boutonEnvoi;
        if (this.state.imageImporter !== null) {
            boutonEnvoi = <Bouton ajoutClass={btn_enre}
                                  cliquer={() => this.props.ajouterImageProjet(this.props.project_id, this.state.imageImporter.base64)}>
                {checkMot("enregistrer_l_image")}
            </Bouton>
        } else {
            boutonEnvoi = <Bouton ajoutClass={btn_enre} desactive={true}>
                {checkMot("enregistrer_l_image")}
            </Bouton>
        }
        return (
            <Auxiliaire>
                <div className={[modalCorp, text_style, s.row].join(" ")}>
                    <div className={[modalCorp, text_style, s.col_12].join(" ")}>
                        <ImporterImage extensionAccepter={".jpg"} gererImageImporter={this.gererImageImporter}/>
                    </div>
                    <div className={[s.text_left, s.col_sm_6, text_style].join(" ")}>
                        <AttenteRequete request_type={requestType.ajouter_une_image_de_projet}>
                            {boutonEnvoi}
                        </AttenteRequete>
                    </div>
                </div>
            </Auxiliaire>
        );
    }

}

export default AjouterImageProjet;
