import React, {Component} from 'react';
import {connect} from "react-redux";

import ProfilAlarmeClient from "./ProfilVue/ProfilAlarmeClient";
import ConteneurInformation from "../../../ConteneurInformation/ConteneurInformation";
import TableauAlarmesClient from './TableauAlarmesClient/TableauAlarmesClient';
import Bouton from "../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";

import * as actions from "../../../../store/actions";
import * as indexConteneurInfo from '../../../../ressources/glossaires/glossaire_conteneur_info';
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";

import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import S from './Alarmes.module.scss';

class Alarmes extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,

    }
    //Fonction pour enregistrer les informations de la ligne sélectionné depuis le tableau de Alarmes
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    //Todo: commenter cette partie
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });

    //fonction pour refermer le contneur information lors d'une annulation de création
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Alarmes non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }

    //fonction pour ouverture du conteneur information avec le formulaire de création
    creerVue = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        //initialisation des varable de style
        let styleConteneurCentralGauche = [S.ConteneurCentralGauche]
        let styleConteneurCentralDroite = [S.ConteneurCentralDroite]
        let commutateurClasses = [S.CommutateurInfo];

        if (this.state.montrerInfo) {
            //style pour un conteneur info ouvert
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
            // styleConteneurCentralDroite.push(S.ConteneurCentralGaucheOuvert);
        } else {
            //style pour un conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        if (this.state.montrerInfo && this.state.montrerInfoFull) {
            //conteneur info ouvert & plein écran
            //on met la largeur à 100% du conteneur info
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
            //on réduit à zéro la largeur du conteneur central
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
        } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
            //conteneur info ouvert & !plein écran
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
        } else {
            //conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        return (
            <Auxiliaire>
                <div className={[S.ConteneurVue].join(" ")}>
                    <div className={styleConteneurCentralGauche.join(" ")}>
                        {/*Tableau de vue*/}
                        <TableauAlarmesClient siLaListeAUneValeur={this.props.siLaListeAUneValeur} vueSelectione={this.ligneSelectione} creer={this.creerVue}/>
                    </div>
                    {/*Todo: commenter cette partie*/}
                   {/* bouton plein ferme conteneur  info */}

                    <div className={styleConteneurCentralDroite.join(" ")}>
                    {/* bouton plein ferme conteneur  info */}
                      <Bouton ajoutClass={[commutateurClasses, S.expandTO].join(' ')} cliquer={this.commutateurInfoCreer} rond={true} type={type_btn.danger} >
                       <BiArrowFromLeft size={15}/>
                    </Bouton>
                        {/* bouton plein écran */}
                        <Bouton ajoutClass={S.expand} cliquer={this.commutateurInfoFull} rond={true} type={type_btn.danger}>
                        <BiArrowFromRight size={15}/>
                        </Bouton>
                        <ConteneurInformation
                            // information du type de conteneur information à afficher
                            selectionInfo={this.state.indexConteneurInfo}
                            // formulaire de profil vue
                            profil={<ProfilAlarmeClient infoVue={this.state.parametresSelectione}
                                                        reqModifier={this.props.reqModifier}
                                                        reqSupprimer={this.props.reqSupprimer}/>}
                        />
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //début dimension de la popup
        left: state.popup.left,
        width: state.popup.width,
        top: state.popup.top,
        height: state.popup.height,
        //fin de dimension de la popup
    };
}
const mapDispatchToProps = dispatch => {
    return {
        //fonction de création d'alerte/notification
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        //requête de suppression de Alarmes
        reqSupprimer: (idVue) => dispatch(actions.reqSupprimerVue(idVue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alarmes);
