import React from 'react';
import S from './EnTete.module.scss';
import * as s from "../../ressources/StyleGlobal/StyleGlobal";
import EnTeteProfil from './EnTeteProfil/EnTeteProfil';
import EnTeteParametre from './EnTeteParametre/EnTeteParametre';
import Aide from "./Aide/Aide";

const enTete = () => {

  
    
    return (
      
        <div className={[s.row, s.justify_content_center].join(" ")}>
            <div className={[S.BoutonEnTete].join(" ")}>
                <EnTeteProfil/>
            </div>
            <div className={S.BoutonEnTete}>
                <EnTeteParametre/>
            </div>
            <div  className={S.BoutonEnTete}>
                <Aide/>
             
            </div>
        </div>
    );
}
export default enTete;