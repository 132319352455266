import React, {Component} from 'react';
import {connect} from 'react-redux';
import {textFilter} from "react-bootstrap-table2-filter";

import Tableau from "../../UI/tableau/Tableau";
import Bouton from "../../UI/Bouton/Bouton";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";

import * as actions from "../../../store/actions";
import {jsDictionaryEquals} from "../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as privilege from "../../../ressources/glossaires/glossaire_privileges";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";

import {VscNewFile, VscRefresh} from "react-icons/vsc";
import {VscSearch} from "react-icons/vsc";

class TableauVue extends Component {
    state = {
        columns: [// liste des clés pour l'entête du tableau de vue
            {
                dataField: 'view_id', text: [checkMot("id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("view_id", checkMot("id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'project_id', text: [checkMot("id_projet"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_id", checkMot("id_projet"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'view_name', text: [checkMot("nom"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("view_name", checkMot("nom"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'view_title', text: [checkMot("descriptif"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("view_title", checkMot("descriptif"))}><VscSearch size={10}/>
                    </Bouton>

                ], sort: true
            },
            // {dataField: 'update_time', text: checkMot("update_time"), sort: true},
            // {dataField: 'view_projection', text: checkMot("view_projection"), sort: true},
            // {dataField: 'view_bound_coordinates', text: checkMot("view_bound_coordinates"), sort: true},
            // {dataField: 'view_zoom_levels', text: checkMot("view_zoom_levels"), sort: true},
            // {dataField: 'view_copyright', text: checkMot("view_copyright"), sort: true},
            // {dataField: 'view_flag_fix_pos', text: checkMot("view_flag_fix_pos"), sort: true},
        ],
        selectRow: {// paramètre d'affichage des lignes du tableau
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            style: () => {//style après sélection
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne
                return this.props.projetSelectione(row);
            }
        },
        iconeCreer: <VscNewFile size={15}/>,
    }

    componentDidMount() {
        //récupère la liste des vues (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            this.props.recupererListeVues([parseInt(this.props.project_id_url)]);
        } else if (this.props.filtre_projects_list_id.length > 0) {// envoie la requête de recupération de vue si la liste de projets n'est pas vide
            this.props.recupererListeVues(this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des vues en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.recupererListeVues([parseInt(this.props.project_id_url)]);
        } else if (JSON.stringify(prevProps.listeVues) !== JSON.stringify(this.props.listeVues)) {// envoie la requête de recupération de vue si la liste de projets change entre temps
            this.setState({...this.state, listeVues: this.props.listeVues});
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.recupererListeVues(this.props.filtre_projects_list_id);
        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }

    render() {
        let listeCleACache = [];
        if (this.props.listeVues !== undefined) {
            if (this.props.interfaceAdmin) {
                for (let indLigne = 0; indLigne < this.props.listeVues.length; indLigne++) {
                    if (this.props.filtre_projects_list_id.indexOf(parseInt(this.props.listeVues[indLigne].project_id)) < 0) {
                        listeCleACache.push(this.props.listeVues[indLigne].view_id)
                    }
                }
            } else {
                for (let indLigne = 0; indLigne < this.props.listeVues.length; indLigne++) {
                    if (parseInt(this.props.listeVues[indLigne].project_id) !== parseInt(this.props.project_id_url)) {
                        listeCleACache.push(this.props.listeVues[indLigne].view_id)
                    }
                }
            }
        }
        return (
            <Auxiliaire>
                <Tableau
                    cleLigne={"view_id"}
                    privilegeCreer={privilege.creer_des_vues}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    donnees={this.props.listeVues}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                    lignesCache={listeCleACache}
                >
                    <AttenteRequete request_type={requestType.recuperation_de_la_liste_des_vues} str={checkMot("maj_vues_en_cour")}>
                        {/*bouton de rafraîchissement du tableau*/}
                        <Bouton hoverText={checkMot("refresh")} type={type_btn.outil} rond={true} cliquer={() => {
                            this.props.recupererListeVues(this.props.filtre_projects_list_id)
                        }}>
                            <VscRefresh size={15}/>

                        </Bouton>
                    </AttenteRequete>
                </Tableau>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        interfaceAdmin: state.authentifie.interfaceAdmin,
        listeVues: state.vue.views_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeVues: (listeIdProjets) => dispatch(actions.reqRecupererListeVues(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauVue);
