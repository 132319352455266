import React, {Component} from 'react';
import {connect} from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {textFilter, selectFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

import FormulaireObjet from "./FormulaireObjet/FormulaireObjet";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import Bouton from "../../../UI/Bouton/Bouton";
import Popup from "../../../UI/PopupVue/Popup";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";

import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import {confirmeOnClick} from "../../../../ressources/functionJS/confirmeOnClick";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../../../store/actions";
import { AiOutlineCloseCircle } from "react-icons/ai";
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {AiOutlineClose} from "react-icons/ai";
import {MdRefresh} from "react-icons/md";
import {RiFileAddFill} from "react-icons/ri";
import {RiEditBoxFill} from "react-icons/ri";
import S from "../../../UI/tableau/Tableau.module.scss";
import * as z from './FormulaireObjetsAssocieVue.module.scss';
import {VscSearch} from "react-icons/vsc";
class FormulaireObjetsAssocieVue extends Component {
    afficherFiltre = (dataField, placeholder) => {
        
        const newCol = this.state.columns.map((col) => {
           
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder,
                             
                        }
                    ), 
                    
                }
                
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    
    }
    afficherFiltreClose = (dataField) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: null, 
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    
    }
    priceFormatter(cell) {
        if (cell === 0) {
          return (
            <span>
              <strong> Text</strong>
            </span>
          );
        }
        if (cell === 1) {
            return (
              <span>
                <strong> Capteur </strong>
              </span>
            );
          }
          if (cell === 2) {
            return (
              <span>
                <strong> Graphe </strong>
              </span>
            );
          }
          if (cell === 3) {
            return (
              <span>
                <strong> Vue </strong>
              </span>
            );
          }
      }
    selectOptions = {
        0: checkMot("text"),
        1: checkMot("capteur"),
        2: checkMot("graphe"),
        3:  checkMot("vue")
      };
    state = {
        columns: [
            {dataField: 'bonton_supprimer', text: checkMot("supprimer"), editable: false},
            // {dataField: 'viewobj_id', text: checkMot("viewobj_id"), sort: true, filter: textFilter(), editable: false},
            {dataField: 'name', text: [checkMot("name"),
            <Bouton type={type_btn.lupeSearch}
            cliquer={() => this.afficherFiltre("name", checkMot("name"))}><VscSearch size={15}/>
            </Bouton>,  
       
            <Bouton type={type_btn.lupeSearchClose} 
                cliquer={() => this.afficherFiltreClose("name", checkMot("name"))}><AiOutlineCloseCircle size={15}/>
            </Bouton>],
             headerStyle: {
                paddingRight: '100px',
                paddingLeft: '60px',
                textAlign: 'center'
              },
            
             editable: false},

            {dataField: 'viewobj_type', text: checkMot("viewobj_type"),
            formatter: this.priceFormatter,
            filter: selectFilter({
                options: this.selectOptions,
                placeholder: checkMot("viewobj_type"),
                style: {
                    marginLeft: '10px',
                    marginRight:'10px',
                    paddingRight:'15px'
                  },
            }),
            headerStyle: {
                paddingRight: '100px',
                paddingLeft: '70px',
                textAlign: 'center'
              },
            editable: false},
            {dataField: 'sensor_id', text: checkMot("sensor_id"), sort: true, editable: false},
            {
                dataField: 'positions_in_sensor_list',
                text: checkMot("composantes_capteur"),
                formatter: cell=> {
                    console.log("cell",cell);
                    return cell !== undefined ? cell.join(", ") : null
                },
                sort: true,
                editable: false

                
            },
            // {dataField: 'graph_id', text: checkMot("graph_id"), sort: true, editable: false},
            // {dataField: 'view_in_view_id', text: checkMot("view_in_view_id"), sort: true, editable: false},
            {dataField: 'get_values_onload', text: checkMot("get_values_onload"), sort: true, editable: false},
            {dataField: 'get_metadata_onload', text: checkMot("get_metadata_onload"), sort: true, editable: false},
            // {dataField: 'flag_coord_reel', text: checkMot("flag_coord_reel"), sort: true, editable: false},
            {dataField: 'projection_id', text: checkMot("projection_id"), sort: true, editable: false},
            {dataField: 'coord_x', text: checkMot("coord_x"), sort: true, editable: false},
            {dataField: 'coord_y', text: checkMot("coord_y"), sort: true, editable: false},
            // {dataField: 'coord_z', text: checkMot("coord_z"), sort: true, editable: false},
            {dataField: 'viewobj_path', text: checkMot("viewobj_path"), sort: true, editable: false},
        ],
        filtre: filterFactory(),
       
        selectRow: {
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            
            style: () => {
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row) => {
                return this.setState({objetSelectionne: row});
            }
        },
        objetSelectionne: {},
        views_objects_lists_list: [],
        position_list: [],
    }

    componentDidMount() {
        if (this.props.view_id !== undefined) {
            //envoie la requête de récupération de données d'objets de la vue si l'id de la vue n'est pas indéfini
            this.props.reqRecupererListeObjetsAssocieVue(this.props.view_id);
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.view_id !== undefined && prevProps.view_id !== this.props.view_id) {
            this.props.reqRecupererListeObjetsAssocieVue(this.props.view_id);
        }
        if (this.props.view_id !== undefined && prevProps.view_id !== this.props.view_id || JSON.stringify(this.props.views_objects_lists_list) !== JSON.stringify(prevProps.views_objects_lists_list)) {
            if (this.props.views_objects_lists_list[this.props.view_id] !== undefined) {

                const listeCouches = this.props.views_objects_lists_list[this.props.view_id];
                //initialisation en local de la liste de couches et la liste de la position des couches (pour la modification)
                let newListeCouches = new Array(listeCouches.length);
                let position_list = [];
                for (let indCouche = 0; indCouche < listeCouches.length; indCouche++) {
                    //duplique les données
                    newListeCouches[indCouche] = {
                        ...listeCouches[indCouche],
                        bonton_supprimer: <Bouton type={type_btn.danger}
                                                  cliquer={() => confirmeOnClick(checkMot("voulez_vous_vraiment_supprimer_cette_objet"), () => this.props.reqSupprimerObjetVue(listeCouches[indCouche].viewobj_id))}>
                            <AiOutlineClose/>
                        </Bouton>
                    };
                    //liste à envoyer pour la modification des position des couches
                    position_list.push({
                        layer_id: parseInt(this.props.views_objects_lists_list[this.props.view_id][indCouche].layer_id),
                        layer_position: parseInt(this.props.views_objects_lists_list[this.props.view_id][indCouche].position)
                    })
                }
                this.setState({...this.state, views_objects_lists_list: newListeCouches, position_list: position_list})
            }
        }
    }

    render() {
        let afficherListeCouche;
        if (this.props.views_objects_lists_list[this.props.view_id] !== undefined) {
            afficherListeCouche = <BootstrapTable
                striped
                hover
                headerWrapperClasses={S.theader}

                keyField={"viewobj_id"}
                data={this.state.views_objects_lists_list}
                columns={this.state.columns}

                selectRow={this.state.selectRow}
                classes={S.tableuStil}
                rowStyle={{margin: "0px", textAlign: "center"}}
                filter={this.state.filtre}
                pagination={paginationFactory()}
                bordered={true}
                
                // cellEdit={cellEdit}
                // cellEdit={ cellEditFactory({ mode: 'dbclick' }) }
            />
        } else {
            afficherListeCouche =
                <Bouton cliquer={() => this.props.reqRecupererListeObjetsAssocieVue(this.props.view_id)}>
                    {checkMot("refresh_liste_objet")}
                </Bouton>
        }
        return (
            <Auxiliaire>
                <div className={s.row}>
                   
                        {/*Bouton ouvrir la popup de création d'objet de la vue*/}
                      <div className={[s.col_1, z.marginB].join(" ")}>
                        <AttenteRequete request_type={requestType.ajouter_un_objet_associe_a_la_vue}>
                            <Popup typeBoutonAffichage={type_btn.outil} icone={<RiFileAddFill/>}
                                   hoverText={checkMot("ajouter_un_objet")}>
                                {/*Ajouter une couche de vue avec formulaire de sélection ... différents type de couche*/}
                                <FormulaireObjet view_id={this.props.view_id}/>
                            </Popup>
                        </AttenteRequete>
                        </div>
                        <div className={[s.col_1, z.marginB].join(" ")}>
                        {/*Bouton ouvrir la popup pour modifier l'objet sélectionné dans le tableau*/}
                        <AttenteRequete request_type={requestType.modifier_un_objet_associe_a_la_vue}>
                            <Popup typeBoutonAffichage={type_btn.outil}
                                   desactive={JSON.stringify(this.state.objetSelectionne) === "{}"}
                                   icone={<RiEditBoxFill/>} hoverText={checkMot("modifier_l_objet_selectionne")}>
                                {/*Ajouter une couche de vue avec formulaire de sélection ... différents type de couche*/}
                                <FormulaireObjet view_id={this.props.view_id}
                                                 preconfigurerObjet={this.state.objetSelectionne}/>
                            </Popup>
                        </AttenteRequete>
                        </div>
                        <div className={[s.col_1, z.marginB].join(" ")}>
                        <AttenteRequete request_type={requestType.recuperer_la_liste_des_objets_associe_a_la_vue}>
                            <Bouton hoverText={checkMot("refresh_liste_objet")} type={type_btn.outil}
                                    cliquer={() => this.props.reqRecupererListeObjetsAssocieVue(this.props.view_id)}>
                                <MdRefresh/>
                            </Bouton>
                        </AttenteRequete>
                        {/*Affiche la liste des couches sous forme de liste pouvant être supprimé individuellement*/}
                        </div>
                    
                </div>
                {afficherListeCouche}
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        //import liste couches d'une vue
        views_objects_lists_list: state.vue.views_objects_lists_list
    };
}
const mapDispatchToProps = dispatch => {
    return {
        reqRecupererListeObjetsAssocieVue: (idVue) => dispatch(actions.reqRecupererListeObjetsAssocieVue(idVue)),
        reqSupprimerObjetVue: (idObjet) => dispatch(actions.reqSupprimerObjetVue(idObjet)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaireObjetsAssocieVue);
