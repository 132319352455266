import {ratioString2PuissanceNiveauZoom} from "./ratioString2PuissanceNiveauZoom";

export const ratioStyleCSS2PuissanceNiveauZoom = (styleCSS, ratio) => {
    if(styleCSS!==undefined){
        let newStyleCSS = {}
        if(styleCSS.width!==undefined){newStyleCSS.width=ratioString2PuissanceNiveauZoom(styleCSS.width,ratio)}
        if(styleCSS.height!==undefined){newStyleCSS.height=ratioString2PuissanceNiveauZoom(styleCSS.height,ratio)}
        if(styleCSS.fontSize!==undefined){newStyleCSS.fontSize=ratioString2PuissanceNiveauZoom(styleCSS.fontSize,ratio)}
        if(styleCSS.left!==undefined){newStyleCSS.left=ratioString2PuissanceNiveauZoom(styleCSS.left,ratio)}
        if(styleCSS.right!==undefined){newStyleCSS.right=ratioString2PuissanceNiveauZoom(styleCSS.right,ratio)}
        if(styleCSS.top!==undefined){newStyleCSS.top=ratioString2PuissanceNiveauZoom(styleCSS.top,ratio)}
        if(styleCSS.bottom!==undefined){newStyleCSS.bottom=ratioString2PuissanceNiveauZoom(styleCSS.bottom,ratio)}
        if(styleCSS.marginLeft!==undefined){newStyleCSS.marginLeft=ratioString2PuissanceNiveauZoom(styleCSS.marginLeft,ratio)}
        if(styleCSS.marginRight!==undefined){newStyleCSS.marginRight=ratioString2PuissanceNiveauZoom(styleCSS.marginRight,ratio)}
        if(styleCSS.marginTop!==undefined){newStyleCSS.marginTop=ratioString2PuissanceNiveauZoom(styleCSS.marginTop,ratio)}
        if(styleCSS.marginBottom!==undefined){newStyleCSS.marginBottom=ratioString2PuissanceNiveauZoom(styleCSS.marginBottom,ratio)}
        if(styleCSS.paddingLeft!==undefined){newStyleCSS.paddingLeft=ratioString2PuissanceNiveauZoom(styleCSS.paddingLeft,ratio)}
        if(styleCSS.paddingRight!==undefined){newStyleCSS.paddingRight=ratioString2PuissanceNiveauZoom(styleCSS.paddingRight,ratio)}
        if(styleCSS.paddingTop!==undefined){newStyleCSS.paddingTop=ratioString2PuissanceNiveauZoom(styleCSS.paddingTop,ratio)}
        if(styleCSS.paddingBottom!==undefined){newStyleCSS.paddingBottom=ratioString2PuissanceNiveauZoom(styleCSS.paddingBottom,ratio)}
        if(styleCSS.borderRadius!==undefined){newStyleCSS.borderRadius=ratioString2PuissanceNiveauZoom(styleCSS.borderRadius,ratio)}
        return {...styleCSS, ...newStyleCSS}
    }else{
        return {}
    }
}