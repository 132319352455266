import React from "react";

import * as D from "./PopupObjet.module.scss";
import * as s from "../../../../../ressources/StyleGlobal/StyleGlobal";
import Modal from "react-modal";
import {connect} from "react-redux";
import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";
import Bouton from "../../../../UI/Bouton/Bouton";
import S from '../../../../UI/Popup/Popup.module.scss';
import * as type_btn from "../../../../../ressources/glossaires/glossaire_type_de_bouton";

const PopupObjet = (props) => {

    const [showModal, setShowModal] = React.useState(false)

    return (
        <Auxiliaire>
            <span onClick={() => setShowModal(!showModal)}>
                {props.iconeObjet}
            </span>
            <Modal isOpen={showModal}
                   onRequestClose={() => setShowModal(false)}
                   style={{
                    overlay: {
                        position: "absolute",
                        zIndex: "1000",
                        overflowX: "hidden",
                        overflowY: "hidden",
                        border: "black 1px solid",
                     backgroundColor: "transparent",


                        transition: "width 2s, height 4s",
                      
                    },
                    content: {
                     overflowX: "auto",
                     inset: "7% 0.1% 1% 15.4%",
                     padding:"0px",
                     boxShadow:"-5px 0px 5px rgba(0, 0, 0, 0.226)",
                    }
                   }}
            ><div className={[s.text_right]}>
                <div className={s.card_header}>
                <Bouton ajoutClass={D.left} type={type_btn.fermer} rond={true} cliquer={() => setShowModal(false)}/>
                </div>
            </div>
                {props.children}
            </Modal>
        </Auxiliaire>
    );
};


const mapStateToProps = (state) => {
    return {
        left: state.popup.left,
        width: state.popup.width,
        top: state.popup.top,
        height: state.popup.height,
    };
}

export default connect(mapStateToProps)(PopupObjet);