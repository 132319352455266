import React, {Component, Fragment} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Type} from 'react-bootstrap-table2-editor';
import {unixTimestampToDate} from "../../../../ressources/functionJS/unixTimestampToDate";
import {FloatChampInput} from "../../../../ressources/functionJS/FloatChampInput";
import * as S from "./TableauComposante.module.scss"
import {checkMot} from "../../../../ressources/functionJS/checkMot";

class TableauComposante extends Component {

    componentDidMount() {
        //initialise les données de la table de composantes
        this.setState({...this.state, component_list: this.props.component_list});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.component_list) !== JSON.stringify(this.props.component_list)) {
            this.setState({...this.state, component_list: this.props.component_list});
        }
    }

    state = {
        columns: [
            {dataField: 'component_id', text: checkMot("composante_id"), hidden: true, sort: true},
            // {dataField: 'sensor_id', text: 'sensor_id', sort: true, },
            {dataField: 'position_in_sensor', text: checkMot("position_in_sensor"), sort: true,},
            {dataField: 'forumula', text: checkMot("forumula"), sort: true, editor: {type: Type.TEXTAREA}},
            {dataField: 'prefix', text: checkMot("prefix"), sort: true},
            {dataField: 'engineering_unit', text: checkMot("unite"), sort: true},
            {
                dataField: 'precision_decimal_notation', text: checkMot("resolution_decimales"), sort: true,
                formatter: (cell) => {
                    return FloatChampInput(cell);
                }
            },
            {
                dataField: 'start_formula', text: checkMot("start_formula"), sort: true,
                formatter: (cell) => {
                    return unixTimestampToDate(cell);
                },
            },
            {
                dataField: 'end_formula', text: checkMot("end_formula"), sort: true,
                formatter: (cell) => {
                    return unixTimestampToDate(cell);
                },
            },
            {dataField: 'calibration_factors', text: checkMot("calibration_factors"), sort: true},
            {dataField: 'y_scale', text: checkMot("y_scale"), sort: true},
            {dataField: 'time_period', text: checkMot("time_period"), sort: true},
            {dataField: 'measures_num', text: checkMot("measures_num"), sort: true},
            {dataField: 'serial_number', text: checkMot("serial_number"), sort: true},
            {dataField: 'coordinate_x', text: checkMot("coordinate_x"), sort: true},
            {dataField: 'coordinate_y', text: checkMot("coordinate_y"), sort: true},
            {dataField: 'coordinate_z', text: checkMot("coordinate_z"), sort: true},
            {dataField: 'alarmlevel_high4', text: checkMot("alarmlevel_high4_black"), sort: true},
            {dataField: 'alarmlevel_high3', text: checkMot("alarmlevel_high3_red"), sort: true},
            {dataField: 'alarmlevel_high2', text: checkMot("alarmlevel_high2_amber"), sort: true},
            {dataField: 'alarmlevel_high1', text: checkMot("alarmlevel_high1_blue"), sort: true},
            {dataField: 'alarmlevel_low1', text: checkMot("alarmlevel_low1_blue"), sort: true},
            {dataField: 'alarmlevel_low2', text: checkMot("alarmlevel_low2_amber"), sort: true},
            {dataField: 'alarmlevel_low3', text: checkMot("alarmlevel_low3_red"), sort: true},
            {dataField: 'alarmlevel_low4', text: checkMot("alarmlevel_low4_black"), sort: true},

            {dataField: 'time_refresh', text: checkMot("time_refresh"), sort: true},
        ],
        incre: 0,
        component_list: [],
    }

    AjoutLigne = () => {
        const newListe = [...this.state.component_list, {
            "component_id": "Nouveau composant n°" + this.state.incre,
            sensor_id: parseInt(this.props.sensor_id),
            position_in_sensor: null,
            serial_number: null,
            coordinate_x: null,
            coordinate_y: null,
            coordinate_z: null,
            forumula: null,
            prefix: null,
            engineering_unit: null,
            precision_decimal_notation: null,
            alarmlevel_high4: null,
            alarmlevel_high3: null,
            alarmlevel_high2: null,
            alarmlevel_high1: null,
            alarmlevel_low1: null,
            alarmlevel_low2: null,
            alarmlevel_low3: null,
            alarmlevel_low4: null,
            start_formula: null,
            end_formula: null,
            calibration_factors: null,
            y_scale: [null, null],
            time_period: null,
            measures_num: 100,
            time_refresh: null,
        }];
        this.setState({
            component_list: newListe,
            incre: this.state.incre + 1,
        });
    }

    render() {
        return (
            <Fragment>
                <BootstrapTable
                    striped
                    hover
                    keyField={"component_id"}
                    data={this.state.component_list}
                    columns={this.state.columns}
                    classes={S.TableauBootstrap}
                    rowStyle={{margin: "0px", color: "black"}}
                />
                {/*{console.log(this.state.component_list)}*/}
                {/*TEST*/}
            </Fragment>
        );
    }
}


export default TableauComposante;