import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from "axios";
import * as actions from "./index";
import * as actionTypes from './actionTypes';

const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) =>{
    if(res.data.errors !== undefined){
        for(let erreurKey= 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre,["ApiEchecErreursEnBoucle", {numAvertissement:erreurKey, idErreur:res.data.errors[erreurKey]}]));
        }
    }else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api","errors est undefine, pour une requête de connexion"));
    }
}
const requetePost=(dispatch, requeteAPI)=>{
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios.post("", requeteAPI, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
        console.log('envoi: ',JSON.parse(res.config.data));
        console.log('reception: ',res);
        let titreAlerteSucces = "Requête api succès";
        let corpsAlerteSucces = "Requête api succès";
        let titreAlerteEchec = "Requête api échec";
        let corpsAlerteEchec = "Requête api échec";
        let succesRequete = {alerte() {}, actionReducteur() {}, fonctionSupplementaire() {}};
        let succesRequeteAvecErreur = {alerte() {}, actionReducteur() {}, fonctionSupplementaire() {}};
        let echecRequete = {alerte() {}, actionReducteur() {}, fonctionSupplementaire() {}};
        switch ( requeteAPI.request_type ) {
            case requestType.recuperation_de_la_liste_des_projets:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_projet";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_projet";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_projet";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_projet";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
                succesRequete.actionReducteur = () => dispatch(enregistrerListeProjets(res.data));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerListeProjets(res.data));
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    // echecRequete.actionReducteur = () => dispatch(enregistrerListeProjets(res.data));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.creation_d_un_projet:
                titreAlerteSucces = "ApiSuccesTitre_creation_d_un_projet";
                corpsAlerteSucces = "ApiSuccesCorps_creation_d_un_projet";
                titreAlerteEchec = "ApiEchecTitre_creation_d_un_projet";
                corpsAlerteEchec = "ApiEchecCorps_creation_d_un_projet";
                if(res.data.project_id > 0 && res.data.project_id !== null && res.data.project_id !== undefined) {
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.project_id]));
                    // succesRequete.fonctionSupplementaire = () => dispatch(actions.reqRecupererListeProjets());
                }
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.modification_d_un_projet:
                titreAlerteSucces = "ApiSuccesTitre_modification_d_un_projet";
                corpsAlerteSucces = "ApiSuccesCorps_modification_d_un_projet";
                titreAlerteEchec = "ApiEchecTitre_modification_d_un_projet";
                corpsAlerteEchec = "ApiEchecCorps_modification_d_un_projet";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.project_id]));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.supprimer_un_projet:
                titreAlerteSucces = "ApiSuccesTitre_supprimer_un_projet";
                corpsAlerteSucces = "ApiSuccesCorps_supprimer_un_projet";
                titreAlerteEchec = "ApiEchecTitre_supprimer_un_projet";
                corpsAlerteEchec = "ApiEchecCorps_supprimer_un_projet";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.project_id]));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.project_id]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.ajouter_une_image_de_projet:
                titreAlerteSucces = "ApiSuccesTitre_ajouter_une_image_de_projet";
                corpsAlerteSucces = "ApiSuccesCorps_ajouter_une_image_de_projet";
                titreAlerteEchec = "ApiEchecTitre_ajouter_une_image_de_projet";
                corpsAlerteEchec = "ApiEchecCorps_ajouter_une_image_de_projet";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.project_id]));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.project_id]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.rajouter_un_projet_en_favori:
                titreAlerteSucces = "ApiSuccesTitre_rajouter_un_projet_en_favori";
                corpsAlerteSucces = "ApiSuccesCorps_rajouter_un_projet_en_favori";
                titreAlerteEchec = "ApiEchecTitre_rajouter_un_projet_en_favori";
                corpsAlerteEchec = "ApiEchecCorps_rajouter_un_projet_en_favori";
                if(res.data.project_id > 0 && res.data.project_id !== null && res.data.project_id !== undefined) {
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.project_id]));
                    // succesRequete.fonctionSupplementaire = () => dispatch(actions.reqRecupererListeProjets());
                }
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.supprimer_un_projet_des_favoris:
                titreAlerteSucces = "ApiSuccesTitre_supprimer_un_projet_des_favoris";
                corpsAlerteSucces = "ApiSuccesCorps_supprimer_un_projet_des_favoris";
                titreAlerteEchec = "ApiEchecTitre_supprimer_un_projet_des_favoris";
                corpsAlerteEchec = "ApiEchecCorps_supprimer_un_projet_des_favoris";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.project_id]));

                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, requeteAPI.project_id]));
                    succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            case requestType.recuperation_de_la_liste_des_projets_des_favoris:
                titreAlerteSucces = "ApiSuccesTitre_recuperation_de_la_liste_des_projets_des_favoris";
                corpsAlerteSucces = "ApiSuccesCorps_recuperation_de_la_liste_des_projets_des_favoris";
                titreAlerteEchec = "ApiEchecTitre_recuperation_de_la_liste_des_projets_des_favoris";
                corpsAlerteEchec = "ApiEchecCorps_recuperation_de_la_liste_des_projets_des_favoris";

                succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces,corpsAlerteSucces));
                succesRequete.actionReducteur = () => dispatch(enregistrerListeProjetsFavoris(res.data));
                if(res.data.errors.length > 0){
                    succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerListeProjets(res.data));
                }
                if(res.data.state === false){
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    // echecRequete.actionReducteur = () => dispatch(enregistrerListeProjets(res.data));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                }
                break;
            default:
                break;
        }
        if(res.data.state){
            if(res.data.errors.length === 0){
                succesRequete.actionReducteur();
                succesRequete.alerte();
                succesRequete.fonctionSupplementaire();
            }else{
                succesRequeteAvecErreur.actionReducteur();
                succesRequeteAvecErreur.alerte();
                succesRequeteAvecErreur.fonctionSupplementaire();
            }
        }else{
            echecRequete.actionReducteur();
            echecRequete.alerte();
            echecRequete.fonctionSupplementaire();
        }
        if(requeteAPI.request_type===requestType.recuperation_de_la_liste_des_projets){
            //teste de rafraîchissement automatique après chaque interval de temps
            // setTimeout(()=>dispatch(actions.reqRecupererListeProjets()),7000)
        }else {
            //rafraîchie la liste des projets après chaque requête
        }
        if(requeteAPI.request_type===requestType.rajouter_un_projet_en_favori || requeteAPI.request_type===requestType.supprimer_un_projet_des_favoris){
            dispatch(actions.reqRecupererListeProjetsFavoris());
        }else if(requeteAPI.request_type===requestType.creation_d_un_projet || requeteAPI.request_type===requestType.modification_d_un_projet || requeteAPI.request_type===requestType.supprimer_un_projet|| requeteAPI.request_type===requestType.ajouter_une_image_de_projet){
            dispatch(actions.reqRecupererListeProjets());
        }
    })
        .catch(err => {
            console.log("Error API",requeteAPI.request_type,":",err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
        })
        .finally(()=>dispatch(actions.finAttente(requeteAPI.request_type)));
}

export const reqRecupererListeProjets = () => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_projets,
        });
    };
};
export const reqAjouterProjet = (infoNouveauProjet) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.creation_d_un_projet,
            ...infoNouveauProjet,
            // project_name: infoNouveauProjet.project_name,
            // groupe: infoNouveauProjet.groupe,
            // users_list_id: infoNouveauProjet.users_list_id,
            // description: infoNouveauProjet.description,
            // parent_id: infoNouveauProjet.parent_id,
            // active: infoNouveauProjet.active,

            //todo mettre les infos du projet créé
        });
    };
};
export const reqModifierProjet = (infoModifierProjet) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.modification_d_un_projet,
            ...infoModifierProjet,
        });
    };
};
export const reqSupprimerProjet = (idProjet) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.supprimer_un_projet,
            project_id: idProjet,

        });
    };
};
export const reqAjouterImageProjet = (idProjet, base64_img) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.ajouter_une_image_de_projet,
            project_id: idProjet,
            image: base64_img,

        });
    };
};
export const reqSupprimerImageProjet = (idProjet) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.supprimer_un_projet,
            project_id: idProjet,

        });
    };
};
export const reqAjouterProjetFavori = (idProjet) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.rajouter_un_projet_en_favori,
            project_id: idProjet,

        });
    };
};
export const reqSupprimerProjetFavori = (idProjet) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.supprimer_un_projet_des_favoris,
            project_id: idProjet,

        });
    };
};
export const reqRecupererListeProjetsFavoris = () => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.recuperation_de_la_liste_des_projets_des_favoris,
        });
    };
};

const enregistrerListeProjets = data => {return {type:actionTypes.ENREGISTRER_LISTE_PROJETS, projects_list:data.projects_list};};
const enregistrerListeProjetsFavoris = data => {return {type:actionTypes.ENREGISTRER_LISTE_PROJETS_FAVORIS, favorite_projects_list_id:data.favorite_projects_list_id};};

