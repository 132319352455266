import React, {Component} from 'react';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {text} from './FormulaireSelectionProjetParent.module.scss';
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {checkMot} from "../../../../ressources/functionJS/checkMot";


class FormulaireSelectionProjetParent extends Component {

    state={
        parentId:null,
    }

    componentDidMount() {
        this.props.recupererListeProjets();
        this.setState({...this.state, parentId:this.props.preconfigurerParentId});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.preconfigurerParentId!==prevProps.preconfigurerParentId){
            this.setState({...this.state, parentId:this.props.preconfigurerParentId});
        }
    }
    gererProjetParentId = (event) => {
        /**
         * gère id du projet parent en fonction de l'option sélectionnée
         */
        let newParentId;
        if (event.target.value === -1 || event.target.value === "-1"){
            newParentId = null;
        }else{
            newParentId = event.target.value;
        }
        this.setState({...this.state,parentId:newParentId});
        this.props.gererProjetParentId(newParentId);

    }
    render(){
        // récupérer liste des projets avec this.props.listeProjets
        // créer un formulaire pour ajouter un projet parent à un projet sous forme d'une liste déroulante
        return(
            <Auxiliaire>
                <label className={[text].join(" ")} htmlFor="inlineFormCustomSelect">
                    {checkMot("projet_parent_id")}
                </label>
                {this.props.listeProjets!==undefined&&this.props.listeProjets!==[]
                    ?<select
                        className={[s.custom_select, s.mr_sm_2].join(" ")}
                        onChange={(event) =>this.gererProjetParentId(event)}
                        defaultValue={this.props.preconfigurerParentId===null?-1:this.props.preconfigurerParentId}
                    >
                        <option value={-1} key={"Projet principal (sans parent)"}>
                        {checkMot("Projet principal (sans parent)")}
                        </option>
                        {Object.keys(this.props.listeProjets).map(indListeProjets=>{
                            return <option key={this.props.listeProjets[indListeProjets].project_id}
                                           value={this.props.listeProjets[indListeProjets].project_id}>
                                id: {this.props.listeProjets[indListeProjets].project_id+", name: "+this.props.listeProjets[indListeProjets].project_name}
                            </option>
                        })}
                    </select>
                    :<div>{checkMot("La liste de projet n'a pas été récupéré, veuillez actualiser la page")}/></div>}
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeProjets: state.authentifie.projects_list
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeProjets:() => dispatch(actions.reqRecupererListeProjets()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaireSelectionProjetParent);

