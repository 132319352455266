import React, {Component} from 'react';

import FormulaireVue from "./FormulaireVue/FormulaireVue";
import FormulaireCouche from "./FormulaireCouche/FormulaireCouche";
import FormulaireObjetsAssocieVue from "./FormulaireObjetsAssocieVue/FormulaireObjetsAssocieVue";
import AffichageVue from "../AffichageVue/AffichageVue";

import Popup from "../../UI/PopupVue/Popup";
import Bouton from "../../UI/Bouton/Bouton";
import AccesRestreins from "../../../hoc/AccesRestreins/AccesRestreins";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import Collapse from "../../../hoc/Collapse/Collapse"

import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import * as privilege from "../../../ressources/glossaires/glossaire_privileges";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import {confirmeOnClick} from "../../../ressources/functionJS/confirmeOnClick";

import {AiFillEdit, AiFillDelete} from "react-icons/ai";
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import {
    newStyle,
    sas,
    hedes,
    ConteneurInfo,
    text_profil_vue,
    bts,
    ConteneurProfilVue,
    style,
    styleA,

} from './ProfilVue.module.scss';

class ProfilVue extends Component {
    state = {
        afficherModifierVue: false,
    }

    //fonction afficher le formulaire de modification du profil de la vue
    afficherModifierProfil = () => {
        this.setState({afficherModifierVue: true});
    }
    //fonction pour annuler la modification du profil de la vue
    annulerModificationProfil = () => {
        this.setState({...this.state, afficherModifierVue: false});
    }

    render() {
        const formulaireVue = <div className={[s.col_md_12, ConteneurProfilVue].join(" ")}>
            <div className={text_profil_vue}>

                {/*Formulaire du profil de la Vue*/}
                <FormulaireVue affichageProfil={!this.state.afficherModifierVue}
                               view_id={this.props.infoVue.view_id}
                               preconfigurerVue={this.props.infoVue}
                               annuler={this.annulerModificationProfil}/>
                {/*Début barre d'outil pour formulaire Vue*/}
                {this.state.afficherModifierVue
                    ? null
                    : <div className={[s.row, bts].join(" ")}>
                        <AccesRestreins numPrivilege={privilege.modifier_des_vues}>
                            <div className={sas}>
                                {/*Bouton pour passer le formulaire Vue en mode modifiable*/}
                                <Bouton cliquer={this.afficherModifierProfil}>
                                    <AiFillEdit/> {checkMot("modifier")}
                                </Bouton>
                            </div>
                            <div className={sas}>
                                {/*<Bouton cliquer={this.handleOpenModal}> <AiFillPicture/> {checkMot("ajouter_une_image")}</Bouton>*/}
                                {/*<Modal isOpen={this.state.showModal}  className={Modale} overlayClassName={Overlay} onRequestClose={this.handleCloseModal}>*/}
                                {/*    <span onClick={this.handleCloseModal} className={btsFermer}><IoIosCloseCircleOutline size={40}/></span>*/}
                                {/*    <span className={modalCorp}>*/}
                                {/*    </span>*/}
                                {/*</Modal>*/}
                            </div>
                        </AccesRestreins>
                        <div className={sas}>
                            <AccesRestreins numPrivilege={privilege.supprimer_des_vues}>
                                <AttenteRequete request_type={requestType.supprimer_une_vue}>
                                    <Bouton
                                        cliquer={() => confirmeOnClick(checkMot("voulez_vous_vraiment_supprimer_cette_vue"), () => this.props.reqSupprimer(this.props.infoVue.view_id))}>
                                        <AiFillDelete/> {checkMot("supprimer")}
                                    </Bouton>
                                </AttenteRequete>
                            </AccesRestreins>
                        </div>
                    </div>
                }
                {/*Fin barre d'outil pour formulaire Vue*/}
            </div>
        </div>
        const formulaireCouches = <div className={[s.row, ConteneurProfilVue].join(" ")}>
            <div className={[text_profil_vue, s.col_12, s.text_left].join(" ")}>
                {/*Formulaire de couche de vue*/}
                <FormulaireCouche view_id={this.props.infoVue.view_id}/>
            </div>
        </div>

        const formulaireObjetsAssocieVue = <div className={[s.row, ConteneurProfilVue].join(" ")}>
            <div className={[text_profil_vue, s.col_12, s.text_left].join(" ")}>
                {/*Formulaire d'objets associé à la vue*/}
                <FormulaireObjetsAssocieVue view_id={this.props.infoVue.view_id}/>
            </div>
        </div>;
        return (
            <div className={ConteneurInfo}>
                {/*entête du profil vue*/}
                <div className={[s.card_header, hedes].join(" ")}>
                    {/* logo "i"*/}
                    <span className={style}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-info-circle-fill"
                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>  
                    </span>
                    <span className={style}>
                    {/*l'id de la vue*/}
                        {checkMot("vue_id")} : {this.props.infoVue.view_id}
                    </span>
                    <span className={styleA}>
                    <Popup hideHeader={true} texte_ouverture={"Afficher_vue"}>
                        <AffichageVue view_id={this.props.infoVue.view_id} uniciteVue={checkMot("profil_vue")}/>
                    </Popup>
                    </span>
                </div>
                {/*conteneur pour l'affichage général du conteneur info*/}
                <div className={[s.container, newStyle].join("")}>
                    <Collapse ExpandText={checkMot("info_general")}>
                        {formulaireVue}
                    </Collapse>
                    <Collapse ExpandText={checkMot("couches")}>
                        {formulaireCouches}
                    </Collapse>
                    <Collapse ExpandText={checkMot("objets")}>
                        {formulaireObjetsAssocieVue}
                       
                    </Collapse>
                </div>
            </div>
        )

    }
}

export default ProfilVue;


