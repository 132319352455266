import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import axios from 'axios';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './store/reducteurs/auth';
import dictionnaireReducer from './store/reducteurs/dictionnaire';
import alertesReducer from './store/reducteurs/alertes';
import utilisateurReducer from './store/reducteurs/utilisateur';
import capteurReducer from './store/reducteurs/capteur';
import projetReducer from './store/reducteurs/projet';
import grapheReducer from './store/reducteurs/graphe';
import vueReducer from './store/reducteurs/vue';
import privilegeReducer from './store/reducteurs/privilege';
import attenteRequeteReducer from './store/reducteurs/attenteRequete';
import popupReducer from './store/reducteurs/popup';
import interfaceClientReducer from './store/reducteurs/interfaceClient';
import alarmeReducer from './store/reducteurs/alarme';
import rapportReducer from './store/reducteurs/rapport';
import exportImpressionEcranReducer from './store/reducteurs/exportImpressionEcran';
import maintenanceReducer from './store/reducteurs/maintenance';
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@emotion/react";

// const composeEnhancers = compose;
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

let cheminURLAPI;
switch (document.domain) {
    case "localhost":
        cheminURLAPI = "http://localhost/argos_frontend/api_argos/api.php";
        break;
    case "argos-dev-test.itmsol.fr":
        cheminURLAPI = "./index.php";
        break;
    case "argos-dev.itmsol.fr":
        cheminURLAPI = "./index.php";
        break;
    case "argos-v19.itmsol.fr":
        cheminURLAPI = "./index.php";
        break;
    default:
        cheminURLAPI = "./index.php";
}

axios.defaults.baseURL = cheminURLAPI;
console.log("Chemin api", axios.defaults.baseURL)


const rootReducer = combineReducers({
    authentifie: authReducer,
    dico: dictionnaireReducer,
    alrt: alertesReducer,
    utilisateur: utilisateurReducer,
    capteur: capteurReducer,
    projet: projetReducer,
    graphe: grapheReducer,
    vue: vueReducer,
    alarme: alarmeReducer,
    rapport: rapportReducer,
    privilegeUtilisateur: privilegeReducer,
    attenteRequete: attenteRequeteReducer,
    popup: popupReducer,
    interfaceClient: interfaceClientReducer,
    exportImpressionEcran: exportImpressionEcranReducer,
    maintenance: maintenanceReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3C3935',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

ReactDOM.render(
    <BrowserRouter getUserConfirmation={(message, callback) => {
        // this is the default behavior
        const allowTransition = window.confirm(message);
        callback(allowTransition);
    }}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
