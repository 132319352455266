import React, {Component} from 'react';

import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";
import Bouton from "../../../UI/Bouton/Bouton";

import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import {checkMot} from "../../../../ressources/functionJS/checkMot";

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {ConteneurInfo, Formulaire} from './AjouterSensorType.module.scss';
import * as glossaire_affichage_type_capteur from "../../../../ressources/glossaires/glossaire_affichage_type_capteur";

class AjouterSensorType extends Component {

    constructor(props){
        super(props);
        this.state = {
            sensor_type_name: '',
            sensor_type_display: glossaire_affichage_type_capteur.default_display,
        };
    }
    gererEtatSensorTypeName = (event) => {
        this.setState({ ...this.state, sensor_type_name: event.target.value });
    };
    gererSensorTypeDisplay = (event) => {
        this.setState({...this.state, sensor_type_display: event.target.value});
    };
    render(){
        return(
            <Auxiliaire>
               <div className={[ConteneurInfo].join(" ")}>
                    <div className={[s.container,s.border, s.text_left, Formulaire].join(" ")}>
                        <div className={s.form_group}>
                            <div className={[s.form_row, s.align_items_center].join(" ")}>
                                <div className={s.col_12}>
                                    <label className={s.col_form_label}>{checkMot("sensor_type_display")}</label>
                                    <input
                                        type="text"
                                        pattern="[0-9a-zA-Z._%+-\\s ]+"
                                        className={[s.form_control, s.mb_2].join(" ")}
                                        placeholder="sensor_type_name"
                                        value={this.state.sensor_type_name}
                                        onChange={(event) => this.gererEtatSensorTypeName(event)}
                                    />
                                </div>
                            </div>
                        </div>{/**sensor_type_name**/}
                        <div className={s.form_group}>
                            <div className={[s.form_row, s.align_items_center].join(" ")}>
                                <div className={s.col_12}>
                                    <label className={s.col_form_label}>{checkMot("sensor_type_display")}</label>
                                    <select onChange={(event) => this.gererSensorTypeDisplay(event)}
                                            defaultValue={this.state.sensor_type_display}
                                            value={this.state.sensor_type_display}
                                            className={[s.custom_select].join(" ")}
                                    >
                                        {Object.keys(glossaire_affichage_type_capteur).map(cleTypeAffichage => {
                                            return (<option
                                                value={glossaire_affichage_type_capteur[cleTypeAffichage]}>{checkMot(glossaire_affichage_type_capteur[cleTypeAffichage])}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>{/**sensor_type_name**/}
                        <div className={s.form_group}>
                            <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                                <div>
                                    <Bouton cliquer={this.props.annuler}>{checkMot("annuler")}</Bouton>
                                </div>
                                <div>
                                <AttenteRequete request_type={requestType.creation_d_un_sensor_type} str={checkMot("creation_en_cour")}>
                                    <Bouton cliquer={()=>this.props.reqAjouter(this.state.sensor_type_name, this.state.sensor_type_display)}>{checkMot("creer")}</Bouton>
                                </AttenteRequete>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliaire>
        );
    }

}

export default AjouterSensorType
