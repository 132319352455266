import React, {Fragment, useState} from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Bouton from "../Bouton/Bouton";
import S from "./Tableau.module.scss";
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import '../../../ressources/StyleGlobal/personnaliser.css';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import AccesRestreins from "../../../hoc/AccesRestreins/AccesRestreins";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {VscSearch} from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";
const MySearch = (props) => {
    const [searchValue, setSearchValue] = useState('');

    let input;
    const handleClick = (e) => {
        input.value = '';
        props.onSearch(input.value); 
        setSearchValue(e.target.value)
    };
    const handleChange = (e) => {
      setSearchValue(e.target.value);
      props.onSearch(e.target.value);
    };
    
    return (
      <div className={[s.row, S.box_search].join(" ")}>
           <Bouton ajoutClass={[S.bts_search].join(" ")} cliquer={handleClick} type={type_btn.outil} rond={true}>{searchValue  ? (
         <AiOutlineClose size={15} />  
        ) : (
            <VscSearch size={15} />
        )}</Bouton>
        <input
            ref={n => input = n}
          className={[s.col, S.serch, s.form_control].join(" ")}
          type="text"
          placeholder={checkMot("recherche") }
          onChange={handleChange}
        />
 
     
      </div>
    );
  };

export const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
    {checkMot("ligne")} {from} - {to} {checkMot("sur")} {size} {checkMot("resultats")}
  </span>
);


const Tableau = (props) => {
    console.log(props)
    const options = {
        paginationSize: 25,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: 'All', value: props.donnees.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
        <Fragment>
            <div className={[S.Tableau].join(" ")}>
                <ToolkitProvider
                    keyField={props.cleLigne}
                    data={props.donnees}
                    columns={props.colonnes}
                    search>
                    {propriete => (<Fragment>
                            <div className={[S.BarreOutil].join(" ")}>
                                {props.hide_general_search===true?null:<MySearch {...propriete.searchProps} />}
                                {Array.isArray(props.privilegeCreer)
                                    ? <AccesRestreins listNumPrivilege={props.privilegeCreer}>
                                        <Bouton hoverText={checkMot("creer")} type={type_btn.outil} rond={true}
                                                cliquer={props.creer}>
                                            {props.iconeCreer}
                                        </Bouton>
                                    </AccesRestreins>
                                    : <AccesRestreins numPrivilege={props.privilegeCreer}>
                                        <Bouton hoverText={checkMot("creer")} type={type_btn.outil} rond={true}
                                                cliquer={props.creer}>
                                            {props.iconeCreer}
                                        </Bouton>
                                    </AccesRestreins>}
                                {props.children}
                            </div>
                            <div className={S.TableauBootstrap}>
                                <BootstrapTable
                                    bootstrap4
                                    {...propriete.baseProps}
                                    striped
                                    hover
                                    condensed
                                    selectRow={props.selectionnerLignes}
                                    rowStyle={{marginLeft: "0px"}}
                                    filter={filterFactory()}
                                    pagination={paginationFactory(options)}
                                    bordered={true}
                                    hiddenRows={props.lignesCache}
                                    defaultSorted={props.defaultSorted}
                                />
                            </div>
                        </Fragment>
                    )}</ToolkitProvider>
            </div>
        </Fragment>
    );
}

export default Tableau;
