import React, {Component} from 'react';
import {connect} from 'react-redux';

import Bouton from "../../../../UI/Bouton/Bouton";
import FormulaireGraphe from "./FormulaireGraphe/FormulaireGraphe";

import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";
import AccesRestreins from "../../../../../hoc/AccesRestreins/AccesRestreins";
import AttenteRequete from "../../../../../hoc/AttenteRequete/AttenteRequete";

import * as privilege from "../../../../../ressources/glossaires/glossaire_privileges";
import * as requestType from "../../../../../ressources/glossaires/glossaire_requetes";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";
import {confirmeOnClick} from "../../../../../ressources/functionJS/confirmeOnClick";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import * as actions from "../../../../../store/actions";

import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import {AiFillEdit, AiFillDelete, AiOutlineRedo} from "react-icons/ai";
import {BsFillInfoCircleFill} from "react-icons/bs";
import {
    sas,
    hedes,
    ConteneurInfo,
    bts,
} from './ProfilGraphe.module.scss';
import AffichageGraphe from "../../../../Graphe/AffichageGraphe/AffichageGraphe";

class ProfilGraphe extends Component {
    state = {
        configurationGraphe: {graph_id: null},
        afficherModifier: false,
    }
    afficherModifierProfil = () => {
        this.setState({afficherModifier: true});
    }
    annulerModificationProfil = () => {
        this.setState({...this.state, afficherModifier: false});
    }
    componentDidMount() {
        if (this.props.listeConfigurationsGraphes[this.props.infoGraphe.graph_id] === undefined) {
            //si il y a un changement de graph_id et qu'il ne correspond pas au formulaire actuel
            this.props.reqRecupererGraphe(this.props.infoGraphe.graph_id);
            this.setState({
                ...this.state,
                configurationGraphe: {...this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)]},
                afficherModifier: false

            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.infoGraphe.graph_id !== prevProps.infoGraphe.graph_id && this.props.infoGraphe.graph_id !== this.state.configurationGraphe.graph_id) {
            //si il y a un changement de graph_id et qu'il ne correspond pas au formulaire actuel
            this.props.reqRecupererGraphe(this.props.infoGraphe.graph_id);
            this.setState({
                ...prevState,
                configurationGraphe: {...this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)]},
                afficherModifier: false

            })
        }
        if (this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)] !== undefined && !jsDictionaryEquals(this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)], prevProps.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)])) {
            this.setState({
                ...prevState,
                configurationGraphe: {...this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)]},
                afficherModifier: false

            })
        }
    }

    render() {
        const formulaireModifier = this.props.listeConfigurationsGraphes[this.props.infoGraphe.graph_id] !== undefined ?
            <FormulaireGraphe annuler={() => this.setState({...this.state, afficherModifier: false})}
                              preconfigurerGraphe={this.props.listeConfigurationsGraphes[this.props.infoGraphe.graph_id]}/> : null
        const profil = <Auxiliaire>
            <div className={[s.row, ConteneurInfo].join(" ")}>
                {
                    this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)] !== undefined && this.props.listeConfigurationsGraphes[parseInt(this.props.infoGraphe.graph_id)].graph_id === parseInt(this.props.infoGraphe.graph_id)
                        ? <div className={[s.col_12, s.text_left].join(" ")}>
                            <FormulaireGraphe affichageProfil={true}
                                              annuler={() => this.setState({...this.state, afficherModifier: false})}
                                              preconfigurerGraphe={this.props.listeConfigurationsGraphes[this.props.infoGraphe.graph_id]}/>
                        </div>
                        : null
                }

                <div className={[s.row, bts].join(" ")}>
                    <div className={sas}>
                        <AffichageGraphe graph_id={this.props.infoGraphe.graph_id}/>
                    </div>
                    <div className={sas}>
                        <AttenteRequete request_type={requestType.recuperer_un_graphe}>
                            <AccesRestreins numPrivilege={privilege.modifier_des_projets}>
                                {this.props.listeConfigurationsGraphes[this.props.infoGraphe.graph_id]
                                    ? <Bouton cliquer={this.afficherModifierProfil}>
                                        <AiFillEdit/>{checkMot("modifier")}
                                    </Bouton>
                                    : <Bouton
                                        cliquer={() => this.props.reqRecupererGraphe(this.props.infoGraphe.graph_id)}>
                                        <AiOutlineRedo/>{checkMot("refresh")}{this.props.infoGraphe.graph_id}
                                    </Bouton>
                                }
                            </AccesRestreins>
                        </AttenteRequete>
                    </div>
                    <div className={sas}>
                        <AccesRestreins numPrivilege={privilege.supprimer_des_graphes}>
                            <AttenteRequete request_type={requestType.supprimer_un_graphe}>
                                <Bouton
                                    cliquer={() => confirmeOnClick(checkMot("voulez_vous_vraiment_supprimer_ce_graphe"), () => this.props.reqSupprimer(this.props.infoGraphe.graph_id))}>
                                    <AiFillDelete/>{checkMot("supprimer")}
                                </Bouton>
                            </AttenteRequete>
                        </AccesRestreins>
                    </div>
                </div>
            </div>
        </Auxiliaire>
        return (
            <div className={ConteneurInfo}>
                <div className={[s.card_header, hedes].join(" ")}>
                    <BsFillInfoCircleFill size={14}/>{checkMot("graphe_id")} : {this.props.infoGraphe.graph_id}
                </div>
                <div className={s.container}>
                    {this.state.afficherModifier
                        ? formulaireModifier
                        : profil}
                </div>
            </div>
        )

    }
}


const mapStateToProps = state => {
    return {
        listeGraphes: state.graphe.graphs_list,
        listeConfigurationsGraphes: state.graphe.liste_configurations_graphes
    };
}
const mapDispatchToProps = dispatch => {
    return {
        reqRecupererGraphe: (idGraphe) => dispatch(actions.reqRecupererGraphe(idGraphe)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilGraphe);


