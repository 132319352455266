import React, {Component} from "react";
import BibliothequePlotly from "../BibliothequePlotly/BibliothequePlotly";
import {convertiUtsToDate} from "../GrapheXT/GrapheXT";
import {average} from "../GraphesMachinePosition/GraphesMachinePosition";

class GrapheGeoposition extends Component {
    state = {
        data: [],
        layout: {},
    }

    render() {
        let mapbox_layout = {};
        let layout = {};
        let data = [];
        let geo_bounds = {
            zoom: 9
        };
        if (this.props.configuration !== undefined && this.props.donnees !== undefined) {
            layout = {
                font: {size: 14},
                title: {
                    text: this.props.configuration.title + '<br>' + this.props.configuration.subtitle,
                    font: {
                        // size: 25,
                        color: '#3c3935',
                    },
                },
                showlegend: true,
                legend: {
                    orientation: "h",
                    x: 0,
                    y: -0.0,
                },
                ...(this.props.viewobj_json_content === undefined || this.props.viewobj_json_content === null ? {} : this.props.viewobj_json_content.layout)

            };

            let positions_x;
            let positions_y;
            //mise en forme des données de composantes de capteurs
            for (let indSetDonnees = 0; indSetDonnees < this.props.donnees.length; indSetDonnees++) {

                if (this.props.donnees[indSetDonnees].prefix === "x") {
                    positions_x = this.props.donnees[indSetDonnees]
                } else if (this.props.donnees[indSetDonnees].prefix === "y") {
                    positions_y = this.props.donnees[indSetDonnees]
                }
            }


            if (positions_x !== undefined && positions_y !== undefined) {

                let nb_values = positions_y.y.length - 1

                let centre_lon = average(positions_y.y)
                let centre_lat = average(positions_x.y)
                mapbox_layout = {
                    lon: centre_lon,
                    lat: centre_lat,
                }
                if (nb_values < 0) {

                    nb_values = nb_values < 0 ? 0 : nb_values
                    centre_lon = 2
                    centre_lat = 46.90
                    geo_bounds = {
                        center: {
                            lon: centre_lon,
                            lat: centre_lat,
                        },
                        zoom: 4.9
                    };
                }

                const markers_color = [...Array(nb_values).fill("#736e6e"), "#a6153e"]
                const markers_size = [...Array(nb_values).fill(5), 15]

                data.push({
                    type: "scattermapbox",
                    mode: 'markers+text+lines',
                    lon: positions_y.y,
                    lat: positions_x.y,
                    line: {
                        dash: 'dashdot',
                        width: 3,
                        color: '#679ef6',

                    },
                    marker: {
                        symbol: "circle",
                        color: markers_color, //affiche la dernière valeur en rouge
                        size: markers_size,// affiche la dernière valeur en plus gros
                        line: {
                            color: '#ffffff',
                            width: 6,
                        }
                    },
                    name: positions_x.sensor_name,

                    text: convertiUtsToDate(positions_x.x),
                })
            }

        }

        return <BibliothequePlotly
            data={data}
            layout={{
                ...layout,
                mapbox: {
                    center: mapbox_layout,
                    ...geo_bounds,
                    style: "open-street-map",
                },
                responsive: true,
                ...this.props.viewobj_json_content
            }}
            ouvrirPopupModifierIntervalle={this.props.switchAfficherModifierIntervalle}
            dimensionWidth={this.props.dimensionWidth}
            dimensionHeight={this.props.dimensionHeight}
            viewobj_json_content={this.props.viewobj_json_content}
        />
    }


}

export default GrapheGeoposition;
