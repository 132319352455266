export const dateValider = (dateString) => {
    // Utilisez une expression régulière ou une bibliothèque comme moment.js pour valider la date.
    // Voici un exemple d'expression régulière simple pour valider une date au format "YYYY-MM-DD".
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateString.match(dateRegex)) {
        return false;
    }

    const dateObj = new Date(dateString);

    // Vérifiez si la date est valide en utilisant la méthode isNaN() sur l'objet Date.
    if (isNaN(dateObj.getTime())) {
        return false;
    }

    // Obtenez l'année de la date.
    const annee = dateObj.getFullYear();

    // Ajoutez des conditions pour vérifier que l'année est supérieure à 1900.
    return annee > 1900;


}
