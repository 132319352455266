import React, {Component} from 'react';
import {connect} from 'react-redux';
import {textFilter} from "react-bootstrap-table2-filter";

import Tableau from "../../../UI/tableau/Tableau";
import Bouton from "../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";

import * as actions from "../../../../store/actions";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as privilege from "../../../../ressources/glossaires/glossaire_privileges";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";

import {VscNewFile, VscRefresh} from "react-icons/vsc";
import {VscSearch} from "react-icons/vsc";
import {convertiUtsToDateHour} from "../../../InterfaceClient/Tabs/Alarmes/TableauAlarmesClient/TableauAlarmesClient";

class TableauRapportGabarit extends Component {
    state = {
        columns: [// liste des clés pour l'entête du tableau de rapports gabarits
            {
                dataField: 'template_report_id', text: [checkMot("id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("template_report_id", checkMot("id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'project_id', text: [checkMot("projet"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_id", checkMot("projet"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    for (let i = 0; i < this.props.projects_list.length; i++) {
                        if (this.props.projects_list[i].project_id === cell) {
                            return this.props.projects_list[i].project_name
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'name', text: [checkMot("name"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("name", checkMot("name"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'time_zone', text: [checkMot("time_zone"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("time_zone", checkMot("time_zone"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'email_group_id', text: [checkMot("email_group_id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("email_group_id", checkMot("email_group_id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'last_unixtimestamp_run', text: [checkMot("derniere_generation"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("last_unixtimestamp_run", checkMot("derniere_generation"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return convertiUtsToDateHour(cell)
                }
            },
            {
                dataField: 'next_unixtimestamp_run', text: [checkMot("prochaine_generation"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("next_unixtimestamp_run", checkMot("prochaine_generation"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return convertiUtsToDateHour(cell)
                }
            },
            {
                dataField: 'mode', text: [checkMot("mode"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("mode", checkMot("mode"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'number_of_days', text: [checkMot("number_of_days"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("number_of_days", checkMot("number_of_days"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'path_config', text: [checkMot("telecharger_config")], sort: true,
                formatter: (cell) => {
                    return <a href={cell}>{cell}</a>
                }
            }
        ],
        selectRow: {// paramètre d'affichage des lignes du tableau
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            style: () => {//style après sélection
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne
                return this.props.projetSelectione(row);
            }
        },
        iconeCreer: <VscNewFile size={15}/>,
    }

    componentDidMount() {
        this.props.recupererListeRapportsGabarits(this.props.filtre_projects_list_id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des rapports gabarits en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.recupererListeRapportsGabarits([parseInt(this.props.project_id_url)]);
        } else if (JSON.stringify(prevProps.listeRapportsGabarits) !== JSON.stringify(this.props.listeRapportsGabarits)) {// envoie la requête de recupération de rapports gabarits si la liste de projets change entre temps
            this.setState({...this.state, listeRapportsGabarits: this.props.listeRapportsGabarits});
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.recupererListeRapportsGabarits(this.props.filtre_projects_list_id);
        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }

    render() {
        const defaultSorted = [{
            dataField: 'template_report_id',
            order: 'desc'
          }];
        return (
            <Auxiliaire>
                <Tableau
                    cleLigne={"template_report_id"}
                    privilegeCreer={privilege.creer_des_rapports_gabarits}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    donnees={this.props.listeRapportsGabarits}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                    defaultSorted={ defaultSorted }
                >
                    <AttenteRequete request_type={requestType.recuperer_la_liste_des_rapports_gabarits} str={checkMot("maj_rapport_gabarit_en_cour")}>
                        {/*bouton de rafraîchissement du tableau*/}
                        <Bouton hoverText={checkMot("refresh")} type={type_btn.outil} rond={true} cliquer={() => {
                            this.props.recupererListeRapportsGabarits(this.props.filtre_projects_list_id)
                        }}>
                            <VscRefresh size={15}/>
                        </Bouton>
                        {this.props.children}
                    </AttenteRequete>
                </Tableau>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeRapportsGabarits: state.rapport.templates_reports_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        projects_list: state.authentifie.projects_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeRapportsGabarits: (listeIdProjets) => dispatch(actions.reqRecupererListeRapportsGabarits(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauRapportGabarit);
