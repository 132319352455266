import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectFilter, textFilter} from 'react-bootstrap-table2-filter';

import Tableau from "../../../../UI/tableau/Tableau";
import Bouton from "../../../../UI/Bouton/Bouton";

import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../../hoc/AttenteRequete/AttenteRequete";

import * as glossaire_type_de_graphe from "../../../../../ressources/glossaires/glossaire_type_de_graphe";
import * as privilege from "../../../../../ressources/glossaires/glossaire_privileges";
import * as requestType from "../../../../../ressources/glossaires/glossaire_requetes";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import * as type_btn from "../../../../../ressources/glossaires/glossaire_type_de_bouton";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";
import * as actions from "../../../../../store/actions";
import privet from "../../../../../ressources/Logo/close_p.png";
import publiq from "../../../../../ressources/Logo/open_p.png";
import {VscSearch} from "react-icons/vsc";
import {VscNewFile, VscRefresh} from "react-icons/vsc";
import S from "./TableauGraphe.module.scss";
import {getProjectIDTree} from "../../../../../ressources/functionJS/getProjectIDTree";
import {searchKeyInTree} from "../../../../../ressources/functionJS/searchKeyInTree";


class TableauGraphe extends Component {
    state = {
        columns: [ {
            dataField: 'private', text: checkMot("propriete") + " ", sort: true, headerAlign: 'center', align: 'center',  style: {
                width:"150px"
              },
            formatter: (cell) => {
                switch (parseInt(cell)) {
                    case 1:
                        return  <img alt="prive" title="prive" src={privet} className=""/>

                    case 0:
                        return  <img alt="public" title="public" src={publiq} className=""/>
                    default:
                        return checkMot(cell)
                }
            },
            filter: selectFilter({
                options: {
                    1: checkMot("prive"),
                    0: checkMot("public")
                },
                placeholder: checkMot("selectionner")
            })

        },
            {
                dataField: 'graph_id', text: [checkMot("id"),
                    <Bouton key={"graph_id_header_search"} type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("graph_id", checkMot("graph_id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true, hidden: !this.props.interfaceAdmin, headerAlign: 'left', align: 'left',
            },
            {
                dataField: 'project_id', text: [checkMot("projet"),
                    <Bouton key={"project_id_header_search"} type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_id", checkMot("projet"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true, headerAlign: 'left', align: 'left', hidden: true,
                formatter: (cell) => {
                    for (let i = 0; i < this.props.projects_list.length; i++) {
                        if (this.props.projects_list[i].project_id === cell) {
                            return this.props.projects_list[i].project_name
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'graph_name', text: [checkMot("nom")+" ",
                    // <Bouton key={"graph_name_header_search"} type={type_btn.lupeSearch}
                    //         cliquer={() => this.afficherFiltre("graph_name", checkMot("graph_name"))}><VscSearch size={10}/>
                    // </Bouton>
                ], sort: true, headerAlign: 'left', align: 'left', filter: textFilter(),  
                 formatter: (cell, row) => {
                    // return <span title={row.graph_type_id}>{cell}</span>;

                    return <span title={checkMot(Object.keys(glossaire_type_de_graphe).filter((objKey)=> glossaire_type_de_graphe[objKey] === row.graph_type_id))}>{cell}</span>;
                },
            },
           
            {
                dataField: 'graph_type_id', text: checkMot("type_de_graphe") + " ", sort: true,  headerAlign: 'left', align: 'left', hidden: true,
                formatter: (cell) => checkMot(Object.keys(glossaire_type_de_graphe).find(key => glossaire_type_de_graphe[key] === parseInt(cell))),
                filter: selectFilter({
                    options: Object.fromEntries(Object.keys(glossaire_type_de_graphe).map((objKey)=> [glossaire_type_de_graphe[objKey], checkMot(objKey)]).slice(1)),
                    placeholder: checkMot("selectionner")
                })
            },
        ],
        selectRow: {
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            style: () => {
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row) => {
                return this.props.projetSelectione(row);
            }
        },
        iconeCreer: <VscNewFile size={15}/>,
    }

    componentDidMount() {
        //récupère la liste des graphes (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        // console.log("this.props.project_id_url mount:", JSON.stringify(this.props.project_id_url))
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {
            const listeDesProjetsId = getProjectIDTree(searchKeyInTree({nodes: this.props.arborescence_projets}, parseInt(this.props.project_id_url)));
            this.props.recupererListeGraphes(listeDesProjetsId);
        } else if (this.props.filtre_projects_list_id.length > 0) {
            this.props.recupererListeGraphes(this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("this.props.project_id_url update:", JSON.stringify(this.props.project_id_url), parseInt(this.props.project_id_url)!==null, this.props.project_id_url!==null, this.props.project_id_url!==undefined, isNaN(this.props.project_id_url), )
        if (!this.props.interfaceAdmin && !isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") {
            const listeDesProjetsId = getProjectIDTree(searchKeyInTree({nodes: this.props.arborescence_projets}, parseInt(this.props.project_id_url)));
            this.props.recupererListeGraphes(listeDesProjetsId);
        } else if (this.props.interfaceAdmin && !jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.recupererListeGraphes(this.props.filtre_projects_list_id);
        } else if (JSON.stringify(prevProps.listeGraphes) !== JSON.stringify(this.props.listeGraphes)) {
            this.setState({...this.state, listeGraphes: this.props.listeGraphes});
        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }
    rafraichirTableau = () => {
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {
            const listeDesProjetsId = getProjectIDTree(searchKeyInTree({nodes: this.props.arborescence_projets}, parseInt(this.props.project_id_url)));
            this.props.recupererListeGraphes(listeDesProjetsId);
        } else {
            this.props.recupererListeGraphes(this.props.filtre_projects_list_id);
        }
    }

    render() {
        let listeGraphesAfficher = [];
        if (this.props.listeGraphes !== undefined) {
            if (this.props.interfaceAdmin) {
                for (let indLigne = 0; indLigne < this.props.listeGraphes.length; indLigne++) {
                    if (!(this.props.filtre_projects_list_id.indexOf(parseInt(this.props.listeGraphes[indLigne].project_id)) < 0)) {
                        listeGraphesAfficher.push(this.props.listeGraphes[indLigne])
                    }
                }
            } else {
                for (let indLigne = 0; indLigne < this.props.listeGraphes.length; indLigne++) {
                    const listeDesProjetsId = getProjectIDTree(searchKeyInTree({nodes: this.props.arborescence_projets}, parseInt(this.props.project_id_url)));
                    if (listeDesProjetsId.indexOf(parseInt(this.props.listeGraphes[indLigne].project_id))>-1) {
                        listeGraphesAfficher.push(this.props.listeGraphes[indLigne])
                    }
                }
            }
        }

        if(listeGraphesAfficher.length > 0){
            this.props.siLaListeAUneValeur(true)
        }
        return (
            <Auxiliaire>
                <Tableau
                    cleLigne={"graph_id"}
                    privilegeCreer={privilege.creer_des_graphes}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    donnees={listeGraphesAfficher}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                >
                    <AttenteRequete request_type={requestType.recuperation_de_la_liste_des_graphes}
                                    str={checkMot("maj_graphes_en_cour")}>
                        <Bouton hoverText={checkMot("refresh")} ajoutClass={S.btn} type={type_btn.outil} rond={true}
                                cliquer={this.rafraichirTableau}>
                            <VscRefresh size={15}/>
                        </Bouton>
                    </AttenteRequete>
                </Tableau>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeGraphes: state.graphe.graphs_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        arborescence_projets: state.interfaceClient.arborescence_projets,
        interfaceAdmin: state.authentifie.interfaceAdmin,
        projects_list: state.authentifie.projects_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeGraphes: (listeIdProjets) => dispatch(actions.reqRecupererListeGraphes(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauGraphe);
