import React, {Component} from 'react';
import {connect} from "react-redux";

import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import * as indexConteneurInfo from "../../../ressources/glossaires/glossaire_conteneur_info";
import * as actions from "../../../store/actions";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import {Tableau, BarreOutil, TableauBootstrap} from "../../UI/tableau/Tableau.module.scss";
import S from "./ArborescenceConteneur.scss";
import TreeMenu from "react-simple-tree-menu";
import {ListGroup} from "reactstrap";
import ListItem from "./ArborescenceMaintenance/AffichageNoeud/AffichageNoeud";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import Bouton from "../../UI/Bouton/Bouton";
import * as type_btn from "../../../ressources/glossaires/glossaire_type_de_bouton";
import {VscRefresh} from "react-icons/vsc";
import {jsDictionaryEquals} from "../../../ressources/functionJS/jsDictionaryEquals";

function transformProjectsToTreeData(projects) {
    // Crée une nouvelle carte pour stocker les relations parent-enfant
    const projectMap = new Map();

    // Première itération pour initialiser chaque projet avec ses données de base et un tableau vide pour les nodes
    projects.forEach(project => {
        projectMap.set(project.project_id, {
            key: project.project_id,
            label: project.project_name,
            parent_id: project.parent_id,
            project_id: project.project_id,
            project_name: project.project_name,
            project_fails: project.project_fails,
            sen_total: project.sen_total,
            ratio_fails: project.ratio_fails,
            nodes: []
        });
    });

    // Deuxième itération pour assigner les enfants à leurs parents
    projects.forEach(project => {
        if (project.parent_id && projectMap.has(project.parent_id)) {
            projectMap.get(project.parent_id).nodes.push(projectMap.get(project.project_id));
        }
    });

    // Filtrer et retourner uniquement les éléments de niveau supérieur (sans parent_id ou avec parent_id inconnu)
    return Array.from(projectMap.values()).filter(project => !project.parent_id || !projectMap.has(project.parent_id));
}

class ArborescenceConteneur extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,

    }


    componentDidMount() {
        //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererListeProjetsMaintenance(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, [parseInt(this.props.project_id_url)]);
        } else if (this.props.filtre_projects_list_id.length > 0) {// envoie la requête de recupération de alarme si la liste de projets n'est pas vide
            this.props.reqRecupererListeProjetsMaintenance(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des alarmes_xy en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererListeProjetsMaintenance(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, [parseInt(this.props.project_id_url)]);
        } else if (JSON.stringify(prevProps.projects_list) !== JSON.stringify(this.props.projects_list)) {// envoie la requête de recupération de alarme si la liste de projets change entre temps
            this.setState({...this.state, projects_list: this.props.projects_list});
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.reqRecupererListeProjetsMaintenance(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id);
        }
    }

    //Fonction pour enregistrer les informations de la ligne sélectionné depuis le tableau de de rapports gabarits
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });

    //fonction pour refermer le contneur information lors d'une annulation de création
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Rapport gabarit non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }

    //fonction pour ouverture du conteneur information avec le formulaire de création
    creer = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {

        return (
            <Auxiliaire>
                <div className={[Tableau].join(" ")}>
                    <div className={[BarreOutil].join(" ")}>
                        <AttenteRequete request_type={requestType.recuperer_objet_projets_taux_de_pannes}
                                        str={checkMot("maj_rapports_generes_en_cours")}>
                            {this.props.toolsbar}
                            {/*bouton de rafraîchissement du tableau*/}
                            <Bouton key={"refresh-projets-list-maintenance-criticity-"+this.props.criticity_id_list}
                                    overText={checkMot("refresh")}
                                    type={type_btn.outil}
                                    rond={true}
                                    desactive={this.props.start_interval === null || this.props.end_interval === null || this.props.criticity_id_list.length === 0}
                                    cliquer={() => {
                                        this.props.reqRecupererListeProjetsMaintenance(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id)
                                    }}>
                                <VscRefresh size={15}/>
                            </Bouton>
                        </AttenteRequete>
                    </div>
                    <div className={[TableauBootstrap, S.ArbConteneur].join(" ")}>
                        <div>
                        {/*<div className={}>*/}

                            <TreeMenu
                                data={transformProjectsToTreeData(this.props.projects_list.filter(obj => this.props.filtre_projects_list_id.indexOf(obj.project_id) > -1 && obj))}
                                debounceTime={125}>
                                {({search, items}) => (
                                    <>
                                        {this.props.afficherBarreDeRecherche ? <input onChange={e => search(e.target.value)}
                                                                                      placeholder={checkMot("recherche")}/> : search("")}
                                        <ListGroup>
                                            {items.map((value, index) => (
                                                <ListItem
                                                    start_interval={this.props.start_interval}
                                                    end_interval={this.props.end_interval}
                                                    criticity_id_list={this.props.criticity_id_list}
                                                    types_id_list={this.props.types_id_list}
                                                    handleProjetCapteurDefaillant={this.props.handleProjetCapteurDefaillant}
                                                    project_id={items[index].key.split("/")[items[index].key.split("/").length - 1]}
                                                    {...value}/>
                                            ))}
                                        </ListGroup>
                                    </>
                                )}
                            </TreeMenu>
                        </div>

                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        projects_list: state.maintenance.projects_list,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        reqRecupererListeProjetsMaintenance: (start_interval, end_interval, types_id_list, criticity_id_list, projects_id) => dispatch(actions.reqRecupererListeProjetsMaintenance(start_interval, end_interval, types_id_list, criticity_id_list, projects_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArborescenceConteneur);
