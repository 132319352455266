import * as actionTypes from './actionTypes';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from "axios";
import * as actions from "./index";
import {store} from "../../index";
import CryptoJS from 'crypto-js';

export const intervalDeTempsDemandeInfoConnexionApresConnexion = 1440000;

const requetePost=(dispatch, requeteAPI)=>{
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios.post("", requeteAPI, {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"

        }
    })
        .then(res => {
            // console.log('envoi: ',JSON.parse(res.config.data));
            // console.log('reception: ',res);
            // if (res.Method == "OPTIONS") {
            //     config.WriteHeader(http.StatusOK)
            // }
            let titreAlerteSucces = "Requête api succès";
            let corpsAlerteSucces = "Requête api succès";
            let titreAlerteEchec = "Requête api échec";
            let corpsAlerteEchec = "Requête api échec";
            let succesRequete = {alerte() {}, actionReducteur() {}, fonctionSupplementaire() {}};
            let succesRequeteAvecErreur = {alerte() {}, actionReducteur() {}, fonctionSupplementaire() {}};
            let echecRequete = {alerte() {}, actionReducteur() {}, fonctionSupplementaire() {}};

            switch ( requeteAPI.request_type ) {
                case requestType.connexion:
                    titreAlerteSucces = "ApiSuccesTitreConnexion";
                    corpsAlerteSucces = "ApiSuccesCorpsConnexion";
                    titreAlerteEchec = "ApiEchecTitreConnexion";
                    corpsAlerteEchec = "ApiEchecCorpsConnexion";
                    if(res.data.user_id > 0 && res.data.user_id !== null && res.data.user_id !== undefined) {
                        localStorage.setItem('auth', JSON.stringify({"login_name":requeteAPI.login_name, "login_password":CryptoJS.SHA1(requeteAPI.login_password).toString()}));
                        succesRequete.actionReducteur = () => dispatch(enregistrerUtilisateur(res.data));
                        succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.lastname]));
                        succesRequete.fonctionSupplementaire= () => {
                            setTimeout(()=>actions.demandeInformationDeConnexion(dispatch), intervalDeTempsDemandeInfoConnexionApresConnexion);
                        }
                    }
                    if(res.data.errors.length > 0){
                        succesRequeteAvecErreur.actionReducteur = () => dispatch(enregistrerUtilisateur(res.data));
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteEchec, res.data.errors.length]));
                        succesRequeteAvecErreur.fonctionSupplementaire= () => {
                            setTimeout(()=>actions.demandeInformationDeConnexion(dispatch), intervalDeTempsDemandeInfoConnexionApresConnexion);
                            affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                        }
                    }
                    if(res.data.state === false){
                        // echecRequete.actionReducteur = () =>
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.deconnexion:
                    titreAlerteSucces = "ApiSuccesTitreDeconnexion";
                    corpsAlerteSucces = "ApiSuccesCorpsDeconnexion";
                    titreAlerteEchec = "ApiEchecTitreDeconnexion";
                    corpsAlerteEchec = "ApiEchecCorpsDeconnexion";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(actions.deconnexion());
                    succesRequete.fonctionSupplementaire = () => {
                        // reset l'url
                        localStorage.clear();
                        window.location.href = '/';

                    }

                    if(res.data.errors.length > 0){
                        succesRequeteAvecErreur.actionReducteur = () => dispatch(actions.deconnexion());
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                        succesRequeteAvecErreur.fonctionSupplementaire= () => {
                            // reset l'url
                            localStorage.clear();
                            window.location.href = '/';
                            affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                        }
                    }
                    if(res.data.state === false){
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.changer_son_mot_de_passe:
                    titreAlerteSucces = "ApiSuccesTitre_changer_son_mot_de_passe";
                    corpsAlerteSucces = "ApiSuccesCorps_changer_son_mot_de_passe";
                    titreAlerteEchec = "ApiEchecTitre_changer_son_mot_de_passe";
                    corpsAlerteEchec = "ApiEchecCorps_changer_son_mot_de_passe";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(validationChangementMotDePasse());

                    if(res.data.errors.length > 0){
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteSucces, res.data.errors.length]));
                        succesRequeteAvecErreur.fonctionSupplementaire= () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if(res.data.state === false){
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.demande_de_reinitialisation_du_mot_de_passe:
                    titreAlerteSucces = "ApiSuccesTitreDemande_de_reinitialisation_du_mot_de_passe";
                    corpsAlerteSucces = "ApiSuccesCorpsDemande_de_reinitialisation_du_mot_de_passe";
                    titreAlerteEchec = "ApiEchecTitreDemande_de_reinitialisation_du_mot_de_passe";
                    corpsAlerteEchec = "ApiEchecCorpsDemande_de_reinitialisation_du_mot_de_passe";

                    succesRequete.actionReducteur = () => dispatch(demandeReinitialisationMDPReussie());
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.email]));

                    if(res.data.errors.length > 0){
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteEchec, requeteAPI.email]));
                        succesRequeteAvecErreur.fonctionSupplementaire= () => {
                            affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                        }
                    }
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);

                    break;
                case requestType.validation_du_code_de_verification:
                    titreAlerteSucces = "ApiSuccesTitrevalidation_du_code_de_verification";
                    corpsAlerteSucces = "ApiSuccesCorpsvalidation_du_code_de_verification";
                    titreAlerteEchec = "ApiEchecTitrevalidation_du_code_de_verification";
                    corpsAlerteEchec = "ApiEchecCorpsvalidation_du_code_de_verification";

                    succesRequete.actionReducteur = () => dispatch(validationCodeVerificationReussie());
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));

                    if(res.data.errors.length > 0){
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces,[corpsAlerteEchec, requeteAPI.login_names]));
                        succesRequeteAvecErreur.fonctionSupplementaire= () => {
                            affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                        }
                    }
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);

                    break;
                case requestType.reinitialisation_du_mot_de_passe:
                    titreAlerteSucces = "ApiSuccesTitre_reinitialisation_du_mot_de_passe";
                    corpsAlerteSucces = "ApiSuccesCorps_reinitialisation_du_mot_de_passe";
                    titreAlerteEchec = "ApiEchecTitre_reinitialisation_du_mot_de_passe";
                    corpsAlerteEchec = "ApiEchecCorps_reinitialisation_du_mot_de_passe";

                    succesRequete.actionReducteur = () => dispatch(reinitialisationMDPReussie());
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));

                    if(res.data.errors.length > 0){
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, corpsAlerteEchec));
                        succesRequeteAvecErreur.fonctionSupplementaire= () => {
                            affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                        }
                    }
                    echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec,[corpsAlerteEchec, res.data.errors.length]));
                    echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);

                    break;
                default:
                    break;
            }
            if(res.data.state){
                if(res.data.errors.length === 0){
                    succesRequete.actionReducteur();
                    succesRequete.alerte();
                    succesRequete.fonctionSupplementaire();
                }else{
                    succesRequeteAvecErreur.actionReducteur();
                    succesRequeteAvecErreur.alerte();
                    succesRequeteAvecErreur.fonctionSupplementaire();
                }
            }else {
                echecRequete.actionReducteur();
                echecRequete.alerte();
                echecRequete.fonctionSupplementaire();
            }
        })
        .catch(() => {
            // console.log("Error API",requeteAPI.request_type,":",err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", [requeteAPI.request_type, err.toString()]]))
        })
        .finally(()=>dispatch(actions.finAttente(requeteAPI.request_type)));
}
const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) =>{
    if(res.data.errors !== undefined){
        for(let erreurKey= 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre,["ApiEchecErreursEnBoucle", {numAvertissement:erreurKey, idErreur:res.data.errors[erreurKey]}]));
        }
    }else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api","errors est undefine, pour une requête de connexion"));
    }
}


//[non authentifié] fonction permettant de se connecter
export const authentification = (idConnexion, motDePasse) => {
    return dispatch =>{
        requetePost(dispatch,{
            request_type: requestType.connexion,
            login_name: idConnexion,
            login_password: motDePasse
        });
    }
};

//[déjà authentifié] Groupe de fonction permettant de gérer les statuts d'authentification
export const demandeInformationDeConnexion = (dispatch) => {
    /**
     * Fonction récursive pour la requête d'information de connexion
     * @return une fonction dispatch - une fonction pour réaliser des action asynchrone, dispatch aussi des alertes pour agir sur le réducteur alerte.js
     *      tant que l'utilisateur est connécté sur la base de donné fait un appel récursive
     */
    const state = store.getState();
    const requeteAPI = {request_type: requestType.information_de_connexion}
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios.post("", {
            request_type: requeteAPI.request_type,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    ).then(res => {
        // console.log('envoi: ',JSON.parse(res.config.data));
        // console.log('reception: ',res);

        if(res.data.state){
            setTimeout(()=>actions.demandeInformationDeConnexion(dispatch), intervalDeTempsDemandeInfoConnexionApresConnexion);

            if(state.authentifie.user_id===null){//cas d'une nouvelle page ou du rafraichissement de la page
                //récupération des informations de l'utilisateur connecté
                dispatch(actions.reqRecupererUnUtilisateur(res.data.user_id));
            }
            else if(res.data.user_id!==state.authentifie.user_id){//cas où user_id de la session sur le serveur ne correspond pas à user_id local
                dispatch(actions.deconnexionAutomatique());//déconnexion partielle si l'utilisateur n'est pas le même que celui connecté sur le serveur
            }
        }else {
            dispatch(actions.deconnexionAutomatique());//déconnexion partielle si l'utilisateur n'est pas connecté sur le serveur
            affichageRetourErreurs(dispatch,"danger", res);
        }
    }).catch(err => {
            console.error(err)
        }).finally(()=>dispatch(actions.finAttente(requeteAPI.request_type)));
};
export const seDeconnecter = () => {
    // return {type:actionTypes.RE_CONNEXION, idConnexion:idConnexion, motDePasse:motDePasse};
    return dispatch =>{
        requetePost(dispatch,{
            request_type: requestType.deconnexion
        });
    }
};
export const deconnexionAutomatique = () => {
    return {type:actionTypes.DECONNEXION_AUTOMATIQUE};
};
export const deconnexion = () => {
    return {type:actionTypes.DECONNEXION};
};

//[déjà authentifié] Groupe de fonction permettant de gérer les informations de l'utilisateur connecté
export const reqChangerMDP = (mdpActuel, nouveauMDP, confirmationNouveauMDP) => {
    return dispatch => {
        requetePost(dispatch,{
            request_type: requestType.changer_son_mot_de_passe,
            login_password: mdpActuel,
            new_password: nouveauMDP,
            new_pass_confirm: confirmationNouveauMDP,
        });
    };
};

//[déjà authentifié] Groupe de fonction permettant de gérer l'interface de l'utilisateur (commutateur interface admin/client, filtre par projet_id)
export const commutateurInterfaceClient = () => {
    return {type:actionTypes.COMMUTER_INTERFACE_CLIENT};
};
export const commutateurInterfaceAdmin = () => {
    return {type:actionTypes.COMMUTER_INTERFACE_ADMIN};
};
export const gererFiltreParProjet = (filtre_projects_list_id) => {
    return {type:actionTypes.GERER_FILTRE_PAR_PROJET, filtre_projects_list_id:filtre_projects_list_id};
};

//[non authentifié] Groupe de fonction permettant de réinitialiser un mot de passe
export const demandeReinitialisationMDP = (email) => {
    return dispatch =>{
        requetePost(dispatch,{
            request_type: requestType.demande_de_reinitialisation_du_mot_de_passe,
            email: email
        });
    }
};
export const validationCodeVerification = (codeVerification) => {
    /**
     * envoie la requête de vérification du code de validation
     */
    return dispatch =>{
        requetePost(dispatch,{
            request_type: requestType.validation_du_code_de_verification,
            verification_code: codeVerification
        });
    }
};
export const reinitialisationMDP = (verification_code, new_password, new_password_confirm) => {
    /**
     * envoi la requête de réinitialisation de mot de passe (cas où l'utilisateur n'est pas connecté)
     */
    return dispatch =>{
        requetePost(dispatch,{
            request_type: requestType.reinitialisation_du_mot_de_passe,
            verification_code: verification_code,
            new_password:new_password,
            new_password_confirm:new_password_confirm,

        });
    }
};


const demandeReinitialisationMDPReussie = () => {
    return {type:actionTypes.DEMANDE_DE_REINITIALISATION_MDP_REUSSIE};
};
const validationCodeVerificationReussie = () => {
    return {type:actionTypes.VALIDATION_CODE_VERIFICATION_REUSSIE};
};
const reinitialisationMDPReussie = () => {
    return {type:actionTypes.REINITIALISATION_MDP_REUSSIE};
};
const validationChangementMotDePasse = () => {
    return {type:actionTypes.VALIDATION_CHANGEMENT_MOT_DE_PASSE};
};
export const enregistrerUtilisateur = (data) => {
    /**
     * Enregistre les informations de l'utilisateur après la connexion
     */
    return {
        type:actionTypes.DEBUT_AUTH,
        ...data
        // user_id: data.user_id,
        // lastname: data.lastname,
        // name: data.name,
        // privileges_list: data.privileges_list,
        // projects_list: data.projects_list,
        // favorite_projects_list_id: data.favorite_projects_list_id,
        // email:data.email,
        // phone_index:data.phone_index,
        // phone_number:data.phone_number,
        // company_name:data.company_name,
        // profil_image:data.profil_image
    };
};
