export const getProjectIDTree = (element) => {
    //récupère de manière récursive la liste des id de projet enfant
    if (element !== null) {
        if (element.nodes !== null) {
            var i;
            var result = [element.key];
            for (i = 0; i < element.nodes.length; i++) {
                result = [...result, ...getProjectIDTree(element.nodes[i])];
            }
            return result;
        } else if (element.key !== undefined) {
            return [element.key];
        }
    }
    return [];
}
