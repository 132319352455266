import React, {useState, useEffect} from "react";

import TableauxDeBord from "./TableauxDeBord/TableauxDeBord";
import Vues from "./Vues/Vues";
import Graphe from "./GrapheClient/Graphe";
import Alarmes from "./Alarmes/Alarmes";

import {checkMot} from "../../../ressources/functionJS/checkMot";

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import logoEntry from '../../../ressources/logoD.png';
import S from './OngletsClient.module.scss';
import Rapports from "./Rapports/Rapports";

function OngletsClient(props) {
    const [toggleState, setToggleState] = useState(checkMot("plans"));

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const [plansBool, plansBoolChange] = useState(checkMot(false));
    const [tableaux_de_bordBool, tableaux_de_bordBoolChange] = useState(checkMot(false));
    const [graphesBool, graphesBoolChange] = useState(checkMot(false));
    const [capteursBool, capteursBoolChange] = useState(checkMot(false));
    const [alarmesBool, alarmesBoolChange] = useState(checkMot(false));
    const [rapportsBool, rapportsBoolChange] = useState(checkMot(false));

    useEffect(() => {

        if (plansBool) {
            toggleTab(checkMot("plans"))
        } else if (tableaux_de_bordBool) {
            toggleTab(checkMot("tableaux_de_bord"))
        } else if (graphesBool) {
            toggleTab(checkMot("graphes"))
        } else if (alarmesBool) {
            toggleTab(checkMot("alarmes"))
        } else {
            toggleTab(checkMot("plans"))
        }
    }, [plansBool, tableaux_de_bordBool, graphesBool, capteursBool, alarmesBool, rapportsBool]);


    return (
        <div className={[S.container, S.fade_in_image].join(" ")}>
            <div className={S.bloc_tabs}>
            <span>
                     {
                         props.montrerMenu
                             ? null
                             : <div className={S.logo}>
                                 {/* <Logo/> */}
                                 <img alt="logo" src={logoEntry} className={S.logoSty}/>
                             </div>
                     }
                </span>
                {/*Onglets généraux*/}
                <button
                    className={toggleState === checkMot("plans") ? S.activeTabs : S.tabs}
                    onClick={() => toggleTab(checkMot("plans"))}
                >
                    {checkMot("plans")}
                </button>
                <button
                    className={toggleState === checkMot("tableaux_de_bord") ? S.activeTabs : S.tabs}
                    onClick={() => toggleTab(checkMot("tableaux_de_bord"))}
                >
                    {checkMot("tableaux_de_bord")}
                </button>
                <button
                    className={toggleState === checkMot("graphes") ? S.activeTabs : S.tabs}
                    onClick={() => toggleTab(checkMot("graphes"))}
                >
                    {checkMot("graphes")}
                </button>
                {/* <button
                    className={toggleState === checkMot("capteurs") ? S.activeTabs : S.tabs}
                    onClick={() => toggleTab(checkMot("capteurs"))}
                >
                    {checkMot("capteurs")}
                </button> */}
                <button
                    className={toggleState === checkMot("alarmes") ? S.activeTabs : S.tabs}
                    onClick={() => toggleTab(checkMot("alarmes"))}
                >
                    {checkMot("alarmes")}
                </button>
                <button
                    className={toggleState === checkMot("rapports") ? S.activeTabs : S.tabs}
                    onClick={() => toggleTab(checkMot("rapports"))}
                >
                    {checkMot("rapports")}
                </button>
            </div>
            {/*corps après sélections d'un onglet*/}
            <div className={S.contentTabs}>
                <div
                    className={toggleState === checkMot("plans") ? [S.content, S.activeContent].join(" ") : S.content}>
                    <Vues siLaListeAUneValeur={plansBoolChange}/>
                </div>
                <div
                    className={toggleState === checkMot("tableaux_de_bord") ? [S.content, S.activeContent].join(" ") : S.content}>
                    <TableauxDeBord siLaListeAUneValeur={tableaux_de_bordBoolChange}/>
                </div>
                <div
                    className={toggleState === checkMot("graphes") ? [S.content, S.activeContent].join(" ") : S.content}>
                    <Graphe siLaListeAUneValeur={graphesBoolChange}/>
                </div>
                {toggleState === checkMot("capteurs") && <div
                    className={toggleState === checkMot("capteurs") ? [S.content, S.activeContent].join(" ") : S.content}>
                    {/*<Capteur siLaListeAUneValeur={capteursBoolChange}}/>todo: liste des capteurs réduites*/}
                    <div
                        className={[s.alert, s.alert_dark].join(" ")}>{checkMot("vos_droits_actuels_ne_vous_permettent_pas_d_acceder_a_la_bibliotheque_de_capteurs_merci_de_bien_vouloir_contacter_votre_administrateur_argos")}</div>
                </div>}
                {toggleState === checkMot("alarmes") && <div
                    className={toggleState === checkMot("alarmes") ? [S.content, S.activeContent].join(" ") : S.content}>
                    <Alarmes siLaListeAUneValeur={alarmesBoolChange}/>
                    {/*<div className={[s.alert,s.alert_dark].join(" ")}>{checkMot("vos_droits_actuels_ne_vous_permettent_pas_d_acceder_au_module_d_alarmes_merci_de_bien_vouloir_contacter_votre_administrateur_argos")}</div>*/}
                </div>}
                {toggleState === checkMot("rapports") && <div
                    className={toggleState === checkMot("rapports") ? [S.content, S.activeContent].join(" ") : S.content}>
                    <Rapports siLaListeAUneValeur={rapportsBoolChange}/>
                    {/*<div className={[s.alert,s.alert_dark].join(" ")}>{checkMot("vos_droits_actuels_ne_vous_permettent_pas_d_acceder_a_la_bibliotheque_de_rapports_merci_de_bien_vouloir_contacter_votre_administrateur_argos")}</div>*/}
                </div>}
            </div>
        </div>
    );
}

export default OngletsClient;