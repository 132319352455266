import React, {Component} from 'react';

import GroupeEmail from "../../composant/UI/GroupeEmail/GroupeEmail";
import Popup from "../../composant/UI/Popup/Popup";

import {checkMot} from "../../ressources/functionJS/checkMot";
import * as type_btn from "../../ressources/glossaires/glossaire_type_de_bouton";

import {VscListUnordered} from "react-icons/vsc";
import S from './AlarmeAdmin.module.scss';
import {MdGraphicEq} from "react-icons/md";
import Gabarit from "./Gabarit/Gabarit";
import TableauAlarmeXY from "./TableauAlarmeXY/TableauAlarmeXY";
import Auxiliaire from "../../hoc/Auxiliaire/Auxiliaire";
import Bouton from "../UI/Bouton/Bouton";
import {BiArrowFromLeft, BiArrowFromRight} from "react-icons/bi";
import ConteneurInformation from "../ConteneurInformation/ConteneurInformation";
import * as indexConteneurInfo from "../../ressources/glossaires/glossaire_conteneur_info";
import FormulaireAlarmeXY from "./ProfilAlarmeXY/FormulaireAlarmeXY/FormulaireAlarmeXY";
import ProfilAlarmeXY from "./ProfilAlarmeXY/ProfilAlarmeXY";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import * as privilege from "../../ressources/glossaires/glossaire_privileges";
import AccesRestreins from "../../hoc/AccesRestreins/AccesRestreins";

class AlarmeAdmin extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        indexConteneurInfo: null,
        montrerInfoFull: false,

    }

    //Fonction pour enregistrer les informations de la ligne sélectionné depuis le tableau de Alarmes
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }

    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });

    //fonction pour refermer le contneur information lors d'une annulation de création
    commutateurAnnuler = () => {
        // this.props.ajouterAlerte("warning", "Annuler","Alarmes non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }

    //fonction pour ouverture du conteneur information avec le formulaire de création
    creerVue = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        let GroupeEmailVar =
            <Popup hoverText={checkMot("groupe_email")} typeBoutonAffichage={type_btn.outil} ajoutOverlay={S.Overlay}
                   icone={<VscListUnordered size={15}/>}>
                <GroupeEmail/>
            </Popup>;
        let GabaritVar = <Popup hoverText={checkMot("gabarit")} typeBoutonAffichage={type_btn.outil}
                                ajoutOverlay={S.Overlay} icone={<MdGraphicEq size={15}/>}>
            <Gabarit/>
        </Popup>


        //initialisation des varable de style
        let styleConteneurCentralGauche = [S.ConteneurCentralGauche]
        let styleConteneurCentralDroite = [S.ConteneurCentralDroite]
        let commutateurClasses = [S.CommutateurInfo];

        if (this.state.montrerInfo) {
            //style pour un conteneur info ouvert
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
            // styleConteneurCentralDroite.push(S.ConteneurCentralGaucheOuvert);
        } else {
            //style pour un conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        if (this.state.montrerInfo && this.state.montrerInfoFull) {
            //conteneur info ouvert & plein écran
            //on met la largeur à 100% du conteneur info
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
            //on réduit à zéro la largeur du conteneur central
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
        } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
            //conteneur info ouvert & !plein écran
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
        } else {
            //conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }

        return (
            <Auxiliaire>
                <div className={[S.ConteneurVue].join(" ")}>
                    <div className={styleConteneurCentralGauche.join(" ")}>
                        {/*Tableau de vue*/}
                        <TableauAlarmeXY projetSelectione={this.ligneSelectione} creer={this.creerVue}>
                            <AccesRestreins
                                listNumPrivilege={[privilege.creer_des_gabarits, privilege.modifier_des_gabarits, privilege.supprimer_des_gabarits]}>
                                {GabaritVar}
                            </AccesRestreins>
                            {GroupeEmailVar}
                        </TableauAlarmeXY>
                    </div>
                    <div className={S.barConteneur}>
                        <div className={S.barOnglet}>
                            <div className={S.titreOnglet}> {checkMot("alarmes_std")} </div>
                            <div className={S.titreOnglet}> {checkMot("alarmes_xy")} </div>
                        </div>
                    </div>
                    <div className={styleConteneurCentralDroite.join(" ")}>
                        {/* bouton plein ferme conteneur  info */}
                        <Bouton ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
                                cliquer={this.commutateurInfoCreer} rond={true} type={type_btn.danger}>
                            <BiArrowFromLeft size={15}/>
                        </Bouton>
                        {/* bouton plein écran */}
                        <Bouton ajoutClass={S.expand} cliquer={this.commutateurInfoFull} rond={true}
                                type={type_btn.danger}>
                            <BiArrowFromRight size={15}/>
                        </Bouton>
                        <ConteneurInformation
                            // information du type de conteneur information à afficher
                            selectionInfo={this.state.indexConteneurInfo}
                            // formulaire de création
                            ajouterForm={<FormulaireAlarmeXY ajouterAlerte={this.props.ajouterAlerte}
                                                        annuler={this.commutateurAnnuler}/>}
                            // formulaire de profil vue
                            profil={<ProfilAlarmeXY infoAlarmeXY={this.state.parametresSelectione}
                                               reqModifier={this.props.reqModifier}
                                               reqSupprimer={this.props.reqSupprimer}/>}
                        />
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        reqSupprimer: (idAlarmeXY) => dispatch(actions.reqSupprimerAlarmesXY(idAlarmeXY))
    };
};

export default connect(null, mapDispatchToProps)(AlarmeAdmin);
