import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

export const initialState = {
    capteursListe: [],//todo liste vide de base
    sensors_short_list: [],//todo liste vide de base
    sensor_types_list: [],//todo liste vide de base
    emailgroups_list: [],//todo liste vide de base
    import_filters_list: [],//todo liste vide de base
    modificationListe:[],
};

const enregistrerListeCapteurs = (state, action) => {
    return updateObject( state, {capteursListe: action.capteursListe});
}
const enregistrerListeCapteursReduites = (state, action) => {
    return updateObject( state, {sensors_short_list: action.sensors_short_list});
}
const enregistrerListeSensorsType = (state, action) => {
    return updateObject( state, {sensor_types_list: action.sensor_types_list});
}
const enregistrerListeEmailgroups = (state, action) => {
    return updateObject( state, {emailgroups_list: action.emailgroups_list});
}
const enregistrerListeImportFilters = (state, action) => {
    return updateObject( state, {import_filters_list: action.import_filters_list});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ENREGISTRER_LISTE_CAPTEURS: return enregistrerListeCapteurs(state, action);
        case actionTypes.ENREGISTRER_LISTE_CAPTEURS_REDUITES: return enregistrerListeCapteursReduites(state, action);
        case actionTypes.ENREGISTRER_LISTE_SENSORS_TYPES: return enregistrerListeSensorsType(state, action);
        case actionTypes.ENREGISTRER_LISTE_EMAIL_GROUPS: return enregistrerListeEmailgroups(state, action);
        case actionTypes.ENREGISTRER_LISTE_IMPORT_FILTERS: return enregistrerListeImportFilters(state, action);
        default:
            return state;
    }
};

export default reducer;
