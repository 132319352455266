import React, {Component} from 'react';
import {connect} from "react-redux";

import FormulaireSelectionProjet from "../../../../Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet";
import FormulaireSelectionDateHeureUnix
    from "../../../../Graphe/ProfilGraphe/FormulaireGraphe/FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix";

import * as actions from "../../../../../store/actions";
import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../../hoc/AttenteRequete/AttenteRequete";
import * as requestType from '../../../../../ressources/glossaires/glossaire_requetes';
import {glossaire_alarm_level} from "../../../../../ressources/glossaires/glossaire_niveau_alarme";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";

import * as S from "../../../../Graphe/ProfilGraphe/FormulaireGraphe/FormulaireGraphe.module.scss";
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import {ConteneurInfo, Formulaire, label, btF} from './FormulaireGabarit.module.scss';
import {RiMailSendFill} from "react-icons/all";

class FormulaireGabarit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project_id: null,
            selected_project_id: null,
            template_name: '',
            start: null,
            end: null,
            private: 0,
            alarm_level: 2,
            template: "",
        };
    }

    componentDidMount() {
        if (this.props.preconfigurerGabarit !== undefined) {
            this.setState({
                ...this.props.preconfigurerGabarit,
                template: JSON.stringify(this.props.preconfigurerGabarit.template)
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.preconfigurerGabarit !== undefined && this.props.preconfigurerGabarit.template_id !== prevProps.preconfigurerGabarit.template_id) {
            this.setState({
                ...this.props.preconfigurerGabarit,
                template: JSON.stringify(this.props.preconfigurerGabarit.template)
            })
        }
    }

    gererEtatDeCibleName = (event) => {
        this.setState({...this.state, [event.target.name]: event.target.value});
    };
    gererTemplate = (event) => {
        this.setState({...this.state, template: event.target.value});
    };
    gererProjetId = (projetId) => {
        this.setState({...this.state, project_id: projetId, selected_project_id: projetId});
    };
    reqEnvoi = () => {
        /**
         * envoie la requête de création ou modification de gabarit
         * @type {{template_name: (string|*), list_emails: ([]|*), project_id: number}}
         */
        let formulaireGabarit = {//création de l'objet à envoyer
            project_id: parseInt(this.state.project_id),
            template_name: this.state.template_name,
            template_id: this.state.template_id,
            start: this.state.start,
            end: this.state.end,
            private: parseInt(this.state.private),
            alarm_level: parseInt(this.state.alarm_level),
            template: JSON.parse(this.state.template),
        }
        if (this.state.template_id === undefined) {
            //envoi la requête de création de gabarit si template_id n'est pas défini
            this.props.reqAjouter(formulaireGabarit)
        } else {
            //ajoute le template_id et envoie la requête de modification
            formulaireGabarit["template_id"] = this.state.template_id
            this.props.reqModifier(formulaireGabarit)
        }
    };

    gererDebut_time_interval = (unix) => this.setState({
        ...this.state, start: unix
    });
    gererFin_time_interval = (unix) => this.setState({
        ...this.state, end: unix
    });

    render() {

        let affichageBouton;
        if (!this.props.affichageProfil) {//cache les boutons d'envoie de req si c'est le mode profil
            if (this.props.preconfigurerGabarit !== undefined) {
                //formulaire de modification
                affichageBouton = <Auxiliaire>
                    <AttenteRequete request_type={requestType.modifier_un_gabarit}
                                    str={checkMot("modification_en_cours")}>
                        {/*bouton d'envoi de la requête de modification de gabarit*/}
                        <button className={btF}
                                onClick={this.reqEnvoi}>
                            <RiMailSendFill size={24}/> {checkMot("envoi_modification")}</button>
                    </AttenteRequete>

                </Auxiliaire>
            } else {
                //formulaire de création
                affichageBouton = <Auxiliaire>
                    <AttenteRequete request_type={requestType.creer_un_gabarit}
                                    str={checkMot("creation_en_cours")}>
                        {/*bouton d'envoi de la requête de modification de gabarit*/}
                        <button className={btF}
                                onClick={this.reqEnvoi}>
                            <RiMailSendFill size={24}/> {checkMot("envoi_creation")}</button>
                    </AttenteRequete>
                </Auxiliaire>
            }
        }
        return (
            <Auxiliaire>
                <div className={[ConteneurInfo].join(" ")}>
                    <div className={[s.container, s.border, s.text_left, Formulaire].join(" ")}>
                        <div>

                            <div className="radio">
                                <label onClick={() => this.setState({project_id: null})}>
                                    <input type="radio" checked={this.state.project_id === null}/>
                                    {checkMot("acces_libre")}
                                </label>
                            </div>
                            <div className="radio" >
                                <label onClick={() => this.setState({project_id: this.state.selected_project_id})}>
                                    <input type="radio" checked={this.state.project_id !== null}/>
                                    <FormulaireSelectionProjet affichageProfil={this.props.affichageProfil}
                                                               gererProjetId={this.gererProjetId}
                                                               preconfigurerProjetId={this.state.project_id}/>{/**FIN project_id**/}
                                </label>
                            </div>
                        </div>
                        <div className={s.form_group}>

                            <label className={[label].join(" ")} htmlFor="inlineFormCustomSelect">
                                {checkMot("template_name")}
                            </label>
                            <div className={[s.form_row, s.align_items_center].join(" ")}>
                                <div className={s.col_12}>
                                    <input
                                        type="text"
                                        pattern="[0-9a-zA-Z._%+-\\s ]+"
                                        className={[s.form_control, s.mb_2].join(" ")}
                                        name="template_name"
                                        placeholder="template_name"
                                        value={this.state.template_name}
                                        disabled={this.props.affichageProfil}
                                        onChange={(event) => this.gererEtatDeCibleName(event)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/**template_name**/}

                        <div className={s.form_group}>{/**DEBUT time_interval**/}
                            <div className={[s.form_row, s.align_items_center].join(" ")}>
                                <span
                                    className={s.col_12}>{checkMot("Interval de temps sur lequel le gabarit s'applique [début, fin]")}</span>
                                <div className={s.col_6}>
                                    <FormulaireSelectionDateHeureUnix value={this.state.start}
                                                                      gereUnixDate={this.gererDebut_time_interval}
                                                                      disabled={this.props.affichageProfil === true}/>
                                </div>
                                <div className={s.col_6}>
                                    <FormulaireSelectionDateHeureUnix value={this.state.end}
                                                                      gereUnixDate={this.gererFin_time_interval}
                                                                      disabled={this.props.affichageProfil === true}/>
                                </div>
                            </div>
                        </div>
                        {/**FIN time_interval**/}

                        {/**DEBUT private**/}
                        <div className={[s.form_row, S.heder].join(" ")}>
                            <div className={s.col_12}>
                                <label className={S.stil2}>{checkMot("acces_prive")}</label>
                                <select
                                    onChange={(event) => this.gererEtatDeCibleName(event)}
                                    name="private"
                                    defaultValue={this.state.private}
                                    disabled={this.props.affichageProfil === true}
                                    value={this.state.private}
                                    className={[s.custom_select, S.stile].join(" ")}>
                                    <option value={1}>{checkMot("prive")}</option>
                                    <option value={0}>{checkMot("publique")}</option>
                                </select>
                            </div>
                        </div>
                        {/**FIN private**/}
                        {/**DEBUT alarm_level**/}
                        <div className={[s.form_row, S.heder].join(" ")}>
                            <div className={s.col_12}>
                                <label className={S.stil2}>{checkMot("alarm_level")}</label>
                                <select
                                    onChange={(event) => this.gererEtatDeCibleName(event)}
                                    name="alarm_level"
                                    defaultValue={this.state.alarm_level}
                                    disabled={this.props.affichageProfil === true}
                                    value={this.state.alarm_level}
                                    className={[s.custom_select, S.stile].join(" ")}>
                                    {Object.keys(glossaire_alarm_level).map(key => {
                                        if (parseInt(key) !== 9) { // omet 9: "Watchdog"
                                            return <option value={key}>{checkMot(glossaire_alarm_level[key])}</option>
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        {/**FIN alarm_level**/}
                        <div className={[s.form_row, S.heder].join(" ")}>
                            <label className={S.stil2}>{checkMot("template")}</label>
                            <textarea
                                className={[s.form_control, S.textarea].join(" ")}
                                placeholder={checkMot("template")}
                                name={"template"}
                                value={this.state.template}
                                disabled={this.props.affichageProfil === true}
                                onChange={(event) => this.gererTemplate(event)}/>
                        </div>
                        {/**FIN template**/}
                        {affichageBouton}
                    </div>
                </div>
            </Auxiliaire>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        reqAjouter: (formulaireGabarit) => dispatch(actions.reqCreerGabarit(formulaireGabarit)),
        reqModifier: (idGabarit) => dispatch(actions.reqModifierGabarit(idGabarit)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireGabarit);
