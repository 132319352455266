import React, {Component} from 'react';

import BibliothequePlotly from "../../../Graphe/AffichageGraphe/BibliothequePlotly/BibliothequePlotly";

// import * as S from './ProgresseBar.module.scss';

class ProgresseBar extends Component {
    /**
     * @property [value] : donnée de progression
     * @property [units] : unité de la donnée
     * @property [viewobj_json_content] : json de l'objet, contenant des informations de style voir ci-dessous
     * Objet json de référence lié à l'objet
        {
          "type_de_representation": 6,
          "displayModeBar": false,
          "distanceFinale": 60,
          "margeFinale": 0,
          "position_in_sensor": 1,
          "layout": {
            "title": {
              "text": ""
            },
            "showlegend": false,
            "paper_bgcolor": "rgba(0,0,0,0)",
            "plot_bgcolor": "rgba(0,0,0,0)",
            "height": 200,
            "margin": {
              "b": "35",
              "l": "50",
              "t": "20",
              "r": "5"
            }
          },
          "annotationPourcentage": {
            "font": { "size": 40 },
            "showarrow": false,
            "align": "center",
            "xanchor": "left",
            "x": 0.465,
            "y": 0.1,
            "xref": "paper",
            "yref": "paper"
          },
          "gaugeStep": [
            {
              "range": [ 5, 40 ],
              "color": "gray"
            }
          ]
        }
     * @returns {JSX.Element}: affichage de la progression d'une composante de capteur sur une vue
     */
    render() {
        // données d'affichage de la bar de progression
        let data = [
            {
                value: this.props.value,// valeur de la données en props
                type: "indicator",//type d'affichage indicateur [indicator]
                mode: "gauge+number",//affichage de la bar de progression [gauge] et de la donnée numérique [number]
                title: {text: "Progression", align: "left", size: 54*this.props.ratio},
                number: {
                    // font: {size: "200px"},//taille de la police
                    prefix: "<b style='font-size: 500px'>"+(this.props.value / parseInt(this.props.viewobj_json_content.distanceFinale) * 100).toPrecision(2)+"%</b><br><br><br><b style='font-size: 300px'>",//affichage d'un saut de ligne devant la valeur numérique pour afficher le pourcentage au dessus
                    suffix: " " + this.props.units+"</b>"// valeur suivie d'un espace et de l'unité
                },
                gauge: {
                    axis: {range: [0, parseInt(this.props.viewobj_json_content.distanceFinale) + parseInt(this.props.viewobj_json_content.margeFinale)]}, //distance finale arrondie pour voir le trait rouge final
                    steps: [
                        ...this.props.viewobj_json_content.gaugeStep !== undefined ? this.props.viewobj_json_content.gaugeStep : []//ajout de zone de couleur, voir exemple de la clé [gaugeStep]
                    ],
                    threshold: {// affichage de la distance finale via une ligne rouge sur la bar de progression
                        line: {color: "red", width: 4},//couleur et longueur de la ligne
                        thickness: 0.75,//épaisseur de la ligne
                        value: parseInt(this.props.viewobj_json_content.distanceFinale)//valeur de la distance finale
                    }
                },
            }
        ];
        // données d'affichage du fond de plan
        let layout = {
            ...this.props.viewobj_json_content.layout,// ajout du layout de l'objet json
            height: this.props.viewobj_json_content.layout.height*this.props.ratio,
            width: this.props.viewobj_json_content.layout.width*this.props.ratio,
            // annotations: [
            //     {// affichage du pourcentage de la progression de la donnée en fonction de la distance finale
            //         text: (this.props.value / parseInt(this.props.viewobj_json_content.distanceFinale) * 100).toPrecision(2) + "%",//valeur de l'affichage en pourcent
            //         font: {size: 40*this.props.ratio},//taille de la police
            //         align: 'center',// affichage du text centré
            //         x: 0.455,//coordonnée abscisse entre sur une échelle de 0 à 1 de gauche à droite
            //         y: 0.455,//coordonnée ordonnée entre sur une échelle de 0 à 1 de bas en haut
            //         xref: 'paper',//référence d'affichage abscisse ("paper"->trame de fond)
            //         yref: 'paper',//référence d'affichage ordonnée ("paper"->trame de fond)
            //         showArrow: false,
            //         ...this.props.viewobj_json_content.annotationPourcentage !== undefined ? this.props.viewobj_json_content.annotationPourcentage : {}// ajout de l'annotation du pourcentage de l'objet json
            //     },
            //     ...this.props.viewobj_json_content.annotations !== undefined ? this.props.viewobj_json_content.annotations : []// ajout des annotations de l'objet json
            // ]
        };
        return <BibliothequePlotly
            data={data}
            layout={layout}
            viewobj_json_content={this.props.viewobj_json_content}
            modeBar={false}
        />
    }
}

export default ProgresseBar;
