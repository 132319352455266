import React, {Component} from 'react';
import AttenteRequete from "../../../../../../../hoc/AttenteRequete/AttenteRequete";
import * as requestType from "../../../../../../../ressources/glossaires/glossaire_requetes";
import {checkMot} from "../../../../../../../ressources/functionJS/checkMot";
import BibliothequePlotly from "../../../../../../Graphe/AffichageGraphe/BibliothequePlotly/BibliothequePlotly";
// import {convertiUtsToDate} from "../../../../../../Graphe/AffichageGraphe/GrapheXT/GrapheXT";
import {jsDictionaryEquals} from "../../../../../../../ressources/functionJS/jsDictionaryEquals";
import * as actions from "../../../../../../../store/actions";
import {connect} from "react-redux";
function convertiUtsToDate(utsArray) {
    return utsArray.map(uts => {
        const date = new Date(uts * 1000); // Convertir UTS en millisecondes
        date.setHours(0, 0, 0, 0); // Ajuster à minuit
        return date;
    });
}

function convertProjectData(projects) {
        return projects.map(project => {
            return {
                "name": project.project_name,
                "yaxis": "y1", // ou "y2", selon la logique de votre application
                "type": "bar",
                "mode": "lines",
                "x": convertiUtsToDate(project.fails_list.time),
                "y": project.fails_list.rates
            };
        });
    }

const layout = {
    title: "Taux de pannes par jour ",
    xaxis:{
        tickformat: '%d %b %Y',
        tickmode: 'linear',
        dtick: 86400000
    },
    yaxis:{
        title:"Taux de pannes [%]",
    }
};
class GrapheTauxDePannes extends Component {

    componentDidMount() {
        //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererLeTauxDePannesParJour(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, [parseInt(this.props.project_id_url)]);
        } else if (this.props.filtre_projects_list_id.length > 0) {// envoie la requête de recupération de alarme si la liste de projets n'est pas vide
            this.props.reqRecupererLeTauxDePannesParJour(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des alarmes_xy en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererLeTauxDePannesParJour(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, [parseInt(this.props.project_id_url)]);
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.reqRecupererLeTauxDePannesParJour(this.props.start_interval, this.props.end_interval, this.props.types_id_list, this.props.criticity_id_list, this.props.filtre_projects_list_id);
        }
    }


    render(){
        return <AttenteRequete request_type={requestType.recuperation_des_donnees_d_un_graphe} str={checkMot("en_cour_de_reception_des_donnees_du_graphe")} >
            <BibliothequePlotly
                data={convertProjectData(this.props.donnees_pannes)}
                layout={layout}
                modeBar={true}
            />
        </AttenteRequete>
    }
}
const mapStateToProps = state => {
    return {
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        donnees_pannes: state.maintenance.donnees_pannes,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        reqRecupererLeTauxDePannesParJour: (start_interval, end_interval, types_id_list, criticity_id_list, projects_id) => dispatch(actions.reqRecupererLeTauxDePannesParJour(start_interval, end_interval, types_id_list, criticity_id_list, projects_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GrapheTauxDePannes);