import * as actionTypes from './actionTypes';

export const enregistrerProjectIdUrl = (project_id) => {
    /**
     * Enregistre l'information de l'id de projet de l'Url
     */
    return {
        type: actionTypes.ENREGISTRER_PROJECT_ID_URL,
        project_id_url: project_id,
    };
};
export const enregistrerTypeObjetAffichage = (type_objet_afficher) => {
    /**
     * Enregistre l'information de l'objet à afficher
     */
    return {
        type: actionTypes.ENREGISTRER_TYPE_OBJET_AFFICHAGE,
        type_objet_affichage: type_objet_afficher,
    };
};
export const enregistrerIDObjetAffichage = (objet_id) => {
    /**
     * Enregistre l'information de l'id de l'objet à afficher
     */
    return {
        type: actionTypes.ENREGISTRER_ID_OBJET_AFFICHAGE,
        ID_objet_affichage: objet_id,
    };
};
export const enregistrerTempsDeDefilement = (tempsDeDefilement) => {
    /**
     * Enregistre l'information du temps de défilement du diaporama de tableau de bord
     */
    return {
        type: actionTypes.ENREGISTRER_TEMPS_DE_DEFILEMENT,
        tempsDeDefilement: tempsDeDefilement,
    };
};
export const enregistrerArborescenceProjets = (arborescence_projets) => {
    /**
     * Enregistre l'information de l'id de projet de l'Url
     */
    return {
        type: actionTypes.ENREGISTRER_ARBORESCENCE_PROJECTS,
        arborescence_projets: arborescence_projets,
    };
};
export const ajoutConteurVueMarkers = (nbMarkers) => {
    /**
     * Ajout nbMarkers au conteurs de markers des vues ouvertes
     */
    return {
        type: actionTypes.AJOUT_CONTEUR_VUE_MARKERS,
        nbMarkers: nbMarkers,
    };
};
