import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import axios from "axios";
import * as actions from "./index";
import * as actionTypes from './actionTypes';
import {store} from "../../index";

const affichageRetourErreurs = (dispatch, typeAlerte, titre, res) => {
    if (res.data.errors !== undefined) {
        for (let erreurKey = 0; erreurKey < res.data.errors.length; erreurKey++) {
            dispatch(actions.ajouterAlerte(typeAlerte, titre, ["ApiEchecErreursEnBoucle", {
                numAvertissement: erreurKey,
                idErreur: res.data.errors[erreurKey]
            }]));
        }
    } else {
        dispatch(actions.ajouterAlerte(typeAlerte, "Erreur retour api", "errors est undefine, pour une requête de connexion"));
    }
}
const requetePost = (dispatch, requeteAPI, content_type) => {
    /**
     * gère l'envoi du type de requête et du retour de l'api
     * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
     * @param requeteAPI - permet de rendre la requête à l'API
     * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
     */

    const state = store.getState();
    dispatch(actions.debutAttente(requeteAPI.request_type));
    axios.post("", requeteAPI, {
        headers: {
            'Content-Type': content_type===undefined?'application/json':content_type
        }
    })
        .then(res => {
            console.log('envoi: ', JSON.parse(res.config.data));
            console.log('reception: ', res);
            let titreAlerteSucces = "Requête api succès";
            let corpsAlerteSucces = "Requête api succès";
            let titreAlerteEchec = "Requête api échec";
            let corpsAlerteEchec = "Requête api échec";
            let succesRequete = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            let succesRequeteAvecErreur = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            let echecRequete = {
                alerte() {
                }, actionReducteur() {
                }, fonctionSupplementaire() {
                }
            };
            switch (requeteAPI.request_type) {
                case requestType.recuperer_la_liste_des_rapports_publies:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_rapports_publies";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_rapports_publies";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_rapports_publies";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_rapports_publies";
                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeRapportsPublies(res.data));
                    break;
                case requestType.recuperer_la_liste_des_rapports_gabarits:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_rapports_gabarits";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_rapports_gabarits";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_rapports_gabarits";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_rapports_gabarits";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeRapportsGabarits(res.data));
                    break;
                case requestType.recuperer_les_configurations_d_un_rapport_gabarit:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_les_configurations_d_un_rapport_gabarit";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_les_configurations_d_un_rapport_gabarit";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_les_configurations_d_un_rapport_gabarit";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_les_configurations_d_un_rapport_gabarit";

                    succesRequete.actionReducteur = () => dispatch(enregistrerRapportGabarit(res.data));
                    break;
                case requestType.creer_un_rapport_gabarit:
                    titreAlerteSucces = "ApiSuccesTitre_creer_un_rapport_gabarit";
                    corpsAlerteSucces = "ApiSuccesCorps_creer_un_rapport_gabarit";
                    titreAlerteEchec = "ApiEchecTitre_creer_un_rapport_gabarit";
                    corpsAlerteEchec = "ApiEchecCorps_creer_un_rapport_gabarit";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, res.data.email_group_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, res.data.email_group_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.modifier_un_rapport_gabarit:
                    titreAlerteSucces = "ApiSuccesTitre_modifier_un_rapport_gabarit";
                    corpsAlerteSucces = "ApiSuccesCorps_modifier_un_rapport_gabarit";
                    titreAlerteEchec = "ApiEchecTitre_modifier_un_rapport_gabarit";
                    corpsAlerteEchec = "ApiEchecCorps_modifier_un_rapport_gabarit";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.graph_id]));

                    if (res.data.errors.length > 0) {
                        succesRequeteAvecErreur.alerte = () => dispatch(actions.ajouterAlerte("warning", titreAlerteSucces, [corpsAlerteSucces, requeteAPI.graph_id]));
                        succesRequeteAvecErreur.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "warning", titreAlerteSucces, res);
                    }
                    if (res.data.state === false) {
                        echecRequete.alerte = () => dispatch(actions.ajouterAlerte("danger", titreAlerteEchec, [corpsAlerteEchec, res.data.errors.length]));
                        echecRequete.fonctionSupplementaire = () => affichageRetourErreurs(dispatch, "danger", titreAlerteEchec, res);
                    }
                    break;
                case requestType.recuperer_la_liste_des_rapports_generes:
                    titreAlerteSucces = "ApiSuccesTitre_recuperer_la_liste_des_rapports_generes";
                    corpsAlerteSucces = "ApiSuccesCorps_recuperer_la_liste_des_rapports_generes";
                    titreAlerteEchec = "ApiEchecTitre_recuperer_la_liste_des_rapports_generes";
                    corpsAlerteEchec = "ApiEchecCorps_recuperer_la_liste_des_rapports_generes";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeRapportsGeneres(res.data));
                    break;
                case requestType.publier_un_rapport_genere:
                    titreAlerteSucces = "ApiSuccesTitre_publier_un_rapport_genere";
                    corpsAlerteSucces = "ApiSuccesCorps_publier_un_rapport_genere";
                    titreAlerteEchec = "ApiEchecTitre_publier_un_rapport_genere";
                    corpsAlerteEchec = "ApiEchecCorps_publier_un_rapport_genere";

                    succesRequete.alerte = () => dispatch(actions.ajouterAlerte("success", titreAlerteSucces, corpsAlerteSucces));
                    succesRequete.actionReducteur = () => dispatch(enregistrerListeRapportsGeneres(res.data));
                    break;
                default:
                    break;
            }
            if (res.data.state) {
                if (res.data.errors.length === 0) {
                    succesRequete.actionReducteur();
                    succesRequete.alerte();
                    succesRequete.fonctionSupplementaire();
                } else {
                    succesRequeteAvecErreur.actionReducteur();
                    succesRequeteAvecErreur.alerte();
                    succesRequeteAvecErreur.fonctionSupplementaire();
                }
            } else {
                echecRequete.actionReducteur();
                echecRequete.alerte();
                echecRequete.fonctionSupplementaire();
            }
            if (requeteAPI.request_type === requestType.confirmer_une_alarme) {
                dispatch(actions.reqRecupererListeAlarmes(state.interfaceClient.project_id_url));
            }
            if (requeteAPI.request_type === requestType.creer_un_groupe_d_email || requeteAPI.request_type === requestType.modifier_un_groupe_d_email || requeteAPI.request_type === requestType.supprimer_un_groupe_d_email) {
                dispatch(actions.reqListeGroupesEmails(state.authentifie.filtre_projects_list_id));
            }
            if (requeteAPI.request_type === requestType.creer_un_gabarit || requeteAPI.request_type === requestType.modifier_un_gabarit || requeteAPI.request_type === requestType.supprimer_un_gabarit) {
                dispatch(actions.reqListeGabarit(state.authentifie.filtre_projects_list_id));
            }
            if (requeteAPI.request_type === requestType.configurer_des_alarmes_xy || requeteAPI.request_type === requestType.modifier_des_alarmes_xy || requeteAPI.request_type === requestType.supprimer_une_configuration_d_alarme_xy) {
                dispatch(actions.reqListeAlarmesXY(state.authentifie.filtre_projects_list_id));
            }
        })
        .catch(err => {
            console.log("Error API", requeteAPI.request_type, ":", err)
            // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
        })
        .finally(() => dispatch(actions.finAttente(requeteAPI.request_type)));
}

export const reqRecupererListeRapportsPublies = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_rapports_publies,
            list_project_id: listeIdProjets
        });
    };
};
export const reqRecupererListeRapportsGabarits = (listeIdProjets) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_rapports_gabarits,
            list_project_id: listeIdProjets
        });
    };
};
export const reqCreerRapportGabarit = (formulaireRapportGabarit) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.creer_un_rapport_gabarit,
            ...formulaireRapportGabarit,
        });
    };
};
export const reqModifierRapportGabarit = (formulaireRapportGabarit) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.modifier_un_rapport_gabarit,
            ...formulaireRapportGabarit,
        });
    };
};
export const reqSupprimerRapportGabarit = (idRapportGabarit) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_un_rapport_gabarit,
            template_report_id: idRapportGabarit,

        });
    };
};
export const reqGenererRapport = (idRapportGabarit, start_report, end_report) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.generer_un_rapport,
            template_report_id: idRapportGabarit,
            start_report: start_report,
            end_report: end_report,

        });
    };
};
export const reqRecupererListeRapportsGeneres = (listeIdProjets,start_list_generate_report,end_list_generate_report) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.recuperer_la_liste_des_rapports_generes,
            list_project_id: listeIdProjets,
            start_list_generate_report: start_list_generate_report,
            end_list_generate_report: end_list_generate_report,
        });
    };
};
export const reqPublierRapportGenere = (generate_report_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.publier_un_rapport_genere,
            generate_report_id: generate_report_id,
        });
    };
};
export const reqChargerDocumentRapport = (generate_report_id, data_report) => {
    console.log(data_report)
    const formData = new FormData();
    formData.append("request_type", requestType.charger_le_document_rapport);
    formData.append("generate_report_id", generate_report_id);
    formData.append("data_report", data_report);
    console.log(formData)
    return dispatch => {
        requetePost(dispatch, formData,"multipart/form-data");
    };
};
export const reqSupprimerRapportGenere = (generate_report_id) => {
    return dispatch => {
        requetePost(dispatch, {
            request_type: requestType.supprimer_un_rapport_genere,
            generate_report_id: generate_report_id,
        });
    };
};
const enregistrerListeRapportsPublies = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_RAPPORTS_PUBLIES, publish_reports_list: data.publish_reports_list};
};
const enregistrerListeRapportsGabarits = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_RAPPORTS_GABARITS, templates_reports_list: data.templates_reports_list};
};
const enregistrerRapportGabarit = data => {
    return {type: actionTypes.ENREGISTRER_RAPPORT_GABARIT, configuration_rapport_gabarit: data};
};
const enregistrerListeRapportsGeneres = data => {
    return {type: actionTypes.ENREGISTRER_LISTE_RAPPORTS_GENERES, generates_reports_list: data.generates_reports_list};
};