import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {connect} from "react-redux";

import Projet from '../../composant/Projet/Projet';
import Capteur from "../../composant/Capteur/Capteur";
import Utilisateur from "../../composant/Utilisateur/Utilisateur";
import AideAdmin from "../AideAdmin/AideAdmin";
import Formation from "../Formation/Formation";
import Graphe from "../../composant/Graphe/Graphe";
import FiltreParProjet from "../../composant/FiltreParProjet/FiltreParProjet";
import InterfaceClient from "../../composant/InterfaceClient/InterfaceClient";
import AlarmeAdmin from "../../composant/AlarmeAdmin/AlarmeAdmin";
import Rapport from "../../composant/Rapport/Rapport";
import Maintenance from "../../composant/Maintenance/Maintenance";
import ExportImpressionEcran from "../../composant/ExportImpressionEcran/ExportImpressionEcran";

import * as privilege from "../../ressources/glossaires/glossaire_privileges";
import * as url from "../../ressources/glossaires/glossaire_liens_pages";

import AccesRestreins from "../../hoc/AccesRestreins/AccesRestreins";
import Vue from "../../composant/Vue/Vue";
import Info from "../EnTete/Info/Info"

import * as S from "./ConteneurCentral.module.scss"
import Profil from "../../composant/Profil/Profil";
import Accueil from "../../composant/Accueil/Accueil";

class ConteneurCentral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            montrerInfoUtilisateur: true,
        }
    }

    render() {
        let styleConteneurCentral = [];
        if (!this.props.montrerMenu) {
            styleConteneurCentral.push(S.SansMenu);
        } else {
            styleConteneurCentral.push(S.AvecMenu);
        }
        styleConteneurCentral.push(S.ConteneurCentral);
        styleConteneurCentral.push(S.SansEnTete);
        return (
            <div className={styleConteneurCentral.join(" ")}>
                <AccesRestreins numPrivilege={privilege.acces_a_l_interface_admin}>
                    <Route path={url.projet} exact component={Projet}/>
                    <Route path={url.filtre} exact component={FiltreParProjet}/>
                    <Route path={url.capteur} exact component={Capteur}/>
                    <Route path={url.graphe} exact component={Graphe}/>
                    <Route path={url.vue} exact component={Vue}/>
                    <Route path={url.alarme} exact component={AlarmeAdmin}/>
                    <Route path={url.rapport} exact component={Rapport}/>
                    <Route path={url.utilisateur} exact component={Utilisateur}/>
                    <Route path={url.maintenance} exact component={Maintenance}/>
                    <Route path={url.AideAdmin} exact component={AideAdmin}/>
                </AccesRestreins>
                <Route path={url.export_impression_ecran} component={ExportImpressionEcran}/>
                <Route path={url.accueil} exact component={Accueil}/>
                <Route path={url.profil} exact component={Profil}/>
                {/* <Route path={url.AideAdmin} exact component={AideAdmin}/> */}
                <Route path={url.Formation} exact component={Formation}/>
                <Route path={url.Info} exact component={Info}/>

                <Route path={url.regex_projet_client}
                       component={() => <InterfaceClient project_id={this.props.project_id_url}
                                                         montrerMenu={this.props.montrerMenu}/>}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project_id_url: state.interfaceClient.project_id_url,
        user_id: state.authentifie.user_id,
    };
}

export default connect(mapStateToProps)(ConteneurCentral);
