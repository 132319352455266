import React, {Component} from "react";
import {connect} from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";

import Bouton from "../../../../UI/Bouton/Bouton";
import * as actions from "../../../../../store/actions";
import AttenteRequete from "../../../../../hoc/AttenteRequete/AttenteRequete";

import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import * as requestType from "../../../../../ressources/glossaires/glossaire_requetes";
import * as type_btn from "../../../../../ressources/glossaires/glossaire_type_de_bouton";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";

import {VscRefresh} from "react-icons/vsc";
import * as S from "./EditTableauxDeBordFavoris.module.scss"
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';

function insertAndShift(arr, from, to) {
    let cutOut = arr.splice(from, 1) [0]; // cut the element at index 'from'
    arr.splice(to, 0, cutOut);            // insert it at index 'to'
    return arr
}

class TableauxDeBord extends Component {
    componentDidMount() {
        //récupère la liste des vues (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.user_id) && this.props.user_id !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererListeTableauxDeBord(this.props.user_id);
        }
        if (this.props.favorite_views_list !== undefined) {
            this.setState({
                    favorisSelectionne: this.props.listeTableauxDeBord.filter(tdB => this.props.favorite_views_list.map((value) => value[0]).indexOf(tdB.view_id) > -1).map(
                        tdB => {
                            const favList = [...this.props.favorite_views_list];
                            for (let indFav = 0; indFav < favList.length; indFav++) {
                                if (favList[indFav][0] === tdB.view_id) {
                                    return {...tdB, indOrdre: favList[indFav][2]}//ajoute la clé de l'ordre de la couche
                                }
                            }
                        }
                    ).sort((a, b) => {
                        return a.indOrdre - b.indOrdre;
                    }),
                    selected: [...this.props.favorite_views_list.map((value) => value[0])]
                }
            )
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des vues en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.user_id) && prevProps.user_id !== this.props.user_id && this.props.user_id !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererListeTableauxDeBord(this.props.user_id);
        }

        if (this.props.favorite_views_list !== undefined && !jsDictionaryEquals(this.props.favorite_views_list, prevProps.favorite_views_list)) {
            this.setState({
                    favorisSelectionne: this.props.listeTableauxDeBord.filter(tdB => this.props.favorite_views_list.map((value) => value[0]).indexOf(tdB.view_id) > -1).map(
                        tdB => {
                            const favList = [...this.props.favorite_views_list];
                            for (let indFav = 0; indFav < favList.length; indFav++) {
                                if (favList[indFav][0] === tdB.view_id) {
                                    return {...tdB, indOrdre: favList[indFav][2]}//ajoute la clé de l'ordre de la couche
                                }
                            }
                        }
                    ).sort((a, b) => {
                        return a.indOrdre - b.indOrdre;
                    }),
                    selected: [...this.props.favorite_views_list.map((value) => value[0])]
                }
            )
        }
    }

    state = {
        listTableauxDeBordFavoris: [],
        //todo: optimiser la récupération de la liste des favoris
        favorisSelectionne: this.props.favorite_views_list !== undefined ? this.props.listeTableauxDeBord.filter(tdB => this.props.favorite_views_list.map((value) => value[0]).indexOf(tdB.view_id) > -1).map(
            tdB => {
                const favList = [...this.props.favorite_views_list];
                for (let indFav = 0; indFav < favList.length; indFav++) {
                    if (favList[indFav][0] === tdB.view_id) {
                        return {...tdB, indOrdre: favList[indFav][2]}//ajoute la clé de l'ordre de la couche
                    }
                }
            }
        ).sort((a, b) => {
            return a.indOrdre - b.indOrdre;
        }) : this.props.listeTableauxDeBord,
        selected: this.props.favorite_views_list !== undefined ? [...this.props.favorite_views_list.map((value) => value[0])] : [],
        columns: [// liste des clés pour l'entête du tableau de vue
            {dataField: 'view_id', text: checkMot("id"), sort: true},
            {dataField: 'project_id', text: checkMot("id_projet"), sort: true},
            {dataField: 'view_name', text: checkMot("nom"), sort: true},
            {dataField: 'view_title', text: checkMot("view_title"), sort: true},
        ],
        tableauDeBord_id: null,
        afficherPopupVue: false,
    }
    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.view_id],
                favorisSelectionne: [...this.state.favorisSelectionne, {
                    ...row,
                    indOrdre: this.state.favorisSelectionne.length
                }]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.view_id),
                favorisSelectionne: this.state.favorisSelectionne.filter(tdB => {
                    if (this.state.selected.indexOf(tdB.view_id) > -1 && row.view_id !== tdB.view_id) {
                        return tdB
                    }
                }),
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.view_id);
        if (isSelect) {
            this.setState(() => ({
                selected: [...ids],
                favorisSelectionne: [...this.props.listeTableauxDeBord.map(
                    (tdb, indTdB) => {
                        return {
                            ...tdb,
                            indOrdre: indTdB
                        }
                    }
                )],
            }));
        } else {
            this.setState(() => ({
                selected: [],
                favorisSelectionne: []
            }));
        }
    }
    vueSelectionne = (view_id) => {
        this.setState({tableauDeBord_id: view_id})
    }
    fermerPopupVue = () => {
        this.setState({afficherPopupVue: false})
    }
    ouvrirPopupVue = () => {
        this.setState({afficherPopupVue: true})
    }

    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };
        let listeFavAAfficher = [
            <thead>
            <tr>
                <th scope="col">Change</th>
                {/*<th scope="col">#</th>*/}
                <th scope="col">{checkMot("view_name")}</th>
                <th scope="col">{checkMot("view_id")}</th>
            </tr>
            </thead>
        ];
        for (let indFavSelect = 0; indFavSelect < this.state.favorisSelectionne.length; indFavSelect++) {
            listeFavAAfficher.push(
                <tr>
                    <th scope="row"><Bouton desactive={indFavSelect === 0}
                                            cliquer={() => this.setState({favorisSelectionne: insertAndShift(this.state.favorisSelectionne, indFavSelect - 1, indFavSelect)})}>^</Bouton>
                        <Bouton desactive={indFavSelect === this.state.favorisSelectionne.length - 1}
                                cliquer={() => this.setState({favorisSelectionne: insertAndShift(this.state.favorisSelectionne, indFavSelect, indFavSelect + 1)})}>v</Bouton>
                    </th>
                    {/*<th scope="row">{this.state.favorisSelectionne[indFavSelect].indOrdre}</th>*/}
                    <td>{this.state.favorisSelectionne[indFavSelect].view_name}</td>
                    <td>{this.state.favorisSelectionne[indFavSelect].view_id}</td>
                </tr>
            )
        }
        return (
            <div className={S.Conteneur}>
                <div className={S.conteneurTableauModificationOrdre}>
                    <div className={S.tableauModificationOrdre}>
                        <table className={s.table}>
                            {listeFavAAfficher}
                        </table>
                    </div>
                    <div className={S.conteneurBoutonModificationOrdre}>
                        <AttenteRequete
                            request_type={requestType.modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur}>
                            <Bouton
                                cliquer={() => this.props.reqModifierListeTableauxDeBordFavorisUtilisateur(this.props.user_id, this.state.favorisSelectionne ? [...this.state.favorisSelectionne.map((tdb, ind) => {
                                    return [tdb.view_id, tdb.view_name, ind, tdb.project_id]
                                })] : [])}>
                                {checkMot("enregistrer")}
                            </Bouton>
                        </AttenteRequete>
                    </div>
                </div>
                <div className={S.tableauSelection}>
                    <AttenteRequete request_type={requestType.recuperation_de_la_liste_des_vues}
                                    str={checkMot("maj_vues_en_cour")}>
                        {/*bouton de rafraîchissement du tableau*/}
                        <Bouton hoverText={checkMot("refresh")} type={type_btn.outil} rond={true}
                                cliquer={() => {
                                    this.props.reqRecupererListeTableauxDeBord(this.props.user_id)
                                }}>
                            <VscRefresh size={15}/>
                        </Bouton>
                    </AttenteRequete>
                    <BootstrapTable
                        striped
                        hover
                        condensed
                        keyField={"view_id"}
                        data={this.props.listeTableauxDeBord !== undefined ? this.props.listeTableauxDeBord : []}
                        columns={this.state.columns}
                        selectRow={selectRow}
                        rowStyle={{margin: "0px"}}
                        filter={filterFactory()}
                        pagination={paginationFactory()}
                        bordered={true}
                        // cellEdit={ cellEditFactory({ mode: 'dbclick' }) }
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        listeTableauxDeBord: state.vue.dashbords_list_by_user[ownProps.user_id] !== undefined ? state.vue.dashbords_list_by_user[ownProps.user_id].views_list : [],
        user_id: state.authentifie.user_id,
        project_id_url: state.interfaceClient.project_id_url,
        favorite_views_list: state.vue.dashbords_list_by_user[ownProps.user_id] !== undefined ? state.vue.dashbords_list_by_user[ownProps.user_id].favorite_views_list : [],
    };
}

const mapDispatchToProps = dispatch => {
    return {
        reqRecupererListeTableauxDeBord: (listeIdProjets) => dispatch(actions.reqRecupererListeTableauxDeBord(listeIdProjets)),
        reqModifierListeTableauxDeBordFavorisUtilisateur: (user_id, liste_fav_tableaux_de_bord) => dispatch(actions.reqModifierListeTableauxDeBordFavorisUtilisateur(user_id, liste_fav_tableaux_de_bord)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauxDeBord);
