import React, { Component } from "react";
import {connect} from "react-redux";
//scss
import * as s from "../../../ressources/StyleGlobal/StyleGlobal";
//glossaire et variable importés
import * as actions from "../../../store/actions";
//composant
import ChangementIntervalTempsGraphe from "./ChangementEchantillonGraphe/ChangementEchantillonGraphe";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
//icône
import Popup from "../../UI/Popup/Popup";
import AttenteRequete from "../../../hoc/AttenteRequete/AttenteRequete";
import * as requestType from "../../../ressources/glossaires/glossaire_requetes";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import {AiOutlineLineChart} from "react-icons/ai";
import SwitchGraphe from "./SwitchGraphe";

class AffichageGraphe extends Component {
    state={
        afficherModifierIntervalle:false
    }
    componentDidMount() {
        if(this.props.graph_id!==undefined){
            this.props.reqRecupererDonneesGraphe(this.props.graph_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.donneesGraphe===undefined || this.props.graph_id!==prevProps.graph_id){
            this.props.reqRecupererDonneesGraphe(this.props.graph_id);
        }
    }
    switchAfficherModifierIntervalle = () => this.setState({...this.state, afficherModifierIntervalle: !this.state.afficherModifierIntervalle});
    render() {
        return <Auxiliaire>{/**  {headerPopupText={this.props.donneesGraphe.config  !== undefined ? <div> {this.props.donneesGraphe.config.title}<br/> {this.props.donneesGraphe.config.subtitle}</div>  : null}  **/}
            <Popup  texte_ouverture={checkMot("afficher_graphe")} icone={<AiOutlineLineChart size={20}/>}>
                <AttenteRequete request_type={requestType.recuperation_des_donnees_d_un_graphe} str={checkMot("en_cour_de_reception_des_donnees_du_graphe")} >
                    <SwitchGraphe
                        graph_id={this.props.graph_id}
                        switchAfficherModifierIntervalle={this.switchAfficherModifierIntervalle}
                        donneesGraphe={this.props.donneesGraphe}
                        configuration = {this.props.donneesGraphe.config}
                        donnees = {this.props.donneesGraphe.data}
                        seuils={this.props.donneesGraphe.alarms_levels!==undefined?this.props.donneesGraphe.alarms_levels:[]}
                        reqRecupererDonneesGraphe={this.props.reqRecupererDonneesGraphe}
                    />
                </AttenteRequete>
                <div className={s.row}>
                    <ChangementIntervalTempsGraphe graph_id={this.props.graph_id} afficherPopup={this.state.afficherModifierIntervalle} fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}/>
                </div>
            </Popup>
        </Auxiliaire>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        donneesGraphe: {...state.graphe.liste_donnees_graphes[ownProps.graph_id]},

    };
}
const mapDispatchToProps = dispatch => {
    return {
        reqRecupererDonneesGraphe: (idGraphe) => dispatch(actions.reqRecupererDonneesGraphe(idGraphe)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(AffichageGraphe);
