import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {createTheme} from "@mui/material";
import {useEffect} from "react";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip(props) {
    const theme = createTheme();

    theme.typography.label = {
        fontSize: '0.8rem'
    };

    const [personName, setPersonName] = React.useState(props.preconfigurerListe.map(value => value.toString()) || []);
    const [dictValueLabel, setDictValueLabel] = React.useState({});

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    useEffect(() => {
        props.gererListeSelectionne(personName.map(value => isNaN(value) ? value : parseFloat(value)))
    }, [personName]);
    useEffect(() => {
        let newDictValueLabel = {}
        for (let item of props.namesList) {
            newDictValueLabel[item[props.valueKey]] = item[props.labelKey]
        }
        setDictValueLabel(newDictValueLabel)
    }, [props.namesList]);
    return (
        <FormControl sx={props.sx} size="small">
            <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                // sx={{ fontSize: 5, fontStyle: 'italic' }}
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label={props.label}/>}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0}}>
                        {selected.map((value) => (
                            <Chip
                                sx={{margin: 0, fontSize: 15, maxHeight: 25, padding: 0}}
                                key={value} label={dictValueLabel[value]}/>
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {Object.keys(dictValueLabel).map((valueKey) => {
                    const label = dictValueLabel[valueKey]
                    return (
                        <MenuItem
                            key={valueKey}
                            value={valueKey}
                            style={getStyles(label, personName, theme)}
                        >
                            {label}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
}
