import React, {Component} from "react";
import {unixTimestampToDate} from "../../../../../../../ressources/functionJS/unixTimestampToDate";
import * as S from "./FormulaireSelectionDateHeureUnix.module.scss";
import {checkMot} from "../../../../../../../ressources/functionJS/checkMot";

class FormulaireSelectionDateHeureUnix extends Component {
    state={
        active:(this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value))||this.props.disabled===true,
        defaultDate: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[0].split("/"):null,
        defaultTime: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[1].split(":"):null,
        date: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-"):null,
        time: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[1]:"00:00",
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value!==prevProps.value && !isNaN(this.props.value)){
            // console.log("!isNaN(this.props.value)", !isNaN(this.props.value))
            this.setState({
                active:(this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value))||this.props.disabled===true,
                defaultDate: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[0].split("/"):null,
                defaultTime: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[1].split(":"):null,
                date: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-"):null,
                time: this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[1]:"00:00",
            });
        }
    }

    render() {
        const gereDate = (event) => {
            this.setState({date:event.target.value});
            envoiDate(event.target.value,this.state.time);
        }
        const gereTime = (event) => {
            this.setState({time:event.target.value});
            envoiDate(this.state.date,event.target.value);
        }
        const gereDesactive = () => {
            const dateValue = null;
            const timeValue = null;
            this.setState({active:false,date:dateValue, time:timeValue});
            envoiDate(dateValue,timeValue);
        }
        const envoiDate = (dateValue,timeValue) => {
            if(dateValue!==null){
                const splitDate = dateValue.split("-");
                let splitHour;
                if(timeValue!==null){
                    splitHour = timeValue.split(":");
                }else {
                    splitHour = ["00","00"];
                }
                //les mois sont compris entre 0 and 11 (January to December).
                //Les heures sont comprises entre 0 et 23 (00h pour minuit et 23h)
                //correctif timezone
                let valeurFuseau = new Date().getTimezoneOffset()/60;
                let datum = new Date(Date.UTC(splitDate[0],parseInt(splitDate[1])-1,splitDate[2],parseInt(splitHour[0])+valeurFuseau,splitHour[1],'00'));
                const timeToUnix = datum.getTime();
                this.props.gereUnixDate( timeToUnix/1000 );
            }else {
                this.props.gereUnixDate(null);
            }
        }
        const gereActive = () => {
            const dateValue = this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-"):null;
            const timeValue = this.props.value!==undefined && this.props.value!==null && !isNaN(this.props.value)?unixTimestampToDate(this.props.value).split(", ")[1]:"00:00";
            this.setState({active:true,
                date: dateValue,
                time: timeValue,
            });
            envoiDate(dateValue,timeValue);
        }
        return this.state.active
                    ?<div>

                        <input className={S.style} type="date"
                               defaultValue={this.state.defaultDate!==null?this.state.defaultDate[2]+'-'+this.state.defaultDate[1]+'-'+this.state.defaultDate[0]:null}
                               value={this.state.date}
                               onChange={(event)=>gereDate(event)}
                               disabled={this.props.disabled===true}
                        />
                        <input className={S.style}  type="time"
                               defaultValue={this.state.defaultTime!==null?this.state.defaultTime[0]+':'+this.state.defaultTime[1]:"00:00"}
                               value={this.state.time}
                               onChange={(event)=>gereTime(event)}
                               disabled={this.props.disabled===true}
                        />
                        {this.props.disabled===true?null:<div className={S.BoutonDiv} onClick={gereDesactive}>{checkMot("desactive_la_plage_de_temps")}</div>}
                        
                    </div>
                    :<div className={S.BoutonDiv} onClick={gereActive}>{checkMot("active_la_plage_de_temps")}</div>
    }
}
export default FormulaireSelectionDateHeureUnix;