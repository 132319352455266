import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import ArborescenceProjets from "./ArborescenceProjets/ArborescenceProjets";

import * as actions from "../../../store/actions";
import {checkMot} from "../../../ressources/functionJS/checkMot";

import '../../../../node_modules/react-simple-tree-menu/dist/main.css';
import {CgListTree} from "react-icons/cg";
import {RiPushpinFill, RiPushpin2Fill} from "react-icons/ri";
import S from "./RubriquesNavigationClient.module.scss";
import {BiSearch, MdClose} from "react-icons/all";
import Bouton from "../../UI/Bouton/Bouton";

function sortByProjectName(list) {
    return list.sort(function (a, b) {
        var nameA = a.project_name.toUpperCase(); // Ignore la casse
        var nameB = b.project_name.toUpperCase(); // Ignore la casse
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // Les noms de projets sont égaux
        return 0;
    });
}

class rubriquesNavigationClient extends Component {
    componentDidMount() {
        this.props.reqRecupererListeTableauxDeBordFavoris();
    }

    state = {
        afficherBarreDeRecherche: false
    }

    gererAfficherBarreDeRecherche = (etat) => {
        this.setState({afficherBarreDeRecherche: etat})
    }

    render() {
        /*done: afficher la liste des projets favoris
             1) récupérer la liste des ids de projet favoris de l'utilisateur connecté depuis le reducteur de auth via le connect() de redux
             2) récupérer la liste des informations de projets de l'utilisateur connecté depuis le reducteur de auth via le connect() de redux
             3) avec une boucle récupérer la liste des informations de projets favoris de l'utilisateur avec la liste de ses projets
        Boucle pour récupérer la liste des informations des projets favoris*/
        let projets_favoris_liste = [];
        if (this.props.listeProjets !== null && this.props.listeProjets !== undefined && this.props.listeFavoris !== null && this.props.listeFavoris !== undefined) {
            for (let i = 0; i < this.props.listeProjets.length; i++) {
                for (let j = 0; j < this.props.listeFavoris.length; j++) {
                    if (this.props.listeFavoris[j] === this.props.listeProjets[i].project_id) {
                        projets_favoris_liste.push(this.props.listeProjets[i])
                    }
                }
            }
        }

        projets_favoris_liste = sortByProjectName(projets_favoris_liste)
        return (<ul className={[S.RubriquesNavigation].join(" ")}>
            {/*label de l'arborescence*/}
            <li className={S.LiTitre} key={"favoris_general"}>
                <span className={S.mark}>{checkMot("projet_favoris")}</span>
                <RiPushpin2Fill size={15} className={S.btstyle}/>
            </li>
            {Object.keys(projets_favoris_liste).map(//affiche la liste des favoris
                favorisKey => {
                    return <span className={S.LiensFavoris} key={"favorisKey" + favorisKey}>
                           <span className={S.facIco}><RiPushpinFill size={14} color={'#eeb214'}/> </span>
                           <span className={S.Lienfav}>
                        <Link
                            to={"/" + projets_favoris_liste[favorisKey].project_id}
                            className={S.bouton_favoris}
                            onClick={() => this.props.enregistrementProjectIdUrl(projets_favoris_liste[favorisKey].project_id)}
                        >
                            <span
                                className={this.props.project_id_url === projets_favoris_liste[favorisKey].project_id ? S.bouton_favoris_actif : S.bouton_favoris}>
                                {projets_favoris_liste[favorisKey].project_name}
                            </span>
                        </Link>
                        </span>
                        </span>
                })}
            {/*label de l'arborescence*/}
            <li className={S.LiTitre} key={"Arborescence des projets"}>
                <CgListTree size={15} className={S.btstyle}/>
                <span className={[S.mark, S.barre_recherche_arborescence].join(" ")}>
                    <div className={S.texte_arborescence}>
                        {checkMot("arborescence_des_projets")}
                    </div>

                    <Bouton ajoutClass={S.bouton_recherche}
                            cliquer={() => this.gererAfficherBarreDeRecherche(!this.state.afficherBarreDeRecherche)}>
                        {this.state.afficherBarreDeRecherche ? <MdClose/> : <BiSearch/>}
                    </Bouton>
                </span>

            </li>
            {/*Affichage de l'arborescence de projets*/}
            <ArborescenceProjets afficherBarreDeRecherche={this.state.afficherBarreDeRecherche}/>
        </ul>);
    }

}


const mapStateToProps = state => {
    return {
        listeFavoris: state.authentifie.favorite_projects_list_id,
        listeProjets: state.authentifie.projects_list,
        interfaceAdmin: state.authentifie.interfaceAdmin,
        project_id_url: state.interfaceClient.project_id_url,

    };
}
const mapDispatchToProps = dispatch => {
    return {
        enregistrementProjectIdUrl: (project_id) => dispatch(actions.enregistrerProjectIdUrl(project_id)),
        reqRecupererListeTableauxDeBordFavoris: () => dispatch(actions.reqRecupererListeTableauxDeBordFavoris())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(rubriquesNavigationClient);
