import React, {Component} from "react";
import BibliothequePlotly from "../BibliothequePlotly/BibliothequePlotly";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import {convertiUtsToDate} from "../GrapheXT/GrapheXT";
import {formatUniteArrayTable} from "../TableauDernieresValeursEtTotaux/TableauDernieresValeursEtTotaux";

class TableauDeltaDesBornesEtTotaux extends Component {
    state = {
        data: [],
        layout: {},
    }

    render() {
        let layout = {};
        let data = [];
        let headers_values = [];
        let headers_unit = [];
        let sensors_names = []
        let noms_responsables = []
        let dates_values = []
        let values = [];
        if (this.props.configuration !== undefined && this.props.donnees !== undefined) {
            layout = {
                font: {size: 14},
                title: {
                    text: this.props.configuration.title + '<br>' + this.props.configuration.subtitle,
                    font: {
                        // size: 25,
                        color: '#3c3935',
                    },
                },
                showlegend: true,
                legend: {
                    orientation: "h",
                    x: 0,
                    y: -0.0,
                },
                ...(this.props.viewobj_json_content === undefined || this.props.viewobj_json_content === null ? {} : this.props.viewobj_json_content.layout)

            };

            for (let curve of this.props.donnees) {
                let index_sensor_name = sensors_names.indexOf(curve.sensor_name) // index du nom du capteur sinon -1

                /*** si le nom de la courbe est aussi une colonne
                 let index_name = curve.name === "" ? -1 : headers_values.indexOf(curve.name) // index du nom de la "courbe" sinon -1
                 */
                let index_prefix = curve.prefix === "" ? -1 : headers_values.indexOf(curve.prefix)  // index du préfixe sinon -1
                let index_position_in_sensor = curve.position_in_sensor === "" ? -1 : headers_values.indexOf(curve.position_in_sensor)  // index du préfixe sinon -1

                const last_index = curve.y.length - 1
                const new_date = curve.x[last_index] // récupère la dernière date de la courbe
                if (index_sensor_name < 0) {
                    // ajoute le sensor name et la date si le sensorname n'est pas dans la liste
                    sensors_names.push(curve.sensor_name)
                    // ajoute le nom du responsable
                    noms_responsables.push(curve.name)
                    //ajoute la date
                    dates_values.push(new_date)

                    for (let ind_col = 0; ind_col < headers_values.length; ind_col++) values[ind_col].push(null);


                } else if (dates_values[index_sensor_name] < new_date) {
                    //modifie la dernière date si elle est plus récente
                    dates_values[index_sensor_name] = new_date
                }

                // cas où le sensor_name vient d'être ajouté à la liste
                index_sensor_name = index_sensor_name < 0 ? sensors_names.length - 1 : index_sensor_name
                /*** si le nom de la courbe est aussi une colonne
                 if (index_name < 0 && index_prefix < 0) {
                 */
                if (index_prefix < 0) {
                    //si le nom de la courbe et le préfixe n'existe pas
                    // ajoute une colonne de null
                    values.push(new Array(sensors_names.length).fill(null))


                    // positionne la valeur dans la ligne du sensor_name
                    values[values.length - 1][index_sensor_name] = curve.y[last_index] - curve.y[0]
                    /*** si le nom de la courbe est aussi une colonne
                     //ajoute l'entête de la colonne
                     if (curve.name !== "") {
                        // ajoute le nom de la courbe si elle n'est pas une chaîne vide
                        headers_values.push(curve.name)
                    } else */
                    if (curve.prefix !== "") {
                        // ajoute le préfixe si elle n'est pas une chaîne vide
                        headers_values.push(curve.prefix)
                    } else {
                        // ajoute la position de la composante sinon
                        headers_values.push(curve.position_in_sensor)
                    }
                    headers_unit.push(curve.engineering_unit)

                    /*** si le nom de la courbe est aussi une colonne
                     } else if (index_name > -1) {
                    // positionne la valeur dans la ligne du sensor_name si le nom de la courbe existe déjà dans l'entête
                    values[index_name][index_sensor_name] = curve.y[last_index] - curve.y[0]
                     */
                } else if (index_prefix > -1) {
                    // positionne la valeur dans la ligne du sensor_name si le nom du préfixe existe déjà dans l'entête
                    values[index_prefix][index_sensor_name] = curve.y[last_index] - curve.y[0]
                } else {
                    // positionne la valeur dans la ligne du sensor_name sinon en fonction de la index_position_in_sensor
                    values[index_position_in_sensor][index_sensor_name] = curve.y[last_index] - curve.y[0]
                }


            }

        }
        dates_values = convertiUtsToDate(dates_values)
        dates_values.push(checkMot("totaux")) // ajout de Totaux
        for (let index_colonne = 0; index_colonne < values.length; index_colonne++) {
            // somme toutes les valeurs et ignore les valeurs non numérique
            let sum = 0;
            for (let index_valeur = 0; index_valeur < values[index_colonne].length; index_valeur++) {
                if (!isNaN(values[index_colonne][index_valeur])) {
                    sum += values[index_colonne][index_valeur]
                }
            }
            values[index_colonne].push(sum)//ajoute la somme à la colonne

            values[index_colonne] = formatUniteArrayTable([...values[index_colonne]], headers_unit[index_colonne]);
            console.log("values[index_colonne]", values[index_colonne])
        }

        let headerColor = "#EFB805";
        // let ligne_blanche = new Array(values.length+3).fill('#000000')
        // let lineCell = values[0].map(value=>[...ligne_blanche])
        // lineCell[0][lineCell.length - 1] = '#FFFFFF'
        // lineCell[1][lineCell.length - 1] = '#FFFFFF'
        let rowOthersColor = "white";
        let rowLastColor = "lightgrey";
        let fillCell = new Array(values[0].length).fill(rowOthersColor)
        fillCell[fillCell.length - 1] = rowLastColor;

        data.push({
            type: 'table',
            columnwidth:[5,18,8,5,5],
            header: {
                values: [[checkMot("responsable")], [checkMot("nom")], [checkMot("date")], ...headers_values.map((valeur, index) => valeur + " [" + headers_unit[index] + "]")].map(valeur => "<b>" + valeur + "</b>"),
                align: ["left", "center"],
                line: {width: 1, color: '#000000'},
                fill: {color: headerColor},
                font: {family: "Arial", size: 20, color: "white"}
            },
            cells: {
                values: [noms_responsables, sensors_names, dates_values, ...values],
                align: ["left", "center"],
                line: {color: "#000000"},
                fill: {color: [fillCell]},
                font: {family: "Arial", size: 18, color: ["#506784"]},
                height: 25
            }
        })

        return <BibliothequePlotly
            data={data}
            layout={{
                ...layout,
                responsive: true,
                ...this.props.viewobj_json_content
            }}
            donneesGraphe={this.props.donneesGraphe}
            ouvrirPopupModifierIntervalle={this.props.switchAfficherModifierIntervalle}
            dimensionWidth={this.props.dimensionWidth}
            dimensionHeight={this.props.dimensionHeight}
            viewobj_json_content={this.props.viewobj_json_content}
        />
    }


}

export default TableauDeltaDesBornesEtTotaux;
