import React, {Component} from "react";
import {connect} from "react-redux";
import Modal from "react-modal";

import GrapheXT from "../Graphe/AffichageGraphe/GrapheXT/GrapheXT";
import ChangementIntervalTempsGrapheAutomatique
    from "../Graphe/AffichageGraphe/ChangementEchantillonGrapheAutomatiqueXT/ChangementIntervalTempsGrapheAutomatique";
import ChangementIntervalTempsGraphe
    from "../Graphe/AffichageGraphe/ChangementEchantillonGraphe/ChangementEchantillonGraphe";
import Bouton from "../UI/Bouton/Bouton";
import AttenteRequete from "../../hoc/AttenteRequete/AttenteRequete";
import AccesRestreins from "../../hoc/AccesRestreins/AccesRestreins";

import {checkMot} from "../../ressources/functionJS/checkMot";
import * as actions from "../../store/actions";
import * as requestType from "../../ressources/glossaires/glossaire_requetes";
import * as glossaire_type_export from "../../ressources/glossaires/glossaire_type_export";
import * as privilege from "../../ressources/glossaires/glossaire_privileges";

import S from "./ExportImpressionEcran.module.scss";
import AffichageVue from "../Vue/AffichageVue/AffichageVue";
import {checkPhrase} from "../../ressources/functionJS/checkPhrase";
import SwitchGraphe from "../Graphe/AffichageGraphe/SwitchGraphe";

class ExportImpressionEcran extends Component {

    state = {
        afficherModifierIntervalle: false,
        dimensionWidthGraphe: window.innerWidth - 55,
        dimensionHeightGraphe: window.innerHeight - 25,

    }
    switchAfficherModifierIntervalle = () => this.setState({
        ...this.state,
        afficherModifierIntervalle: !this.state.afficherModifierIntervalle
    });

    componentDidMount() {
        switch (this.props.type_affichage) {
            case glossaire_type_export.graphe:
                if (this.props.intervalle_objet_affichage !== undefined) {
                    //cas où les paramètres d'intervalle du graphe sont renseignés
                    const listeIntervalle = this.props.intervalle_objet_affichage.split("_")
                    if (listeIntervalle !== undefined) {
                        const debut = parseInt(listeIntervalle[0])
                        const fin = parseInt(listeIntervalle[1])
                        this.props.reqRecupererDonneesGrapheAvecIntervalle(parseInt(this.props.iD_objet_affichage), debut, fin)
                    }
                } else {
                    //cas par défaut s'il y a juste le id graphe
                    this.props.reqRecupererDonneesGraphe(parseInt(this.props.iD_objet_affichage));
                }
                this.setState({
                    modalClassName: S.ModalGraphe
                })
                break;
            case glossaire_type_export.graphe_automatique:
                //récupération des ids sensor et position
                const listeId = this.props.iD_objet_affichage.split("_")
                const sensor_id = parseInt(listeId[0])
                const position_in_sensor = parseInt(listeId[1])

                if (this.props.intervalle_objet_affichage !== undefined) {
                    //cas où les paramètres d'intervalle du graphe sont renseignés
                    const listeIntervalle = this.props.intervalle_objet_affichage.split("_")
                    if (listeIntervalle !== undefined) {
                        const debut = parseInt(listeIntervalle[0])
                        const fin = parseInt(listeIntervalle[1])
                        this.props.reqRecupererDonneesGrapheAutomatiqueXTAvecIntervalle(sensor_id, position_in_sensor, debut, fin)
                    }
                } else {
                    //cas par défaut s'il y a juste les ids sensor et position
                    this.props.reqRecupererDonneesGrapheAutomatiqueXT(sensor_id, position_in_sensor)
                }

                this.setState({
                    modalClassName: S.ModalGraphe
                })
                break;
            case glossaire_type_export.visualisation:
                this.setState({
                    modalClassName: S.ModalVisualisation
                })
                break;
            default:
                break;
        }
        window.addEventListener("resize", () => this.setState({
            dimensionWidthGraphe: window.innerWidth - 55,
            dimensionHeightGraphe: window.innerHeight - 75,
        }))
    }

    render() {
        let affichageObjetExport;
        let affichageComposantChangementInterval = null;
        switch (this.props.type_affichage) {
            case glossaire_type_export.graphe:
                if (this.props.donneesGraphe[this.props.iD_objet_affichage] !== undefined && this.props.donneesGraphe[this.props.iD_objet_affichage].config !== undefined) {
                    let boolAfficherGraphe = true;
                    for (let indSetDonnees = 0; indSetDonnees < this.props.donneesGraphe[this.props.iD_objet_affichage].length; indSetDonnees) {
                        if (this.props.donneesGraphe[this.props.iD_objet_affichage][indSetDonnees].t !== undefined && this.props.donneesGraphe[this.props.iD_objet_affichage][indSetDonnees].t !== null && this.props.donneesGraphe[this.props.iD_objet_affichage][indSetDonnees].t.length > 0) {
                            boolAfficherGraphe = false;
                            break;
                        }
                    }
                    if (boolAfficherGraphe) {
                        affichageObjetExport = <SwitchGraphe
                            graph_id={this.props.iD_objet_affichage}
                            donneesGraphe={this.props.donneesGraphe[this.props.iD_objet_affichage]}
                            switchAfficherModifierIntervalle={this.switchAfficherModifierIntervalle}
                            dimensionWidth={this.state.dimensionWidthGraphe}
                            dimensionHeight={this.state.dimensionHeightGraphe}
                            configuration={this.props.donneesGraphe[this.props.iD_objet_affichage].config}
                            donnees={this.props.donneesGraphe[this.props.iD_objet_affichage].data}
                            seuils={this.props.donneesGraphe[this.props.iD_objet_affichage].alarms_levels !== undefined ? this.props.donneesGraphe[this.props.iD_objet_affichage].alarms_levels : []}
                            reqRecupererDonneesGraphe={this.props.reqRecupererDonneesGraphe}
                            reqRecupererDonneesGrapheAvecNDerniereValeurs={this.props.reqRecupererDonneesGrapheAvecNDerniereValeurs}
                            attenteReq_recuperation_des_donnees_d_un_graphe={this.props.attenteReq_recuperation_des_donnees_d_un_graphe}
                        />;
                        affichageObjetExport =
                            <AccesRestreins numPrivilege={privilege.recuperer_la_liste_des_graphes}>
                                <AttenteRequete request_type={requestType.recuperation_des_donnees_d_un_graphe}
                                                str={checkMot("en_cour_de_reception_des_donnees_du_graphe")}>
                                    {affichageObjetExport}
                                </AttenteRequete>
                            </AccesRestreins>
                        affichageComposantChangementInterval =
                            <ChangementIntervalTempsGraphe graph_id={this.props.iD_objet_affichage}
                                                           afficherPopup={this.state.afficherModifierIntervalle}
                                                           fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}/>

                    } else {
                        affichageObjetExport = "Erreur données API non conforme.";
                    }
                } else {
                    affichageObjetExport = <Bouton
                        ajoutClass={S.style}
                        cliquer={() => {
                            this.props.reqRecupererDonneesGraphe(this.props.iD_objet_affichage);
                        }
                        }>{checkMot("refresh")}</Bouton>
                }
                break;
            case glossaire_type_export.graphe_automatique:
                const listeId = this.props.iD_objet_affichage.split("_")
                const sensor_id = parseInt(listeId[0])
                const position_in_sensor = parseInt(listeId[1])
                if (this.props.donneesGraphe[sensor_id + "+" + position_in_sensor] !== undefined && this.props.donneesGraphe[sensor_id + "+" + position_in_sensor].config !== undefined) {
                    const titre_graphe = this.props.donneesGraphe[sensor_id + "+" + position_in_sensor].config.title
                    affichageObjetExport =
                        <AccesRestreins numPrivilege={privilege.recuperer_la_liste_des_graphes}>
                            <AttenteRequete
                                request_type={requestType.recuperation_des_donnees_d_un_graphe_automatique_xt}
                                str={checkMot("en_cour_de_reception_des_donnees_du_graphe_automatique")}>
                                <GrapheXT donneesGraphe={this.props.donneesGraphe[sensor_id + "+" + position_in_sensor]}
                                          dimensionWidth={this.state.dimensionWidthGraphe}
                                          dimensionHeight={this.state.dimensionHeightGraphe}
                                          switchAfficherModifierIntervalle={this.switchAfficherModifierIntervalle}
                                          configuration={{
                                              ...this.props.donneesGraphe[sensor_id + "+" + position_in_sensor].config,
                                              title: checkPhrase("traduction_titre_graphe_automatique", titre_graphe.substring(21, titre_graphe.length - 21))
                                          }}
                                          donnees={this.props.donneesGraphe[sensor_id + "+" + position_in_sensor].data}
                                          seuils={this.props.donneesGraphe[sensor_id + "+" + position_in_sensor].alarms_levels}/>
                            </AttenteRequete>
                        </AccesRestreins>;
                    affichageComposantChangementInterval =
                        <ChangementIntervalTempsGrapheAutomatique sensor_id={sensor_id}
                                                                  position_in_sensor={position_in_sensor}
                                                                  afficherPopup={this.state.afficherModifierIntervalle}
                                                                  fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}/>
                } else {
                    affichageObjetExport = <Bouton
                        ajoutClass={S.style}
                        cliquer={() => {
                            this.props.reqRecupererDonneesGrapheAutomatiqueXT(sensor_id, position_in_sensor)
                        }
                        }>{checkMot("refresh")}</Bouton>
                }
                break;
            case glossaire_type_export.visualisation:
                affichageObjetExport = <AffichageVue view_id={parseInt(this.props.iD_objet_affichage)}
                                                     uniciteVue={checkMot("export")}
                />
                break;
            default:
                break;
        }

        return <Modal isOpen={this.props.reConnexion}
                      style={{
                          overlay: {
                              position: "absolute",
                              zIndex: "250",
                              border: "black 1px solid",
                              backgroundColor: "transparent",
                              transition: "width 2s, height 4s",
                          },
                          content: {
                              inset: "0% 0% 0% 0%",
                              boxShadow: "-5px 0px 5px rgba(0, 0, 0, 0.226)",
                          }
                      }}
                      className={this.state.modalClassName}
        >
            {affichageObjetExport}
            {affichageComposantChangementInterval}
        </Modal>
    }
}


const mapStateToProps = (state) => {
    return {
        reConnexion: state.authentifie.reConnexion,
        donneesGraphe: state.graphe.liste_donnees_graphes,
        type_affichage: state.exportImpressionEcran.type_affichage,
        iD_objet_affichage: state.exportImpressionEcran.iD_objet_affichage,
        intervalle_objet_affichage: state.exportImpressionEcran.intervalle_objet_affichage,
        attenteReq_recuperation_des_donnees_d_un_graphe: state.attenteRequete["ATTENTE_REQUETE"+requestType.recuperation_des_donnees_d_un_graphe]
    };
}
const mapDispatchToProps = dispatch => {
    return {
        reqRecupererDonneesGrapheAvecNDerniereValeurs: (idGraphe, measures_num) => dispatch(actions.reqRecupererDonneesGraphe(idGraphe, null, measures_num, [], [null, null])),
        reqRecupererDonneesGrapheAvecIntervalle: (idGraphe, debut, fin) => dispatch(actions.reqRecupererDonneesGraphe(idGraphe, null, null, [], [debut, fin])),
        reqRecupererDonneesGraphe: (idGraphe) => dispatch(actions.reqRecupererDonneesGraphe(idGraphe)),
        reqRecupererDonneesGrapheAutomatiqueXT: (sensor_id, position_in_sensor) => dispatch(actions.reqRecupererDonneesGrapheAutomatiqueXT(sensor_id, position_in_sensor, null, null, [], [null, null])),
        reqRecupererDonneesGrapheAutomatiqueXTAvecIntervalle: (sensor_id, position_in_sensor, debut, fin) => dispatch(actions.reqRecupererDonneesGrapheAutomatiqueXT(sensor_id, position_in_sensor, null, null, [], [debut, fin])),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportImpressionEcran);