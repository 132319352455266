import React, {Component} from "react";
import {unixTimestampToDate} from "../../../../../ressources/functionJS/unixTimestampToDate";
import * as S from "./FormulaireSelectionDateHeureUnix.module.scss";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import {dateValider} from "../../../../../ressources/functionJS/dateValide";
import {validerHeure} from "../../../../../ressources/functionJS/heureValide";

class FormulaireSelectionDateHeureUnix extends Component {
    state = {
        date_valide: true,
        heure_valide: true,
        active: (this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value)) || this.props.disabled === true,
        defaultDate: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/") : null,
        defaultTime: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1].split(":") : null,
        date: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-") : null,
        time: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1] : "00:00",
    }

    componentDidMount() {
        if (this.props.value === null) {
            this.setState({
                date_valide: true,
                heure_valide: true,
                active: false,
                defaultDate: null,
                defaultTime: null,
                date: null,
                time: null,
            });
        } else {
            this.setState({
                active: (this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value)) || this.props.disabled === true,
                date_valide: true,
                heure_valide: true,
                defaultDate: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/") : null,
                defaultTime: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1].split(":") : null,
                date: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-") : null,
                time: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1] : "00:00",
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value && !isNaN(this.props.value)) {
            console.log("this.props.value", this.props.value)
            if (this.props.value === null) {
                this.setState({
                    date_valide: true,
                    heure_valide: true,
                    active: false,
                    defaultDate: null,
                    defaultTime: null,
                    date: null,
                    time: null,
                });
            } else {
                this.setState({
                    date_valide: true,
                    heure_valide: true,
                    active: (this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value)) || this.props.disabled === true,
                    defaultDate: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/") : null,
                    defaultTime: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1].split(":") : null,
                    date: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-") : null,
                    time: this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1] : "00:00",
                });
            }
        }
    }

    render() {
        const gereDate = (event) => {
            if (dateValider(event.target.value)) {
                this.setState({
                    date: event.target.value,
                    date_valide: true
                });
                envoiDate(event.target.value, this.state.time);
            } else {
                this.setState({date: event.target.value, date_valide: false});
            }
        }
        const gereTime = (event) => {
            if (validerHeure(event.target.value)) {
                this.setState({
                    time: event.target.value,
                    heure_valide: true
                });
                envoiDate(this.state.date, event.target.value);
            } else {
                this.setState({time: event.target.value, heure_valide: false});
            }
        }
        const gereDesactive = () => {
            const dateValue = null;
            const timeValue = null;
            this.setState({active: false, date: dateValue, time: timeValue});
            envoiDate(dateValue, timeValue);
        }
        const envoiDate = (dateValue, timeValue) => {
            if (dateValue !== null) {
                this.props.gereUnixDate(new Date(dateValue+"T"+timeValue).getTime() / 1000);
            } else {
                this.props.gereUnixDate(null);
            }
        }
        const gereActive = () => {
            const dateValue = this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[0].split("/").reverse().join("-") : null;
            const timeValue = this.props.value !== undefined && this.props.value !== null && !isNaN(this.props.value) ? unixTimestampToDate(this.props.value).split(", ")[1] : "00:00";
            this.setState({
                active: true,
                date: dateValue,
                time: timeValue,
            });
            envoiDate(dateValue, timeValue);
        }

        let classNameDate = [S.style]
        if (!this.state.date_valide) {
            classNameDate.push(S.wrong)
        }
        let classNameHeure = [S.style]
        if (!this.state.heure_valide) {
            classNameHeure.push(S.wrong)
        }
        return this.state.active
            ? <div>
                <input className={classNameDate.join(" ")} type="date"
                       defaultValue={this.state.defaultDate !== null ? this.state.defaultDate[2] + '-' + this.state.defaultDate[1] + '-' + this.state.defaultDate[0] : null}
                       value={this.state.date}
                       onChange={(event) => gereDate(event)}
                       disabled={this.props.disabled === true}
                />
                <input className={classNameHeure.join(" ")} type="time"
                       pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                       defaultValue={this.state.defaultTime !== null ? this.state.defaultTime[0] + ':' + this.state.defaultTime[1] : "00:00"}
                       // value={this.state.time}
                       onChange={(event) => gereTime(event)}
                       disabled={this.props.disabled === true}
                />
                {this.props.disabled === true ? null :
                    <div className={S.BoutonDiv} onClick={gereDesactive}>{checkMot("desactive_la_plage_de_temps")}
                    </div>}

            </div>
            : <div className={S.BoutonDiv} onClick={gereActive}>{checkMot("active_la_plage_de_temps")}</div>
    }
}

export default FormulaireSelectionDateHeureUnix;