export const glossaire_erreurs = {
    100000:"La requête n’est pas au format JSON.",
    100001:"request_type inconnu",
    100002:"Privilège insuffisant pour cette action.",
    100003:"login_name déjà existant",
    100004:"champs vide",
    100005:"utilisateur désactivé",
    100006:"mot de passe incorrect",
    100007:"identifiant inconnu",
    100008:"identifiant déjà existant",
    100009:"ID utilisateur inexistant",
    100010:"Utilisateur non authentifié",
    100011:"confirmation de mot de passe erronée",
    100012:"le mail n’a pas été envoyé",
    100013:"Code de vérification incorrect (réinitialisation mdp)",
    100014:"l’identifiant n’a pas demandé de réinitialisation",
    100015:"nom du projet déjà existant",
    100016:"groupe inexistant",
    100017:"projet inexistant",
    100018:"ID projet inexistant",
    100019:"Nom du capteur déjà existant",
    100020:"ID capteur inexistant",
    100021:"Sensor type déjà existant",
    100022:"ID sensor_type inexistant",
    100023:"Nom de graphe déjà existant",
    100024:"ID graph inexistant",
}

