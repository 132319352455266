import React, {Component} from 'react';
import {connect} from 'react-redux';

import FormulaireConfirmerAlarme from "./FormulaireConfirmerAlarme/FormulaireConfirmerAlarme";
import AffichageGrapheAutomatiqueXT from "../../../../Graphe/AffichageGraphe/AffichageGrapheAutomatiqueXT";

import Collapse from "../../../../../hoc/Collapse/Collapse"

import * as actions from "../../../../../store/actions";
import {checkMot} from "../../../../../ressources/functionJS/checkMot";

import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import {ConteneurInfo, ConteneurProfilVue, hedes, style, text_profil_vue,} from './ProfilAlarmeClient.module.scss';

class ProfilAlarmeClient extends Component {
    state = {
        configurationGraphe: {view_id: null},
        afficherModifierVue: false,
        afficherModifier: false,
    }

    //fonction afficher le formulaire de modification du profil de la alarme
    afficherModifierProfil = () => {
        this.setState({afficherModifierVue: true});
    }
    //fonction pour annuler la modification du profil de la alarme
    annulerModificationProfil = () => {
        this.setState({...this.state, afficherModifierVue: false});
    }
    render() {
        const informationAlarme = <div className={[s.col_md_12, ConteneurProfilVue].join(" ")}>
            <div className={text_profil_vue}>

                <AffichageGrapheAutomatiqueXT
                    key={this.props.infoVue.sensor_id + " " + this.props.infoVue.alarm_position}
                    prefix={this.props.infoVue.prefix}
                    derniereValeur={this.props.infoVue.alarm_value}
                    engineering_unit={this.props.infoVue.engineering_unit}
                    sensor_id={this.props.infoVue.sensor_id}
                    position_in_sensor={this.props.infoVue.alarm_position}
                    debut_date={parseInt(this.props.infoVue.alarm_start) - 24*60*60} // un jour avant
                    fin_date={parseInt(this.props.infoVue.alarm_end) + 24*60*60} // un jour après
                />
                {/*/!*Formulaire du profil de la Alarmes*!/*/}
                {/*<FormulaireVue affichageProfil={!this.state.afficherModifierVue}*/}
                {/*               view_id={this.props.infoVue.view_id}*/}
                {/*               preconfigurerVue={this.props.infoVue}*/}
                {/*               annuler={this.annulerModificationProfil}/>*/}
                {/*Début barre d'outil pour formulaire Alarmes*/}

                {/*Fin barre d'outil pour formulaire Alarmes*/}
            </div>
        </div>
        return (
            <div className={ConteneurInfo}>
                {/*entête du profil alarme*/}
                <div className={[s.card_header, hedes].join(" ")}>
                    {/* logo "i"*/}
                    <span className={style}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-info-circle-fill"
                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>  
                    </span>
                    <span className={style}>
                    {/*l'id de la alarme*/}
                        {checkMot("alarme_id")} : {this.props.infoVue.alarm_id}
                    </span>
                </div>
                {/*conteneur pour l'affichage général du conteneur info*/}
                <div className={s.container}>
                    <Collapse ExpandText={checkMot("info_general")}>
                        {informationAlarme}
                    </Collapse>
                    <Collapse showAdvanceParameters={true} ExpandText={checkMot("confirmer_alarme")}>
                        <div>
                            <FormulaireConfirmerAlarme preconfigurerAlarme={this.props.infoVue}/>
                        </div>
                    </Collapse>
                </div>
            </div>
        )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        //fonction pour envoyer une req de confirmation d'alamre
        confirmerAlarme: (typeAlerte, titre, corps) => dispatch(actions.reqConfirmerAlarme(typeAlerte, titre, corps)),
    };
};
export default connect(null, mapDispatchToProps)(ProfilAlarmeClient);


