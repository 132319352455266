import React, {Component} from 'react';
import {connect} from "react-redux";

import {jsDictionaryEquals} from "../../../../../../../../ressources/functionJS/jsDictionaryEquals";
import * as S from "./ImageRapport.module.scss";
import * as actions from "../../../../../../../../store/actions";
import Collapse from "../../../../../../../../hoc/Collapse/Collapse";
import ImporterImage from "../../../../../../../UI/ImporterImage/ImporterImage";
import {checkMot} from "../../../../../../../../ressources/functionJS/checkMot";


class ImageRapport extends Component {
    state = {
        ...this.props.preconfigureObjet,

    }

    getBase64Image = (url) => {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            this.setState({image_base64: dataURL})
        }
        img.src = url
    }

    async componentDidMount() {
        if (this.props !== undefined && this.props.preconfigureObjet === undefined) {
            this.setState({
                //paramètre par défaut du rapport gabarit(voir si dessus)
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigureObjet !== undefined) {
            this.setState({
                //pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigureObjet,
            })
        }
        if (this.props.preconfigureObjet !== undefined && this.props.preconfigureObjet.image_path !== undefined) {
            this.getBase64Image(this.props.preconfigureObjet.image_path)
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== undefined && prevProps.preconfigureObjet !== this.props.preconfigureObjet && this.props.preconfigureObjet === undefined) {
            this.setState({
                //todo: paramètre par défaut
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigureObjet !== undefined && !jsDictionaryEquals(prevProps.preconfigureObjet, this.props.preconfigureObjet)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigureObjet,
            })
        }
        if (!jsDictionaryEquals(prevState, this.state)) {
            this.props.gererJson(this.state)
        }
        if (this.props.preconfigureObjet.image_path !== prevProps.preconfigureObjet.image_path) {
            // await console.log("this.props.preconfigureObjet.image_path", toObjectUrl(this.props.preconfigureObjet.image_path))
        }
    }

    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value});
    gererImageImporter = (imageImporter) => {
        // Decode Base64 image to URL
        var imageUrl = imageImporter.base64;
        var image = new Image();
        image.src = imageUrl;

        // Get image height and width
        image.onload = () => {
        var width = image.naturalWidth;
        var height = image.naturalHeight;

        //calcul de la hauteur de l'image de manière proportionnelle
        let imgHeight = (height * 450) / width;

        this.setState({
            image_base64: imageImporter.base64,
            width: 450,
            height: imgHeight,
            image_path: "" // supprime le chemin de l'image
        })
        }

       
    }

    render() {
        return (
            <Collapse ExpandText={
                <div className={S.contenuEnTete}>
                    {this.props.contenuEnTete}
                    <span className={S.titre}>{this.state.text}</span>
                    <img className={S.imageVisualisation} alt={checkMot("apercu_image")} src={this.state.image_base64}/>
                </div>
            } ExpandTextOuvert={
                <div className={S.contenuEnTete}>
                    {this.props.contenuEnTete}
                    <span className={S.titre}>{this.state.text}</span>
                </div>
            }>
                <ImporterImage extensionAccepter={"image/png, image/jpeg, image/jpg, .svg"}
                               gererImageImporter={this.gererImageImporter}
                               preconfigureImage={this.state.image_base64}/>
                {this.props.children}
            </Collapse>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reqCreerRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
        reqModifierRapportGabarit: (formulaireRapportGabarit) => dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
    };
};

export default connect(null, mapDispatchToProps)(ImageRapport);
