import React, {Component} from 'react';
import {connect} from "react-redux";

import AjouterProjet from "./Ajouter/AjouterProjet";
import ProfilProjet from "./ProfilProjet/ProfilProjet";
import ConteneurInformation from "../ConteneurInformation/ConteneurInformation";
import TableauProjet from './TableauProjet/TableauProjet';
import Bouton from "../UI/Bouton/Bouton";

import * as indexConteneurInfo from '../../ressources/glossaires/glossaire_conteneur_info';
import Auxiliaire from "../../hoc/Auxiliaire/Auxiliaire";
import * as type_btn from "../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../store/actions";
import {checkMot} from "../../ressources/functionJS/checkMot";
import S from "./Projet.module.scss";
import {BiArrowFromLeft, BiArrowFromRight} from "react-icons/bi";

class Projet extends Component {
    state = {
        parametresSelectione: {},
        montrerInfo: false,
        montrerInfoFull: false,
        indexConteneurInfo: null,
    }
    ligneSelectione = (info) => {
        this.setState({
            ...this.state,
            parametresSelectione: info,
            indexConteneurInfo: indexConteneurInfo.profil,
            montrerInfo: true
        })
    };
    //fonction switch conteneur info
    commutateurInfoCreer = () => this.setState((prevState) => {
        if (prevState.montrerInfo) {
            //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
            return {...this.state, montrerInfo: !prevState.montrerInfo, montrerInfoFull: false}
        } else {
            return {...this.state, montrerInfo: !prevState.montrerInfo}
        }
    });
    //fonction switch conteneur info full screen
    commutateurInfoFull = () => this.setState((prevState) => {
        return {...this.state, montrerInfoFull: !prevState.montrerInfoFull}
    });
    commutateurAnnuler = () => {
        this.props.ajouterAlerte("warning", "Annuler", "Projet non créé");
        this.setState({...this.state, montrerInfo: false, indexConteneurInfo: null});
    }
    creerProjet = () => {
        this.setState({...this.state, montrerInfo: true, indexConteneurInfo: indexConteneurInfo.ajouterForm})
    };

    render() {
        let styleConteneurCentralGauche = [S.ConteneurCentralGauche];
        let styleConteneurCentralDroite = [S.ConteneurCentralDroite];
        let commutateurClasses = [S.CommutateurInfo];
        if (this.state.montrerInfo && this.state.montrerInfoFull) {
            //conteneur info ouvert & plein écran
            //on met la largeur à 100% du conteneur info
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
            //on réduit à zéro la largeur du conteneur central
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
        } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
            //conteneur info ouvert & !plein écran
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
        } else {
            //conteneur info fermé
            styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
            styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
        }
        return (
            <Auxiliaire>
                <div className={[S.ConteneurProjet].join(" ")}>
                    <div className={styleConteneurCentralGauche.join(" ")}>
                        <TableauProjet projetSelectione={this.ligneSelectione} creer={this.creerProjet}/>
                        <div className={S.titleModule}> {checkMot("projets")} </div>
                    </div>
                    {/* bouton plein ferme conteneur  info */}
                    <div className={styleConteneurCentralDroite.join(" ")}>
                        {/* bouton plein ferme conteneur  info */}
                        <Bouton ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
                                cliquer={this.commutateurInfoCreer} rond={true} type={type_btn.danger}>
                            <BiArrowFromLeft size={15}/>
                        </Bouton>
                        {/* bouton plein écran */}
                        <Bouton ajoutClass={S.expand} cliquer={this.commutateurInfoFull} rond={true}
                                type={type_btn.danger}>
                            < BiArrowFromRight size={15}/>
                        </Bouton>
                        <ConteneurInformation
                            selectionInfo={this.state.indexConteneurInfo}
                            ajouterForm={<AjouterProjet ajouterAlerte={this.props.ajouterAlerte}
                                                        annuler={this.commutateurAnnuler}
                                                        ajouter={this.props.reqAjouter}/>}
                            profil={<ProfilProjet infoProjet={this.state.parametresSelectione}
                                                  reqModifier={this.props.reqModifier}
                                                  reqSupprimer={this.props.reqSupprimer}/>}
                        />
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ajouterAlerte: (typeAlerte, titre, corps) => dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
        reqAjouter: (infoNouveauProjet) => dispatch(actions.reqAjouterProjet(infoNouveauProjet)),
        reqModifier: (infoModifierProjet) => dispatch(actions.reqModifierProjet(infoModifierProjet)),
        reqSupprimer: (idProjet) => dispatch(actions.reqSupprimerProjet(idProjet))
    };
};

export default connect(null, mapDispatchToProps)(Projet);