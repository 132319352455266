import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectFilter, textFilter} from "react-bootstrap-table2-filter";

import Tableau from "../../../UI/tableau/Tableau";
import Bouton from "../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../hoc/AttenteRequete/AttenteRequete";

import * as actions from "../../../../store/actions";
import {jsDictionaryEquals} from "../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import * as requestType from "../../../../ressources/glossaires/glossaire_requetes";
import * as type_btn from "../../../../ressources/glossaires/glossaire_type_de_bouton";

import {VscNewFile, VscRefresh} from "react-icons/vsc";
import {VscSearch} from "react-icons/vsc";
import {convertiUtsToDateHour} from "../../../InterfaceClient/Tabs/Alarmes/TableauAlarmesClient/TableauAlarmesClient";

class TableauRapportGenere extends Component {
    state = {
        columns: [// liste des clés pour l'entête du tableau de alarme
            {
                dataField: 'generate_report_id', text: [checkMot("id"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("generate_report_id", checkMot("id"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true
            },
            {
                dataField: 'template_report_id', text: [checkMot("template_report"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("template_report_id", checkMot("template_report"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    for(let i = 0; i<this.props.listeRapportsGabarits.length; i++){
                        if(this.props.listeRapportsGabarits[i].template_report_id === cell){
                            return this.props.listeRapportsGabarits[i].name
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'project_id', text: [checkMot("projet"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("project_id", checkMot("projet"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    for (let i = 0; i < this.props.projects_list.length; i++) {
                        if (this.props.projects_list[i].project_id === cell) {
                            return this.props.projects_list[i].project_name
                        }
                    }
                    return cell;
                },
            },
            {
                dataField: 'creation_time', text: [checkMot("creation_time"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("creation_time", checkMot("creation_time"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return cell!==null?convertiUtsToDateHour(cell):null
                }
            },
            {
                dataField: 'start_report', text: [checkMot("start_report"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("start_report", checkMot("start_report"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return cell!==null?convertiUtsToDateHour(cell):null
                }
            },
            {
                dataField: 'end_report', text: [checkMot("end_report"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("end_report", checkMot("end_report"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    return cell!==null?convertiUtsToDateHour(cell):null
                }
            },
            {
                dataField: 'private', text: [checkMot("statut"),
                    <Bouton type={type_btn.lupeSearch}
                            cliquer={() => this.afficherFiltre("private", checkMot("statut"))}><VscSearch size={10}/>
                    </Bouton>
                ], sort: true,
                formatter: (cell) => {
                    if (parseInt(cell)===1){
                        return checkMot("prive")
                    }else if (parseInt(cell)===0){
                        return checkMot("publie")
                    }
                    return cell
                },
                filter: selectFilter({
                    options: {
                        1: checkMot("prive"),
                        0: checkMot("publie")
                    },
                    placeholder: checkMot("selectionner")
                })
            },
            {
                dataField: 'path_report', text: [checkMot("telecharger")], sort: true,
                formatter: (cell) => {
                    return <a href={cell}>{cell}</a>
                }
            },
        ],
        selectRow: {// paramètre d'affichage des lignes du tableau
            mode: 'radio',
            clickToSelect: true,
            hideSelectAll: true,
            hideSelectColumn: true,
            style: () => {//style après sélection
                return {
                    background: "#eeb214",
                    color: "black",
                };
            },
            onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne
                return this.props.projetSelectione(row);
            }
        },
        iconeCreer: <VscNewFile size={15}/>,
    }

    componentDidMount() {
        //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (!isNaN(this.props.project_id_url) && this.props.project_id_url !== "") {// cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererListeRapportsGeneres([parseInt(this.props.project_id_url)]);
        } else if (this.props.filtre_projects_list_id.length > 0) {// envoie la requête de recupération de alarme si la liste de projets n'est pas vide
            this.props.reqRecupererListeRapportsGeneres(this.props.filtre_projects_list_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //récupère la liste des alarmes_xy en fonction de la liste de projets sélectionné dans le filtre, si re lance la requête si la liste de projets change
        if (!isNaN(this.props.project_id_url) && prevProps.project_id_url !== this.props.project_id_url && this.props.project_id_url !== "") { // cas pour l'interface client utilisation de l'id projet de l'url
            this.props.reqRecupererListeRapportsGeneres([parseInt(this.props.project_id_url)]);
        } else if (JSON.stringify(prevProps.generates_reports_list) !== JSON.stringify(this.props.generates_reports_list)) {// envoie la requête de recupération de alarme si la liste de projets change entre temps
            this.setState({...this.state, generates_reports_list: this.props.generates_reports_list});
        } else if (!jsDictionaryEquals(prevProps.filtre_projects_list_id, this.props.filtre_projects_list_id)) {
            this.props.reqRecupererListeRapportsGeneres(this.props.filtre_projects_list_id);
        }
    }

    afficherFiltre = (dataField, placeholder) => {
        const newCol = this.state.columns.map((col) => {
            if (col.dataField === dataField) {
                return {
                    ...col, filter: textFilter({
                            placeholder: placeholder
                        }
                    )
                }
            } else {
                return col
            }
        })
        this.setState({
            ...this.state,
            columns: [...newCol]
        })
    }

    render() {
        const defaultSorted = [{
            dataField: 'creation_time',
            order: 'desc'
          }];
        return (
            
            <Auxiliaire>
                <Tableau
                    cleLigne={"generate_report_id"}
                    creer={this.props.creer}
                    iconeCreer={this.state.iconeCreer}
                    donnees={this.props.generates_reports_list}
                    colonnes={this.state.columns}
                    selectionnerLignes={this.state.selectRow}
                    defaultSorted={ defaultSorted }
                >
                    <AttenteRequete request_type={requestType.recuperer_la_liste_des_rapports_generes} str={checkMot("maj_rapports_generes_en_cours")}>
                        {/*bouton de rafraîchissement du tableau*/}
                        <Bouton hoverText={checkMot("refresh")} type={type_btn.outil} rond={true} cliquer={() => {
                            this.props.reqRecupererListeRapportsGeneres(this.props.filtre_projects_list_id)
                        }}>
                            <VscRefresh size={15}/>

                        </Bouton>
                        {this.props.children}
                    </AttenteRequete>
                </Tableau>
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeRapportsGabarits: state.rapport.templates_reports_list,
        interfaceAdmin: state.authentifie.interfaceAdmin,
        generates_reports_list: state.rapport.generates_reports_list,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
        project_id_url: state.interfaceClient.project_id_url,
        projects_list: state.authentifie.projects_list,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        reqRecupererListeRapportsGeneres: (listeIdProjets) => dispatch(actions.reqRecupererListeRapportsGeneres(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauRapportGenere);
