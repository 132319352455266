import React, {Component} from 'react';
import {connect} from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import * as actions from "../../../../store/actions";
import Bouton from "../../Bouton/Bouton";
import {checkMot} from "../../../../ressources/functionJS/checkMot";

class TableauGroupeEmail extends Component {
    componentDidMount() {
        //importer la table de sensorType (utiliser la requête api)
        this.props.recupererListeGroupesEmails(this.props.filtre_projects_list_id);
    }

    state = {
        columns: [
            {dataField: 'group_email_id', text: checkMot("group_email_id"), sort: true},
            {dataField: 'project_id', text: checkMot("project_id"), sort: true},
            {dataField: 'email_group_name', text: checkMot("email_group_name"), sort: true},
        ],
        list_emails_groups: this.props.list_emails_groups,
        selectRow: {
            mode: 'radio',
            clickToSelect: true,
            style: {
                background: "#a6153e",
                color: "white",
            },
            onSelect: (row, isSelect) => {
                if (isSelect) {
                    return this.props.selectioneLigne(row);
                }
            }
        }
    }

    render() {
        return (
            this.props.list_emails_groups !== undefined && this.props.list_emails_groups !== []
                ? <BootstrapTable
                    keyField={"group_email_id"}
                    data={this.props.list_emails_groups}
                    columns={this.state.columns}
                    selectRow={this.state.selectRow}
                />
                : <Bouton
                    cliquer={() => this.props.recupererListeGroupesEmails(this.props.filtre_projects_list_id)}>{checkMot("refresh")}</Bouton>
        );
    }
}


const mapStateToProps = state => {
    return {
        list_emails_groups: state.alarme.list_emails_groups,
        filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        recupererListeGroupesEmails: (listeIdProjets) => dispatch(actions.reqListeGroupesEmails(listeIdProjets)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableauGroupeEmail);