import React, { Component } from "react";
import S from "./AideAdmin.module.scss";
import * as s from "../../ressources/StyleGlobal/StyleGlobal";
// import Bouton from "../../composant/UI/Bouton/Bouton";
// import Auxiliaire from "../../hoc/Auxiliaire/Auxiliaire";
// import * as actions from "../../store/actions";
// import {connect} from "react-redux";
import { IoMdHelpCircle } from "react-icons/io";
import { RiVideoFill } from "react-icons/ri";
import { GrDocumentPdf } from "react-icons/gr";
import { checkMot } from "../../ressources/functionJS/checkMot";
import { RiFolderOpenFill } from "react-icons/ri";
import { RiLineChartFill } from "react-icons/ri";
import { GiMovementSensor } from "react-icons/gi";
import { RiTreasureMapFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import * as url from "../../ressources/glossaires/glossaire_liens_pages";
class AideAdmin extends Component {
  state = {};

  render() {
    return (
      <div className={[S.container,  S.molder].join(" ")}>
        <div className={S.title}>
          <IoMdHelpCircle size={49} color={"#EEB214"} /> {checkMot("aide")}
          <Link to={url.Formation}>
            <div className={S.bts}>
              <span className={S.Span}>/</span>

              {checkMot("client")}
            </div>
          </Link>
        </div>

        <div className={[ S.img].join(" ")}>
          <h4 className={S.subtitle}>{checkMot("bienvenue_dans_argos_v19")}</h4>
          <p className={S.Parag}>
            {checkMot("Nous sommes ravis de vous compter parmi nous ! consulte le guide l'essentiel pour avoir une vue d'ensemble de tous les aspects de ArgosV19. Consultez des vidéos pour obtenir d'autres informations.")}
          </p>
        </div>
        {/* <div className={[s.order_sm_3, S.intro].join(" ")}>
          <h4 className={S.subtitle}>{checkMot("formation")}</h4>
          <div className={S.Parag}>
            <div className={S.icon}>
              <RiVideoFill size={40} color={"#a6163f"} />
            </div>
            <a
              onClick={() =>
                window.open(
                  "/videos/demo.MP4",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
              className={S.lien}
            >
              {checkMot("regardez_la_video")}{" "}
            </a>
          </div>
        </div> */}
        <div className={[s.order_sm_3, S.bloc].join(" ")}>
          <h4 className={S.subtitle}>
            <RiFolderOpenFill size={40} color={"#EEB214"} />{" "}
            {checkMot("Démarrage rapide : L'essentiel")}
          </h4>
          <div className={S.Parag}>
          <div className={S.icon}><GrDocumentPdf size={20} color={"#a6163f"}/></div>
                        <a onClick={() => window.open('/pdf/start.pdf', '_blank', 'toolbar=0,location=0,menubar=0, width=1200, height=750')} className={S.lien} >{checkMot("Guide l'essentiel")}  </a>
          </div>
          <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/Creationdeprojet.pdf")} className={S.lien}>
              {checkMot("creation_de_projet")}{" "}
            </a>
          </div>
            {/* <div className={S.Parag}>
              <div className={S.icon}>
                <GrDocumentPdf size={20} />
              </div>
              <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
                {checkMot("modifier_un_projet")}
              </a>
            </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {" "}
              {checkMot("Niveau d'accès de un projet")}{" "}
            </a>
          </div> */}
        </div>
        <div className={[s.order_sm_3, S.bloc].join(" ")}>
          <h4 className={S.subtitle}>
            <GiMovementSensor size={40} color={"#EEB214"} />{" "}
            {checkMot("capteurs")}
          </h4>
          <div className={S.Parag}>
            <div className={S.icon}>
              <RiVideoFill size={40} color={"#a6163f"} />
            </div>
            <a
              onClick={() =>
                window.open(
                  "/videos/Capteur.mp4",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
              className={S.lien}
            >
              {" "}
              {checkMot("configuration_des_capteurs")}
            </a>
          </div>
          <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/CreationdeCapteurs.pdf")} className={S.lien}>
              {checkMot("creation_de_capteurs")}{" "}
            </a>
          </div>
          <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/Creationtypedecapteur.pdf")} className={S.lien}>
              {checkMot("creation_type_de_capteur")}{" "}
            </a>
          </div>
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {" "}
              {checkMot("Sous-conposante de capteur")}{" "}
            </a>
          </div> */}
        </div>
        <div className={[s.order_sm_3, S.bloc].join(" ")}>
          <h4 className={S.subtitle}>
            <RiLineChartFill size={40} color={"#EEB214"} />{" "}
            {checkMot("graphes")}
          </h4>
          <div className={S.Parag}>
            <div className={S.icon}>
              <RiVideoFill size={40} color={"#a6163f"} />
            </div>
            <a  className={S.lien} onClick={() => window.open('/videos/graphe_demo.mp4', '_blank', 'toolbar=0,location=0,menubar=0, width=1200, height=750')}> {checkMot("affichage_des_graphes")}</a>

          </div>
          <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/Creationdesgraphes.pdf")} className={S.lien}>
              {checkMot("creation_de_graphe")}{" "}
            </a>
          </div>
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("modifier_un_graphe")}{" "}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("graphes_personnaliser")}{" "}
            </a>
          </div> */}
        </div>
        <div className={[s.order_sm_3, S.bloc].join(" ")}>
          <h4 className={S.subtitle}>
            <RiTreasureMapFill size={40} color={"#EEB214"} /> {checkMot("vues")}
          </h4>
          <div className={S.Parag}>
            <div className={S.icon}>
              <RiVideoFill size={40} color={"#a6163f"} />
            </div>
            <a  className={S.lien} onClick={() => window.open('/videos/vues_demo.mp4', '_blank', 'toolbar=0,location=0,menubar=0,width=1000, height=750')}> {checkMot("Affichages : Tableaux de bord et Plans")}</a>

          </div>
          <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/Creationdesvues.pdf")} className={S.lien}>
              {checkMot("creation_des_vue")}{" "}
            </a>
          </div>
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/Création des vues.pdf")} className={S.lien}>
              {checkMot("modifier_un_vue")}{" "}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("vues_personnaliser")}{" "}
            </a>
          </div> */}
        </div>
        {/* <div className={[s.order_sm_3, S.bloc].join(" ")}>
          <h4 className={S.subtitle}>
            <FaUsers size={40} color={"#EEB214"} /> {checkMot("utilisateurs")}
          </h4>
          <div className={S.Parag}>
            <div className={S.icon}>
              <RiVideoFill size={40} color={"#a6163f"} />
            </div>
            <a
              className={S.lien}
              onClick={() =>
                window.open(
                  "/videos/demo.MP4",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              {" "}
              {checkMot("configurer_et_personnaliser_des_utilisateurs")}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("creation_de_utilisateur")}{" "}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("modifiers_un_utilisateur")}{" "}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("Niveau d'accès de un Utilisateur")}{" "}
            </a>
          </div> */}
        {/* </div> */}
        {/* <div className={[s.order_sm_3, S.bloc].join(" ")}>
          <h4 className={S.subtitle}>
            <MdNotificationsActive size={40} color={"#EEB214"} />{" "}
            {checkMot("alarme")}
          </h4> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <RiVideoFill size={40} color={"#a6163f"} />
            </div>
            <a
              className={S.lien}
              onClick={() =>
                window.open(
                  "/videos/demo.MP4",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              {" "}
              {checkMot("configurer_et_personnaliser_des_alarmes")}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("creation_de_alarme")}{" "}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("modifier_un_alarme")}{" "}
            </a>
          </div> */}
          {/* <div className={S.Parag}>
            <div className={S.icon}>
              <GrDocumentPdf size={20} />
            </div>
            <a onClick={() => window.open("pdf/demo.pdf")} className={S.lien}>
              {checkMot("alarme_personnaliser")}{" "}
            </a>
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}

export default AideAdmin;
