import React, {Component} from 'react';
import {connect} from "react-redux";

import Bouton from "../../composant/UI/Bouton/Bouton";

import Logo from "../../composant/Logo/Logo";
import RubriquesNavigationAdmin from "../../composant/Navigation/RubriquesNavigationAdmin/RubriquesNavigationAdmin";
import RubriquesNavigationClient from "../../composant/Navigation/RubriquesNavigationClient/RubriquesNavigationClient";
import EnTete from "../EnTete/EnTete";

import Auxiliary from '../../hoc/Auxiliaire/Auxiliaire';

import * as type_btn from "../../ressources/glossaires/glossaire_type_de_bouton";
import * as s from "../../ressources/StyleGlobal/StyleGlobal";
import {BiArrowFromRight, BiArrowFromLeft} from "react-icons/bi"

import S from './Menu.module.scss';
import {Link} from "react-router-dom";
import * as url from "../../ressources/glossaires/glossaire_liens_pages";

class Menu extends Component {

    render() {
        // classe du menu fermé par défaut
        let attachedClasses = [s.d_flex, s.flex_column, S.Menu, S.Fermer, S.RubliquesNavigationScroll];

        // classe du commutateur du menu fermé par défaut
        let commutateurClasses = [];

        if (this.props.interfaceAdmin) {
            //classe du commutateur du menu ouvert admin
            commutateurClasses.push(S.CommutateurMenuAdmin)
        } else {
            //classe du commutateur du menu ouvert client
            commutateurClasses.push(S.CommutateurMenu)
        }

        // icône du commutateur du menu ouvert par défaut
        let iconeFleche =   <Bouton ajoutClass={S.ckas}  rond={true} type={type_btn.danger}>
                                    <BiArrowFromLeft size={15}/>
                            </Bouton>

        //classe de l'élément fond gris
        let fondGris = [S.FondGrisMenu]

        //affiche le menu
        if (this.props.ouvert) {
            //classe du menu ouvert
            attachedClasses = [S.Menu, S.Ouvert];

            //classe du commutateur du menu ouvert
            commutateurClasses.push(S.CommutateurOuvert);

            // icône du commutateur du menu fermé <IoMdArrowDropleft size={60}/>
            iconeFleche = <BiArrowFromRight size={25}/>
    
        } else {
            commutateurClasses.push(S.CommutateurFermer)
            //fait disparaitre le fond gris si le menu est fermé
            fondGris.push(S.disparait)
        }
        return (
            <Auxiliary>
                <div className={[attachedClasses.join(' '), S.RubliquesNavigationScroll].join(" ")}>
                    {this.props.interfaceAdmin
                        ? null
                        : <Link to={url.accueil}><Logo/></Link>
                    }
                    <div className={[].join(" ")}>
                        <EnTete/>
                        <nav>
                            {this.props.interfaceAdmin
                                ?<RubriquesNavigationAdmin/>
                                :<RubriquesNavigationClient/>
                            }
                        </nav>
                    </div>
                    {/*todo : fixer le pied de page quand il y a trop d'élement dans le menu-> <PiedPage/>*/}
                    {this.props.interfaceAdmin
                        ? <Link to={url.projet}><Logo/></Link>
                        : null
                    }
                </div>

                {/*Commutateur Menu*/}
                <span className={commutateurClasses.join(' ')} onClick={this.props.commutateur}>
                {iconeFleche}
                </span>
                {/*Affichage du fond gris*/}
                <span className={fondGris} onClick={this.props.commutateur}/>
                {/*Affichage du bouton commutateur du menu*/}
                {/* <Bouton ajoutClass={S.BoutonBurger} cliquer={this.props.commutateur}>
                    {iconeFleche}
                </Bouton> */}
            </Auxiliary>
        );
    }


}
const mapStateToProps = state => {
    return {
        interfaceAdmin: state.authentifie.interfaceAdmin,
    };
}

export default connect(mapStateToProps)(Menu);