import React, {Component} from 'react';
import {connect} from "react-redux";

import FormulaireSelectionProjet
    from "../../../../../../composant/Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet";
import FormulaireCourbe from "./FormulaireCourbe/FormulaireCourbe";
import FormulaireAxe from "./FormulaireAxe/FormulaireAxe";
import FormulaireSelectionDateHeureUnix from "./FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix";
import FormulaireSelectUtsDate from "./FormulaireSelectUtsDate/FormulaireSelectUtsDate";

import Bouton from "../../../../../UI/Bouton/Bouton";

import Auxiliaire from "../../../../../../hoc/Auxiliaire/Auxiliaire";
import AttenteRequete from "../../../../../../hoc/AttenteRequete/AttenteRequete";
import Collapse from "../../../../../../hoc/Collapse/Collapse";

import * as requestType from '../../../../../../ressources/glossaires/glossaire_requetes';
import * as glossaire_type_de_graphe from "../../../../../../ressources/glossaires/glossaire_type_de_graphe";
import {jsDictionaryEquals} from "../../../../../../ressources/functionJS/jsDictionaryEquals";
import {checkMot} from "../../../../../../ressources/functionJS/checkMot";
import * as type_btn from "../../../../../../ressources/glossaires/glossaire_type_de_bouton";
import * as actions from "../../../../../../store/actions";

import * as s from '../../../../../../ressources/StyleGlobal/StyleGlobal';
import {AiFillPlusCircle} from "react-icons/ai";
import {AiOutlineCheck} from "react-icons/ai";
import * as S from './FormulaireGraphe.module.scss';
import FormulaireSelectionGabarits from "../../../../../UI/FormulaireSelectionGabarits/FormulaireSelectionGabarits";

const valeurParDefautCourbe = {
    //config générale d'une courbe
    "name": "", //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: nom de la courbe
    "show_alarms": 1, //TYPE: int + ValeurParDéfaut: 1 +  DESCRIPTION: affichage des seuils sur le graphe, 1 (affiche les alarmes) ou 0 (masque les alarmes)
    "formula": "", //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
    "parameters": "", //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
    "yaxis": "y1", //TYPE: String + ValeurParDéfaut: "y1" +  DESCRIPTION: "y1" ou "y2"
    "type": "scatter", //TYPE: String + ValeurParDéfaut: "scatter" +  DESCRIPTION: "scatter" ou "bar"

    //configuration pour un type de courbe scatter
    "mode": "lines+markers", //TYPE: String + ValeurParDéfaut: "lines+markers" +  DESCRIPTION: 'lines et/ou markers et/ou text' concaténer avec un "+"

    //configuration pour un mode incluant line
    "line": { //TYPE: Object + ValeurParDéfaut: {} +  DESCRIPTION: si mode contient "line"
        "dash": 'solid', //TYPE: String + ValeurParDéfaut: "solid" +  DESCRIPTION:  'solid' ou 'dashdot' ou 'dot'
        "shape": 'linear', //TYPE: String + ValeurParDéfaut: "linear" +  DESCRIPTION: 'vh' ou 'hv' ou 'linear' ou 'spline' ou 'hvh' ou 'vhv'
    },
}

class FormulaireGraphe extends Component {
    state = {
        //paramètre par défaut du graphe
        private: 1,
        time_refresh: null,
        time_period: 2678400, // 31×24×60×60=2678400 secondes = 31 jour
        measures_num: null,
        select_time: [],
        time_interval: [null, null],

        graph_type_id: glossaire_type_de_graphe.graphe_x_t,
        graph_name: "",
        title: "",
        subtitle: "",
        showgrid: 1,
        showlegend: 1,
        xaxis: {
            title: "",
            type: "date",
            range: [null, null],
        },
        yaxis: {
            title: "",
            type: "linear",
            range: [null, null],
        },
        yaxis2: {
            title: "",
            type: "linear",
            range: [null, null],
        },
        barmode: "group",
        curves_conf_list: [],
        list_template_id: [],
    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigurerGraphe === undefined) {
            this.setState({
                //todo: paramètre par défaut du graphe
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerGraphe !== undefined) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerGraphe,
                xaxis: {
                    ...this.props.preconfigurerGraphe.xaxis
                },
                yaxis: {
                    ...this.props.preconfigurerGraphe.yaxis
                },
                yaxis2: {
                    ...this.props.preconfigurerGraphe.yaxis2
                },
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.curves_conf_list !== prevState.curves_conf_list) {
            //liste formulaire de courbe
            let listeFormulaireCourbe = [];
            for (let indCourbe = 0; indCourbe < this.state.curves_conf_list.length; indCourbe++) {
                listeFormulaireCourbe.push(<Auxiliaire>
                    <div className={[S.info].join(" ")}><FormulaireCourbe
                        preConfigCourbe={{...this.state.curves_conf_list[indCourbe]}}
                        gererConfigCourbe={(configCourbe) => this.gererCourbe(indCourbe, configCourbe)}
                        supprimerCourbe={() => this.supprimerIndiceCourbe(indCourbe)}
                        disabled={this.props.affichageProfil === true}
                    /></div>
                </Auxiliaire>)
            }
            this.setState({
                ...this.state,
                listeFormulaireCourbe: listeFormulaireCourbe
            })
        }

        if (this.props !== undefined && this.props.preconfigurerGraphe === undefined && !jsDictionaryEquals(prevProps, this.props)) {
            this.setState({
                //todo: paramètre par défaut du graphe
                ...this.state,
            })
        } else if (this.props !== undefined && this.props.preconfigurerGraphe !== undefined && !jsDictionaryEquals(prevProps.preconfigurerGraphe, this.props.preconfigurerGraphe)) {
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerGraphe,
                xaxis: {
                    ...this.props.preconfigurerGraphe.xaxis
                },
                yaxis: {
                    ...this.props.preconfigurerGraphe.yaxis
                },
                yaxis2: {
                    ...this.props.preconfigurerGraphe.yaxis2
                },
            })
        }
    }

    gererEnvoi = () => {
        //mise en forme de l'objet pour l'envoi de la requête API
        let formulaireGraphe = {
            //clé du formulaire de graphe de l'api
            project_id: parseInt(this.state.project_id),
            private: parseInt(this.state.private),
            time_refresh: parseInt(this.state.time_refresh),
            time_period: parseInt(this.state.time_period),
            measures_num: parseInt(this.state.measures_num),
            select_time: this.state.select_time,
            time_interval: [parseInt(this.state.time_interval[0]), parseInt(this.state.time_interval[1])],

            graph_type_id: parseInt(this.state.graph_type_id),
            graph_name: this.state.graph_name,
            title: this.state.title,
            subtitle: this.state.subtitle,
            showlegend: parseInt(this.state.showlegend),
            showgrid: this.state.showgrid === null ? 1 : parseInt(this.state.showgrid),
            xaxis: {
                ...this.state.xaxis
            },
            yaxis: {
                ...this.state.yaxis
            },
            yaxis2: {
                ...this.state.yaxis2
            },
            curves_conf_list: [...this.state.curves_conf_list],
            list_template_id: [...this.state.list_template_id],
            barmode: this.state.barmode,
        }
        // formulaireGraphe["configs_y2axis_list"] = newConfigs_y2axis_list;

        // //ajout de la configuration de barmode si une courbe au moins à un type === "bar"
        // for(let courbe of this.state.curves_conf_list){
        //     if(courbe.type === "bar"){
        //         formulaireGraphe["barmode"] = this.state.barmode
        //         break;
        //     }
        // }

        //Envoi la requête de création de graphe si le graphe n'a pas de pré-remplissage des champs du formulaire sinon modification
        if (this.props !== undefined && this.props.preconfigurerGraphe === undefined) {
            this.props.reqCreerGraphe(formulaireGraphe);
        } else {
            formulaireGraphe["graph_id"] = parseInt(this.props.preconfigurerGraphe.graph_id);
            this.props.reqModifierGraphe(formulaireGraphe)
        }
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererAxe = (axe, etatAxe) => {
        console.log(axe, etatAxe, this.state)
        this.setState({...this.state, [axe]: etatAxe})
    };
    gererProjetId = (projetId) => {
        this.setState({...this.state, project_id: projetId});
    };
    gererListTemplateId = (listTemplateId) => {
        this.setState({...this.state, list_template_id: [...listTemplateId]});
    };
    ajouterCourbe = () => this.setState(prevState => ({
        ...this.state,
        curves_conf_list: [...prevState.curves_conf_list, valeurParDefautCourbe]
    }));
    gererCourbe = (indCourbe, newConfigCourbe) => {
        let newCurves_conf_list = [...this.state.curves_conf_list];
        newCurves_conf_list[indCourbe] = newConfigCourbe;
        this.setState({...this.state, curves_conf_list: newCurves_conf_list});
    }
    supprimerIndiceCourbe = (indCourbe) => {
        let newCurves_conf_list = [];
        for (let courbe of this.state.curves_conf_list) {
            newCurves_conf_list.push({...courbe});
        }
        newCurves_conf_list.splice(indCourbe, 1);
        this.setState({...this.state, curves_conf_list: newCurves_conf_list});
    }
    gererConfigSelectUtsDate = (newConfigSelectUtsDate) => {
        this.setState({...this.state, select_time: [...newConfigSelectUtsDate]});
    }
    gereDate = (event) => {
        this.setState({date: event.target.value});

        if (this.state.date !== null) {
            const splitDate = this.state.date.split("-");
            const splitHour = this.state.time.split(":");
            //les mois sont compris entre 0 and 11 (January to December).
            //Les heures sont comprises entre 0 et 23 (00h pour minuit et 23h)
            let datum = new Date(Date.UTC(splitDate[0], parseInt(splitDate[1]) - 1, splitDate[2], parseInt(splitHour[0]) - 1, splitHour[1], '00'));
            const timeToUnix = datum.getTime();
            this.setState({select_xaxis_manual_scalingMinX: timeToUnix / 1000});
        } else {
            // onUpdate(null);
        }
    }
    gererDebut_time_interval = (unix) => this.setState({
        ...this.state, time_interval: [unix, this.state.time_interval[1]]
    });
    gererFin_time_interval = (unix) => this.setState({
        ...this.state, time_interval: [this.state.time_interval[0], unix]
    });

    render() {
        //configuration en fonction du type de graphe
        let formulaire_par_type_de_graphe;
        switch (parseInt(this.state.graph_type_id)) {
            case glossaire_type_de_graphe.graphe_x_t:
                formulaire_par_type_de_graphe =
                    <div className={[S.info, s.col_xl_12].join(" ")}>
                        <FormulaireAxe gererConfigAxe={this.gererAxe}
                                       axisKey={"xaxis"}
                                       axisName={checkMot("xaxis")}
                                       preConfigAxe={{
                                           ...this.state.xaxis,
                                           type: "date",//Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                                       }}
                                       disabled={this.props.affichageProfil === true}/>
                    </div>
                break;
            case glossaire_type_de_graphe.graphe_x_y:
                formulaire_par_type_de_graphe =
                    <div className={[S.info, s.col_xl_12].join(" ")}>
                        <FormulaireAxe gererConfigAxe={this.gererAxe}
                                       axisKey={"xaxis"}
                                       axisName={checkMot("xaxis")}
                                       preConfigAxe={{
                                           ...this.state.xaxis,
                                           type: "linear",//Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                                       }}
                                       disabled={this.props.affichageProfil === true}/>
                    </div>
                break;
            case glossaire_type_de_graphe.graphe_x_y_t:
                formulaire_par_type_de_graphe =
                    <div className={[S.info, s.col_xl_12].join(" ")}>
                        <FormulaireAxe gererConfigAxe={this.gererAxe}
                                       axisKey={"xaxis"}
                                       axisName={checkMot("xaxis")}
                                       preConfigAxe={{
                                           ...this.state.xaxis,
                                           type: "linear",//Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                                       }}
                                       disabled={this.props.affichageProfil === true}/>
                    </div>
                break;
            default:
                formulaire_par_type_de_graphe = <div>{checkMot("veuillez_selectionner_un_type_de_graphe")}</div>
                break;
        }


        //Fomulaire de configuration général de bar si une courbe au moins à un type === "bar"
        let formulaire_general_bar;
        for (let courbe of this.state.curves_conf_list) {
            if (courbe.type === "bar") {
                formulaire_general_bar =

                    <div className={[s.row, S.info].join(" ")}>{/**DEBUT private**/}
                        <div className={s.col_12}>{/**DEBUT private**/}
                            <div className={[S.stil2].join(" ")}>{checkMot("mode_d_affichage_de_bar")}</div>
                            <select
                                onChange={(event) => this.gererEtatDeCibleName(event)}
                                name="barmode"
                                defaultValue={this.state.barmode}
                                disabled={this.props.affichageProfil === true}
                                value={this.state.barmode}
                                className={[s.custom_select, s.mr_sm_2].join(" ")}>
                                <option value="stack">{checkMot("stack")}</option>
                                <option value="group">{checkMot("groupe")}</option>
                                <option value="relative">{checkMot("relative")}</option>
                            </select>
                        </div>
                        {/**FIN private**/}

                    </div>;
                break;
            } else {
                formulaire_general_bar = null;
            }
        }
        return (
            <Auxiliaire>
                <div className={S.ConteneurInfo}>
                    <div className={[s.container, s.text_left].join(" ")}>
                        <form onSubmit={event => event.preventDefault()}>
                            <Collapse ExpandText={checkMot("info_general")}>
                                {/**DEBUT project_id **/}
                                <div className={s.form_row}>
                                    <FormulaireSelectionProjet affichageProfil={this.props.affichageProfil}
                                                               gererProjetId={this.gererProjetId}
                                                               preconfigurerProjetId={this.state.project_id}/>
                                </div>
                                {/**FIN project_id **/}
                                <div className={s.form_group}>{/**DEBUT graph_name**/}
                                    <div className={[s.form_row, s.align_items_center].join(" ")}>
                                        <div className={s.col_12}>
                                            <label className={[S.stil2].join(" ")}>{checkMot("nom_du_graphe")}</label>
                                            <input
                                                type="text"
                                                pattern="[0-9a-zA-Z._%+-\\s ]+"
                                                className={[s.form_control, s.mb_2].join(" ")}
                                                placeholder="nom_du_graphe"
                                                name="graph_name"
                                                value={this.state.graph_name}
                                                disabled={this.props.affichageProfil === true}
                                                required
                                                onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                        </div>
                                    </div>
                                </div>
                                {/**FIN graph_name**/}

                                <div className={s.form_group}>{/**DEBUT graph_title **/}
                                    <div className={s.form_row}>
                                        <div className={s.col_6}>
                                            <label className={S.stil2}>{checkMot("titre_du_graphe")}</label>
                                            <input
                                                type="text"
                                                pattern="[0-9a-zA-Z._%+-\\s ]+"
                                                className={[s.form_control, s.mb_2].join(" ")}
                                                placeholder="titre_du_graphe"
                                                name="title"
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.title}
                                                onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                        </div>

                                        <div className={s.col_6}>
                                            <label className={S.stil2}>{checkMot("sous_titre_du_graphe")}</label>
                                            <input
                                                type="text"
                                                pattern="[0-9a-zA-Z._%+-\\s ]+"
                                                className={[s.form_control, s.mb_2].join(" ")}
                                                placeholder="sous_titre_du_graphe"
                                                name="subtitle"
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.subtitle}
                                                onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                        </div>
                                    </div>
                                </div>
                                {/**FIN graph_title **/}
                                {/**DEBUT private**/}
                                <div className={[s.form_row, S.heder].join(" ")}>
                                    <div className={s.col_12}>
                                        <label className={S.stil2}>{checkMot("acces_prive")}</label>
                                        <select
                                            onChange={(event) => this.gererEtatDeCibleName(event)}
                                            name="private"
                                            defaultValue={this.state.private}
                                            disabled={this.props.affichageProfil === true}
                                            value={this.state.private}
                                            className={[s.custom_select, S.stile].join(" ")}>
                                            <option value={1}>{checkMot("prive")}</option>
                                            <option value={0}>{checkMot("publique")}</option>
                                        </select>
                                    </div>
                                </div>
                                {/**FIN private**/}


                                <div className={[s.form_group, S.stil2].join(" ")}>{/**DEBUT showlegend and showgrid**/}
                                    <div className={s.form_row}>
                                        <div className={s.col_6}>{/**DEBUT showgrid**/}
                                            <label className={s.card_header}>{checkMot("grille")}</label>
                                            <select
                                                onChange={(event) => this.gererEtatDeCibleName(event)}
                                                name="showgrid"
                                                defaultValue={this.state.showgrid}
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.showgrid}
                                                className={[s.custom_select, s.mr_sm_2].join(" ")}>
                                                <option value={1}>{checkMot("afficher_la_grille_de_fond")}</option>
                                                <option value={0}>{checkMot("masquer_la_grille_de_fond")}</option>
                                            </select>
                                        </div>
                                        {/**FIN showgrid**/}
                                        <div className={s.col_6}>{/**DEBUT showlegend**/}
                                            <label className={S.stil2}>{checkMot("legende_du_graphe")}</label>
                                            <select
                                                onChange={(event) => this.gererEtatDeCibleName(event)}
                                                name="showlegend"
                                                defaultValue={this.state.showlegend}
                                                disabled={this.props.affichageProfil === true}
                                                value={this.state.showlegend}
                                                className={[s.custom_select, s.mr_sm_2].join(" ")}>
                                                <option value={1}>{checkMot("afficher_la_legende")}</option>
                                                <option value={0}>{checkMot("masquer_la_legende")}</option>
                                            </select>
                                        </div>
                                        {/**FIN showlegend**/}
                                    </div>
                                </div>
                                {/**FIN showlegend and showgrid and **/}


                                {/*formulaire pour barmode si au moins une courbe et affiché en type bar*/ formulaire_general_bar}

                                <div className={s.form_group}>{/**DEBUT graph_type_id**/}
                                    <label className={S.stil2}>{checkMot("type_de_graphe")}</label>
                                    <select
                                        onChange={(event) => this.gererEtatDeCibleName(event)}
                                        name="graph_type_id"
                                        defaultValue={this.state.graph_type_id}
                                        value={this.state.graph_type_id}
                                        disabled={this.props.preconfigurerGraphe !== undefined || this.props.affichageProfil === true}
                                        className={[s.custom_select, s.mr_sm_2].join(" ")}>
                                        {Object.keys(glossaire_type_de_graphe).map((keyTypeDeGraphe) => {
                                                if (keyTypeDeGraphe !== "graphe_automatique") {
                                                    return <option value={glossaire_type_de_graphe[keyTypeDeGraphe]}>
                                                        {checkMot(keyTypeDeGraphe)}
                                                    </option>
                                                }
                                            }
                                        )}
                                    </select>
                                </div>

                                {/**FIN graph_type_id**/}


                            </Collapse>
                            <Collapse ExpandText={checkMot("info_avance")}>
                                <div className={[S.stil2, S.stilles].join(" ")}>
                                    {/*Formulaire pour l'interval de récupération des données*/}
                                    <div className={s.form_group}>{/**DEBUT time_interval**/}
                                        <div className={[s.form_row, s.align_items_center].join(" ")}>
                                            <label
                                                className={[s.col_12, S.stil2].join(" ")}>{checkMot("interval_de_recuperation_de_donnees_debut_fin")}</label>
                                            <div className={[s.col_6, s.card_body].join(" ")}>
                                                <FormulaireSelectionDateHeureUnix value={this.state.time_interval[0]}
                                                                                  gereUnixDate={this.gererDebut_time_interval}
                                                                                  disabled={this.props.affichageProfil === true}/>
                                            </div>
                                            <div className={[s.col_6, s.card_body].join(" ")}>
                                                <FormulaireSelectionDateHeureUnix value={this.state.time_interval[1]}
                                                                                  gereUnixDate={this.gererFin_time_interval}
                                                                                  disabled={this.props.affichageProfil === true}/>
                                            </div>
                                        </div>
                                    </div>
                                    {/**FIN time_interval**/}


                                    <div className={[S.sti1].join(" ")}>
                                        <div className={s.form_group}>{/**DEBUT time_refresh**/}
                                            <div className={s.row}>
                                                <div className={s.col_12}>
                                                    <label
                                                        className={S.stil2}>{checkMot("temps_apres_actualisation")}</label>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9a-zA-Z._%+-]+"
                                                        className={[s.form_control, s.mb_2, s.card_header].join(" ")}
                                                        placeholder="temps_apres_actualisation"
                                                        name="time_refresh"
                                                        value={this.state.time_refresh}
                                                        disabled={this.props.affichageProfil === true}
                                                        required
                                                        onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/**FIN time_refresh**/}
                                        <div className={s.form_group}>{/**DEBUT time_period**/}
                                            <div className={s.form_row}>
                                                <div className={s.col_12}>
                                                    <label
                                                        className={S.stil2}>{checkMot("recuperation_des_donnees_sur_le_dernier_interval_de_temps_jusqu_a_present")}</label>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9a-zA-Z._%+-]+"
                                                        className={[s.form_control, s.mb_2].join(" ")}
                                                        placeholder="recuperation_des_donnees_sur_le_dernier_interval_de_temps_jusqu_a_present"
                                                        name="time_period"
                                                        value={this.state.time_period}
                                                        disabled={this.props.affichageProfil === true}
                                                        required
                                                        onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/**FIN time_period**/}
                                        <div className={s.form_group}>{/**DEBUT measures_num**/}
                                            <div className={s.form_row}>
                                                <div className={s.col_12}>
                                                    <label
                                                        className={S.stil2}>{checkMot("recuperation_des_n_derniere_donnees_jusqu_a_present")}</label>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9]+"
                                                        className={[s.form_control, s.mb_2].join(" ")}
                                                        placeholder="measures_num"
                                                        name="measures_num"
                                                        value={this.state.measures_num}
                                                        disabled={this.props.affichageProfil === true}
                                                        required
                                                        onChange={(event) => this.gererEtatDeCibleName(event)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/**FIN measures_num**/}
                                        <div className={s.form_group}>{/**DEBUT select_time**/}
                                            <div className={s.form_row}>
                                                <div className={s.col_12}>
                                                    <label
                                                        className={S.stil2}>{checkMot("recuperation_des_donnees_des_dates_selectionnees")}</label>
                                                    <FormulaireSelectUtsDate
                                                        preConfigSelectUtsDate={this.state.select_time}
                                                        gererConfigSelectUtsDate={this.gererConfigSelectUtsDate}
                                                        disabled={this.props.affichageProfil === true}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        {/**FIN select_time**/}
                                    </div>

                                    {/*Formulaire de configuration du graphe en général*/}
                                </div>


                            </Collapse>
                            <Collapse ExpandText={checkMot("parametre_de_axe")}>

                                {
                                    /*sélection de x-t ou x-y
                                 affichage de composant spécifique à x-t
                                     xaxis en type: uts
                                 affichage de composant spécifique à x-y
                                     xaxis en type: double*/
                                    formulaire_par_type_de_graphe
                                }
                                <div className={[s.col_12].join(" ")}>
                                    <FormulaireAxe gererConfigAxe={this.gererAxe}
                                                   axisKey={"yaxis"}
                                                   axisName={checkMot("yaxis")}
                                                   preConfigAxe={{
                                                       ...this.state.yaxis,
                                                       type: "linear",//Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                                                   }}
                                                   disabled={this.props.affichageProfil === true}/>
                                </div>

                                <label className={[s.col_12].join(" ")}>
                                    <FormulaireAxe
                                        gererConfigAxe={this.gererAxe}
                                        axisKey={"yaxis2"}
                                        axisName={checkMot("yaxis2")}
                                        preConfigAxe={{
                                            ...this.state.yaxis2,
                                            type: "linear",//Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                                        }} disabled={this.props.affichageProfil === true}/>
                                </label>
                            </Collapse>
                            {parseInt(this.state.graph_type_id) === glossaire_type_de_graphe.graphe_x_y_t || parseInt(this.state.graph_type_id) === glossaire_type_de_graphe.graphe_x_y
                                ? <Collapse ExpandText={checkMot("parametre_gabarit")}>
                                    <FormulaireSelectionGabarits
                                        preconfigurerListTemplateId={this.state.list_template_id}
                                        gererListTemplateId={this.gererListTemplateId}
                                        desactiverModification={this.props.affichageProfil === true}/>
                                </Collapse>
                                : null
                            }
                            <Collapse ExpandText={checkMot("parametre_courbe")}>
                                {/*liste de formulaireCourbe*/}
                                {/*bouton pour ajouter une courbe*/}
                                {this.props.affichageProfil === true ? null : <Auxiliaire><Bouton cliquer={(e) => {
                                    e.preventDefault();
                                    this.ajouterCourbe();
                                }}><AiFillPlusCircle size={15}/> courbe</Bouton><br/></Auxiliaire>}
                                {this.state.listeFormulaireCourbe}
                            </Collapse>
                            {this.props.affichageProfil === true
                                ? undefined
                                : <div className={s.form_group}>
                                    <div className={[s.d_flex, s.justify_content_between].join(" ")}>
                                        <div>
                                            <Bouton cliquer={(e) => {
                                                e.preventDefault();
                                                this.props.annuler();
                                            }} type={type_btn.danger}>{checkMot("annuler")}</Bouton>
                                        </div>
                                        <div>
                                            {this.props !== undefined && this.props.preconfigurerGraphe === undefined
                                                ? <AttenteRequete request_type={requestType.creer_un_graphe}
                                                                  str={checkMot("creation_en_cour")}>
                                                    <Bouton ajoutClass={S.Bts}
                                                            cliquer={() => this.gererEnvoi()}><AiOutlineCheck/> {checkMot("creer")}
                                                    </Bouton>
                                                </AttenteRequete>
                                                : <AttenteRequete request_type={requestType.modifier_un_graphe}
                                                                  str={checkMot("modification_en_cour")}>
                                                    <Bouton cliquer={() => this.gererEnvoi()}
                                                            type={type_btn.succes}>{checkMot("enregistrer")}</Bouton>
                                                </AttenteRequete>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        </form>
                    </div>
                </div>
            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reqCreerGraphe: (formulaireGrapheT_X) => dispatch(actions.reqCreerGraphe(formulaireGrapheT_X)),
        reqModifierGraphe: (formulaireGrapheT_X) => dispatch(actions.reqModifierGraphe(formulaireGrapheT_X)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireGraphe);
