import React, {Component} from "react";
import BibliothequePlotly from "../BibliothequePlotly/BibliothequePlotly";
import {checkMot} from "../../../../ressources/functionJS/checkMot";
import {convertiUtsToDate} from "../GrapheXT/GrapheXT";

export const formatUniteArrayTable = (valeurs, format) => {
    let calculValeurs = [...valeurs];
    let res = []
    // boucle pour convertir chaque valeur dans le format en entrée
    for (let i = 0; i < calculValeurs.length; i++) {
        const totalSeconde = calculValeurs[i];
        let calculValeur = calculValeurs[i];
        const jours = parseInt(calculValeur / (24 * 60 * 60))
        calculValeur -= jours * (24 * 60 * 60)
        const heures = parseInt(calculValeur / (60 * 60))
        calculValeur -= heures * (60 * 60)
        const minutes = parseInt(calculValeur / 60)
        calculValeur -= minutes * (60)
        const seconde = parseInt(calculValeur)
        if (Number.isInteger(calculValeur)) {
            switch (format) { //simplifier avec un regex :
                case "jj:hh:mm:ss":
                    res.push([jours, heures, minutes, seconde].join(":"))
                    break;
                case "hh:mm:ss":
                    res.push([jours * 24 + heures, minutes, seconde].join(":"))
                    break;
                case "mm:ss":
                    res.push([(jours * 24 + heures) * 60 + minutes, seconde].join(":"))
                    break;
                case "jj":
                    res.push(jours)
                    break;
                case "hh":
                    res.push(jours * 24 + heures)
                    break;
                case "mm":
                    res.push((jours * 24 + heures) * 60 + minutes)
                    break;
                case "ss":
                    res.push(totalSeconde)
                    break;
                default:
                    res.push(totalSeconde)
                    break;
            }
        } else {
            res.push(totalSeconde)
        }
    }
    return res
}

class TableauDernieresValeursEtTotaux extends Component {
    state = {
        data: [],
        layout: {},
    }

    render() {
        let layout = {};
        let data = [];
        let headers_values = [];
        let headers_unit = [];
        let sensors_names = []
        let noms_responsables = []
        let dates_values = []
        let values = [];
        if (this.props.configuration !== undefined && this.props.donnees !== undefined) {
            layout = {
                font: {size: 14},
                title: {
                    text: this.props.configuration.title + '<br>' + this.props.configuration.subtitle,
                    font: {
                        // size: 25,
                        color: '#3c3935',
                    },
                },
                showlegend: true,
                legend: {
                    orientation: "h",
                    x: 0,
                    y: -0.0,
                },
                ...(this.props.viewobj_json_content === undefined || this.props.viewobj_json_content === null ? {} : this.props.viewobj_json_content.layout)

            };
            for (let curve of this.props.donnees) {
                let index_sensor_name = sensors_names.indexOf(curve.sensor_name) // index du nom du capteur sinon -1

                /*** si le nom de la courbe est aussi une colonne
                 let index_name = curve.name === "" ? -1 : headers_values.indexOf(curve.name) // index du nom de la "courbe" sinon -1
                 */
                let index_prefix = curve.prefix === "" ? -1 : headers_values.indexOf(curve.prefix)  // index du préfixe sinon -1
                let index_position_in_sensor = curve.position_in_sensor === "" ? -1 : headers_values.indexOf(curve.position_in_sensor)  // index du préfixe sinon -1

                const last_index = curve.y.length - 1
                const new_date = curve.x[last_index] // récupère la dernière date de la courbe
                if (index_sensor_name < 0) {
                    // ajoute le sensor name et la date si le sensorname n'est pas dans la liste
                    sensors_names.push(curve.sensor_name)
                    // ajoute le nom du responsable
                    noms_responsables.push(curve.name)
                    //ajoute la date
                    dates_values.push(new_date)

                    for (let ind_col = 0; ind_col < headers_values.length; ind_col++) values[ind_col].push(null);


                } else if (dates_values[index_sensor_name] < new_date) {
                    //modifie la dernière date si elle est plus récente
                    dates_values[index_sensor_name] = new_date
                }

                // cas où le sensor_name vient d'être ajouté à la liste
                index_sensor_name = index_sensor_name < 0 ? sensors_names.length - 1 : index_sensor_name
                /*** si le nom de la courbe est aussi une colonne
                 if (index_name < 0 && index_prefix < 0) {
                 */
                if (index_prefix < 0) {
                    //si le nom de la courbe et le préfixe n'existe pas
                    // ajoute une colonne de null
                    values.push(new Array(sensors_names.length).fill(null))


                    // positionne la valeur dans la ligne du sensor_name
                    values[values.length - 1][index_sensor_name] = curve.y[last_index]
                    /*** si le nom de la courbe est aussi une colonne
                     //ajoute l'entête de la colonne
                     if (curve.name !== "") {
                        // ajoute le nom de la courbe si elle n'est pas une chaîne vide
                        headers_values.push(curve.name)
                    } else */
                    if (curve.prefix !== "") {
                        // ajoute le préfixe si elle n'est pas une chaîne vide
                        headers_values.push(curve.prefix)
                    } else {
                        // ajoute la position de la composante sinon
                        headers_values.push(curve.position_in_sensor)
                    }
                    headers_unit.push(curve.engineering_unit)

                    /*** si le nom de la courbe est aussi une colonne
                     } else if (index_name > -1) {
                    // positionne la valeur dans la ligne du sensor_name si le nom de la courbe existe déjà dans l'entête
                    values[index_name][index_sensor_name] = curve.y[last_index]
                     */
                } else if (index_prefix > -1) {
                    // positionne la valeur dans la ligne du sensor_name si le nom du préfixe existe déjà dans l'entête
                    values[index_prefix][index_sensor_name] = curve.y[last_index]
                } else {
                    // positionne la valeur dans la ligne du sensor_name sinon en fonction de la index_position_in_sensor
                    values[index_position_in_sensor][index_sensor_name] = curve.y[last_index]
                }


            }

        }
        dates_values = convertiUtsToDate(dates_values)

        let headerColor = "#EFB805";
        data.push({
            type: 'table',
            columnwidth:[6,8,5,5],
            header: {
                values: [[checkMot("nom")], [checkMot("date")], ...headers_values.map((valeur, index) => valeur + " [" + headers_unit[index] + "]")].map(valeur => valeur),
                align: ["left", "center"],
                line: {width: 1, color: '#000000'},
                fill: {color: headerColor},
                font: {family: "Arial", size: 17, color: "white"}
            },
            cells: {
                values: [sensors_names, dates_values, ...values],
                align: ["left", "center"],
                line: {color: "#000000"},
                font: {family: "Arial", size: 17, color: ["#506784"]},
                height: 25
            }
        })

        return <BibliothequePlotly
            data={data}
            layout={{
                ...layout,
                responsive: true,
                ...this.props.viewobj_json_content
            }}
            donneesGraphe={this.props.donneesGraphe}
            ouvrirPopupModifierIntervalle={this.props.switchAfficherModifierIntervalle}
            dimensionWidth={this.props.dimensionWidth}
            dimensionHeight={this.props.dimensionHeight}
            viewobj_json_content={this.props.viewobj_json_content}
        />
    }


}

export default TableauDernieresValeursEtTotaux;
