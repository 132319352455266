import React, { useEffect, useRef } from 'react';
import { LinearGauge } from 'canvas-gauges';
import * as S from './LinearGauges.module.scss';

const LinearGauges = (props) => {
    const linearRef = useRef(null);

    useEffect(() => {
        const options2 = {
            ...props,
            renderTo: linearRef.current,
            units: props.value + " " + props.units,
            value: props.value,
            ...props.parametreAffichage,
        };

        const gauge = new LinearGauge(options2).draw();

        // Cette ligne est nécessaire si vous avez besoin d'accéder à gauge pour d'autres opérations. Sinon, elle peut être omise.
        // return () => gauge.destroy(); // Nettoyer en cas de démontage du composant
    }, [props.ratio, props.value, props.units, props.parametreAffichage]); // Dépendances pour re-déclencher useEffect

    return (
        <div className={S.widget_body}>
            <canvas className={S.text} ref={linearRef} />
        </div>
    );
};

export default LinearGauges;
