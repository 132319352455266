import React, {Component} from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import {connect} from "react-redux";
import {checkMot} from "../../../ressources/functionJS/checkMot";
import * as S from './FormulaireSelectionProjets.module.scss';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import {FaWindowClose} from "react-icons/fa";

class FormulaireSelectionProjets extends Component {

    state = {
        listeIdProjet: [],
        listeNameProjet: ''

    }

    componentDidMount() {
        if (this.props.preconfigurerProjectsListId !== undefined) {
            this.setState({...this.state, listeIdProjet: this.props.preconfigurerProjectsListId});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            this.props.gererProjetsList(this.state.listeIdProjet);
        }
    }

    gererListeIdProjet = (event) => {
        /**
         * ajoute la valeur de l'event sélectionné dans la liste déroulante
         */
        if (event.target.value !== "unselectable" && this.state.listeIdProjet.indexOf(parseInt(event.target.value)) < 0) {// restreint la sélection par défaut et la redondance
            let newListeIdProjet = [...this.state.listeIdProjet];

            newListeIdProjet.push(parseInt(event.target.value));
            this.setState({...this.state, listeIdProjet: newListeIdProjet});
        } else {


            let newListeIdProjet = [...this.state.listeIdProjet];
            const index = this.state.listeIdProjet.indexOf(parseInt(event.target.value));
            if (index > -1) {
                newListeIdProjet.splice(index, 1);
            }
            this.setState({...this.state, listeIdProjet: newListeIdProjet});
        }
    }
    gererListeIdProjetLigne = () => {
        // if (event.target.value !== "unselectable" && this.state.listeIdProjet.indexOf(parseInt(event.target.value)) < 0) {// restreint la sélection par défaut et la redondance
        //     let newListeIdProjet = [...this.state.listeIdProjet];
        //
        //     newListeIdProjet.push(parseInt(event.target.value));
        //     this.setState({...this.state, listeIdProjet: newListeIdProjet});
        // } else {
        //
        //
        //     let newListeIdProjet = [...this.state.listeIdProjet];
        //     const index = this.state.listeIdProjet.indexOf(parseInt(event.target.value));
        //     if (index > -1) {
        //         newListeIdProjet.splice(index, 1);
        //     }
        //     this.setState({...this.state, listeIdProjet: newListeIdProjet});
        // }
    }
    enleverProjet = (indInArray) => {
        /** via l'id du projet enlève le projet de la liste des projets sélectionnés
         * @idNameListe : [id, name]
         * */
            // enlève l'id du projet de la liste des id projet pour l'envoie
        let newListeIdProjet = [...this.state.listeIdProjet];
        newListeIdProjet.splice(indInArray, 1);
        //mise à jour de l'état de la liste d'ids à envoyer et de la liste d'affichage lors de la suppression d'un projet
        this.setState({...this.state, listeIdProjet: newListeIdProjet});
    }

    render() {

        // récupérer liste des projets avec this.props.listeProjets
        // créer un formulaire pour ajouter des projets sous forme de 1)liste déroulante
        const listeDeroulanteProjetCheck = <Auxiliaire>
            {/* <label className={S.text} htmlFor="inlineFormCustomSelect">{checkMot("liste_projets")}</label> */}

            <div className={S.Dib}>
                <TextField
                    className={S.search}
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    label={checkMot("nom_du_project")}
                    onChange={(event) => this.setState({...this.state, listeNameProjet: event.target.value})}
                />

                <table className={S.search}>
                    <thead>
                        <tr>
                                <th/>
                                <th>{checkMot("id")}</th>
                                <th>{checkMot("project_name")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {

                        Object.keys(this.props.listeProjets).map(indListeProjets => {
                            if ('' === this.state.listeNameProjet || this.props.listeProjets[indListeProjets].project_name.toLowerCase().indexOf(this.state.listeNameProjet.toLowerCase()) > -1) {
                                return <tr onClick={() => this.gererListeIdProjetLigne()}>
                                    <td style={{padding: "0"}} >
                                        <Checkbox type="checkbox"
                                                  checked={this.state.listeIdProjet.indexOf(this.props.listeProjets[indListeProjets].project_id) > -1}
                                                  value={this.props.listeProjets[indListeProjets].project_id}
                                                  onChange={(event) => this.gererListeIdProjet(event)}/>
                                    </td>
                                    <td>
                                        {this.props.listeProjets[indListeProjets].project_id}
                                    </td>
                                    <td>
                                        {this.props.listeProjets[indListeProjets].project_name}
                                    </td>
                                </tr>


                            }

                        })}

                    </tbody>
                </table>
            </div>
        </Auxiliaire>;


        // todo: 2) sous forme d'une arborescence avec checkbox

        // récupération des informations des projets sélectionnés
        let information_projet_liste = [];
        if (this.props.listeProjets !== undefined && this.state.listeIdProjet !== []) {
            for (let i = 0; i < this.props.listeProjets.length; i++) {
                for (let j = 0; j < this.state.listeIdProjet.length; j++) {
                    if (this.state.listeIdProjet[j] === this.props.listeProjets[i].project_id) {
                        information_projet_liste.push(this.props.listeProjets[i])
                    }
                }
            }
        }


        return (
            <Auxiliaire>
                <div className={s.table_responsive}>
                    <table className={s.table}>
                        <thead>
                        <tr>
                            <th scope="col">{checkMot("liste_des_projets")}</th>
                            <th scope="col">{checkMot("liste_des_projets_selectionner")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{listeDeroulanteProjetCheck}</td>
                            <td> {Object.keys(information_projet_liste).map(indListeProjets => {
                                return <div className={S.labell} key={indListeProjets}
                                            onClick={() => this.enleverProjet(indListeProjets)}
                                            style={{cursor: "pointer"}}><span className={S.iconn}><FaWindowClose
                                    size={20}/></span> {information_projet_liste[indListeProjets].project_id + ", " + information_projet_liste[indListeProjets].project_name}
                                </div>
                            })}</td>

                        </tr>


                        </tbody>
                    </table>
                </div>
                {/* <div className={s.row}> */}
                {/* <div> {listeDeroulanteProjet}</div> */}
                {/* <div className={s.col_6}>{listeDeroulanteProjetCheck}</div> */}

                {/* affiche les projets sélectionnés avec la possibilité de les retirer individuellement avec une croix*/}
                {/* <div className={s.col_6}> {Object.keys(information_projet_liste).map(indListeProjets=>{ */}
                {/* return <div key={indListeProjets}><span onClick={()=>this.enleverProjet(indListeProjets)}>{iconeEnlever}</span> {information_projet_liste[indListeProjets].project_id+", "+information_projet_liste[indListeProjets].project_name}</div> */}
                {/* })}</div> */}
                {/* </div> */}
            </Auxiliaire>
        );
    }
}

const mapStateToProps = state => {
    return {
        listeProjets: state.authentifie.projects_list
    };
}

export default connect(mapStateToProps)(FormulaireSelectionProjets);
